import * as React from "react";
import { useParams } from "react-router-dom";
import { IWorkTimeDetailsPage } from "./workTimeBetaDetailsPageMain";
import { useAppDispatch } from "../../framework/customStore";
import { useSelectWorkShiftTimeSlotsByWorkShiftDate } from "../../hooks/workTime/useSelectWorkShiftTimeSlots";
import { WorkTimeDetailsMap } from "./workTimeDetailsMap";
import { WorkTimeDetailsLayout } from "../layout/workTimeDetailsLayout";
import { useAsyncThunkAction } from "../../hooks/useAsyncThunkAction";
import { handleApiError } from "../../models/store/storeEffects";
import { fetchWorkShifts } from "../../store/workShiftSlice.";

export function WorkTimeFullScreenMap() {
    const { employeeId, date } = useParams<IWorkTimeDetailsPage>();
    const dispatch = useAppDispatch();

    const workShiftTimeSlots = useSelectWorkShiftTimeSlotsByWorkShiftDate(
        employeeId,
        date,
        date
    );

    useAsyncThunkAction(
        () => {
            if (date && employeeId) {
                return fetchWorkShifts({
                    employeeIds: employeeId,
                    startDate: date,
                    endDate: date,
                });
            }
        },
        {
            onError: (e) => handleApiError(e, dispatch),
        },
        [employeeId, date]
    );

    return (
        <WorkTimeDetailsLayout
            maximized={
                <WorkTimeDetailsMap
                    workShiftTimeSlots={workShiftTimeSlots}
                    isMaximized={true}
                />
            }
        />
    );
}
