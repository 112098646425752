import * as React from "react";
import { Translations } from "../../models/translations";
import { Base } from "../../framework/base";
import * as workOrderService from "../../services/workOrderService";
import { IWorkOrderItem, WorkOrderItem } from "../../models/work/workOrderItem";
import { IWorkOrderCalendarLineItem } from "../../models/work/workOrderCalendarLineItem";
import { CalendarAccuracyType, TimeFormat, WorkOrderCategory } from "../../models/common/enums";
import { WorkMainCalendar, WorkMainCalendarLegend } from "./workMainCalendar";
import { WorkOrderCalendar } from "../../models/work/workOrderCalendar";
import { IWorkOrderEdit } from "../../models/work/workOrderEdit";
import { WorkOrderSearchWeekDialog } from "./workOrderSearchWeekDialog";
import { ICalendarDayItem } from "../../models/calendarDay/calendarDayItem";
import { IDayBookingItem } from "../../models/dayBooking/dayBookingItem";
import { SettingsEmployeeDialog } from "../settings/settingsEmployeeDialog";
import { IEmployeeEdit } from "../../models/employee/employeeEdit";
import { INumberTitle } from "../../models/common/numberTitle";
import { WorkOrderCalendarItemDetailsView } from "./workOrderCalendarItemDetailsView";
import { EmployeeParametersHelper, IEmployeeParameters } from "../../models/employee/employeeIParameters";
import { employeeParameterWorkOrderCalendarAccuracy, employeeParameterWorkOrderCalendarCategoryExpanded, employeeParameterWorkOrderCalendarSelectedId } from "../../models/common/consts";
import { WorkOrderOperations } from "../../models/work/workOrderOperations";
import { InlineDatePicker } from "../framework/datePicker";
import { ICustomerEdit } from "../../models/customer/customerEdit";
import { IContactEdit } from "../../models/contact/contactEdit";
import { SettingsCustomerDialog } from "../settings/settingsCustomerDialog";
import { SettingsContactDialog } from "../settings/settingsContactDialog";
import { WorkMainMap } from "./workMainMap";
import { IVehicleEdit } from "../../models/vehicle/vehicleEdit";
import { SettingsVehicleDialog } from "../settings/settingsVehicleDialog";
import { IVehicleItem } from "../../models/vehicle/vehicleItem";
import { IOwnerState, IUserState } from "../../models/store/storeTypes";
import { WorkMainList } from "./workMainList";
import { WorkMainWorkListFavorites } from "./workMainWorkListFavorites";
import { WorkMainWorkListFilter } from "./workMainWorkListFilter";
import { EmployeeOperations } from "../../models/employee/employeeOperations";
import { CustomerOperations } from "../../models/customer/customerOperations";
import { VehicleOperations } from "../../models/vehicle/vehicleOperations";
import { ContactOperations } from "../../models/contact/contactOperations";
import { WorkOrderCopyDialog } from "./workOrderCopyDialog";
import { IVehicleGroupItem } from "../../models/vehicleGroup/vehicleGroupItem";
import { IEmployeeGroupItem } from "../../models/employeeGroup/employeeGroupItem";
import { WorkOrderDialogConnected } from "./workOrderDialog";
import { SelectWorkOrderTemplateDialog } from "./selectWorkOrderTemplateDialog";
import { AppUtils } from "../../models/common/appUtils";
import { OwnerParameters } from "../../models/owner/ownerParameters";
import { WorkMainPortlet } from "./workMainPortlet";

/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-unused-vars */
export const enum WorkMainViewMode {
    Calendar = "1",
    List = "2",
    Map = "3",
}
/* eslint-enable no-unused-vars */

// WorkMain
export interface IWorkMainOwnProps {
}

export interface IWorkMainStateProps {
    owner: IOwnerState;
    user: IUserState;
    dataId: number;
    startDate: number;
    endDate: number;
    workTimeFormat: TimeFormat;
    workOrders: IWorkOrderItem[];
    workOrderTemplates: IWorkOrderItem[];
    favoriteWorkOrders: IWorkOrderItem[];
    showOnMapVehicles: IVehicleItem[];
    calendarLines: IWorkOrderCalendarLineItem[];
    calendarDays: ICalendarDayItem[];
    dayBookings: IDayBookingItem[];
    dayBookingTypes: INumberTitle[];
    workOrderStates: INumberTitle[];
    workOrderClasses: INumberTitle[];
    vehicleGroups: IVehicleGroupItem[];
    employeeGroups: IEmployeeGroupItem[];
}

export interface IWorkMainDispatchProps {
    loadWorkOrderMain: () => void;
    loadWorkOrderItems: (startDate: number, endDate: number, calendarAccuracy: CalendarAccuracyType) => void;
    loadWorkOrderTemplates: () => void;
    setEmployeeParameters: (parameters: IEmployeeParameters, saveToDb: boolean) => void;
    onLogout: () => void;
}

type WorkMainProp = IWorkMainOwnProps & IWorkMainStateProps & IWorkMainDispatchProps;

interface IWorkMainState {
    viewMode: WorkMainViewMode;

    favoriteWorkOrders: IWorkOrderItem[];
    filteredWorkOrders: IWorkOrderItem[];
    showOnMapVehicles: IVehicleItem[];
    selectedMapWorkOrderIds: string[];
    mapDataId: number;
    mapAutoFitToBoundsId: number;
    filter: string;
    filterTime: number;
    filterStates: number[];
    filterClasses: number[];
    filterUsingFavorites: boolean;
    filterShowFiltered: boolean;

    checkedListWorkOrderIds: string[];

    selectedId: string;
    showWorkOrderEditDialog: boolean;
    workOrderEditDialogMakeProductBookingsOnOpen: boolean;
    showWorkOrderSearchWeekDialog: boolean;
    showCopyWorkOrderDialog: boolean;
    showEmployeeDialog: boolean;
    showVehicleDialog: boolean;
    showCustomerDialog: boolean;
    showContactDialog: boolean;
    workOrderEdit: IWorkOrderEdit;
    workOrderDetails: IWorkOrderEdit;
    detailsLoading: boolean;
    copyWorkOrderItem: IWorkOrderItem;
    employeeEdit: IEmployeeEdit;
    vehicleEdit: IVehicleEdit;
    customerEdit: ICustomerEdit;
    contactEditItem: IContactEdit;
    calendar: WorkOrderCalendar;
    activeTimeStr: string;
    activeTime: number;
    showSelectWorkOrderTemplateDialog: boolean;
    selectWorkOrderTemplateDialogWorkOrders: IWorkOrderItem[];

    startDateStr: string;
    startDate: number;
    endDate: number;
}

export class WorkMain extends React.Component<WorkMainProp, IWorkMainState> {
    lastCreatedWorkOrderId: string = "";

    // #region RefreshTimer
    resetViewRefreshTimer = () => {
        const obj = this;
        Base.resetViewRefreshTimer(() => {
            const elements = document.getElementsByClassName("mainContent workMain");
            if (Base.isNullOrUndefined(elements) || elements.length < 1) {
                Base.clearViewRefreshTimer();
                return;
            }
            obj.refreshViewDefault();
        });
    };
    // #endregion RefreshTimer

    initializeCollapsedCategories = (employeeParameters: IEmployeeParameters, calendar: WorkOrderCalendar) => {
        //Collapse categories based on user settings
        for (const category of calendar.categories) {
            const value = employeeParameters[employeeParameterWorkOrderCalendarCategoryExpanded + category];
            if (!value || value === "1") continue;
            calendar.collapseCategory(category, true);
        }
        //Expand last created work order category
        if (!this.lastCreatedWorkOrderId) return;
        const categories = calendar.expandCategoriesByWorkOrderId(this.lastCreatedWorkOrderId);
        this.lastCreatedWorkOrderId = "";
        if (categories.length < 1) return;
        //Save setting to db
        const props = this.props;
        let newEmployeeParameters = employeeParameters;
        for (const category of categories) {
            newEmployeeParameters = EmployeeParametersHelper.setBoolParameter(newEmployeeParameters, employeeParameterWorkOrderCalendarCategoryExpanded + category, true);
        }
        props.setEmployeeParameters(newEmployeeParameters, true);
    };

    constructor(props: WorkMainProp) {
        super(props);
        const employeeParameters = props.user.parameters;
        const selectedId = EmployeeParametersHelper.getParameter(employeeParameters, employeeParameterWorkOrderCalendarSelectedId);
        let activeTime = Base.getNowDate().getTime();
        if (props.startDate && activeTime < props.startDate) {
            activeTime = props.startDate;
        }
        if (props.endDate && activeTime >= props.endDate) {
            activeTime = new Date(props.endDate).addDays(-1).getTime();
        }
        const filteredWorkOrders = props.workOrders.slice(0);
        const filterShowFiltered = true;
        const calendar = WorkOrderCalendar.createWorkOrderCalendar(EmployeeParametersHelper.getCalendarAccuracy(employeeParameters),
            EmployeeParametersHelper.getCalendarStartOfficeTime(employeeParameters), EmployeeParametersHelper.getCalendarEndOfficeTime(employeeParameters),
            activeTime, Math.max(activeTime, props.endDate), props.calendarLines, props.workOrders, props.calendarDays, props.dayBookings, props.vehicleGroups, props.employeeGroups,
            filteredWorkOrders, employeeParameters, filterShowFiltered);
        this.initializeCollapsedCategories(employeeParameters, calendar);
        this.state = {
            viewMode: WorkMainViewMode.Calendar,
            calendar: calendar,
            favoriteWorkOrders: props.favoriteWorkOrders.slice(0),
            filteredWorkOrders: filteredWorkOrders,
            showOnMapVehicles: props.showOnMapVehicles.slice(0),
            mapDataId: props.dataId,
            mapAutoFitToBoundsId: 0,
            activeTimeStr: Base.timeToDateStr(activeTime),
            activeTime: activeTime,
            startDateStr: Base.timeToDateStr(props.startDate),
            startDate: props.startDate,
            endDate: props.endDate,
            selectedId: selectedId,
            selectedMapWorkOrderIds: selectedId ? [selectedId] : [],
            filter: "",
            filterTime: 0,
            filterStates: [],
            filterClasses: [],
            filterUsingFavorites: false,
            filterShowFiltered: filterShowFiltered,
            //filterMode: EmployeeParametersHelper.getMapFilterMode(props.employeeParameters),
            checkedListWorkOrderIds: [],
            showWorkOrderEditDialog: false,
            workOrderEditDialogMakeProductBookingsOnOpen: false,
            workOrderEdit: null,
            workOrderDetails: null,
            showCopyWorkOrderDialog: false,
            detailsLoading: false,
            showWorkOrderSearchWeekDialog: false,
            showEmployeeDialog: false,
            showVehicleDialog: false,
            showCustomerDialog: false,
            showContactDialog: false,
            copyWorkOrderItem: null,
            employeeEdit: null,
            vehicleEdit: null,
            customerEdit: null,
            contactEditItem: null,
            showSelectWorkOrderTemplateDialog: false,
            selectWorkOrderTemplateDialogWorkOrders: []
        };
    }

    refreshView = (startDate: number, endDate: number, newSelectedId: string = null) => {
        const state = this.state;
        this.resetViewRefreshTimer();
        this.props.loadWorkOrderItems(startDate, endDate, state.calendar.calendarAccuracy);
        if (!newSelectedId) {
            this.refreshWorkOrderDetails(state.selectedId);
        } else {
            this.setSelectedWorkOrder(newSelectedId, true);
        }
    };

    refreshViewDefault = (newSelectedId: string = null) => {
        this.refreshView(this.props.startDate, this.state.endDate, newSelectedId);
    };

    getFilteredWorkOrders = (workOrders: IWorkOrderItem[], filter: string, filterTime: number, filterStates: number[], filterClasses: number[], filterUsingFavorites: boolean): IWorkOrderItem[] => {
        const state = this.state;
        let result = workOrders.slice(0);
        if (filterUsingFavorites) {
            const favoriteWorkOrderIds = state.favoriteWorkOrders.map(i => i.id);
            result = result.filter(i => favoriteWorkOrderIds.indexOf(i.id) > -1);
        }
        if (filterStates.length > 0) {
            result = result.filter(i => filterStates.indexOf(i.state) > -1);
        }
        if (filterClasses.length > 0) {
            result = result.filter(i => filterClasses.indexOf(i.workOrderClass) > -1);
        }
        if (filter) {
            const lowFilter = filter.toLowerCase();
            const numFilter = Base.isIntStr(filter) ? filter.toInteger() : -1;
            result = result.filter(i => i.number === numFilter || i.name && i.name.toLowerCase().indexOf(lowFilter) >= 0 || i.customerName && i.customerName.toLowerCase().indexOf(lowFilter) >= 0 || i.siteName && i.siteName.toLowerCase().indexOf(lowFilter) >= 0);
        }
        if (filterTime && state.activeTime) {
            const activeTimeEnd = new Date(state.activeTime).addDays(1).getTime();
            result = result.filter(i => !i.startTime || !i.endTime || Base.isOverlapping(state.activeTime, activeTimeEnd, i.startTime, i.endTime));
        }
        return result;
    };

    componentDidMount(): void {
        this.resetViewRefreshTimer();
        this.props.loadWorkOrderMain();
    }

    componentWillUnmount(): void {
        Base.clearViewRefreshTimer();
    }

    componentDidUpdate(prevProps: WorkMainProp, prevState: IWorkMainState): void {
        const props = this.props;
        const state = this.state;

        // if (!props.user.authenticated) return;//TODO autentication should check different way - this check make only proplems.
        //Check for map parameters change
        //const prevFavoriteWorkOrderIds = EmployeeParametersHelper.getFavoriteWorkOrderIds(prevProps.employeeParameters);
        //const favoriteWorkOrderIds = EmployeeParametersHelper.getFavoriteWorkOrderIds(props.employeeParameters);
        //const prevSearchFilterInUse = EmployeeParametersHelper.getMapFilterMode(prevProps.employeeParameters);
        //const searchFilterInUse = EmployeeParametersHelper.getMapFilterMode(props.employeeParameters);
        const employeeParameters = props.user.parameters;
        const calendarAccuracy = EmployeeParametersHelper.getCalendarAccuracy(employeeParameters);
        const officeStartTimeMin = EmployeeParametersHelper.getCalendarStartOfficeTime(employeeParameters);
        const officeEndTimeMin = EmployeeParametersHelper.getCalendarEndOfficeTime(employeeParameters);
        //Check for calendar/map parameters change when data has not been changed
        if (Base.isEqualInteger(prevProps.dataId, props.dataId)) {
            if (props.dataId > 0) {
                const prevEmployeeParameters = prevProps.user.parameters;
                const prevCalendarAccuracy = EmployeeParametersHelper.getCalendarAccuracy(prevEmployeeParameters);
                const prevOfficeStartTimeMin = EmployeeParametersHelper.getCalendarStartOfficeTime(prevEmployeeParameters);
                const prevOfficeEndTimeMin = EmployeeParametersHelper.getCalendarEndOfficeTime(prevEmployeeParameters);
                const showOnDesignVehicleGroupIdsHash = EmployeeParametersHelper.getShowOnDesignVehicleGroupIds(employeeParameters).join("#");
                const prevShowOnDesignVehicleGroupIdsHash = EmployeeParametersHelper.getShowOnDesignVehicleGroupIds(prevEmployeeParameters).join("#");
                const showOnDesignEmployeeGroupIdsHash = EmployeeParametersHelper.getShowOnDesignEmployeeGroupIds(employeeParameters).join("#");
                const prevShowOnDesignEmployeeGroupIdsHash = EmployeeParametersHelper.getShowOnDesignEmployeeGroupIds(prevEmployeeParameters).join("#");
                if (prevCalendarAccuracy !== calendarAccuracy && state.calendar.calendarAccuracy !== calendarAccuracy || prevOfficeStartTimeMin !== officeStartTimeMin || prevOfficeEndTimeMin !== officeEndTimeMin ||
                    prevShowOnDesignVehicleGroupIdsHash !== showOnDesignVehicleGroupIdsHash || prevShowOnDesignEmployeeGroupIdsHash !== showOnDesignEmployeeGroupIdsHash) {
                    //console.log("calendar Parameters Changed");
                    const calendar = WorkOrderCalendar.createWorkOrderCalendar(calendarAccuracy, officeStartTimeMin, officeEndTimeMin, state.activeTime, props.endDate, props.calendarLines,
                        props.workOrders, props.calendarDays, props.dayBookings, props.vehicleGroups, props.employeeGroups, state.filteredWorkOrders,
                        employeeParameters, state.filterShowFiltered);
                    this.initializeCollapsedCategories(employeeParameters, calendar);
                    this.setState({
                        calendar: calendar
                    });
                }
                const prevShowOnMapVehicleIds = EmployeeParametersHelper.getShowOnMapVehicleIdsSpecified(prevEmployeeParameters) ? EmployeeParametersHelper.getShowOnMapVehicleIds(prevEmployeeParameters) : [];
                const showOnMapVehicleIds = EmployeeParametersHelper.getShowOnMapVehicleIdsSpecified(employeeParameters) ? EmployeeParametersHelper.getShowOnMapVehicleIds(employeeParameters) : [];
                if (prevShowOnMapVehicleIds.join("#") !== showOnMapVehicleIds.join("#")) {
                    this.refreshViewDefault();
                }
            }
            return;
        }
        /*console.log("calendar data changed");*/
        const activeTime = Math.min(Math.max(state.activeTime, props.startDate), new Date(props.endDate).addDays(-1).getTime());
        const filteredWorkOrders = this.getFilteredWorkOrders(props.workOrders, state.filter, state.filterTime, state.filterStates, state.filterClasses, state.filterUsingFavorites);
        const calendar = WorkOrderCalendar.createWorkOrderCalendar(calendarAccuracy, officeStartTimeMin, officeEndTimeMin, activeTime, props.endDate, props.calendarLines,
            props.workOrders, props.calendarDays, props.dayBookings, props.vehicleGroups, props.employeeGroups, filteredWorkOrders, employeeParameters, state.filterShowFiltered);
        this.initializeCollapsedCategories(employeeParameters, calendar);
        const mapDataId = state.mapDataId;
        const selectedId = EmployeeParametersHelper.getParameter(employeeParameters, employeeParameterWorkOrderCalendarSelectedId);
        const workOrderDetails = state.workOrderDetails;
        this.setState({
            activeTimeStr: Base.timeToDateStr(activeTime),
            activeTime: activeTime,
            startDateStr: Base.timeToDateStr(props.startDate),
            startDate: props.startDate,
            endDate: props.endDate,
            calendar: calendar,
            favoriteWorkOrders: props.favoriteWorkOrders,
            filteredWorkOrders: filteredWorkOrders,
            showOnMapVehicles: props.showOnMapVehicles,
            mapDataId: mapDataId === props.dataId ? mapDataId + 1 : props.dataId,
            //filterMode: EmployeeParametersHelper.getMapFilterMode(props.employeeParameters),
            selectedId: selectedId,
            selectedMapWorkOrderIds: selectedId ? [selectedId] : [],
            workOrderDetails: selectedId ? workOrderDetails : null
        });
        //Refresh work order details
        if (selectedId && (!state.workOrderDetails || state.workOrderDetails && state.workOrderDetails.workOrder.id !== selectedId)) {
            this.refreshWorkOrderDetails(selectedId);
        }
        //scroll selected work order to view and set details
        if (selectedId) {
            const obj = this;
            window.setTimeout(() => {
                const element = document.querySelector('[data-id="' + selectedId + '"]');
                if (!element) return;
                const background = document.getElementById("employeeBackgroundGrid");
                if (!background) return;
                background.scroll({ top: element.offsetTop, behavior: "smooth" });
                if (!state.workOrderDetails || !state.workOrderDetails.workOrder || state.workOrderDetails.workOrder.id !== selectedId) {
                    obj.refreshWorkOrderDetails(selectedId);
                }
            }, 25);
        }
    }

    refreshWorkOrderDetails = (id: string) => {
        const obj = this;
        if (!id) return;
        this.setState({
            detailsLoading: true
        });
        workOrderService.getWorkOrderEdit(id, null, null, null)
            .then(workOrderEdit => {
                obj.setState({
                    workOrderDetails: workOrderEdit
                });
            })
            .catch(() => {
                obj.setState({
                    workOrderDetails: null
                });
            })
            .finally(() => {
                this.setState({
                    detailsLoading: false
                });
            });
    };

    setSelectedWorkOrder = (id: string, setSelectedMapWorkOrderIds: boolean) => {
        const props = this.props;
        if (!id) return;
        if (!setSelectedMapWorkOrderIds) {
            this.setState({
                selectedId: id
            });
        } else {
            this.setState({
                selectedId: id,
                selectedMapWorkOrderIds: [id]
            });
        }
        this.refreshWorkOrderDetails(id);
        props.setEmployeeParameters(EmployeeParametersHelper.setParameter(props.user.parameters, employeeParameterWorkOrderCalendarSelectedId, id), true);
    };

    handleWorkOrderItemClick = (id: string) => {
        this.setSelectedWorkOrder(id, true);
    };

    openWorkOrderEditDialog = (workOrderEdit: IWorkOrderEdit, state: number, oldEmployeeId: string, newEmployeeId: string, oldVehicleId: string, newVehicleId: string, startTime: number,
        makeProductBookingsOnOpen: boolean) => {
        if (newEmployeeId) {
            const employeeIds = workOrderEdit.workOrder.employeeIds.filter(i => i !== oldEmployeeId);
            employeeIds.push(newEmployeeId);
            workOrderEdit.initStateEmployeeIds = employeeIds;
        }
        if (newVehicleId) {
            const vehicleIds = workOrderEdit.workOrder.vehicleIds.filter(i => i !== oldVehicleId);
            vehicleIds.push(newVehicleId);
            workOrderEdit.initStateVehicleIds = vehicleIds;
        }
        workOrderEdit.initStateState = state;
        workOrderEdit.initStateStartTime = startTime;
        this.setState({
            showWorkOrderEditDialog: true,
            workOrderEditDialogMakeProductBookingsOnOpen: makeProductBookingsOnOpen,
            workOrderEdit: workOrderEdit,
            workOrderDetails: workOrderEdit,
        });
    };

    editWorkOrderItem = (id: string, sourceId: string, category: number, parentId: string, state: number, oldEmployeeId: string, newEmployeeId: string, oldVehicleId: string, newVehicleId: string, startTime: number,
        makeProductBookingsOnOpen: boolean) => {
        const obj = this;
        if (!id) return;
        WorkOrderOperations.getWorkOrderEdit(id, sourceId, category, parentId)
            .then(workOrderEdit => {
                obj.openWorkOrderEditDialog(workOrderEdit, state, oldEmployeeId, newEmployeeId, oldVehicleId, newVehicleId, startTime, makeProductBookingsOnOpen);
            });
    };

    handleMoveWorkOrderToStartTime = async(id: string, startTime: number) => {
        if (OwnerParameters.workOrderOpenDialogAfterDragAndDrop()) {
            this.editWorkOrderItem(id, null, null, null, null, null, null, null, null, startTime, false);
            this.setSelectedWorkOrder(id, true);
        } else {
            const result = await AppUtils.callService(() => workOrderService.setWorkOrderStartTime(id, startTime), false);
            if (!result) return;
            this.refreshViewDefault(id);
        }
    };

    handleMoveWorkOrderToEmployee = async(id: string, oldEmployeeId: string, newEmployeeId: string, startTime: number) => {
        if (OwnerParameters.workOrderOpenDialogAfterDragAndDrop()) {
            this.editWorkOrderItem(id, null, null, null, null, oldEmployeeId, newEmployeeId, null, null, startTime, false);
            this.setSelectedWorkOrder(id, true);
        } else {
            const result = await AppUtils.callService(() => workOrderService.setWorkOrderEmployee(id, newEmployeeId, oldEmployeeId, startTime), false);
            if (!result) return;
            this.refreshViewDefault(id);
        }
    };

    handleMoveWorkOrderToVehicle = async(id: string, oldVehicleId: string, newVehicleId: string, startTime: number) => {
        if (OwnerParameters.workOrderOpenDialogAfterDragAndDrop()) {
            this.editWorkOrderItem(id, null, null, null, null, null, null, oldVehicleId, newVehicleId, startTime, false);
            this.setSelectedWorkOrder(id, true);
        } else {
            const result = await AppUtils.callService(() => workOrderService.setWorkOrderVehicle(id, newVehicleId, oldVehicleId, startTime), false);
            if (!result) return;
            this.refreshViewDefault(id);
        }
    };

    handleAddEmployeeToWorkOrder = async(id: string, employeeId: string) => {
        if (OwnerParameters.workOrderOpenDialogAfterDragAndDrop()) {
            this.editWorkOrderItem(id, null, null, null, null, employeeId, employeeId, null, null, null, false);
        } else {
            const result = await AppUtils.callService(() => workOrderService.setWorkOrderEmployee(id, employeeId, employeeId, null), false);
            if (!result) return;
            this.refreshViewDefault(id);
        }
    };

    handleAddVehicleToWorkOrder = async(id: string, vehicleId: string) => {
        if (OwnerParameters.workOrderOpenDialogAfterDragAndDrop()) {
            this.editWorkOrderItem(id, null, null, null, null, null, null, vehicleId, vehicleId, null, false);
        } else {
            const result = await AppUtils.callService(() => workOrderService.setWorkOrderVehicle(id, vehicleId, vehicleId, null), false);
            if (!result) return;
            this.refreshViewDefault(id);
        }
    };

    handleMoveProject = async(id: string, startTime: number) => {
        const result = await AppUtils.callService(() => workOrderService.moveProjectWorkOrder(id, startTime), false);
        if (!result) return;
        this.refreshViewDefault(id);
    };

    handleExpandCollapseCalendarLine = (lineId: string, collapse: boolean) => {
        const calendar = new WorkOrderCalendar(this.state.calendar);
        calendar.collapseLine(lineId, collapse);
        this.setState({
            calendar: calendar
        });
    };

    handleExpandCollapseCalendarCategory = (category: string, collapse: boolean) => {
        const calendar = new WorkOrderCalendar(this.state.calendar);
        //console.log("before", category, collapse, calendar.lines);
        calendar.collapseCategory(category, collapse);
        /*console.log("after", category, collapse, calendar.lines);*/
        this.setState({
            calendar: calendar
        });
        //Save setting to db
        const props = this.props;
        props.setEmployeeParameters(EmployeeParametersHelper.setBoolParameter(props.user.parameters, employeeParameterWorkOrderCalendarCategoryExpanded + category, !collapse), true);
    };

    handleChangeCalendarAccuracy = (calendarAccuracy: CalendarAccuracyType) => {
        const props = this.props;
        const state = this.state;
        const employeeParameters = EmployeeParametersHelper.setParameter(props.user.parameters, employeeParameterWorkOrderCalendarAccuracy, calendarAccuracy);
        const officeStartTimeMin = EmployeeParametersHelper.getCalendarStartOfficeTime(employeeParameters);
        const officeEndTimeMin = EmployeeParametersHelper.getCalendarEndOfficeTime(employeeParameters);
        const calendar = WorkOrderCalendar.createWorkOrderCalendar(calendarAccuracy, officeStartTimeMin, officeEndTimeMin, state.activeTime, props.endDate, props.calendarLines, props.workOrders, props.calendarDays, props.dayBookings,
            props.vehicleGroups, props.employeeGroups, state.filteredWorkOrders, employeeParameters, state.filterShowFiltered);
        this.initializeCollapsedCategories(employeeParameters, calendar);
        this.setState({
            //endDate: calendar.endDate,
            calendar: calendar
        });
        //Save setting to db
        props.setEmployeeParameters(employeeParameters, true);
    };

    handleChangeViewMode = (viewMode: WorkMainViewMode) => {
        const state = this.state;
        this.setState({
            viewMode: viewMode,
            mapAutoFitToBoundsId: viewMode === WorkMainViewMode.Map ? state.mapAutoFitToBoundsId + 1 : state.mapAutoFitToBoundsId
        });
    };

    handleEditWorkOrderItem = (id: string) => {
        const props = this.props;
        this.setState({ selectedId: id });
        this.editWorkOrderItem(id, null, null, null, null, null, null, null, null, null, false);
        props.setEmployeeParameters(EmployeeParametersHelper.setParameter(props.user.parameters, employeeParameterWorkOrderCalendarSelectedId, id), true);
    };

    goToDate = (date: Date) => {
        const props = this.props;
        const state = this.state;
        const activeTime = date.getTime();
        const startDate = Base.getFirstDayOfWeek(date).addDays(-7);
        if (startDate.getTime() === props.startDate) {
            const employeeParameters = props.user.parameters;
            const calendar = WorkOrderCalendar.createWorkOrderCalendar(EmployeeParametersHelper.getCalendarAccuracy(employeeParameters),
                EmployeeParametersHelper.getCalendarStartOfficeTime(employeeParameters), EmployeeParametersHelper.getCalendarEndOfficeTime(employeeParameters),
                activeTime, props.endDate, props.calendarLines, props.workOrders, props.calendarDays, props.dayBookings, props.vehicleGroups, props.employeeGroups,
                state.filteredWorkOrders, employeeParameters, state.filterShowFiltered);
            this.initializeCollapsedCategories(employeeParameters, calendar);
            this.setState({
                calendar: calendar,
                activeTime: activeTime,
                activeTimeStr: Base.timeToDateStr(activeTime),
            });
        } else {
            const endDate = startDate.addDays(21).getTime();
            this.setState({
                activeTime: activeTime,
                activeTimeStr: Base.timeToDateStr(activeTime),
            });
            this.refreshView(startDate.getTime(), endDate);
        }
    };

    handlePreviousClick = () => {
        const increment = -7;
        const newStartDate = new Date(this.props.startDate).addDays(increment);
        const activeTime = newStartDate.addDays(7).getTime();
        this.setState({
            activeTime: activeTime,
            activeTimeStr: Base.timeToDateStr(activeTime),
        });
        this.refreshView(newStartDate.getTime(), new Date(this.state.endDate).addDays(increment).getTime());
    };

    handleNextClick = () => {
        const increment = 7;
        const newStartDate = new Date(this.props.startDate).addDays(increment);
        const activeTime = newStartDate.addDays(7).getTime();
        this.setState({
            activeTime: activeTime,
            activeTimeStr: Base.timeToDateStr(activeTime),
        });
        this.refreshView(newStartDate.getTime(), new Date(this.state.endDate).addDays(increment).getTime());
    };

    handleTodayClick = () => {
        this.goToDate(Base.getNowDate());
    };

    handlePreviousIndexClick = () => {
        const state = this.state;
        const props = this.props;
        const activeTime = new Date(state.activeTime).addDays(-1).getTime();
        if (state.activeTime <= props.startDate) {
            this.setState({
                activeTime: activeTime,
                activeTimeStr: Base.timeToDateStr(activeTime),
            });
            const startDate = Base.getFirstDayOfWeek(new Date(this.props.startDate)).addDays(-14);
            const endDate = startDate.addDays(21).getTime();
            this.refreshView(startDate.getTime(), endDate);
            return;
        }
        const employeeParameters = props.user.parameters;
        const calendar = WorkOrderCalendar.createWorkOrderCalendar(EmployeeParametersHelper.getCalendarAccuracy(employeeParameters),
            EmployeeParametersHelper.getCalendarStartOfficeTime(employeeParameters), EmployeeParametersHelper.getCalendarEndOfficeTime(employeeParameters),
            activeTime, props.endDate, props.calendarLines, props.workOrders, props.calendarDays, props.dayBookings, props.vehicleGroups, props.employeeGroups,
            state.filteredWorkOrders, employeeParameters, state.filterShowFiltered);
        this.initializeCollapsedCategories(employeeParameters, calendar);
        this.setState({
            calendar: calendar,
            activeTime: activeTime,
            activeTimeStr: Base.timeToDateStr(activeTime),
        });
    };

    handleNextIndexClick = () => {
        const state = this.state;
        const props = this.props;
        const activeTime = new Date(state.activeTime).addDays(1).getTime();
        if (activeTime >= props.endDate) {
            this.setState({
                activeTime: activeTime,
                activeTimeStr: Base.timeToDateStr(activeTime),
            });
            const startDate = Base.getFirstDayOfWeek(new Date(this.props.startDate)).addDays(14);
            const endDate = startDate.addDays(21).getTime();
            this.refreshView(startDate.getTime(), endDate);
            return;
        }
        const employeeParameters = props.user.parameters;
        const calendar = WorkOrderCalendar.createWorkOrderCalendar(EmployeeParametersHelper.getCalendarAccuracy(employeeParameters),
            EmployeeParametersHelper.getCalendarStartOfficeTime(employeeParameters), EmployeeParametersHelper.getCalendarEndOfficeTime(employeeParameters),
            activeTime, props.endDate, props.calendarLines, props.workOrders, props.calendarDays, props.dayBookings, props.vehicleGroups, props.employeeGroups,
            state.filteredWorkOrders, employeeParameters, state.filterShowFiltered);
        this.initializeCollapsedCategories(employeeParameters, calendar);
        this.setState({
            calendar: calendar,
            activeTime: activeTime,
            activeTimeStr: Base.timeToDateStr(activeTime),
        });
    };

    handleFindWeekClick = () => {
        this.setState({
            showWorkOrderSearchWeekDialog: true
        });
    };

    handleNewWorkOrder = () => {
        this.editWorkOrderItem(Base.emptyGuid, null, null, null, null, null, null, null, null, null, false);
    };

    handleNewWorkOrderBasedOnTemplate = () => {
        this.setState({
            showSelectWorkOrderTemplateDialog: true,
            selectWorkOrderTemplateDialogWorkOrders: this.props.workOrderTemplates.filter(i => !i.isProject())
        });
    };

    handleNewProjectBasedOnTemplate = () => {
        this.setState({
            showSelectWorkOrderTemplateDialog: true,
            selectWorkOrderTemplateDialogWorkOrders: this.props.workOrderTemplates.filter(i => i.isProject())
        });
    };

    handleSelectWorkOrderTemplateDialogOk = async(workOrderId: string) => {
        if (!workOrderId) return;
        const result = await AppUtils.callService(() => workOrderService.copyWorkOrder(workOrderId, true, true, true, true, true, true));
        if (!result) return;
        this.setState({
            showSelectWorkOrderTemplateDialog: false
        });
        this.openWorkOrderEditDialog(result, null, null, null, null, null, null, false);
    };

    handleSelectWorkOrderTemplateDialogCancel = () => {
        this.setState({
            showSelectWorkOrderTemplateDialog: false
        });
    };

    handleNewProject = () => {
        this.editWorkOrderItem(Base.emptyGuid, null, WorkOrderCategory.Project, null, null, null, null, null, null, null, false);
    };

    handleOpenWorkOrder = (id: string = null) => {
        if (id && id !== this.state.selectedId) {
            this.handleWorkOrderItemClick(id);
        }
        this.editWorkOrderItem(id ?? this.state.selectedId, null, null, null, null, null, null, null, null, null, false);
    };

    handleEditWorkOrder = () => {
        this.editWorkOrderItem(this.state.selectedId, null, null, null, null, null, null, null, null, null, false);
    };

    handleCopyWorkOrder = () => {
        const selectedId = this.state.selectedId;
        if (!selectedId) return;
        const workOrder = this.props.workOrders.concat(this.state.favoriteWorkOrders).find(i => i.id === selectedId);
        if (!workOrder) return;
        this.setState({
            showCopyWorkOrderDialog: true,
            copyWorkOrderItem: workOrder
        });
    };

    handleCopyWorkOrderDialogOk = (workOrderEdit: IWorkOrderEdit) => {
        this.setState({
            showCopyWorkOrderDialog: false,
        });
        this.openWorkOrderEditDialog(workOrderEdit, null, null, null, null, null, null, false);
    };

    handleCopyWorkOrderDialogCancel = () => {
        this.setState({
            showCopyWorkOrderDialog: false,
        });
    };

    handleRemoveWorkOrder = () => {
        const obj = this;
        const props = this.props;
        const state = this.state;
        const selectedId = this.state.selectedId;
        if (!selectedId) return;
        const workOrder = props.workOrders.concat(state.favoriteWorkOrders).find(i => i.id === selectedId);
        if (!workOrder) return;
        WorkOrderOperations.removeWorkOrder(workOrder.isProject(), [selectedId], workOrder.number, workOrder.hasChildren, workOrder.acknowledgementSent, () => {
            obj.refreshViewDefault();
        });
    };

    setWorkOrderState = (id: string, state: number) => {
        const obj = this;
        WorkOrderOperations.setWorkOrderState([id], state, () => {
            obj.refreshViewDefault();
        });
    };

    handleSetWorkOrderState = (newState: number) => {
        const obj = this;
        const state = this.state;
        if (!state.selectedId) return;
        WorkOrderOperations.setWorkOrderState([state.selectedId], newState, () => {
            obj.refreshViewDefault();
        });
    };

    // #region Employee Edit
    handleEmployeeEdit = (id: string) => {
        const obj = this;
        if (!id) return;
        EmployeeOperations.getEmployeeEdit(id)
            .then(employeeEdit => {
                obj.setState({
                    employeeEdit: employeeEdit,
                    showEmployeeDialog: true,
                });
            });
    };

    handleEmployeeDialogOk = () => {
        this.setState({
            showEmployeeDialog: false
        });
        this.refreshViewDefault(); // TODO UPDATE ONLY CHANGED ITEM
    };

    handleEmployeeDialogCancel = (hasBeenChanged: boolean) => {
        this.setState({
            showEmployeeDialog: false
        });
        if (!hasBeenChanged) return;
        this.refreshViewDefault(); // TODO UPDATE ONLY CHANGED ITEM
    };
    // #endregion Employee Edit

    // #region Vehicle Edit
    handleVehicleEdit = (id: string) => {
        const obj = this;
        if (!id) return;
        VehicleOperations.getVehicleEdit(id)
            .then(editItem => {
                obj.setState({
                    vehicleEdit: editItem,
                    showVehicleDialog: true,
                });
            });
    };

    handleVehicleEditDialogOk = () => {
        this.setState({
            showVehicleDialog: false
        });
        this.refreshViewDefault(); // TODO UPDATE ONLY CHANGED ITEM
    };

    handleVehicleEditDialogCancel = () => {
        this.setState({
            showVehicleDialog: false
        });
    };
    // #endregion Vehicle Edit

    handleEditWorkOrderDialogOk = (openWorkOrderId: string, makeProductBookingsOnOpen: boolean) => {
        this.setState({
            showWorkOrderEditDialog: false
        });
        this.refreshViewDefault(); // TODO UPDATE ONLY CHANGED ITEMS
        if (openWorkOrderId) {
            this.editWorkOrderItem(openWorkOrderId, null, null, null, null, null, null, null, null, null, makeProductBookingsOnOpen);
        }
    };

    handleEditWorkOrderDialogCreated = (newWorkOrderId: string) => {
        this.lastCreatedWorkOrderId = newWorkOrderId;
        this.setSelectedWorkOrder(newWorkOrderId, true);
    };

    handleEditWorkOrderDialogCopied = (workOrderEdit: IWorkOrderEdit) => {
        this.setState({
            showWorkOrderEditDialog: false
        });
        this.refreshViewDefault(); // TODO UPDATE ONLY CHANGED ITEMS
        this.openWorkOrderEditDialog(workOrderEdit, null, null, null, null, null, null, false);
    };

    handleEditWorkOrderDialogCancel = () => {
        this.setState({
            showWorkOrderEditDialog: false
        });
        this.refreshViewDefault(); // TODO UPDATE ONLY CHANGED ITEMS
    };

    handleWorkOrderSearchWeekDialogOk = (date: number) => {
        if (Base.isNullOrUndefined(date)) return;
        this.setState({
            showWorkOrderSearchWeekDialog: false
        });
        const change = date - this.props.startDate;
        this.refreshView(date, this.state.endDate + change);
    };

    handleWorkOrderSearchWeekDialogCancel = () => {
        this.setState({
            showWorkOrderSearchWeekDialog: false
        });
    };

    handlePrintWorkOrder = () => {
        WorkOrderOperations.printWorkOrder(this.state.selectedId);
    };

    // #region Favorites
    addToFavorites = (id: string) => {
        if (!id) return;
        const props = this.props;
        const state = this.state;
        const workOrder = props.workOrders.find(i => i.id === id);
        if (workOrder) {
            const favoriteWorkOrders = state.favoriteWorkOrders.filter(i => i.id !== id);
            favoriteWorkOrders.push(workOrder);
            WorkOrderItem.sortWorkOrderItems(favoriteWorkOrders, "", true);
            this.setState({
                favoriteWorkOrders: favoriteWorkOrders,
                mapDataId: state.mapDataId + 1
            });
        }
        props.setEmployeeParameters(EmployeeParametersHelper.addToFavoriteWorkOrders(props.user.parameters, id), true);
    };

    handleAddToFavorites = () => {
        this.addToFavorites(this.state.selectedId);
    };

    removeFromFavorites = (id: string) => {
        if (!id) return;
        const props = this.props;
        const state = this.state;
        this.setState({
            favoriteWorkOrders: state.favoriteWorkOrders.filter(i => i.id !== id),
            mapDataId: state.mapDataId + 1
        });
        props.setEmployeeParameters(EmployeeParametersHelper.removeFromFavoriteWorkOrders(props.user.parameters, id), true);
    };

    handleRemoveFromFavorites = () => {
        this.removeFromFavorites(this.state.selectedId);
    };
    // #endregion Favorites

    // #region Set duration
    handleSetDurationByEstimatedHours = () => {
        const obj = this;
        const selectedId = this.state.selectedId;
        if (!selectedId) return;
        WorkOrderOperations.setWorkOrderDuration([selectedId], null, () => {
            obj.refreshViewDefault(); // TODO UPDATE ONLY CHANGED ITEM
        });
    };
    // #endregion Set duration

    // #region List
    handleSetSelectedIdAndCheckedIds = (selectedId: string, checkedIds: string[]) => {
        this.setState({
            checkedListWorkOrderIds: checkedIds,
        });
        if (!selectedId) return;
        this.setSelectedWorkOrder(selectedId, true);
    };
    // #endregion List

    // #region Filter
    handleSetSelectedMapWorkOrderIds = (ids: string[]) => {
        this.setState({
            selectedMapWorkOrderIds: ids
        });
        if (ids.length < 1) return;
        this.setSelectedWorkOrder(ids[0], false);
    };

    handleAddWorkOrderItemToMapList = (id: string) => {
        this.addToFavorites(id);
    };

    setStateByFilter = (filter: string, filterTime: number, filterStates: number[], filterClasses: number[], filterUsingFavorites: boolean, filterShowFiltered: boolean) => {
        const props = this.props;
        const state = this.state;
        const filteredWorkOrders = this.getFilteredWorkOrders(props.workOrders, filter, filterTime, filterStates, filterClasses, filterUsingFavorites);
        this.setState({
            calendar: WorkOrderCalendar.createWorkOrderCalendar(EmployeeParametersHelper.getCalendarAccuracy(props.user.parameters),
                EmployeeParametersHelper.getCalendarStartOfficeTime(props.user.parameters), EmployeeParametersHelper.getCalendarEndOfficeTime(props.user.parameters),
                state.activeTime, props.endDate, props.calendarLines, props.workOrders, props.calendarDays, props.dayBookings, props.vehicleGroups, props.employeeGroups,
                filteredWorkOrders, props.user.parameters, filterShowFiltered),
            filter: filter,
            filterTime: filterTime,
            filterStates: filterStates,
            filteredWorkOrders: filteredWorkOrders,
            filterClasses: filterClasses,
            filterUsingFavorites: filterUsingFavorites,
            filterShowFiltered: filterShowFiltered,
            mapDataId: state.mapDataId + 1
        });
    };

    handleChangeFilter = (filter: string) => {
        const state = this.state;
        this.setStateByFilter(filter, state.filterTime, state.filterStates, state.filterClasses, state.filterUsingFavorites, state.filterShowFiltered);
    };

    handleToggleFilterState = (workOrderState: number) => {
        const state = this.state;
        const add = state.filterStates.findIndex(i => i === workOrderState) < 0;
        const filterStates = state.filterStates.filter(i => i !== workOrderState);
        if (add) {
            filterStates.push(workOrderState);
        }
        this.setStateByFilter(state.filter, state.filterTime, filterStates, state.filterClasses, state.filterUsingFavorites, state.filterShowFiltered);
    };

    handleToggleFilterClass = (workOrderClass: number) => {
        const state = this.state;
        const add = state.filterClasses.findIndex(i => i === workOrderClass) < 0;
        const filterClasses = state.filterClasses.filter(i => i !== workOrderClass);
        if (add) {
            filterClasses.push(workOrderClass);
        }
        this.setStateByFilter(state.filter, state.filterTime, state.filterStates, filterClasses, state.filterUsingFavorites, state.filterShowFiltered);
    };

    handleChangeFilterTime = (filterTime: number) => {
        const state = this.state;
        this.setStateByFilter(state.filter, filterTime, state.filterStates, state.filterClasses, state.filterUsingFavorites, state.filterShowFiltered);
    };

    handleFilterUsingFavorites = (filterUsingFavorites: boolean) => {
        const state = this.state;
        this.setStateByFilter(state.filter, state.filterTime, state.filterStates, state.filterClasses, filterUsingFavorites, state.filterShowFiltered);
    };

    handleToggleShowFiltered = (filterShowFiltered: boolean) => {
        const state = this.state;
        this.setStateByFilter(state.filter, state.filterTime, state.filterStates, state.filterClasses, state.filterUsingFavorites, filterShowFiltered);
    };

    handleClearFilters = () => {
        this.setStateByFilter("", 0, [], [], false, true);
    };
    // #endregion Filter

    // #region Customer
    getEditItemCustomer = (id: string) => {
        const obj = this;
        CustomerOperations.getCustomerEdit(id)
            .then(editItem => {
                obj.setState({
                    showCustomerDialog: true,
                    customerEdit: editItem
                });
            });
    };

    handleAddCustomer = () => {
        this.getEditItemCustomer(Base.emptyGuid);
    };

    handleEditDialogOk = () => {
        this.setState({
            showCustomerDialog: false
        });
    };

    handleEditDialogCancel = () => {
        this.setState({
            showCustomerDialog: false
        });
    };
    // #endregion Customer

    // #region Contact
    getContactItem = (id: string) => {
        const obj = this;
        ContactOperations.getContactEdit(id)
            .then(editItem => {
                obj.setState({
                    showContactDialog: true,
                    contactEditItem: editItem
                });
            });
    };

    handleAddContact = () => {
        this.getContactItem(Base.emptyGuid);
    };

    handleEditContactDialogOk = (id: string, name: string, email: string) => {
        this.setState({
            showContactDialog: false
        });
    };

    handleEditContactDialogCancel = () => {
        this.setState({
            showContactDialog: false
        });
    };
    // #endregion Contact

    // #region Converting to and from Project
    handleConvertToProjectClick = () => {
        const obj = this;
        const workOrder = obj.state.workOrderDetails.workOrder;
        WorkOrderOperations.convertToProject(workOrder.isWork(), workOrder.isNew(), workOrder.id, (newId: string) => {
            obj.setSelectedWorkOrder(newId, true);
            obj.handleEditWorkOrderDialogOk(newId, false);
        });
    };

    handleDetachFromProjectClick = () => {
        const obj = this;
        const workOrder = obj.state.workOrderDetails.workOrder;
        WorkOrderOperations.detachFromProject(workOrder.isTask(), workOrder.isNew(), workOrder.parentId, workOrder.id, workOrder.parentTaskCount, () => {
            obj.handleEditWorkOrderDialogCancel();
        });
    };
    // #endregion Converting to and from Project

    // #region Canceling
    handleCancelWorkOrder = () => {
        const obj = this;
        const workOrder = obj.state.workOrderDetails.workOrder;
        WorkOrderOperations.cancelWorkOrder(workOrder.canceled, workOrder.id, () => {
            obj.handleEditWorkOrderDialogCancel();
        });
    };

    handleRestoreWorkOrder = () => {
        const obj = this;
        const workOrder = obj.state.workOrderDetails.workOrder;
        WorkOrderOperations.restoreWorkOrder(workOrder.canceled, workOrder.id, () => {
            obj.handleEditWorkOrderDialogCancel();
        });
    };
    // #endregion Canceling

    // #region Calendar
    handleDateChange = (value: moment.Moment | string) => {
        let activeDateStr: string;
        if (typeof value === "string") {
            activeDateStr = Base.timeToDateStr(value.toDate().getTime());
        } else {
            activeDateStr = Base.timeToDateStr(value);
        }
        this.goToDate(activeDateStr.toDate());
    };
    // #endregion

    // #region WorkList
    handleChangeWorkListExpanded = (listExpandedKey: string, expanded: boolean) => {
        const props = this.props;
        props.setEmployeeParameters(EmployeeParametersHelper.setBoolParameter(props.user.parameters, listExpandedKey, expanded), true);
    };
    // #endregion

    render() {
        const props = this.props;
        const state = this.state;
        return (
            <div>
                <div className="row mainContent workMain">
                    <div className="col col-2 leftPanel">
                        <div className="upper pCalendar portlet pContent">
                            <InlineDatePicker
                                value={state.activeTimeStr}
                                rangeStartDate={state.startDate}
                                rangeEndDate={state.endDate}
                                onChange={this.handleDateChange}
                            />
                        </div>
                        <WorkMainWorkListFilter
                            classes="upper"
                            employeeParameters={props.user.parameters}
                            workOrderStates={props.workOrderStates}
                            workOrderClasses={props.workOrderClasses}
                            title={Translations.FilterWorkOrders}
                            listCode={"OnMap"}
                            filter={state.filter}
                            filterTime={state.filterTime}
                            filterStates={state.filterStates}
                            filterClasses={state.filterClasses}
                            filterUsingFavorites={state.filterUsingFavorites}
                            filterShowFiltered={state.filterShowFiltered}
                            onChangeExpanded={this.handleChangeWorkListExpanded}
                            onChangeFilter={this.handleChangeFilter}
                            onChangeFilterTime={this.handleChangeFilterTime}
                            onToggleFilterState={this.handleToggleFilterState}
                            onToggleFilterClass={this.handleToggleFilterClass}
                            onFilterUsingFavorites={this.handleFilterUsingFavorites}
                            onToggleShowFiltered={this.handleToggleShowFiltered}
                            onClearFilters={this.handleClearFilters}
                        />
                        <WorkMainWorkListFavorites
                            classes="upper"
                            employeeParameters={props.user.parameters}
                            listCode={"Favorites"}
                            title={Translations.MyWorkList}
                            selectedId={state.selectedId}
                            favoriteWorkOrderIds={state.favoriteWorkOrders.map(i => i.id)}
                            items={state.favoriteWorkOrders}
                            onItemClick={(id: string) => { this.setSelectedWorkOrder(id, false); }}
                            onEditWorkOrderItem={this.handleEditWorkOrderItem}
                            onAddWorkOrderToList={this.handleAddWorkOrderItemToMapList}
                            onChangeExpanded={this.handleChangeWorkListExpanded}
                            onRemoveFromFavorites={this.removeFromFavorites}
                        />
                    </div>
                    <div className={"col col-8 " + (state.viewMode === WorkMainViewMode.Calendar ? "calendar" : (state.viewMode === WorkMainViewMode.List ? "listView" : "map"))}>
                        {state.viewMode === WorkMainViewMode.Calendar &&
                            <WorkMainCalendar
                                dayBookingTypes={props.dayBookingTypes}
                                workOrderTemplates={props.workOrderTemplates}
                                activeTime={state.activeTime}
                                startDate={props.startDate}
                                endDate={props.endDate}
                                calendar={state.calendar}
                                selectedWorkOrderId={state.selectedId}
                                onItemClick={this.handleWorkOrderItemClick}
                                onItemDoubleClick={this.handleEditWorkOrderItem}
                                onItemsSelected={null}
                                onPreviousClick={this.handlePreviousClick}
                                onNextClick={this.handleNextClick}
                                onPreviousIndexClick={this.handlePreviousIndexClick}
                                onNextIndexClick={this.handleNextIndexClick}
                                onTodayClick={this.handleTodayClick}
                                onFindWeekClick={this.handleFindWeekClick}
                                onNewWorkOrder={this.handleNewWorkOrder}
                                onNewWorkOrderBasedOnTemplate={this.handleNewWorkOrderBasedOnTemplate}
                                onNewProject={this.handleNewProject}
                                onNewProjectBasedOnTemplate={this.handleNewProjectBasedOnTemplate}
                                onOpenSelectedWorkOrder={this.handleOpenWorkOrder}
                                onEditSelectedWorkOrder={this.handleEditWorkOrder}
                                onRemoveWorkOrder={this.handleRemoveWorkOrder}
                                onCopyWorkOrder={this.handleCopyWorkOrder}
                                onEmployeeEdit={this.handleEmployeeEdit}
                                onVehicleEdit={this.handleVehicleEdit}
                                onAddCustomer={this.handleAddCustomer}
                                onAddContact={this.handleAddContact}
                                onEditWorkOrder={this.handleEditWorkOrderItem}
                                onMoveWorkOrderToEmployee={this.handleMoveWorkOrderToEmployee}
                                onMoveWorkOrderToVehicle={this.handleMoveWorkOrderToVehicle}
                                onMoveWorkOrder={this.handleMoveWorkOrderToStartTime}
                                onMoveProject={this.handleMoveProject}
                                onExpandCollapseCalendarLine={this.handleExpandCollapseCalendarLine}
                                onExpandCollapseCalendarCategory={this.handleExpandCollapseCalendarCategory}
                                onChangeCalendarAccuracy={this.handleChangeCalendarAccuracy}
                                onChangeViewMode={this.handleChangeViewMode}
                            />
                        }
                        {state.viewMode === WorkMainViewMode.List &&
                            <WorkMainList
                                startDate={props.startDate}
                                endDate={props.endDate}
                                selectedWorkOrderId={state.selectedId}
                                checkedWorkOrderIds={state.checkedListWorkOrderIds}
                                calendarAccuracy={state.calendar.calendarAccuracy}
                                workOrders={state.filteredWorkOrders}
                                workOrderTemplates={props.workOrderTemplates}
                                onPreviousClick={this.handlePreviousClick}
                                onNextClick={this.handleNextClick}
                                onNewWorkOrder={this.handleNewWorkOrder}
                                onNewWorkOrderBasedOnTemplate={this.handleNewWorkOrderBasedOnTemplate}
                                onNewProject={this.handleNewProject}
                                onNewProjectBasedOnTemplate={this.handleNewProjectBasedOnTemplate}
                                onOpenSelectedWorkOrder={this.handleOpenWorkOrder}
                                onEditSelectedWorkOrder={this.handleEditWorkOrder}
                                onRemoveWorkOrder={this.handleRemoveWorkOrder}
                                onCopyWorkOrder={this.handleCopyWorkOrder}
                                onAddCustomer={this.handleAddCustomer}
                                onAddContact={this.handleAddContact}
                                onSetSelectedIdAndCheckedIds={this.handleSetSelectedIdAndCheckedIds}
                                onOpenWorkOrder={this.handleEditWorkOrderItem}
                                onChangeViewMode={this.handleChangeViewMode}
                            />
                        }
                        {state.viewMode === WorkMainViewMode.Map &&
                            <WorkMainMap
                                startDate={props.startDate}
                                endDate={props.endDate}
                                selectedWorkOrderId={state.selectedId}
                                selectedWorkOrderIds={state.selectedMapWorkOrderIds}
                                calendarAccuracy={state.calendar.calendarAccuracy}
                                workOrders={state.filteredWorkOrders}
                                workOrderTemplates={props.workOrderTemplates}
                                dataId={state.mapDataId}
                                autoFitToBoundsId={state.mapAutoFitToBoundsId}
                                onPreviousClick={this.handlePreviousClick}
                                onNextClick={this.handleNextClick}
                                onNewWorkOrder={this.handleNewWorkOrder}
                                onNewWorkOrderBasedOnTemplate={this.handleNewWorkOrderBasedOnTemplate}
                                onNewProject={this.handleNewProject}
                                onNewProjectBasedOnTemplate={this.handleNewProjectBasedOnTemplate}
                                onOpenSelectedWorkOrder={this.handleOpenWorkOrder}
                                onEditSelectedWorkOrder={this.handleEditWorkOrder}
                                onRemoveWorkOrder={this.handleRemoveWorkOrder}
                                onCopyWorkOrder={this.handleCopyWorkOrder}
                                onAddCustomer={this.handleAddCustomer}
                                onAddContact={this.handleAddContact}
                                onSetSelectedWorkOrderId={this.handleWorkOrderItemClick}
                                onSetSelectedWorkOrderIds={this.handleSetSelectedMapWorkOrderIds}
                                onOpenWorkOrder={this.handleEditWorkOrderItem}
                                onChangeViewMode={this.handleChangeViewMode}
                            />
                        }
                    </div>
                    <div className="col col-2 rightPanel">
                        <div className="portlet upper legends">
                            <div className="pContent">
                                <div className="row">
                                    <div className="col-12 mb-2">
                                        <WorkMainCalendarLegend key={0} classes="state0" title={Translations.WorkOrderStatePreliminary} />
                                        <WorkMainCalendarLegend key={1} classes="state1" title={Translations.WorkOrderStatePlanned} />
                                        <WorkMainCalendarLegend key={2} classes="state2" title={Translations.WorkOrderStateInProgress} />
                                        <WorkMainCalendarLegend key={3} classes="state3" title={Translations.WorkOrderStateDone} />
                                        <WorkMainCalendarLegend key={4} classes="state4" title={Translations.WorkOrderStateChecked} />
                                        <WorkMainCalendarLegend key={5} classes="state5" title={Translations.WorkOrderStateTransferred} />
                                    </div>
                                </div>
                            </div>
                            <WorkMainPortlet
                                classes={"pWorkList upper"}
                                title={Translations.DayBookingType}
                                content={
                                    <div className="pContent white">
                                        <div className="col-12">
                                            {props.dayBookingTypes.map((dayBookingType) =>
                                                <WorkMainCalendarLegend key={dayBookingType.number} classes={"dbt" + dayBookingType.number.toString(10)} title={dayBookingType.title} />
                                            )}
                                        </div>
                                    </div>}
                                replaceContent={true}
                                onChangeExpanded={(expanded: boolean) => { this.handleChangeWorkListExpanded; }}
                            />
                        </div>
                        <WorkOrderCalendarItemDetailsView
                            classes="upper"
                            workTimeFormat={props.workTimeFormat}
                            employeeParameters={props.user.parameters}
                            title={state.workOrderDetails ? state.workOrderDetails.workOrder.getUiTitle() : Translations.NoSelectedWorkOrders}
                            item={state.workOrderDetails}
                            isLoading={state.detailsLoading}
                            onOpenWorkOrder={this.handleEditWorkOrderItem}
                            onRefresh={this.refreshViewDefault}
                            onRemove={this.handleRemoveWorkOrder}
                            onPrint={this.handlePrintWorkOrder}
                            onCopyWorkOrder={this.handleCopyWorkOrder}
                            convertToProject={this.handleConvertToProjectClick}
                            detachFromProject={this.handleDetachFromProjectClick}
                            onCancelWorkOrder={this.handleCancelWorkOrder}
                            onRestoreWorkOrder={this.handleRestoreWorkOrder}
                            onChangeExpanded={this.handleChangeWorkListExpanded}
                            onStateChange={this.handleSetWorkOrderState}
                            onAddToFavorites={this.handleAddToFavorites}
                            onRemoveFromFavorites={this.handleRemoveFromFavorites}
                            onSetDurationByEstimatedHours={this.handleSetDurationByEstimatedHours}
                            onAddEmployeeToWorkOrder={this.handleAddEmployeeToWorkOrder}
                            onAddVehicleToWorkOrder={this.handleAddVehicleToWorkOrder}
                            setEmployeeParameters={this.props.setEmployeeParameters}
                        />
                    </div>
                </div>
                {state.showWorkOrderEditDialog && !Base.isNullOrUndefined(state.workOrderEdit) &&
                    <WorkOrderDialogConnected
                        workOrderEdit={state.workOrderEdit}
                        makeProductBookingsOnOpen={state.workOrderEditDialogMakeProductBookingsOnOpen}
                        onOk={this.handleEditWorkOrderDialogOk}
                        onCreated={this.handleEditWorkOrderDialogCreated}
                        onCopied={this.handleEditWorkOrderDialogCopied}
                        onCancel={this.handleEditWorkOrderDialogCancel}
                    />
                }
                {state.showCopyWorkOrderDialog &&
                    <WorkOrderCopyDialog
                        workOrderId={state.copyWorkOrderItem.id}
                        number={state.copyWorkOrderItem.number}
                        parentNumber={state.copyWorkOrderItem.parentNumber}
                        name={state.copyWorkOrderItem.name}
                        isProject={state.copyWorkOrderItem.isProject()}
                        isRecurring={state.copyWorkOrderItem.isRecurring()}
                        isWork={state.copyWorkOrderItem.isWork()}
                        hasEmployees={state.copyWorkOrderItem.employees.length > 0}
                        hasVehicles={state.copyWorkOrderItem.vehicles.length > 0}
                        hasRoute={state.copyWorkOrderItem.hasRoute}
                        hasBookings={state.copyWorkOrderItem.hasBookings}
                        hasDocuments={state.copyWorkOrderItem.hasDocuments}
                        hasTasks={state.copyWorkOrderItem.hasChildren}
                        onOk={this.handleCopyWorkOrderDialogOk}
                        onCancel={this.handleCopyWorkOrderDialogCancel}
                    />
                }
                {state.showWorkOrderSearchWeekDialog &&
                    <WorkOrderSearchWeekDialog
                        date={this.props.startDate}
                        onOk={this.handleWorkOrderSearchWeekDialogOk}
                        onCancel={this.handleWorkOrderSearchWeekDialogCancel}
                    />
                }
                {state.showEmployeeDialog &&
                    <SettingsEmployeeDialog
                        employeeEdit={state.employeeEdit}
                        onOk={this.handleEmployeeDialogOk}
                        onCancel={this.handleEmployeeDialogCancel}
                        setEmployeeParameters={props.setEmployeeParameters}
                    />
                }
                {state.showVehicleDialog &&
                    <SettingsVehicleDialog
                        editItem={state.vehicleEdit}
                        onOk={this.handleVehicleEditDialogOk}
                        onCancel={this.handleVehicleEditDialogCancel}
                    />
                }
                {state.showCustomerDialog &&
                    <SettingsCustomerDialog
                        customerEdit={state.customerEdit}
                        onOk={this.handleEditDialogOk}
                        onCancel={this.handleEditDialogCancel}
                    />
                }
                {state.showContactDialog &&
                    <SettingsContactDialog
                        editItem={state.contactEditItem}
                        onOk={this.handleEditContactDialogOk}
                        onCancel={this.handleEditContactDialogCancel}
                    />
                }
                {state.showSelectWorkOrderTemplateDialog &&
                    <SelectWorkOrderTemplateDialog
                        workOrderTemplates={state.selectWorkOrderTemplateDialogWorkOrders}
                        onLoadWorkOrderTemplates={props.loadWorkOrderTemplates}
                        onOk={this.handleSelectWorkOrderTemplateDialogOk}
                        onCancel={this.handleSelectWorkOrderTemplateDialogCancel}
                    />
                }
            </div>
        );
    }
}
