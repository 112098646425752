//SettingsCustomerTypeDialog - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import { Translations } from "../../models/translations";
import * as store from "../../framework/customStore";
import * as baseService from "../../services/baseService";
import * as StoreActions from "../../models/store/storeActions";
import * as customerTypeService from "../../services/customerTypeService";
import { PropertyDialog } from "../framework/dialog";
import { Base } from "../../framework/base";
import { SaveData } from "../../framework/saveData";
import { ConfirmationDialogType } from "../../models/store/storeTypes";
import { ICustomerTypeEdit } from "../../models/CustomerType/CustomerTypeEdit";

// SettingsCustomerTypeDialog
// ***********************************************************************************************************************
export interface ISettingsCustomerTypeDialogProp {
    classes?: string;
    editItem: ICustomerTypeEdit;
    onOk: () => void;
    onCancel: () => void;
}

export interface ISettingsCustomerTypeDialogState {
    code: string;
    name: string;
    //activeState: number;
}

export class SettingsCustomerTypeDialog extends React.Component<ISettingsCustomerTypeDialogProp, ISettingsCustomerTypeDialogState> {
    private static orgStateHash: string = "";

    constructor(props: ISettingsCustomerTypeDialogProp) {
        super(props);
        const customerType = props.editItem.customerType;
        this.state = {
            name: customerType.name,
            code: customerType.code,
            //activeState: customerType.activeState,
        };
        const saveData = SettingsCustomerTypeDialog.getSaveDataFromState(props, this.state);
        SettingsCustomerTypeDialog.orgStateHash = saveData.hash;
    }

    // #region General
    handleChange = (event) => {
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        if (name === "nm") {
            this.setState({ name: value });
        } else if (name === "code") {
            this.setState({ code: value });
        }
    };

    /*    handleActiveStateChange = (newActiveState: number) => {
            this.setState({ activeState: newActiveState });
        }*/
    // #endregion General

    private static validate = (state: ISettingsCustomerTypeDialogState): boolean => {
        if (!state.name) {
            store.customStore.dispatch(StoreActions.showErrorMessage(Translations.NameMustBeDefined));
            return false;
        }
        return true;
    };

    private static getSaveDataFromState = (props: ISettingsCustomerTypeDialogProp, state: ISettingsCustomerTypeDialogState): SaveData => {
        const data = new SaveData();
        const customerType = props.editItem.customerType;
        // Common
        data.append("id", customerType.id);
        data.append("rowId", customerType.rowId);
        // General
        data.append("name", state.name);
        data.append("code", state.code);
        return data;
    };

    saveEditItem = () => {
        const obj = this;
        console.log("Inside SaveEditItem.....");
        if (!SettingsCustomerTypeDialog.validate(this.state)) return;
        const saveData = SettingsCustomerTypeDialog.getSaveDataFromState(this.props, this.state);
        console.log("Save Data", saveData.formData);
        if (!saveData) return;
        // Call server
        store.customStore.dispatch(StoreActions.fetchStart());
        customerTypeService.saveCustomerTypeEdit(saveData.formData)
            .then(success => {
                console.log("Inside the successsss");
                store.customStore.dispatch(StoreActions.showSuccessMessage(success.message));
                obj.props.onOk();
            })
            .catch(error => {
                console.log("Inside failure");
                store.customStore.dispatch(StoreActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
                return null;
            })
            .finally(() => store.customStore.dispatch(StoreActions.fetchEnd()));
    };

    handleOkClick = () => {
        this.saveEditItem();
    };

    handleCancelClick = () => {
        const obj = this;
        const saveData = SettingsCustomerTypeDialog.getSaveDataFromState(this.props, this.state);
        if (!Base.isNullOrUndefined(saveData) && saveData.hash !== SettingsCustomerTypeDialog.orgStateHash) {
            store.customStore.dispatch(StoreActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, Translations.YouHaveNotSavedChangesDoYouWantToSaveChanges,
                () => {
                    store.customStore.dispatch(StoreActions.clearConfirmation());
                    obj.saveEditItem();
                },
                () => {
                    store.customStore.dispatch(StoreActions.clearConfirmation());
                    obj.props.onCancel();
                },
                () => {
                    store.customStore.dispatch(StoreActions.clearConfirmation());
                }));
        } else {
            obj.props.onCancel();
        }
    };

    render() {
        const props = this.props;
        const state = this.state;
        const editItem = props.editItem;
        const dialogClasses = "settings customerType px800" + (props.classes ? " " + props.classes : "");
        return (
            <div>
                <PropertyDialog
                    classes={dialogClasses}
                    title={Translations.CustomerType + " - " + editItem.customerType.name}

                    show={true}
                    body={<div>
                        <div className="">
                            <div>
                                <div>
                                    <div className="row">
                                        <div className="col-2">
                                            <div className="form-group required">
                                                <label className="control-label smallFont">{Translations.Code}</label>
                                                <input type="text" className="form-control" name="code" title={Translations.Code} value={state.code} onChange={this.handleChange} maxLength={50} />
                                            </div>
                                        </div>
                                        <div className="col-7">
                                            <div className="form-group required">
                                                <label className="control-label smallFont">{Translations.Name}</label>
                                                <input type="text" className="form-control" name="nm" title={Translations.Name} value={state.name} onChange={this.handleChange} maxLength={50} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                    buttons={[
                        { title: Translations.Save, classes: "btn-primary", enabled: true, onClick: this.handleOkClick },
                    ]}
                    onClose={this.handleCancelClick}
                />
            </div>
        );
    }
}