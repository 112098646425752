import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export const exportElementByIdToPdf = (elementId: string, parentElementId: string, title: string = "export") => {
    const element = document.getElementById(elementId);
    // set element max height to 100% to avoid scrollbars
    if (element) {
        element.style.maxHeight = "100%";
        element.style.maxWidth = "100%";
        element.style.overflow = "visible";
    }
    // set element parent max height to 100% to avoid scrollbars
    const parentElement = document.getElementById(parentElementId);
    if (parentElement) {
        parentElement.style.maxHeight = "100%";
    }

    const margin = 2;

    if (element) {
        const table = element.querySelector("table");

        if (table) {
            const scrollHeight = table.scrollHeight;

            html2canvas(element, {
                useCORS: true,
                scrollY: - window.scrollY,
                width: element.scrollWidth
            }).then((canvas) => {
                const imgData = canvas.toDataURL("image/png");
                const pdf = new jsPDF("l", "mm", "a4");

                // Calculate image dimensions
                const imgWidth = pdf.internal.pageSize.getWidth() - 2 * margin;
                const imgHeight = (scrollHeight * imgWidth) / canvas.width;

                let position = 0;
                const pageHeight = pdf.internal.pageSize.getHeight() - 2 * margin;

                // Loop through the table rows and add page breaks
                while (position < imgHeight) {
                    pdf.addImage(imgData, "PNG", margin, margin - position, imgWidth, 0, "", "FAST");
                    position += pageHeight;
                    if (position < imgHeight) {
                        pdf.addPage();
                    }
                }

                pdf.save(title + ".pdf");
            });
        }
    }
    // revert element max height
    if (element) {
        element.style.maxHeight = "";
        element.style.overflow = "";
    }
    // revert element parent max height
    if (parentElement) {
        parentElement.style.maxHeight = "";
    }
};

