// OwnSettingsDialog - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import { Translations } from "../../models/translations";
import * as store from "../../framework/customStore";
import * as baseService from "../../services/baseService";
import * as storeActions from "../../models/store/storeActions";
import * as employeeService from "../../services/employeeService";
import * as tokenService from "../../services/tokenService";
import { PropertyDialog } from "../framework/dialog";
import { Base } from "../../framework/base";
import { SaveData } from "../../framework/saveData";
import { ConfirmationDialogType } from "../../models/store/storeTypes";
import { CalendarAccuracyType } from "../../models/common/enums";
import { IEmployeeEdit } from "../../models/employee/employeeEdit";
import { EmployeeParametersHelper, IEmployeeParameters } from "../../models/employee/employeeIParameters";
import { RadioButton } from "../framework/radio";
import { TimeSelector } from "../framework/timeSelector";
import { cultureCodeEnglish, cultureCodeFinnish } from "../../models/common/consts";
import { ImageSelector } from "../framework/imageSelector";
import { Button } from "../framework/button";
import { SettingsChangePasswordDialog } from "../settings/settingsChangePasswordDialog";
import { IVehicleItem } from "../../models/vehicle/vehicleItem";
import { CheckBoxList } from "../framework/checkbox";

// OwnSettingsDialog
// ***********************************************************************************************************************
export interface IOwnSettingsDialogProp {
    classes?: string;
    employeeEdit: IEmployeeEdit;
    setEmployeeParameters: (parameters: IEmployeeParameters, saveToDb: boolean) => void;
    onOk: (reloadApp: boolean) => void;
    onCancel: () => void;
}

export interface IOwnSettingsDialogState {
    integrationVehicles: IVehicleItem[];
    calendarAccuracy: CalendarAccuracyType;
    rowId: string;
    picture: string;
    pictureFile: File;
    removePicture: boolean;
    dateStr: string;
    startTimeStr: string;
    endTimeStr: string;
    defaultCulture: string;
    showOnMapVehicleIds: string[];
    showOnDesignVehicleGroupIds: string[];
    showOnDesignEmployeeGroupIds: string[];
    showChangePasswordDialog: boolean;
}

export class OwnSettingsDialog extends React.Component<IOwnSettingsDialogProp, IOwnSettingsDialogState> {
    private static orgStateHash: string = "";

    private calendarAccuracy = [
        { title: Translations.CalendarAccuracyQuarterHour, onClick: () => { this.handleChangeCalendarAccuracy(CalendarAccuracyType.QuarterHour); }, code: CalendarAccuracyType.QuarterHour },
        { title: Translations.CalendarAccuracyFourHours, onClick: () => { this.handleChangeCalendarAccuracy(CalendarAccuracyType.FourHours); }, code: CalendarAccuracyType.FourHours },
        { title: Translations.CalendarAccuracyDayTwoWeeks, onClick: () => { this.handleChangeCalendarAccuracy(CalendarAccuracyType.DayTwoWeeks); }, code: CalendarAccuracyType.DayTwoWeeks }
    ];

    private cultures = [
        { title: Translations.LanguageFinnish, onClick: () => { this.handleChangeDefaultCulture(cultureCodeFinnish); }, code: cultureCodeFinnish },
        { title: Translations.LanguageEnglish, onClick: () => { this.handleChangeDefaultCulture(cultureCodeEnglish); }, code: cultureCodeEnglish }
    ];

    constructor(props: IOwnSettingsDialogProp) {
        super(props);
        const employee = props.employeeEdit.employee;
        const startOfficeTime = EmployeeParametersHelper.getCalendarStartOfficeTime(props.employeeEdit.employeeParameters);
        const endOfficeTime = EmployeeParametersHelper.getCalendarEndOfficeTime(props.employeeEdit.employeeParameters);
        const showOnMapVehicleIds = EmployeeParametersHelper.getShowOnMapVehicleIdsSpecified(props.employeeEdit.employeeParameters)
            ? EmployeeParametersHelper.getShowOnMapVehicleIds(props.employeeEdit.employeeParameters)
            : props.employeeEdit.vehicles.map(i => i.id);
        const showOnDesignVehicleGroupIds = EmployeeParametersHelper.getShowOnDesignVehicleGroupIdsSpecified(props.employeeEdit.employeeParameters)
            ? EmployeeParametersHelper.getShowOnDesignVehicleGroupIds(props.employeeEdit.employeeParameters)
            : props.employeeEdit.vehicleGroups.map(i => i.id);
        const showOnDesignEmployeeGroupIds = EmployeeParametersHelper.getShowOnDesignEmployeeGroupIdsSpecified(props.employeeEdit.employeeParameters)
            ? EmployeeParametersHelper.getShowOnDesignEmployeeGroupIds(props.employeeEdit.employeeParameters)
            : props.employeeEdit.employeeGroups.map(i => i.id);
        this.state = {
            integrationVehicles: props.employeeEdit.vehicles.filter(i => i.integrationEnabled),
            rowId: employee.rowId,
            calendarAccuracy: EmployeeParametersHelper.getCalendarAccuracy(props.employeeEdit.employeeParameters),
            pictureFile: null,
            picture: employee.picture,
            removePicture: false,
            dateStr: Base.dateToDateStr(Base.getNowDate()),
            startTimeStr: OwnSettingsDialog.getTimeStr(startOfficeTime / 60),
            endTimeStr: OwnSettingsDialog.getTimeStr(endOfficeTime / 60),
            defaultCulture: employee.defaultCulture,
            showOnMapVehicleIds: showOnMapVehicleIds,
            showOnDesignVehicleGroupIds: showOnDesignVehicleGroupIds,
            showOnDesignEmployeeGroupIds: showOnDesignEmployeeGroupIds,
            showChangePasswordDialog: false
        };
        const saveData = OwnSettingsDialog.getSaveDataFromState(props, this.state);
        OwnSettingsDialog.orgStateHash = saveData.hash;
    }

    // #region General
    handleChangeCalendarAccuracy = (calendarAccuracy: CalendarAccuracyType) => {
        this.setState({
            calendarAccuracy: calendarAccuracy
        });
    };

    handleChangeDefaultCulture = (defaultCulture: string) => {
        this.setState({
            defaultCulture: defaultCulture
        });
    };

    static getTimeStr = (hours: number): string => {
        return Base.hoursToTimeStr(hours).replace(":", ".");
    };

    static getTimeInMinutes = (timeStr: string): number => {
        const date = timeStr.toTime();
        return date.getHours() * 60 + date.getMinutes();
    };

    handleStartTimeChange = (value: string) => {
        const state = this.state;
        const orgStartTime = OwnSettingsDialog.getTimeInMinutes(state.startTimeStr);
        const orgEndTime = OwnSettingsDialog.getTimeInMinutes(state.endTimeStr);
        const startTime = OwnSettingsDialog.getTimeInMinutes(value);
        const startTimeDif = Math.min(startTime - orgStartTime, 60);
        if (startTime <= 23 * 60) {
            if (startTime <= orgEndTime - 60) {
                this.setState({ startTimeStr: value });
            } else if (orgEndTime + startTimeDif <= 24 * 60) {
                this.setState({ startTimeStr: value, endTimeStr: OwnSettingsDialog.getTimeStr((orgEndTime + startTimeDif) / 60) });
            }
        }
    };

    handleEndTimeChange = (value: string) => {
        const state = this.state;
        const startTime = OwnSettingsDialog.getTimeInMinutes(state.startTimeStr);
        const endTime = OwnSettingsDialog.getTimeInMinutes(value);
        if (endTime >= 1 * 60 && startTime <= endTime - 60) {
            this.setState({ endTimeStr: value });
        }
    };

    handleSetPicture = (file: File) => {
        if (Base.isNullOrUndefined(file)) return;
        const obj = this;

        Base.blobToBase64(file).then(base64Image => {
            obj.setState({
                pictureFile: file,
                picture: base64Image,
                removePicture: false
            });
        });
    };

    handleRemovePicture = () => {
        this.setState({
            pictureFile: null,
            picture: "",
            removePicture: true
        });
    };

    handleShowOnMapVehicleIdsChange = (vehicleIds: string[]) => {
        this.setState({ showOnMapVehicleIds: vehicleIds });
    };

    handleShowOnDesignEmployeeGroupIdsChange = (employeeGroupIds: string[]) => {
        this.setState({ showOnDesignEmployeeGroupIds: employeeGroupIds });
    };

    handleShowOnDesignVehicleGroupIdsChange = (vehicleGroupIds: string[]) => {
        this.setState({ showOnDesignVehicleGroupIds: vehicleGroupIds });
    };
    // #endregion General

    // #region Change Password
    handleChangePassword = () => {
        this.setState({
            showChangePasswordDialog: true
        });
    };

    handleChangePasswordDialogOk = (rowId: string) => {
        this.setState({
            rowId: rowId,
            showChangePasswordDialog: false
        });
    };

    handleChangePasswordDialogCancel = () => {
        this.setState({
            showChangePasswordDialog: false
        });
    };
    // #endregion Change Password

    private static validate = (state: IOwnSettingsDialogState): boolean => {
        //if (!state.name) {
        //    store.customStore.dispatch(storeActions.showErrorMessage(Translations.NameMustBeDefined));
        //    return false;
        //}
        return true;
    };

    private static getSaveDataFromState = (props: IOwnSettingsDialogProp, state: IOwnSettingsDialogState): SaveData => {
        const data = new SaveData();
        const employee = props.employeeEdit.employee;
        // Common
        data.append("id", employee.id);
        data.append("rowId", state.rowId);
        // General
        if (!Base.isNullOrUndefined(state.pictureFile)) {
            data.append("picture[]", state.pictureFile, state.pictureFile.name);
        }
        data.append("removePicture", state.removePicture ? "1" : "0");
        data.append("defaultCulture", state.defaultCulture);
        //Date saved to employee parameters
        data.append("calendarAccuracy", state.calendarAccuracy);
        data.append("startTime", OwnSettingsDialog.getTimeInMinutes(state.startTimeStr).toString(10));
        data.append("endTime", OwnSettingsDialog.getTimeInMinutes(state.endTimeStr).toString(10));
        console.log("Web sav Data... ", data);
        return data;
    };

    saveEmployeeParameters = () => {
        const props = this.props;
        const state = this.state;
        props.setEmployeeParameters(
            EmployeeParametersHelper.setShowOnDesignEmployeeGroupIds(
                EmployeeParametersHelper.setShowOnDesignVehicleGroupIds(
                    EmployeeParametersHelper.setShowOnMapVehicleIds(
                        EmployeeParametersHelper.setCalendarEndOfficeTime(
                            EmployeeParametersHelper.setCalendarStartOfficeTime(
                                EmployeeParametersHelper.setCalendarAccuracy(props.employeeEdit.employeeParameters,
                                    state.calendarAccuracy),
                                OwnSettingsDialog.getTimeInMinutes(state.startTimeStr)),
                            OwnSettingsDialog.getTimeInMinutes(state.endTimeStr)),
                        state.showOnMapVehicleIds),
                    state.showOnDesignVehicleGroupIds),
                state.showOnDesignEmployeeGroupIds),
            true);
    };

    saveEditItem = () => {
        const obj = this;
        if (!OwnSettingsDialog.validate(this.state)) return;
        const saveData = OwnSettingsDialog.getSaveDataFromState(this.props, this.state);
        if (Base.isNullOrUndefined(saveData)) return;
        // Call server
        store.customStore.dispatch(storeActions.fetchStart());
        employeeService.saveOwnSettings(saveData.formData)
            .then(success => {
                store.customStore.dispatch(storeActions.showSuccessMessage(success.message));
                obj.saveEmployeeParameters();
                const cultureChanged = obj.state.defaultCulture !== obj.props.employeeEdit.employee.defaultCulture;
                if (cultureChanged) {
                    appConfig.culture = obj.state.defaultCulture;
                    Translations.setCulture(appConfig.culture);
                    tokenService.refreshAuthentication(true);
                }
                obj.props.onOk(cultureChanged);
            })
            .catch(error => {
                store.customStore.dispatch(storeActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
                return null;
            })
            .finally(() => store.customStore.dispatch(storeActions.fetchEnd()));
    };

    handleOkClick = () => {
        this.saveEditItem();
    };

    handleCancelClick = () => {
        const obj = this;
        const saveData = OwnSettingsDialog.getSaveDataFromState(this.props, this.state);
        if (!Base.isNullOrUndefined(saveData) && saveData.hash !== OwnSettingsDialog.orgStateHash) {
            store.customStore.dispatch(storeActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, Translations.YouHaveNotSavedChangesDoYouWantToSaveChanges,
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                    obj.saveEditItem();
                },
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                    obj.props.onCancel();
                },
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                }));
        } else {
            obj.props.onCancel();
        }
    };

    render() {
        const props = this.props;
        const state = this.state;
        const dialogClasses = "settings own px700" + (props.classes ? " " + props.classes : "");
        return (
            <div>
                <PropertyDialog
                    classes={dialogClasses}
                    title={Translations.OwnSettings}
                    show={true}
                    body={<div>
                        <div className="row">
                            <div className="col-3">
                                <ImageSelector
                                    fileInputName="picture"
                                    image={state.picture}
                                    aspectRatio={null}
                                    onChange={this.handleSetPicture}
                                    onClear={this.handleRemovePicture}
                                    onError={(message) => { store.customStore.dispatch(storeActions.showErrorMessage(message)); }}
                                />
                            </div>
                            <div className="col-9">
                                <div className="row">
                                    <div className="col-6">
                                        <div>
                                            <label className="control-label smallFont">{Translations.CalendarAccuracyDefault}</label>
                                        </div>
                                        <div className="btn-group btn-group-toggle states">
                                            {this.calendarAccuracy.map((calendarAccuracy) =>
                                                <RadioButton
                                                    key={calendarAccuracy.code}
                                                    classes={"btn-secondary state" + calendarAccuracy.code}
                                                    title={calendarAccuracy.title}
                                                    enabled={true}
                                                    checked={state.calendarAccuracy === calendarAccuracy.code}
                                                    onRadioClick={calendarAccuracy.onClick}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div>
                                            <label className="control-label smallFont">{Translations.DefaultCulture}</label>
                                        </div>
                                        <div className="btn-group btn-group-toggle states">
                                            {this.cultures.map((culture) =>
                                                <RadioButton
                                                    key={culture.code}
                                                    classes={"btn-secondary culture" + culture.code}
                                                    title={culture.title}
                                                    enabled={true}
                                                    checked={state.defaultCulture === culture.code}
                                                    onRadioClick={culture.onClick}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group required">
                                            <label className="control-label smallFont">{Translations.CalendarOfficeTimeStart}</label>
                                            <TimeSelector
                                                date={state.dateStr}
                                                value={state.startTimeStr}
                                                required={true}
                                                onChange={this.handleStartTimeChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group required">
                                            <label className="control-label smallFont">{Translations.CalendarOfficeTimeEnd}</label>
                                            <TimeSelector
                                                date={state.dateStr}
                                                startDate={state.dateStr}
                                                startTime={state.startTimeStr}
                                                value={state.endTimeStr}
                                                required={true}
                                                onChange={this.handleEndTimeChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-3">
                                <div className="form-group">
                                    <label className="control-label smallFont">{Translations.Password}</label>
                                    <div>
                                        <Button
                                            classes="btn-default changePassword"
                                            title={Translations.ChangePassword}
                                            enabled={true}
                                            onClick={(index?: number) => { this.handleChangePassword(); }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-9">
                                <div className="row">
                                    <div className="col-12">
                                        <label className="control-label smallFont">{Translations.VehiclesVisibleOnMap}</label>
                                        <CheckBoxList
                                            selectedIds={state.showOnMapVehicleIds}
                                            values={state.integrationVehicles}
                                            onChange={this.handleShowOnMapVehicleIdsChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <div className="row">
                                    <div className="col-12">
                                        <label className="control-label smallFont">{Translations.EmployeeGroupsVisibleOnDesign}</label>
                                        <CheckBoxList
                                            selectedIds={state.showOnDesignEmployeeGroupIds}
                                            values={props.employeeEdit.employeeGroups}
                                            onChange={this.handleShowOnDesignEmployeeGroupIdsChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="row">
                                    <div className="col-12">
                                        <label className="control-label smallFont">{Translations.VehicleGroupsVisibleOnDesign}</label>
                                        <CheckBoxList
                                            selectedIds={state.showOnDesignVehicleGroupIds}
                                            values={props.employeeEdit.vehicleGroups}
                                            onChange={this.handleShowOnDesignVehicleGroupIdsChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {state.showChangePasswordDialog &&
                            <SettingsChangePasswordDialog
                                employeeId={props.employeeEdit.employee.id}
                                employeeRowId={state.rowId}
                                adminMode={false}
                                onOk={this.handleChangePasswordDialogOk}
                                onCancel={this.handleChangePasswordDialogCancel}
                            />
                        }
                    </div>}
                    buttons={[
                        { title: Translations.Save, classes: "btn-primary", enabled: true, onClick: this.handleOkClick }]}
                    onClose={this.handleCancelClick}
                />
            </div>
        );
    }
}