// AvatarImage
// ***********************************************************************************************************************
import * as React from "react";

export interface IAvatarImageProp {
    name?: string;
    text?: string;
    image?: string;
    classes?: string;
    fullText?: boolean;
    showName?: boolean;
    tooltip?: string;
    imageClass?: string;
    onClick?: () => void;
}

export class AvatarImage extends React.Component<IAvatarImageProp, {}> {
    render() {
        const props = this.props;
        const name = props.name ? props.name.trim() : "";
        let initials = props.text ? props.text.trim() : name;
        if (!props.fullText && initials) {
            const names = initials.split(" ");
            initials = names[0] ? (names[0][0] + (names[1] ? names[1][0] : "")) : "";
        }
        const showName = props.showName && !!name;
        //console.log("name", props.name, props.text, name, initials, props.fullText)
        return (
            <div className={"avatar" + (props.classes ? " " + props.classes : "") + (showName ? " withName" : "") + (props.onClick ? " clickable" : "")} title={props.tooltip} onClick={props.onClick}>
                {(!!props.image || !!props.imageClass) &&
                    <span className={"image" + (props.imageClass ? " " + props.imageClass : "")} style={props.image ? { backgroundImage: "url(" + props.image + ")" } : null} />
                }
                {!props.image && !props.imageClass && !!initials &&
                    <span className={"initials" + (initials.length > 2 ? " long" : "")}>{initials}</span>
                }
                {showName &&
                    <span className="name">{name}</span>
                }
            </div>
        );
    }
}