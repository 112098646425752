import React, { useCallback, useMemo } from "react";
import _groupBy from "lodash/groupBy";
import _map from "lodash/map";
import Typography from "@mui/material/Typography";
import {
    GridColDef,
    GridRowsProp,
    GridValueFormatterParams,
} from "@mui/x-data-grid-premium";

import { Base } from "../../../framework/base";
import { VehicleListItemDto } from "../../../models/transport/vehicle";
import { EmployeeListItemDto } from "../../../models/employee/employee";
import { Translations } from "../../../models/translations";
import { WorkShiftDto } from "../../../models/workShift/workShift";
import { WorkShiftTimeSlotItem } from "../../../models/workShitTimeSlot/workShiftTimeSlotItem";
import { useTranslation } from "react-i18next";
import { WorkTimeType } from "../../../models/workShitTimeSlot/workTimeType";
import { EmployeeDetailsTable } from "./employeeDetailsTable";
import { GroupedDataGrid } from "../../framework/groupedDataGrid";
import { ICostCenterItem } from "../../../models/costCenter/costCenterItem";

export interface WorkSlotItem {
    employeeId: string;
    vehicleId: string;
    costCenterId: string;
    workTimeTypeId: string;
    amount: number;
    startTime: string;
    endTime: string;
    dateStr: string;
    comment: string;
}

export interface GridRow {
    id: string;
    hierarchy: string[];
    workSlotItems: WorkSlotItem[];
    vehicles: VehicleListItemDto[];
    costCenters: ICostCenterItem[];
    workTimeTypes: WorkTimeType[];
    date: string;
    totals: Record<string, number>;
}

interface EmployeesVehicleGridProps {
    vehicles: VehicleListItemDto[];
    employees: EmployeeListItemDto[];
    costCenters: ICostCenterItem[];
    workShifts: WorkShiftDto[];
    workTimeTypes: WorkTimeType[];
    filterByVehicles: (val: string) => boolean;
    filterByCostCenters: (val: string) => boolean;
    filterByEmployees: (val: string) => boolean;
}

export const EmployeesVehicleGrid = ({
    vehicles,
    employees,
    costCenters,
    workShifts,
    workTimeTypes,
    filterByVehicles,
    filterByCostCenters,
    filterByEmployees,
}: EmployeesVehicleGridProps) => {
    const { t } = useTranslation();

    const employeeData = useMemo(
        () =>
            workShifts.flatMap((s) =>
                s.workShiftTimeSlots
                    .filter(
                        (slot) =>
                            !!slot.vehicleId &&
                            filterByVehicles(slot?.vehicleId) &&
                            filterByCostCenters(slot?.costCenterId) &&
                            filterByEmployees(slot?.employeeId)
                    )
                    .map((slot) => ({
                        id: slot.employeeId,
                        workSlotItem: {
                            employeeId: slot.employeeId,
                            vehicleId: slot.vehicleId,
                            costCenterId: slot.costCenterId,
                            workTimeTypeId: slot.workTimeTypeId,
                            comment: slot.comment,
                            startTime: slot.startDate,
                            endTime: slot.endDate,
                            amount: new WorkShiftTimeSlotItem(
                                slot
                            ).getDurationMinutes(),
                            dateStr: s.effectiveDate,
                        } as WorkSlotItem,
                    }))
            ),
        [workShifts, filterByVehicles, filterByCostCenters, filterByEmployees]
    );

    // Group data by date
    const groupedData = _groupBy(
        employeeData,
        (w) => `${w.id}_${w.workSlotItem.dateStr}`
    );

    // Create grid rows, calculate totals for each group
    const gridRows: GridRowsProp<GridRow> = _map(groupedData, (value, key) => {
        const workSlot = value[0].workSlotItem;
        const date = workSlot.dateStr;
        return {
            id: key,
            hierarchy: [workSlot.employeeId, date],
            workSlotItems: value.map((v) => v.workSlotItem),
            vehicles: vehicles,
            costCenters: costCenters,
            workTimeTypes: workTimeTypes,
            date,
            totals: value.reduce((acc, curr) => {
                acc[curr.workSlotItem.vehicleId] =
                    (acc[curr.workSlotItem.vehicleId] ?? 0) +
                    curr.workSlotItem.amount;

                acc["v_total"] =
                    (acc["v_total"] ?? 0) + curr.workSlotItem.amount;

                return acc;
            }, {}),
        };
    }).sort((a, b) => Base.stringCompare(a.date, b.date));

    // Group vehicles by register number
    const groupedVehicles = vehicles?.reduce((acc, vehicle) => {
        if (!acc[vehicle.registerNumber]) {
            acc[vehicle.registerNumber] = [];
        }
        acc[vehicle.registerNumber].push(vehicle);
        return acc;
    }, {} as Record<string, typeof vehicles>);

    const gridDef: GridColDef<GridRow>[] = useMemo(
        () => [
            {
                field: "v_total",
                headerName: Translations.Total,
                description: Translations.Total,
                groupable: false,
                type: "number",
                minWidth: 100,
                flex: 1,
                valueGetter(params) {
                    return (params.row?.totals?.v_total ?? 0) / 60;
                },
                valueFormatter: (params: GridValueFormatterParams<number>) => {
                    const value = params.value;
                    if (!value) return "-";
                    return value.toLocaleFixed(2, "fi-Fi");
                },
            },
            ...Object.entries(groupedVehicles).map(
                ([registerNumber, vehicles]) => ({
                    // if multiple vehicleIds has same register number, use id of the last vehicle
                    // otherwise GroupedDataGrid will hide the column because it can't find values from row.totals[vehicleId]
                    field:
                        vehicles?.length > 1
                            ? vehicles[vehicles.length - 1]?.id
                            : vehicles[0]?.id,
                    headerName: registerNumber,
                    description: `${registerNumber} - ${vehicles
                        .map((v) => v.brand)
                        .join(", ")}`,
                    groupable: false,
                    type: "number",
                    minWidth: 100,
                    flex: 1,
                    valueGetter(params) {
                        return (
                            vehicles.reduce(
                                (sum, v) =>
                                    sum + (params.row?.totals?.[v.id] ?? 0),
                                0
                            ) / 60
                        );
                    },
                    valueFormatter: (
                        params: GridValueFormatterParams<number>
                    ) => {
                        const value = params.value;
                        if (!value) return "-";
                        return value.toLocaleFixed(2, "fi-Fi");
                    },
                })
            ),
        ],
        [vehicles]
    );

    const pinnedColumns = useMemo(() => ["v_total"], []);

    const getEmployeeLabel = useCallback(
        (employeeId: string) =>
            employees?.find((e) => e.id === employeeId)?.fullName ?? "",
        [employees]
    );

    return (
        <div style={{ padding: "1rem" }} id="employees-container">
            <Typography variant="h3">{t("employee.employees")}</Typography>
            <GroupedDataGrid
                noRowsText={t("employee.noRows")}
                detailsComponent={EmployeeDetailsTable}
                columns={gridDef}
                rows={gridRows}
                groupingColDef={{
                    headerName: t("employee.employee"),
                    valueFormatter: (data) => {
                        // for exports
                        const value: string = data?.value ?? Translations.Total;
                        if (value.match(/\w{8}-\w{4}-\w{4}-\w{4}-\w{12}/)) {
                            // val is employeeId
                            return getEmployeeLabel(value);
                        } else {
                            // val is date
                            return value;
                        }
                    },
                }}
                getGroupLabel={getEmployeeLabel}
                persistStateKey="EmployeesVehicleGridState"
                pinnedColumns={pinnedColumns}
            />
        </div>
    );
};
