import { postApiCall, getApiCall, makeApiCallWithAny } from "./tokenService";
import { WorkShiftTimeSlotItems } from "../models/workShitTimeSlot/workShitTimeSlotItems";
import { ApiSuccess } from "./baseService";
import { WorkTimeType } from "../models/workShitTimeSlot/workTimeType";
import { WorkShiftTimeSlotItem } from "../models/workShitTimeSlot/workShiftTimeSlotItem";
import { WorkTimeSaveData } from "../models/workShitTimeSlot/workTimeSaveData";
import { SalaryRowTypeItem } from "../models/salary/salaryRowTypeItem";
import { IObjectEventLogItem } from "../models/eventLog/objectEventLogItem";
import { NonOverlappingWorkShiftTimeSlotSaveData } from "../components/workTimeBeta/workTimeAddBreakDialog";
import { OverlappingSlotsQueryData } from "../models/workShitTimeSlot/overlappingSlotsQueryData";

export const removeWorkTime = (
    workShiftTimeSlotId: string
): Promise<boolean> => {
    return makeApiCallWithAny<boolean>(
        `api/workshifttimeslot/delete/${encodeURIComponent(
            workShiftTimeSlotId
        )}`,
        "DELETE",
        null,
        null
    );
};

export const getWorkShiftTimeSlotItems = (startDate: string, endDate: string, employeeIds: string | string[], signal?: AbortSignal): Promise<WorkShiftTimeSlotItems> => {
    return getApiCall<WorkShiftTimeSlotItems>(
        `api/workshifttimeslots?startDate=${encodeURIComponent(startDate)}&endDate=${encodeURIComponent(endDate)}${[employeeIds].flat().map(id => `&employeeIds=${encodeURIComponent(id)}`).join("")}`,
        WorkShiftTimeSlotItems,
        signal,
    );
};

export const getOverlappingWorkShiftTimeSlots = (
    params: OverlappingSlotsQueryData,
    signal?: AbortSignal
): Promise<WorkShiftTimeSlotItem[]> => {
    const query = new URLSearchParams(params as any).toString();
    console.log("query:", query);
    return getApiCall<WorkShiftTimeSlotItem[]>(
        `api/workshifttimeslots/overlaps?${query}`,
        null,
        signal
    );
};

export const saveWorkShiftTimeSlot = (
    data: WorkTimeSaveData,
    allowOverwrite: boolean = false
): Promise<WorkShiftTimeSlotItem> => {
    const queryString = new URLSearchParams({ allowOverwrite: allowOverwrite.toString() }).toString();
    return postApiCall<WorkShiftTimeSlotItem>(
        `api/workshifttimeslot/save?${queryString}`,
        WorkShiftTimeSlotItem,
        JSON.stringify(data),
    );
};

export const overwritingBreakSave = (
    data: NonOverlappingWorkShiftTimeSlotSaveData
): Promise<WorkShiftTimeSlotItem> => {
    return postApiCall<WorkShiftTimeSlotItem>(
        "api/workshifttimeslot/nonoverlappingsave",
        WorkShiftTimeSlotItem,
        JSON.stringify(data),
    );
};

export function saveWorkShiftTimeSlots(workTimes: WorkShiftTimeSlotItem[]) {
    return postApiCall<ApiSuccess>("api/workshifttimeslots/savebulk", ApiSuccess, JSON.stringify({ workShiftTimeSlots: workTimes.map(w => w.toWorkTimeSaveData()) }));
}

export const lockWorkTimes = (ids: string[]): Promise<ApiSuccess> => {
    return postApiCall<ApiSuccess>("api/worktime/lock", ApiSuccess, JSON.stringify({
        ids: ids
    }));
};

export const unlockWorkTimes = (ids: string[]): Promise<ApiSuccess> => {
    return postApiCall<ApiSuccess>("api/worktime/unlock", ApiSuccess, JSON.stringify({
        ids: ids
    }));
};

export const getWorkTimeTypes = (signal?: AbortSignal): Promise<WorkTimeType[]> => {
    return getApiCall<WorkTimeType[]>("api/workshifttimeslot/worktimetypes", null, signal);
};

export const getSalaryRowTypes = (): Promise<SalaryRowTypeItem[]> => {
    return getApiCall<SalaryRowTypeItem[]>("api/workshifttimeslot/salaryrowtypes", null);
};

export const getWorkShiftTimeSlotEventLog = (
    id: string,
    signal?: AbortSignal
): Promise<IObjectEventLogItem[]> => {
    return getApiCall<IObjectEventLogItem[]>(
        `api/workshifttimeslot/${encodeURIComponent(id)}/eventlog`,
        null,
        signal
    );
};

export const deleteCategoryFromWorkShiftTimeSlot = (
    id: string,
    category: string,
    restore: boolean
): Promise<IObjectEventLogItem[]> => {
    return postApiCall<IObjectEventLogItem[]>(
        `api/workshifttimeslot/${encodeURIComponent(
            id
        )}/ignorecategory/${category}?restore=${encodeURIComponent(
            restore.toString()
        )}`,
        null
    );
};
