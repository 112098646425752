import React from "react";
import { MainLayout } from "../layout/mainLayout";
import { LoadingIndicator } from "../framework/loadingIndicatorNew";
import { ReportingFilters } from "./reportingFiltersNew";
import {
    ReportData,
    ReportDataOptions,
    useReportData,
} from "../../hooks/reporting/useReportData";

interface Props {
    selectedOptions: string[];
    opts?: ReportDataOptions;
    dataInitialized: (data: ReportData) => boolean;
    component: React.ComponentType<ReportData>;
}

export const Reporting = ({
    selectedOptions,
    opts,
    dataInitialized,
    component: Component,
}: Props) => {
    const data = useReportData(selectedOptions, opts);
    const initialized = dataInitialized(data);

    return (
        <MainLayout
            noContainer
            className="fluid"
            topComponent={
                <ReportingFilters
                    vehicles={data.presentVehicles}
                    costCenters={data.presentCostCenters}
                    employees={data.presentEmployees}
                />
            }
        >
            <div style={{ padding: "1rem" }}>
                <LoadingIndicator loading={data.loading || !initialized} />
                {initialized && <Component {...data} />}
            </div>
        </MainLayout>
    );
};
