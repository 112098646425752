// SettingsVehicleDialogToolList - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import * as toolService from "../../services/toolService";
import { Translations } from "../../models/translations";
import { ToolButton } from "../framework/toolButton";
import { SelectIdTitleDialog } from "../framework/selectIdTitleDialog";
import { IEndlessList, EndlessList } from "../../models/common/endlessList";
import { IdTitle, IIdTitle } from "../../models/common/idTitle";

// SettingsVehicleDialogToolListLine
export interface ISettingsVehicleDialogToolListLineProp {
    item: IIdTitle;
    selectedId: string;
    onClick: (id: string) => void;
}

export class SettingsVehicleDialogToolListLine extends React.Component<ISettingsVehicleDialogToolListLineProp, {}> {
    render() {
        const item = this.props.item;
        return (
            <div className={"row line" + (item.id === this.props.selectedId ? " selected" : "")} onClick={() => { this.props.onClick(item.id); }} title={item.title } >
                <div className="col-12">{item.title}</div>
            </div>
        );
    }
}

// SettingsVehicleDialogToolList
export interface ISettingsVehicleDialogToolListProp {
    title?: string;
    items: IIdTitle[];
    onRemoveTool: (id: string) => void;
    onAddTool: (id: string, title: string) => void;
}

interface ISettingsVehicleDialogToolListState {
    selectedId: string;
    showSelectToolDialog: boolean;
    selectToolDialogItems: IIdTitle[];
}

export class SettingsVehicleDialogToolList extends React.Component<ISettingsVehicleDialogToolListProp, ISettingsVehicleDialogToolListState> {
    constructor(props) {
        super(props);
        this.state = { selectedId: null, showSelectToolDialog: false, selectToolDialogItems: [] };
    }

    handleAdd = () => {
        const obj = this;
        toolService.getToolIdTitles(obj.props.items.map(i => i.id)).then(idTitleItems => {
            obj.setState({
                showSelectToolDialog: true,
                selectToolDialogItems: idTitleItems.items
            });
        });
    };

    handleLoadTools = (page: number, filter: string): Promise<IEndlessList<IIdTitle>> => {
        const obj = this;
        const lowFilter = filter.toLowerCase();
        return new Promise<IEndlessList<IdTitle>>((resolve, reject) => {
            const result = new EndlessList<IdTitle>(null, IdTitle);
            result.items = !lowFilter ? obj.state.selectToolDialogItems : obj.state.selectToolDialogItems.filter(i => i.title.toLowerCase().indexOf(lowFilter) > -1);
            result.page = 1;
            result.hasMore = false;
            console.log(result.items);
            resolve(result);
        });
    };

    handleSelectToolDialogOk = (toolId: string, title: string) => {
        const obj = this;
        if (!toolId) return;
        this.props.onAddTool(toolId, title);
        obj.setState({
            showSelectToolDialog: false
        });
    };

    handleSelectToolDialogCancel = () => {
        this.setState({
            showSelectToolDialog: false
        });
    };

    handleRemove = () => {
        const selectedId = this.state.selectedId;
        if (!selectedId) return;
        this.props.onRemoveTool(selectedId);
    };

    handleLineClick = (id: string) => {
        this.setState({
            selectedId: id
        });
    };

    render() {
        const props = this.props;
        const items = props.items;
        return (
            <div>
                <div className="commandRow">
                    {!!props.title &&
                        <label className="control-label listTitle">{props.title}</label>
                    }
                    <ToolButton
                        title={Translations.Remove}
                        enabled={!!this.state.selectedId}
                        classes={"round right remove"}
                        onClick={this.handleRemove}
                    />
                    <ToolButton
                        title={Translations.Add}
                        enabled={true}
                        classes={"round right add"}
                        onClick={this.handleAdd}
                    />
                </div>
                <div className="listContainer vehicleToolsContainer">
                    {items.length > 0 &&
                        <div className="list vehicleTools">
                            {items.map((item) =>
                                <SettingsVehicleDialogToolListLine
                                    key={item.id}
                                    item={item}
                                    selectedId={this.state.selectedId}
                                    onClick={this.handleLineClick}
                                />
                            )}
                        </div>
                    }
                </div>
                {this.state.showSelectToolDialog &&
                    <SelectIdTitleDialog
                        title={Translations.SelectTool}
                        selectedId={null}
                        listbox={true}
                        loadIdCodes={this.handleLoadTools}
                        onOk={this.handleSelectToolDialogOk}
                        onCancel={this.handleSelectToolDialogCancel}
                    />
                }
            </div>
        );
    }
}