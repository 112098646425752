/// <reference path="workmainmap.tsx" />
/* global JSX */
// WorkMainCalendar
// ***********************************************************************************************************************
import * as React from "react";
import { Base } from "../../framework/base";
import { CalendarAccuracyType, WorkOrderState } from "../../models/common/enums";
import { IIdTitle } from "../../models/common/idTitle";
import { INumberTitle } from "../../models/common/numberTitle";
import { ICustomerEdit } from "../../models/customer/customerEdit";
import { IDayBookingItem } from "../../models/dayBooking/dayBookingItem";
import { Translations } from "../../models/translations";
import { ICalendarXPos, IItemLeftWidth, IWorkOrderCalendar, IWorkOrderCalendarDate, IWorkOrderCalendarLine, IWorkOrderCalendarWorkOrderGroup } from "../../models/work/workOrderCalendar";
import { WorkOrderDragData, WorkOrderDragDataType } from "../../models/work/workOrderDragData";
import { IWorkOrderItem } from "../../models/work/workOrderItem";
import { AvatarImage } from "../framework/avatarImage";
import { Button } from "../framework/button";
import { ButtonDropdown } from "../framework/dropdown";
import { HintMark } from "../framework/hintMark";
import { RadioButton } from "../framework/radio";
import { ToolButton } from "../framework/toolButton";
import { WorkMainViewMode } from "./workMain";
import { WorkMainWorkOrderGroup } from "./workMainWorkOrderGroup";
import { WorkNoBox } from "./workNoBox";
import { UseSingleAndDoubleClick } from "../hooks/useSingleAndDoubleClick";

// WorkMainCalendarLegend
// ***********************************************************************************************************************
export interface IWorkMainCalendarLegendProp {
    classes?: string;
    title: string;
}

export class WorkMainCalendarLegend extends React.Component<IWorkMainCalendarLegendProp, {}> {
    render() {
        const props = this.props;
        return (
            <div className={"legend" + (props.classes ? " " + props.classes : "")}><div className="box" /><div className="text">{props.title}</div></div>
        );
    }
}

// WorkMainCalendarDayTitle
// ***********************************************************************************************************************
export interface IWorkMainCalendarDayTitleProp {
    classes?: string;
    date: IWorkOrderCalendarDate;
    nowTime: number;
}

export interface IWorkMainCalendarDayTitleState {
    pressed: boolean;
}

export class WorkMainCalendarDayTitle extends React.Component<IWorkMainCalendarDayTitleProp, IWorkMainCalendarDayTitleState> {
    constructor(props) {
        super(props);
        this.state = { pressed: false };
    }

    render() {
        const props = this.props;
        if (!props.date.visible) return null;
        const date = props.date;
        const dayTitleClass = props.date.colClass + (props.classes ? " " + props.classes : "") + (" dt" + date.dayType) + (props.nowTime === date.dayTime ? " cdt" : "") + (this.state.pressed ? " pressed" : "");
        return (
            <div className={dayTitleClass} title={date.dayTitle + (date.dayDescription ? ", " + date.dayDescription : "")}>
                <div className="wd">{date.dayTitle}</div>
                <div className="dt">{date.title}</div>
            </div>
        );
    }
}

// WorkMainCalendarEmployeeDay
// ***********************************************************************************************************************
export interface IWorkMainCalendarEmployeeDataColProp {
    classes?: string;
    top: number;
    bottom: number;
    line: IWorkOrderCalendarLine;
    getCalendarXPos: (x: number) => ICalendarXPos;
    drawCalendarCell: (x: number, y: number, width: number, height: number, startTime: number) => void;
    clearCalendarCell: () => void;
    onMoveWorkOrderToEmployee: (id: string, oldEmployeeId: string, newEmployeeId: string, startTime: number) => void;
    onMoveWorkOrderToVehicle: (id: string, oldVehicleId: string, newVehicleId: string, startTime: number) => void;
    onMoveWorkOrder: (id: string, startTime: number) => void;
    onMoveProject: (id: string, startTime: number) => void;
}

export class WorkMainCalendarEmployeeDataCol extends React.Component<IWorkMainCalendarEmployeeDataColProp, {}> {
    canDropInto = (dragData: WorkOrderDragData): boolean => {
        const line = this.props.line;
        const isProject = line.isProject();
        return !line.isTitle() && dragData.isLessThanInProgress() && dragData.isWorkOrderType() && (
            isProject && dragData.id === line.id ||
            line.isRecurringProject() && !!line.workOrders.find(i => i.workOrder.id === dragData.id) ||
            (line.isProjectTask() || line.isUnassignedWork()) && dragData.id === line.id ||
            (line.isEmployee() || line.isVehicle()) && !dragData.isProject());
    };

    onDragOver = (ev) => {
        const props = this.props;
        const dragData = WorkOrderDragData.getWorkOrderDragData(ev);
        if (!this.canDropInto(dragData)) return;
        ev.preventDefault();
        const xPos = props.getCalendarXPos(ev.clientX);
        props.drawCalendarCell(xPos.x, props.top, dragData.width > 0 ? dragData.width : xPos.width, props.bottom - props.top, xPos.startTime);
        // console.log("WorkMainCalendarEmployeeDataColDragOver", id);
    };

    onDragLeave = (ev) => {
        const dragData = WorkOrderDragData.getWorkOrderDragData(ev);
        if (!this.canDropInto(dragData)) return;
        ev.preventDefault();
        this.props.clearCalendarCell();
        // console.log("WorkMainCalendarEmployeeDataColOnDragLeave", id);
    };

    onDrop = (ev) => {
        const props = this.props;
        const dragData = WorkOrderDragData.getWorkOrderDragData(ev);
        //console.log("dragData", dragData);
        //console.log("line", props.line.isEmployee(), props.line.isVehicle());
        if (!this.canDropInto(dragData)) return;
        ev.preventDefault();
        props.clearCalendarCell();
        const line = props.line;
        const xPos = props.getCalendarXPos(ev.clientX);
        if (line.isEmployee()) {
            if (dragData.isProject() || dragData.hasChildren || (dragData.employeeId === line.id && Math.abs(dragData.startTime - xPos.startTime) < 0.1)) return;
            props.onMoveWorkOrderToEmployee(dragData.id, dragData.employeeId, line.id, xPos.startTime);
        } else if (line.isVehicle()) {
            if (dragData.isProject() || dragData.hasChildren || (dragData.vehicleId === line.id && Math.abs(dragData.startTime - xPos.startTime) < 0.1)) return;
            props.onMoveWorkOrderToVehicle(dragData.id, dragData.vehicleId, line.id, xPos.startTime);
        } else if (line.isRecurringProject()) {
            if (!line.workOrders.find(i => i.workOrder.id === dragData.id)) return;
            props.onMoveWorkOrder(dragData.id, xPos.startTime);
        } else if (line.isProject()) {
            props.onMoveProject(dragData.id, xPos.startTime);
        } else if (line.isUnassignedWork() || line.isProjectTask()) {
            if (line.id !== dragData.id) return;
            props.onMoveWorkOrder(dragData.id, xPos.startTime);
        }
        // console.log("WorkMainCalendarEmployeeDataColOnDrop", dragData.employeeId, props.line.id, xPos.startTime);
    };

    render() {
        return (
            <div className="dataCol" onDragOver={(e) => this.onDragOver(e)} onDrop={this.onDrop} onDragLeave={this.onDragLeave} />
        );
    }
}

// WorkMainCalendarEmployee
// ***********************************************************************************************************************
export interface IWorkMainCalendarEmployeeProp {
    classes?: string;
    nowTime: number;
    dates: IWorkOrderCalendarDate[];
    item: IWorkOrderCalendarLine;
    top: number;
    height: number;
    getCalendarXPos: (x: number) => ICalendarXPos;
    drawCalendarCell: (x: number, y: number, width: number, height: number, startTime: number) => void;
    clearCalendarCell: () => void;
    onEditEmployee: (id: string) => void;
    onEditVehicle: (id: string) => void;
    onEditWorkOrder: (id: string) => void;
    onSetSlectedWorkOrder: (id: string) => void;
    onMoveWorkOrderToEmployee: (id: string, oldEmployeeId: string, newEmployeeId: string, startTime: number) => void;
    onMoveWorkOrderToVehicle: (id: string, oldVehicleId: string, newVehicleId: string, startTime: number) => void;
    onMoveProject: (id: string, startTime: number) => void;
    onMoveWorkOrder: (id: string, startTime: number) => void;
    onExpandCollapseLine: (collapse: boolean) => void;
    onExpandCollapseCategory: (category: boolean) => void;
}

export interface IWorkMainCalendarEmployeeState {
}

export class WorkMainCalendarEmployee extends React.Component<IWorkMainCalendarEmployeeProp, IWorkMainCalendarEmployeeState> {
    private dayDiv: HTMLDivElement;

    constructor(props) {
        super(props);
        this.state = { focusedDropTime: null };
    }

    handleEditEmployee = () => {
        const props = this.props;
        if (props.item.isEmployee()) {
            props.onEditEmployee(props.item.id);
        } else if (props.item.isVehicle()) {
            props.onEditVehicle(props.item.id);
        } else if (props.item.isProject()) {
            props.onSetSlectedWorkOrder(props.item.id);
        } else {
            props.onEditWorkOrder(props.item.id);
        }
    };

    canDropInto = (dragData: WorkOrderDragData): boolean => {
        const line = this.props.item;
        return !line.isTitle() && dragData.isLessThanInProgress() && dragData.isWorkOrderType() &&
            (line.isEmployee() || line.isVehicle()) && !dragData.isProject();
    };

    onDragOver = (ev) => {
        const dragData = WorkOrderDragData.getWorkOrderDragData(ev);
        if (!this.canDropInto(dragData)) return;
        ev.preventDefault();
        if (this.dayDiv.classList.contains("focused")) return;
        this.dayDiv.classList.add("focused");
        // console.log("employeeColOnDragOver", id);
    };

    onDragLeave = (ev) => {
        const dragData = WorkOrderDragData.getWorkOrderDragData(ev);
        if (!this.canDropInto(dragData)) return;
        ev.preventDefault();
        this.dayDiv.classList.remove("focused");
        // console.log("employeeColOnDragLeave", id);
    };

    onDrop = (ev) => {
        const props = this.props;
        const dragData = WorkOrderDragData.getWorkOrderDragData(ev);
        if (!this.canDropInto(dragData)) return;
        ev.preventDefault();
        this.dayDiv.classList.remove("focused");
        if (props.item.isEmployee()) {
            if (dragData.employeeId === props.item.id) return;
            this.props.onMoveWorkOrderToEmployee(dragData.id, dragData.employeeId, props.item.id, null);
        } else if (props.item.isVehicle()) {
            if (dragData.vehicleId === props.item.id) return;
            this.props.onMoveWorkOrderToVehicle(dragData.id, dragData.vehicleId, props.item.id, null);
        }
        // console.log("employeeColOnDrop", id);
    };

    onDragStart = (ev, itemType: WorkOrderDragDataType, id: string) => {
        //console.log("WorkMainCalendarEmployeeOnDragStart", itemType, id);
        WorkOrderDragData.setWorkOrderDragData(ev, itemType, id, 0, 0, itemType === WorkOrderDragDataType.Employee ? id : "", itemType === WorkOrderDragDataType.Vehicle ? id : "", 0, false, 0);
    };

    render() {
        const props = this.props;
        const item = props.item;
        const hourClass = "employeeRow" + (props.classes ? " " + props.classes : "") + (item.visible && item.categoryVisible ? "" : " d-none");
        const itemStyle = {
            height: props.height + "px",
        };
        const lineTypeClass = (item.isEmployee()
            ? "employee"
            : (item.isProject()
                ? "project"
                : (item.isProjectTask() || item.isUnassignedWork()
                    ? "task"
                    : (item.isTitle()
                        ? "title"
                        : (item.isVehicle()
                            ? "vehicle"
                            : (item.isTeam()
                                ? "team"
                                : "")))))) + (item.childLineIds.length > 0 && item.childLinesVisible ? " expanded" : "");
        const draggingEnabled = item.isEmployee() || item.isVehicle();
        const isEmployeeOrVehicle = item.isEmployee() || item.isVehicle();
        return (
            <div className={hourClass} style={itemStyle}>
                <div className={"titleCol " + lineTypeClass + (item.parenLineId ? (" subLine" + (item.isLastChild ? " lastChild" : "")) : "")}>
                    <div ref={(elem) => { this.dayDiv = elem; }} onDrop={this.onDrop} onDragOver={(e) => this.onDragOver(e)} onDragLeave={this.onDragLeave}>
                        <div onDragStart={draggingEnabled ? (ev) => this.onDragStart(ev, item.isEmployee() ? WorkOrderDragDataType.Employee : WorkOrderDragDataType.Vehicle, item.id) : null} draggable={draggingEnabled}>
                            <div className="link">
                                {isEmployeeOrVehicle &&
                                    <AvatarImage
                                        classes={"left small calendar" + (item.isVehicle() ? " oval" : "")}
                                        image={item.picture}
                                        fullText={item.isVehicle() || !!item.abbreviation}
                                        name={item.getAvatarText()}
                                        tooltip={item.name}
                                    />
                                }
                                {!isEmployeeOrVehicle && !item.isTitle() &&
                                    <div className={"icon lt " + lineTypeClass} />
                                }
                                {item.isTitle() &&
                                    <div className="row1" title={item.tooltip}>{item.name}{!!item.hint && <HintMark classes={"smallIcon"} hint={item.hint} />}</div>
                                }
                                {item.isTitle() &&
                                    <ToolButton
                                        title={item.childLinesVisible ? Translations.Collapse : Translations.Expand}
                                        enabled={true}
                                        classes={"round " + (item.childLinesVisible ? "doCollapse" : "doExpand")}
                                        onClick={() => { props.onExpandCollapseCategory(item.childLinesVisible); }}
                                    />
                                }
                                {item.isTitle() &&
                                    <div className="amountIndicator">{item.numberOfChildren}</div>
                                }
                                {!item.isTitle() &&
                                    <UseSingleAndDoubleClick onClick={this.handleEditEmployee} onDoubleClick={this.handleEditEmployee} className="row1">
                                        <div title={item.tooltip}>{item.isEmployee() || item.isVehicle() ? item.name : item.number.toString(10) + "  " + item.customerName + ", " + item.name + ", " + item.description}</div>
                                    </UseSingleAndDoubleClick>
                                }
                                {item.childLineIds.length > 0 &&
                                    <ToolButton
                                        title={item.childLinesVisible ? Translations.Collapse : Translations.Expand}
                                        enabled={true}
                                        classes={"round " + (item.childLinesVisible ? "doCollapse" : "doExpand")}
                                        onClick={() => { props.onExpandCollapseLine(item.childLinesVisible); }}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <WorkMainCalendarEmployeeDataCol
                    top={props.top}
                    bottom={props.top + props.height}
                    line={props.item}
                    getCalendarXPos={props.getCalendarXPos}
                    drawCalendarCell={props.drawCalendarCell}
                    clearCalendarCell={props.clearCalendarCell}
                    onMoveWorkOrderToEmployee={props.onMoveWorkOrderToEmployee}
                    onMoveWorkOrderToVehicle={props.onMoveWorkOrderToVehicle}
                    onMoveProject={props.onMoveProject}
                    onMoveWorkOrder={props.onMoveWorkOrder}
                />
            </div>
        );
    }
}

export interface IWorkMainCalendarFillerLineProp {
    classes?: string;
    height: number;
}

export class WorkMainCalendarFillerLine extends React.Component<IWorkMainCalendarFillerLineProp, {}> {
    render() {
        const props = this.props;
        const hourClass = "employeeRow" + (props.classes ? " " + props.classes : "");
        const itemStyle = {
            height: props.height + "px",
        };
        return (
            <div className={hourClass} style={itemStyle}>
                <div className="titleCol filler"><div><div /></div></div>
                <div className="dataCol filler" />
            </div>
        );
    }
}

// WorkMainCalendarDayColumns
// ***********************************************************************************************************************
export interface IWorkMainCalendarDayColumnProp {
    date: IWorkOrderCalendarDate;
    nowTime: number;
}

export class WorkMainCalendarDayColumn extends React.Component<IWorkMainCalendarDayColumnProp, {}> {
    render() {
        const props = this.props;
        const date = props.date;
        if (!date.visible) return null;
        const dayClass = date.colClass + (" dt" + date.dayType) + (props.nowTime === date.dayTime ? " cdt" : "") + (!date.officeHour ? " nof" : "");
        return (
            <div className={dayClass}>&nbsp;</div>
        );
    }
}

export interface IWorkMainCalendarDayColumnsProp {
    classes?: string;
    nowTime: number;
    dates: IWorkOrderCalendarDate[];
    height: number;
    width: number;
    left: number;
}

export class WorkMainCalendarDayColumns extends React.Component<IWorkMainCalendarDayColumnsProp, {}> {
    render() {
        const props = this.props;
        const hourClass = "calendarCols" + (props.classes ? " " + props.classes : "");
        const itemStyle = {
            height: props.height + "px",
            width: props.width + "px",
            left: props.left + "px",
        };
        return (
            <div className={hourClass} style={itemStyle}>
                {props.dates.map((date, index) =>
                    <WorkMainCalendarDayColumn
                        key={date.timeTime}
                        date={date}
                        nowTime={props.nowTime}
                    />
                )}
            </div>
        );
    }
}

// WorkMainCalendarDayBooking
// ***********************************************************************************************************************
export interface IWorkMainCalendarDayBookingProp {
    classes?: string;
    top: number;
    bottom: number;
    leftAndWidth: IItemLeftWidth;
    line: IWorkOrderCalendarLine;
    item: IDayBookingItem;
    getCalendarXPos: (x: number) => ICalendarXPos;
    drawCalendarCell: (x: number, y: number, width: number, height: number, startTime: number) => void;
    clearCalendarCell: () => void;
    onMoveWorkOrderToEmployee: (id: string, oldEmployeeId: string, newEmployeeId: string, startTime: number) => void;
}

export interface IWorkMainCalendarDayBookingState {
    top: number;
    bottom: number;
    left: number;
    width: number;
    isValidPosition: boolean;
}

export class WorkMainCalendarDayBooking extends React.Component<IWorkMainCalendarDayBookingProp, IWorkMainCalendarDayBookingState> {
    private itemDiv: HTMLDivElement;

    constructor(props) {
        super(props);
        this.state = { top: props.top, bottom: props.bottom, left: props.leftAndWidth.left, width: props.leftAndWidth.width, isValidPosition: true };
    }

    UNSAFE_componentWillReceiveProps(nextProps: IWorkMainCalendarDayBookingProp, nextContext): void {
        this.setState({ top: nextProps.top, bottom: nextProps.bottom, left: nextProps.leftAndWidth.left, width: nextProps.leftAndWidth.width });
    }

    canDropInto = (dragData: WorkOrderDragData): boolean => {
        const props = this.props;
        return !props.line.isProject() && !dragData.isProject() && dragData.isWorkOrderType() && dragData.isLessThanInProgress();
    };

    onDragOver = (ev: React.DragEvent<HTMLDivElement>) => {
        const props = this.props;
        const state = this.state;
        const dragData = WorkOrderDragData.getWorkOrderDragData(ev);
        if (!this.canDropInto(dragData)) return;
        ev.preventDefault();
        const xPos = props.getCalendarXPos(ev.clientX);
        props.drawCalendarCell(xPos.x, state.top, dragData.width > 0 ? dragData.width : xPos.width, state.bottom - state.top, xPos.startTime);
        // console.log("WorkMainCalendarDayBookingOnDragOver", id);
    };

    onDragLeave = (ev) => {
        const dragData = WorkOrderDragData.getWorkOrderDragData(ev);
        if (!this.canDropInto(dragData)) return;
        ev.preventDefault();
        this.props.clearCalendarCell();
        // console.log("WorkMainCalendarDayBookingOnDragLeave", id);
    };

    onDrop = (ev: React.DragEvent<HTMLDivElement>) => {
        const props = this.props;
        const dragData = WorkOrderDragData.getWorkOrderDragData(ev);
        if (!this.canDropInto(dragData)) return;
        ev.preventDefault();
        this.props.clearCalendarCell();
        const item = this.props.item;
        const xPos = props.getCalendarXPos(ev.clientX);
        if (dragData.id === item.id || (dragData.id !== item.id && dragData.employeeId === item.employeeId && Math.abs(dragData.startTime - xPos.startTime) < 0.1)) return;
        this.props.onMoveWorkOrderToEmployee(dragData.id, dragData.employeeId, item.employeeId, xPos.startTime);
        // console.log("WorkMainCalendarDayBookingOnDrop", dragData.id, dragData.employeeId, item.employeeId, xPos.startTime);
    };

    render() {
        const props = this.props;
        const state = this.state;
        if (!props.line.visible || !props.line.categoryVisible) return null;
        const item = props.item;
        const itemStyle = {
            top: state.top + "px",
            bottom: state.bottom + "px",
            left: state.left + "px",
            width: state.width + "px",
            height: (state.bottom - state.top) + "px"
        };
        return (
            <div className={"dbItem" + item.getClassName()} style={itemStyle} title={item.getComment()} ref={(elem) => { this.itemDiv = elem; }} draggable={false} onDrop={this.onDrop} onDragOver={(e) => this.onDragOver(e)} onDragLeave={this.onDragLeave} />
        );
    }
}

// WorkMainCalendarEmployeeWorkOrder
// ***********************************************************************************************************************
export interface IWorkMainCalendarEmployeeWorkOrderProp {
    classes?: string;
    dayTop: number;
    dayBottom: number;
    top: number;
    leftAndWidth: IItemLeftWidth;
    isSubLine: boolean;
    isVisible: boolean;
    isDisabled: boolean;
    item: IWorkOrderItem;
    employeeId: string;
    vehicleId: string;
    selectedWorkOrderId: string;
    getCalendarXPos: (x: number) => ICalendarXPos;
    drawCalendarCell: (x: number, y: number, width: number, height: number, startTime: number) => void;
    clearCalendarCell: () => void;
    onClick: (id: string) => void;
    onEditWorkOrder: (id: string) => void;
    onMoveWorkOrderToEmployee: (id: string, oldEmployeeId: string, newEmployeeId: string, startTime: number) => void;
    onMoveWorkOrderToVehicle: (id: string, oldVehicleId: string, newVehicleId: string, startTime: number) => void;
}

export interface IWorkMainCalendarEmployeeWorkOrderState {
    top: number;
    left: number;
    width: number;
    isValidPosition: boolean;
}

export class WorkMainCalendarEmployeeWorkOrder extends React.Component<IWorkMainCalendarEmployeeWorkOrderProp, IWorkMainCalendarEmployeeWorkOrderState> {
    private workOrderDiv: HTMLDivElement;

    constructor(props) {
        super(props);
        this.state = { top: props.top, left: props.leftAndWidth.left, width: props.leftAndWidth.width, isValidPosition: true };
    }

    UNSAFE_componentWillReceiveProps(nextProps: IWorkMainCalendarEmployeeWorkOrderProp, nextContext): void {
        this.setState({ top: nextProps.top, left: nextProps.leftAndWidth.left, width: nextProps.leftAndWidth.width });
    }

    onDragStart = (ev, id: string, state: number, category: number, employeeId: string, vehicleId: string, startTime: number, hasChildren: boolean) => {
        //console.log("WorkMainCalendarEmployeeWorkOrderOnDragStart", id, employeeId, vehicleId);
        WorkOrderDragData.setWorkOrderDragData(ev, WorkOrderDragDataType.WorkOrder, id, state, category, employeeId, vehicleId, startTime, hasChildren, this.props.leftAndWidth.fullWidth);
        ev.dataTransfer.setDragImage(this.workOrderDiv, 5, this.workOrderDiv.clientHeight / 2);
    };

    canDropInto = (dragData: WorkOrderDragData): boolean => {
        const props = this.props;
        return dragData.isLessThanInProgress() && dragData.isWorkOrderType() &&
            props.item.isWork() && dragData.isWork() && props.item.id !== dragData.id;
    };

    onDragOver = (ev: React.DragEvent<HTMLDivElement>) => {
        const props = this.props;
        const dragData = WorkOrderDragData.getWorkOrderDragData(ev);
        if (!this.canDropInto(dragData)) return;
        ev.preventDefault();
        const xPos = props.getCalendarXPos(ev.clientX);
        props.drawCalendarCell(xPos.x, props.dayTop, dragData.width > 0 ? dragData.width : xPos.width, props.dayBottom - props.dayTop, xPos.startTime);
        // console.log("WorkMainCalendarEmployeeWorkOrderOnDragOver", id);
    };

    onDragLeave = (ev) => {
        const dragData = WorkOrderDragData.getWorkOrderDragData(ev);
        if (!this.canDropInto(dragData)) return;
        ev.preventDefault();
        this.props.clearCalendarCell();
        // console.log("WorkMainCalendarEmployeeWorkOrderOnDragLeave", id);
    };

    onDrop = (ev: React.DragEvent<HTMLDivElement>) => {
        const props = this.props;
        const dragData = WorkOrderDragData.getWorkOrderDragData(ev);
        if (!this.canDropInto(dragData)) return;
        ev.preventDefault();
        props.clearCalendarCell();
        const employeeId = props.employeeId;
        const vehicleId = props.vehicleId;
        const xPos = this.props.getCalendarXPos(ev.clientX);
        if (dragData.employeeId === employeeId && dragData.vehicleId === vehicleId && Math.abs(dragData.startTime - xPos.startTime) < 0.1) return;
        if (employeeId) {
            props.onMoveWorkOrderToEmployee(dragData.id, dragData.employeeId, employeeId, xPos.startTime);
        } else if (vehicleId) {
            props.onMoveWorkOrderToVehicle(dragData.id, dragData.vehicleId, vehicleId, xPos.startTime);
        }
        /*console.log("WorkMainCalendarEmployeeWorkOrderOnDrop", dragData.employeeId, employeeId, dragData.startTime, xPos.startTime);*/
    };

    handleClick = () => {
        this.props.onClick(this.props.item.id);
    };

    handleEditWorkOrder = () => {
        this.props.onEditWorkOrder(this.props.item.id);
    };

    render() {
        const props = this.props;
        const state = this.state;
        if (!props.isVisible) return null;
        const item = props.item;
        const itemClass = "item" + (" state" + props.item.state) + (props.selectedWorkOrderId === item.id ? " selected" : "") +
            (props.classes ? " " + props.classes : "") + (!state.isValidPosition ? " invalid" : "") +
            (props.isSubLine ? " sub" : "") + (item.canceled ? " canceled" : "") + (props.isDisabled ? " disabled" : "");
        const itemStyle = {
            top: state.top + "px",
            left: state.left + "px",
            width: state.width + "px"
        };
        let draggingEnabled = !Base.isNullOrUndefined(item);
        if (draggingEnabled) {
            draggingEnabled = item.isLessThanInProgress();
        }
        if (!props.isSubLine) {
            return (
                <UseSingleAndDoubleClick onClick={this.handleClick} onDoubleClick={this.handleEditWorkOrder}>
                    <div ref={(elem) => { this.workOrderDiv = elem; }} className={itemClass} style={itemStyle} title={item.tooltip} data-id={item.id}
                        onDragStart={draggingEnabled ? (ev) => this.onDragStart(ev, item.id, item.state, item.category, props.employeeId, props.vehicleId, item.startTime, item.hasChildren) : null} draggable={draggingEnabled}
                        onDrop={this.onDrop} onDragOver={(e) => this.onDragOver(e)} onDragLeave={this.onDragLeave}
                    >
                        <WorkNoBox
                            id={item.id}
                            state={item.state}
                            number={item.number}
                            parentNumber={item.parentNumber}
                            onClick={props.onEditWorkOrder}
                        />
                        {item.getTitle()}
                    </div>
                </UseSingleAndDoubleClick>
            );
        } else {
            return (
                <UseSingleAndDoubleClick onClick={this.handleClick} onDoubleClick={this.handleEditWorkOrder}>
                    <div ref={(elem) => { this.workOrderDiv = elem; }} className={itemClass} style={itemStyle} title={item.tooltip} data-id={item.id}
                        onDragStart={draggingEnabled ? (ev) => this.onDragStart(ev, item.id, item.state, item.category, props.employeeId, props.vehicleId, item.startTime, item.hasChildren) : null} draggable={draggingEnabled}
                        onDrop={this.onDrop} onDragOver={(e) => this.onDragOver(e)} onDragLeave={this.onDragLeave}
                    >
                        <WorkNoBox
                            id={item.id}
                            state={item.state}
                            number={item.number}
                            parentNumber={item.parentNumber}
                            onClick={props.onEditWorkOrder}
                        />
                        {item.getTitle()}
                    </div>
                </UseSingleAndDoubleClick>
            );
        }
    }
}

// WorkMainCalendarEmployeeWorkOrderGroup
// ***********************************************************************************************************************
export interface IWorkMainCalendarEmployeeWorkOrderGroupProp {
    classes?: string;
    dayTop: number;
    dayBottom: number;
    top: number;
    leftAndWidth: IItemLeftWidth;
    isSubLine: boolean;
    isVisible: boolean;
    item: IWorkOrderCalendarWorkOrderGroup;
    selectedWorkOrderId: string;
    onShowWorkOrderGroupMenu: (top: number, left: number, items: IWorkOrderItem[]) => void;
    onClick: (id: string) => void;
    onEditWorkOrder: (id: string) => void;
}

export interface IWorkMainCalendarEmployeeWorkOrderGroupState {
    top: number;
    left: number;
    width: number;
    isValidPosition: boolean;
}

export class WorkMainCalendarEmployeeWorkOrderGroup extends React.Component<IWorkMainCalendarEmployeeWorkOrderGroupProp, IWorkMainCalendarEmployeeWorkOrderGroupState> {
    constructor(props) {
        super(props);
        this.state = { top: props.top, left: props.leftAndWidth.left, width: props.leftAndWidth.width, isValidPosition: true };
    }

    UNSAFE_componentWillReceiveProps(nextProps: IWorkMainCalendarEmployeeWorkOrderGroupProp, nextContext): void {
        this.setState({ top: nextProps.top, left: nextProps.leftAndWidth.left, width: nextProps.leftAndWidth.width });
    }

    handleClick = () => {
        const props = this.props;
        const state = this.state;
        const item = props.item;
        const workOrder = item.items.length === 1 ? item.items[0] : null;
        if (workOrder) {
            props.onClick(workOrder.id);
        } else {
            props.onShowWorkOrderGroupMenu(state.top, state.left, item.items);
        }
    };

    handleEditWorkOrder = () => {
        const props = this.props;
        const item = props.item;
        const workOrder = item.items.length === 1 ? item.items[0] : null;
        if (workOrder) {
            props.onEditWorkOrder(workOrder.id);
        }
    };

    render() {
        const props = this.props;
        const state = this.state;
        const item = props.item;
        const workOrder = item.items.length === 1 ? item.items[0] : null;
        if (!props.isVisible) return null;
        const itemClass = "group" + (props.classes ? " " + props.classes : "") + (!state.isValidPosition ? " invalid" : "") +
            (props.isSubLine ? " sub" : "") + (item.disabled ? " disabled" : "");
        const itemStyle = {
            top: state.top + "px",
            left: state.left + "px",
            width: state.width + "px",
            height: state.width + "px"
        };

        return (
            <UseSingleAndDoubleClick onClick={this.handleClick} onDoubleClick={workOrder ? this.handleEditWorkOrder : null}>
                <div className={itemClass} style={itemStyle} title={workOrder ? workOrder.tooltip : ""}>
                    <div className={"content state" + item.state.toString(10) + (workOrder && props.selectedWorkOrderId === workOrder.id ? " selected" : "")}>{item.items.length.toString(10)}</div>
                </div>
            </UseSingleAndDoubleClick>
        );
    }
}

// ActionsMenu
export interface IActionsMenuProp {
    top: number;
    left: number;
    itemIsSelected: boolean;
    onNew: () => void;
    onOpen: () => void;
    onEdit: () => void;
    onRemove: () => void;
    onCopy: () => void;
    onCancel: () => void;
}

export class ActionsMenu extends React.Component<IActionsMenuProp, {}> {
    handleNewClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.onNew();
    };

    handleCopyClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.onCopy();
    };

    handleOpenClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.onOpen();
    };

    handleEditClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.onEdit();
    };

    handleRemoveClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.onRemove();
    };

    handleCancelClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.onCancel();
    };

    render() {
        const itemIsSelected = this.props.itemIsSelected;
        const style = {
            top: this.props.top + "px",
            left: this.props.left + "px",
        };
        return (
            <div className="actionMenu" onClick={this.handleCancelClick}>
                <div className="menu" style={style}>
                    <div className="line" onClick={this.handleNewClick}>{Translations.New}</div>
                    <div className={"line" + (itemIsSelected ? "" : " disabled")} onClick={itemIsSelected ? this.handleCopyClick : null}>{Translations.Copy}</div>
                    <div className={"line" + (itemIsSelected ? "" : " disabled")} onClick={itemIsSelected ? this.handleOpenClick : null}>{Translations.Open}</div>
                    <div className={"line" + (itemIsSelected ? "" : " disabled")} onClick={itemIsSelected ? this.handleEditClick : null}>{Translations.Edit}</div>
                    <div className={"line" + (itemIsSelected ? "" : " disabled")} onClick={itemIsSelected ? this.handleRemoveClick : null}>{Translations.Remove}</div>
                </div>
            </div>
        );
    }
}

// ***********************************************************************************************************************
export interface IWorkMainCalendarProp {
    dayBookingTypes: INumberTitle[];
    workOrderTemplates: IWorkOrderItem[];
    activeTime: number;
    startDate: number;
    endDate: number;
    calendar: IWorkOrderCalendar;
    selectedWorkOrderId: string;
    onItemClick: (id: string) => void;
    onItemDoubleClick: (id: string) => void;
    onItemsSelected: (ids: string[]) => void;
    onPreviousClick: () => void;
    onNextClick: () => void;
    onPreviousIndexClick: () => void;
    onNextIndexClick: () => void;
    onTodayClick: () => void;
    onNewWorkOrder: () => void;
    onNewWorkOrderBasedOnTemplate: () => void;
    onNewProjectBasedOnTemplate: () => void;
    onNewProject: () => void;
    onFindWeekClick: () => void;
    onOpenSelectedWorkOrder: () => void;
    onEditSelectedWorkOrder: () => void;
    onRemoveWorkOrder: () => void;
    onCopyWorkOrder: () => void;
    onEmployeeEdit: (id: string) => void;
    onVehicleEdit: (id: string) => void;
    onAddCustomer: () => void;
    onAddContact: () => void;
    onEditWorkOrder: (id: string) => void;
    onMoveWorkOrderToEmployee: (id: string, oldEmployeeId: string, newEmployeeId: string, startTime: number) => void;
    onMoveWorkOrderToVehicle: (id: string, oldVehicleId: string, newVehicleId: string, startTime: number) => void;
    onMoveWorkOrder: (id: string, startTime: number) => void;
    onMoveProject: (id: string, startTime: number) => void;
    onExpandCollapseCalendarLine: (lineId: string, collapse: boolean) => void;
    onExpandCollapseCalendarCategory: (category: string, collapse: boolean) => void;
    onChangeViewMode: (viewMode: WorkMainViewMode) => void;
    onChangeCalendarAccuracy: (calendarAccuracy: CalendarAccuracyType) => void;
}

export interface IWorkMainCalendarState {
    showActionsMenu: boolean;
    height: number;
    width: number;
    yOffset: number;
    xOffset: number;
    dragging: boolean;
    startX: number;
    startY: number;
    endX: number;
    endY: number;
    relX: number;
    relY: number;
    lineHeight: number;
    fillerLineHeight: number;
    employeeGridWidth: string;
    employeeGridGridColWidth: number;
    customerDialogOkCallback: (customerId: string) => void;
    customerDialogItem: ICustomerEdit;
    customers: IIdTitle[];
    selectedId: string;
    showWorkOrderGroupMenu: boolean;
    workOrderGroupMenuTop: number;
    workOrderGroupMenuLeft: number;
    workOrderGroupMenuItems: IWorkOrderItem[];
}

export class WorkMainCalendar extends React.Component<IWorkMainCalendarProp, IWorkMainCalendarState> {
    private weekDiv: HTMLDivElement;
    private templateEmployeeRowDiv: HTMLDivElement;
    private templateEmployeeRowGridColDiv: HTMLDivElement;
    private titleRowDiv: HTMLDivElement;
    private titleRowADiv: HTMLDivElement;
    private employeeGridDiv: HTMLDivElement;
    private titleColDiv: HTMLDivElement;
    private footerRowDiv: HTMLDivElement;
    private dataGridCanvas: HTMLCanvasElement;

    private canvasRectX: number = -1;
    private canvasRectY: number = -1;
    private canvasRectWidth: number = -1;
    private canvasRectHeight: number = -1;

    private calendarAccuracyOptions = [
        { id: CalendarAccuracyType.QuarterHour, title: Translations.CalendarAccuracyQuarterHour },
        { id: CalendarAccuracyType.FourHours, title: Translations.CalendarAccuracyFourHours },
        { id: CalendarAccuracyType.DayTwoWeeks, title: Translations.CalendarAccuracyDayTwoWeeks }
    ];

    constructor(props) {
        super(props);
        this.state = {
            showActionsMenu: false,
            height: 0,
            width: 0,
            yOffset: 0,
            xOffset: 0,
            dragging: false,
            startX: 0,
            startY: 0,
            endX: 0,
            endY: 0,
            relX: 0,
            relY: 0,
            lineHeight: 25,
            fillerLineHeight: 0,
            employeeGridWidth: "100%",
            employeeGridGridColWidth: 0,
            customerDialogOkCallback: null,
            customerDialogItem: null,
            customers: [],
            selectedId: null,
            showWorkOrderGroupMenu: false,
            workOrderGroupMenuTop: 0,
            workOrderGroupMenuLeft: 0,
            workOrderGroupMenuItems: []
        };
    }

    updateHeight = () => {
        if (!this.employeeGridDiv || !this.titleRowDiv || !this.titleColDiv || !this.footerRowDiv || !this.templateEmployeeRowGridColDiv) return;
        const state = this.state;
        const yOffset = this.titleRowDiv.clientHeight;
        const xOffset = this.titleColDiv.clientWidth;
        const lineHeight = Base.isNullOrUndefined(this.templateEmployeeRowDiv) ? this.state.lineHeight : this.templateEmployeeRowDiv.clientHeight;
        const employeeGridWidth = this.employeeGridDiv.clientWidth + "px";
        const height = this.weekDiv.clientHeight - this.footerRowDiv.clientHeight + 1;
        //const fillerLineHeight = Math.max((height - yOffset) - this.employeeGridDiv.clientHeight, 30);
        //const fillerLineHeight = Math.max((lineHeight * this.props.calendar.totalLinesCount) - this.employeeGridDiv.clientHeight, 30);
        const fillerLineHeight = Math.max(Math.max((height - yOffset) - this.employeeGridDiv.clientHeight, 30), Math.max((lineHeight * this.props.calendar.totalLinesCount) - this.employeeGridDiv.clientHeight, 30));
        const employeeGridGridColWidth = this.templateEmployeeRowGridColDiv.offsetWidth;
        //const employeeGridGridColWidth = this.props.calendar.calendarAccuracy !== CalendarAccuracyType.QuarterHour
        //    ? (this.employeeGridDiv.clientWidth - xOffset) / this.props.calendar.totalCols
        //    : this.templateEmployeeRowGridColDiv.offsetWidth;
        //console.log("this.employeeGridDiv.clientWidth", this.employeeGridDiv.clientWidth);
        //console.log("xOffset", xOffset);
        //console.log("this.props.calendar.totalCols", this.props.calendar.totalCols);
        //console.log("employeeGridGridColWidth", state.employeeGridGridColWidth, employeeGridGridColWidth);
        if (state.height !== height || state.width !== this.weekDiv.clientWidth || state.yOffset !== yOffset || state.xOffset !== xOffset || state.lineHeight !== lineHeight || state.employeeGridWidth !== employeeGridWidth ||
            state.fillerLineHeight !== fillerLineHeight || state.employeeGridGridColWidth !== employeeGridGridColWidth) {
            this.setState({ height: height, width: this.weekDiv.clientWidth, yOffset: yOffset, xOffset: xOffset, lineHeight: lineHeight, fillerLineHeight: fillerLineHeight, employeeGridWidth: employeeGridWidth, employeeGridGridColWidth: employeeGridGridColWidth });
        }
    };

    componentDidMount(): void {
        this.updateHeight();
        window.addEventListener("resize", this.updateHeight);
    }

    componentWillUnmount(): void {
        window.removeEventListener("resize", this.updateHeight);
    }

    componentDidUpdate(prevProps: IWorkMainCalendarProp, prevState: IWorkMainCalendarState): void {
        this.updateHeight();
    }

    getCalendarXPos = (x: number): ICalendarXPos => {
        const rect = this.weekDiv.getBoundingClientRect();
        const state = this.state;
        return this.props.calendar.getCalendarXPos(x - rect.left, state.employeeGridGridColWidth, state.xOffset);
    };

    clearCalendarCell = () => {
        if (this.canvasRectX < 0) return;
        const ctx = this.dataGridCanvas.getContext("2d");
        ctx.clearRect(0, 0, this.dataGridCanvas.width, this.dataGridCanvas.height);
        this.canvasRectX = -1;
        this.canvasRectY = -1;
        this.canvasRectWidth = -1;
        this.canvasRectHeight = -1;
    };

    drawCalendarCell = (x: number, y: number, width: number, height: number, startTime: number) => {
        if (x === this.canvasRectX && y === this.canvasRectY && width === this.canvasRectWidth && height === this.canvasRectHeight) return;
        this.canvasRectX = x;
        this.canvasRectY = y;
        this.canvasRectWidth = width;
        this.canvasRectHeight = height;
        const ctx = this.dataGridCanvas.getContext("2d");
        ctx.clearRect(0, 0, this.dataGridCanvas.width, this.dataGridCanvas.height);
        ctx.beginPath();
        ctx.fillStyle = "rgba(183, 233, 255, 0.5)";
        ctx.fillRect(x, 0, width, y);
        ctx.fillRect(0, y, x, height);
        //ctx.strokeStyle = "rgba(48, 63, 201, 1)";
        //ctx.lineWidth = 1;
        //ctx.moveTo(x, 0);
        //ctx.lineTo(x, y + height);
        //ctx.lineTo(0, y + height);
        //ctx.moveTo(x + width, 0);
        //ctx.lineTo(x + width, y);
        //ctx.lineTo(0, y);
        //ctx.stroke();
        ctx.font = "14px Arial";
        ctx.fillStyle = "rgba(48, 63, 201, 1)";
        ctx.textAlign = "right";
        if (this.props.calendar.calendarAccuracy === CalendarAccuracyType.DayTwoWeeks) {
            ctx.fillText(Base.timeToDateStr(startTime), x - 10, y - 10);
        } else {
            ctx.fillText(Base.timeToTimeStr(startTime), x - 10, y - 10);
        }
    };

    handleClearChecked = () => {
        this.props.onItemsSelected([]);
    };

    handleActionMenuClick = () => {
        this.setState({ showActionsMenu: true });
    };

    handleActionMenuCancel = () => {
        this.setState({ showActionsMenu: false });
    };

    handleNewWorkOrder = () => {
        this.props.onNewWorkOrder();
        this.setState({ showActionsMenu: false });
    };

    handleOpenWorkOrder = () => {
        this.props.onOpenSelectedWorkOrder();
        this.setState({ showActionsMenu: false });
    };

    handleEditWorkOrder = () => {
        this.props.onEditSelectedWorkOrder();
        this.setState({ showActionsMenu: false });
    };

    handleRemoveWorkOrder = () => {
        this.props.onRemoveWorkOrder();
        this.setState({ showActionsMenu: false });
    };

    handleCopyWorkOrder = () => {
        this.props.onCopyWorkOrder();
        this.setState({ showActionsMenu: false });
    };

    handleShowChange = (mode: number) => {

    };

    handleTeamChange = (mode: number) => {

    };

    handleShowWorkOrderGroupMenu = (top: number, left: number, items: IWorkOrderItem[]) => {
        this.setState({
            showWorkOrderGroupMenu: true,
            workOrderGroupMenuTop: top,
            workOrderGroupMenuLeft: left,
            workOrderGroupMenuItems: items
        });
    };

    handleCancelWorkOrderGroupMenu = () => {
        this.setState({
            showWorkOrderGroupMenu: false
        });
    };

    render() {
        const props = this.props;
        const state = this.state;
        if (!this.props.calendar || this.props.calendar.totalCols < 1) return (
            <img className="loadingImage" alt="Loading..." src={appConfig.ownRoot + "spinner-loader.gif"} />
        );
        const nowTime = Base.getNowDate().getTime();
        const calendar = props.calendar;
        const lineHeight = state.lineHeight;
        const backgroundXOffset = state.xOffset;
        const workOrderIsSelected = !!props.selectedWorkOrderId;
        const titleRowARect = !Base.isNullOrUndefined(this.titleRowADiv) ? this.titleRowADiv.getBoundingClientRect() : { bottom: 0, left: 0 };
        const ticsPerXPx = calendar.ticksPerCol / state.employeeGridGridColWidth;
        const ticsPerXGroup = lineHeight * ticsPerXPx;
        //const gridOffsetLeft = props.activeTime && props.startDate && state.employeeGridGridColWidth
        //    ? ((props.activeTime - props.startDate) * state.employeeGridGridColWidth) + "px"
        //    : null;
        return (
            <div className="week" ref={(elem) => { this.weekDiv = elem; }}>
                <div id="templateEmployeeRow" ref={(elem) => { this.templateEmployeeRowDiv = elem; }} />
                <div id="employeeBackgroundGrid" className="employeeBackgroundGrid" style={{ top: state.yOffset + "px", height: (state.height - state.yOffset) + "px" }}>
                    <div className="employeeGrid" ref={(elem) => { this.employeeGridDiv = elem; }}>
                        <div className="employeeGridData">
                            <WorkMainCalendarDayColumns
                                nowTime={nowTime}
                                dates={calendar.dates}
                                height={state.lineHeight * props.calendar.totalLinesCount}
                                width={state.employeeGridGridColWidth * props.calendar.visibleColsCount}
                                left={backgroundXOffset}
                            />
                            {calendar.lines.map((calendarLine) =>
                                <WorkMainCalendarEmployee
                                    key={calendarLine.getKey()}
                                    nowTime={nowTime}
                                    dates={calendar.dates}
                                    item={calendarLine}
                                    top={calendarLine.linesCountBefore * lineHeight}
                                    height={calendarLine.linesCount * lineHeight}
                                    getCalendarXPos={this.getCalendarXPos}
                                    drawCalendarCell={this.drawCalendarCell}
                                    clearCalendarCell={this.clearCalendarCell}
                                    onEditEmployee={props.onEmployeeEdit}
                                    onEditVehicle={props.onVehicleEdit}
                                    onEditWorkOrder={props.onEditWorkOrder}
                                    onSetSlectedWorkOrder={props.onItemClick}
                                    onMoveWorkOrderToEmployee={props.onMoveWorkOrderToEmployee}
                                    onMoveWorkOrderToVehicle={props.onMoveWorkOrderToVehicle}
                                    onMoveWorkOrder={props.onMoveWorkOrder}
                                    onMoveProject={props.onMoveProject}
                                    onExpandCollapseLine={(collapse: boolean) => { props.onExpandCollapseCalendarLine(calendarLine.id, collapse); }}
                                    onExpandCollapseCategory={(collapse: boolean) => { props.onExpandCollapseCalendarCategory(calendarLine.id, collapse); }}
                                />
                            )}
                        </div>
                    </div>
                    {state.fillerLineHeight > 0 &&
                        <div className="employeeGrid">
                            <WorkMainCalendarFillerLine
                                key="filler"
                                height={state.fillerLineHeight}
                            />
                        </div>
                    }
                    <div className="employeeGrid" style={{ height: 0 }}><div className="employeeRow"><div className="dataCol"><div className={calendar.calendarAccuracy === CalendarAccuracyType.DayTwoWeeks ? "dayCol" : (calendar.calendarAccuracy === CalendarAccuracyType.FourHours ? "hfCol" : "qhCol")} ref={(elem) => { this.templateEmployeeRowGridColDiv = elem; }} /></div></div></div>
                    <div className="dataGrid">
                        <div id="gridCol" className="gridCol">
                            {calendar.lines.map((calendarLine, index) =>
                                calendarLine.dayBookings.map((item) =>
                                    <WorkMainCalendarDayBooking
                                        key={calendarLine.getKey() + item.id}
                                        top={calendarLine.linesCountBefore * lineHeight}
                                        bottom={(calendarLine.linesCountBefore * lineHeight) + calendarLine.linesCount * lineHeight}
                                        leftAndWidth={calendar.getCalendarItemLeftAndWidth(item.startDate, item.endDate, state.employeeGridGridColWidth, backgroundXOffset)}
                                        item={item}
                                        line={calendarLine}
                                        getCalendarXPos={this.getCalendarXPos}
                                        drawCalendarCell={this.drawCalendarCell}
                                        clearCalendarCell={this.clearCalendarCell}
                                        onMoveWorkOrderToEmployee={props.onMoveWorkOrderToEmployee}
                                    />
                                )
                            )}
                            {calendar.lines.map((calendarLine, index) => {
                                const elements: JSX.Element[] = [];
                                if (calendarLine.workOrders.length > 0) {
                                    const groupItems: { [id: string] : IWorkOrderCalendarWorkOrderGroup } = {};
                                    for (const item of calendarLine.workOrders) {
                                        const leftAndWidth = calendar.getCalendarItemLeftAndWidth(item.workOrder.startTime, item.workOrder.endTime, state.employeeGridGridColWidth, backgroundXOffset);
                                        if (leftAndWidth.width < lineHeight) {
                                            const group = Math.floor((item.workOrder.startTime >= calendar.startDate ? item.workOrder.startTime - calendar.startDate : item.workOrder.endTime - calendar.startDate) / ticsPerXGroup);
                                            const key = group.toString(10) + "#" + item.lineIndex.toString(10);
                                            const value: IWorkOrderCalendarWorkOrderGroup = groupItems[key] ?? { groupIndex: group, lineIndex: item.lineIndex, items: [], employeeIds: [], vehicleIds: [], state: item.workOrder.state, disabled: true };
                                            value.items.push(item.workOrder);
                                            for (const employee of item.workOrder.employees) {
                                                if (value.employeeIds.indexOf(employee.id) >= 0) continue;
                                                value.employeeIds.push(employee.id);
                                            }
                                            for (const vehicle of item.workOrder.vehicles) {
                                                if (value.vehicleIds.indexOf(vehicle.id) >= 0) continue;
                                                value.vehicleIds.push(vehicle.id);
                                            }
                                            if (value.state !== item.workOrder.state) {
                                                value.state = WorkOrderState.Undefined;
                                            }
                                            value.disabled = value.disabled && item.disabled;
                                            groupItems[key] = value;
                                        }
                                        if (leftAndWidth.width >= lineHeight) {
                                            elements.push(<WorkMainCalendarEmployeeWorkOrder
                                                key={calendarLine.getKey() + item.workOrder.id}
                                                dayTop={calendarLine.linesCountBefore * lineHeight}
                                                dayBottom={(calendarLine.linesCountBefore * lineHeight) + calendarLine.linesCount * lineHeight}
                                                top={(calendarLine.linesCountBefore * lineHeight) + item.lineIndex * lineHeight}
                                                leftAndWidth={leftAndWidth}
                                                isSubLine={!!calendarLine.parenLineId}
                                                isVisible={calendarLine.visible && calendarLine.categoryVisible}
                                                isDisabled={item.disabled}
                                                item={item.workOrder}
                                                employeeId={calendarLine.isEmployee() ? calendarLine.id : (item.workOrder.employees.length === 1 ? item.workOrder.employees[0].id : "")}
                                                vehicleId={calendarLine.isVehicle() ? calendarLine.id : (item.workOrder.vehicles.length === 1 ? item.workOrder.vehicles[0].id : "")}
                                                selectedWorkOrderId={props.selectedWorkOrderId}
                                                getCalendarXPos={this.getCalendarXPos}
                                                drawCalendarCell={this.drawCalendarCell}
                                                clearCalendarCell={this.clearCalendarCell}
                                                onClick={props.onItemClick}
                                                onEditWorkOrder={props.onItemDoubleClick}
                                                onMoveWorkOrderToEmployee={props.onMoveWorkOrderToEmployee}
                                                onMoveWorkOrderToVehicle={props.onMoveWorkOrderToVehicle}
                                                          />);
                                        }
                                    }
                                    for (const key in groupItems) {
                                        const value = groupItems[key];
                                        const leftAndWidth = calendar.getCalendarItemLeftAndWidth(calendar.startDate + value.groupIndex * ticsPerXGroup, calendar.startDate + (value.groupIndex + 1) * ticsPerXGroup, state.employeeGridGridColWidth, backgroundXOffset);
                                        elements.push(<WorkMainCalendarEmployeeWorkOrderGroup
                                            key={calendarLine.getKey() + key}
                                            dayTop={calendarLine.linesCountBefore * lineHeight}
                                            dayBottom={(calendarLine.linesCountBefore * lineHeight) + calendarLine.linesCount * lineHeight}
                                            top={(calendarLine.linesCountBefore * lineHeight) + value.lineIndex * lineHeight}
                                            leftAndWidth={leftAndWidth}
                                            isSubLine={!!calendarLine.parenLineId}
                                            isVisible={calendarLine.visible && calendarLine.categoryVisible}
                                            item={value}
                                            selectedWorkOrderId={props.selectedWorkOrderId}
                                            onClick={props.onItemClick}
                                            onEditWorkOrder={props.onItemDoubleClick}
                                            onShowWorkOrderGroupMenu={this.handleShowWorkOrderGroupMenu}
                                                      />);
                                    }
                                }
                                return elements;
                            })}
                            {state.showWorkOrderGroupMenu &&
                                <WorkMainWorkOrderGroup
                                    top={state.workOrderGroupMenuTop + lineHeight - 10}
                                    left={state.workOrderGroupMenuLeft + lineHeight - 10}
                                    items={state.workOrderGroupMenuItems}
                                    onItemClick={props.onItemClick}
                                    onEditWorkOrderItem={props.onItemDoubleClick}
                                    onCancel={this.handleCancelWorkOrderGroupMenu}
                                />
                            }
                        </div>
                        <canvas ref={(elem) => { this.dataGridCanvas = elem; }} style={{ top: 0, height: (state.lineHeight * props.calendar.totalLinesCount) + "px", width: state.employeeGridWidth }} width={state.employeeGridWidth} height={state.lineHeight * props.calendar.totalLinesCount} />
                    </div>
                </div>
                <div className="headerBackground" style={{ width: state.employeeGridWidth }}>
                    <div className="titleRow" ref={(elem) => { this.titleRowDiv = elem; }}>
                        <div className="aRow" ref={(elem) => { this.titleRowADiv = elem; }}>
                            <div className="titleCol" ref={(elem) => { this.titleColDiv = elem; }}>
                                <Button
                                    classes="btn-primary"
                                    title={Translations.NewWork}
                                    enabled={true}
                                    onClick={props.onNewWorkOrder}
                                    secondaryActions={[
                                        { title: Translations.NewProject, onClick: props.onNewProject },
                                        { title: Translations.NewWorkBasedOnWorkOrderTemplate, onClick: props.workOrderTemplates.filter(i => !i.isProject()).length > 0 ? props.onNewWorkOrderBasedOnTemplate : null },
                                        { title: Translations.NewProjectBasedOnWorkOrderTemplate, onClick: props.workOrderTemplates.filter(i => i.isProject()).length > 0 ? props.onNewProjectBasedOnTemplate : null }
                                    ]}
                                />
                                <ButtonDropdown
                                    selectedTitle={""}
                                    actions={[
                                        { disabled: !workOrderIsSelected, title: (Translations.Open), onClick: this.handleOpenWorkOrder },
                                        { disabled: !workOrderIsSelected, title: (Translations.Edit), onClick: this.handleEditWorkOrder },
                                        { disabled: !workOrderIsSelected, title: (Translations.Remove), onClick: props.onRemoveWorkOrder },
                                        { disabled: !workOrderIsSelected, title: (Translations.Copy), onClick: props.onCopyWorkOrder },
                                        { title: (Translations.NewCustomer), onClick: props.onAddCustomer },
                                        { title: (Translations.NewContactPerson), onClick: props.onAddContact },
                                    ]}
                                />
                            </div>
                            <div className="contentCol">
                                <div>
                                    <ToolButton
                                        title={Translations.Previous}
                                        classes="previous noFrame left"
                                        enabled={true}
                                        onClick={props.onPreviousClick}
                                    />
                                    <ToolButton
                                        title={Translations.Next}
                                        classes="next noFrame left"
                                        enabled={true}
                                        onClick={props.onNextClick}
                                    />
                                    <Button
                                        title={Translations.Today}
                                        classes="btn-tool withText btn-default today noFrame right"
                                        enabled={true}
                                        onClick={props.onTodayClick}
                                    />
                                </div>
                                <div className="mainTitle">{props.startDate && props.endDate ? Base.timeToDateStr(props.startDate) + "-" + Base.timeToDateStr(props.endDate - 1) : ""}</div>
                                <div className="btn-group btn-group-toggle states">
                                    <RadioButton
                                        key={1}
                                        classes={"btn-secondary"}
                                        title={Translations.TimeTable}
                                        enabled={true}
                                        checked={true}
                                        onRadioClick={() => { props.onChangeViewMode(WorkMainViewMode.Calendar); }}
                                    />
                                    <RadioButton
                                        key={2}
                                        classes={"btn-secondary"}
                                        title={Translations.List}
                                        enabled={true}
                                        checked={false}
                                        onRadioClick={() => { props.onChangeViewMode(WorkMainViewMode.List); }}
                                    />
                                    <RadioButton
                                        key={3}
                                        classes={"btn-secondary"}
                                        title={Translations.Map}
                                        enabled={true}
                                        checked={false}
                                        onRadioClick={() => { props.onChangeViewMode(WorkMainViewMode.Map); }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="bRow">
                            <div className="titleCol" key="employee">
                                <div className="btn-group btn-group-toggle states left">
                                    {this.calendarAccuracyOptions.map((calendarAccuracyOption) =>
                                        <RadioButton
                                            key={calendarAccuracyOption.id}
                                            classes={"btn-secondary state" + calendarAccuracyOption.id}
                                            title={calendarAccuracyOption.title}
                                            enabled={true}
                                            checked={calendar.calendarAccuracy === calendarAccuracyOption.id}
                                            onRadioClick={() => { props.onChangeCalendarAccuracy(calendarAccuracyOption.id); }}
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="dataCol">
                                <div className="timePanel" style={{ height: 100 + "%", width: state.employeeGridGridColWidth * props.calendar.visibleColsCount + "px", left: 0 + "px" }}>
                                    {calendar.dates.map((date) =>
                                        <WorkMainCalendarDayTitle
                                            key={date.timeTime}
                                            date={date}
                                            nowTime={nowTime}
                                        />
                                    )}
                                </div>
                                <div className="timeBtnContainer prev">
                                    <ToolButton
                                        title={Translations.Previous}
                                        classes="previous noFrame"
                                        enabled={true}
                                        onClick={props.onPreviousIndexClick}
                                    />
                                </div>
                                <div className="timeBtnContainer next">
                                    <ToolButton
                                        title={Translations.Next}
                                        classes="next noFrame"
                                        enabled={true}
                                        onClick={props.onNextIndexClick}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footerBackground" style={{ width: state.employeeGridWidth, top: (state.height - 1) + "px" }}>
                    <div className="footerRow" ref={(elem) => { this.footerRowDiv = elem; }} />
                </div>
                {state.showActionsMenu &&
                    <ActionsMenu
                        top={titleRowARect.bottom}
                        left={titleRowARect.left}
                        itemIsSelected={workOrderIsSelected}
                        onNew={this.handleNewWorkOrder}
                        onOpen={this.handleOpenWorkOrder}
                        onEdit={this.handleEditWorkOrder}
                        onRemove={this.handleRemoveWorkOrder}
                        onCopy={this.handleCopyWorkOrder}
                        onCancel={this.handleActionMenuCancel}
                    />
                }
            </div>
        );
    }
}
