import { useCallback, useEffect, useMemo, useState } from "react";
import { Base } from "../../framework/base";
import { useAppDispatch, useAppSelector } from "../../framework/customStore";
import { WorkShiftDto } from "../../models/workShift/workShift";
import { getWorkShifts } from "../../services/workShiftService";
import { handleApiError } from "../../models/store/storeEffects";
import { WorkTimeType } from "../../models/workShitTimeSlot/workTimeType";
import { getVehicleWorkHours } from "../../services/workHoursService";
import { IWorkHoursItem } from "../../models/workTime/workHoursItem";
import {
    fetchReportCostCenters,
    fetchReportEmployees,
    fetchReportSalaryRowTypes,
    fetchReportVehicles,
    fetchReportWorkTimeTypes,
} from "../../store/reportOptionsSlice";
import { useAsyncThunkAction } from "../useAsyncThunkAction";
import { fetchCalendarDays } from "../../store/calendarSlice";
import { EmployeeListItemDto } from "../../models/employee/employee";
import { ICostCenterItem } from "../../models/costCenter/costCenterItem";
import { VehicleListItemDto } from "../../models/transport/vehicle";
import { WorkTimeTypeListItem } from "../../models/workTimeType/workTimeTypeListItem";
import { SalaryRowTypeItem } from "../../models/salary/salaryRowTypeItem";

export const ReportOptionsMapping = {
    vehicles: { method: fetchReportVehicles, attr: "vehicles" },
    employees: { method: fetchReportEmployees, attr: "employees" },
    salaryRowTypes: {
        method: fetchReportSalaryRowTypes,
        attr: "salaryRowTypes",
    },
    costCenters: {
        method: fetchReportCostCenters,
        attr: "costCenters",
    },
    workTimeTypes: {
        method: fetchReportWorkTimeTypes,
        attr: "workTimeTypes",
    },
};

export interface ReportDataOptions {
    includeWorkHours?: boolean;
}

export interface ReportData {
    loading: boolean;
    vehicles: VehicleListItemDto[];
    employees: EmployeeListItemDto[];
    costCenters: ICostCenterItem[];
    workTimeTypes: WorkTimeTypeListItem[];
    workShifts: WorkShiftDto[];
    workHours: IWorkHoursItem[];
    salaryRowTypes: SalaryRowTypeItem[];
    presentEmployees: EmployeeListItemDto[];
    presentVehicles: VehicleListItemDto[];
    presentCostCenters: ICostCenterItem[];
    presentWorkTimeTypes: WorkTimeType[];
    filterByVehicles: (val: string) => boolean;
    filterByCostCenters: (val: string) => boolean;
    filterByEmployees: (val: string) => boolean;
}

export const useReportData = (
    selectedOptions: string[],
    { includeWorkHours = false }: ReportDataOptions = {}
): ReportData => {
    const [loading, setLoading] = useState(true);
    const [workHours, setWorkHours] = useState<IWorkHoursItem[]>();
    const [workShifts, setWorkShifts] = useState<WorkShiftDto[]>();

    const dispatch = useAppDispatch();

    const timeRange = useAppSelector(
        (state) => state.reportOptions.filters.timeRange
    );
    const selectedEmployeeGroups = useAppSelector(
        (state) => state.reportOptions.filters.selectedEmployeeGroups
    );

    const vehicles = useAppSelector((state) => state.reportOptions.vehicles);
    const employees = useAppSelector((state) => state.reportOptions.employees);
    const salaryRowTypes = useAppSelector(
        (state) => state.reportOptions.salaryRowTypes
    );
    const costCenters = useAppSelector(
        (state) => state.reportOptions.costCenters
    );
    const workTimeTypes = useAppSelector(
        (state) => state.reportOptions.workTimeTypes
    );

    const selectedVehicles = useAppSelector(
        (state) => state.reportOptions.filters.selectedVehicles
    );
    const selectedCostCenters = useAppSelector(
        (state) => state.reportOptions.filters.selectedCostCenters
    );
    const selectedEmployees = useAppSelector(
        (state) => state.reportOptions.filters.selectedEmployees
    );

    const filterByVehicles = useCallback(
        (vehicleId: string) => {
            if (!selectedVehicles || selectedVehicles.length === 0) return true;
            if (!selectedVehicles.includes(vehicleId)) return false;
            return true;
        },
        [selectedVehicles]
    );

    const filterByCostCenters = useCallback(
        (costCenterId: string) => {
            if (!selectedCostCenters || selectedCostCenters.length === 0)
                return true;
            if (!selectedCostCenters.includes(costCenterId)) return false;
            return true;
        },
        [selectedCostCenters]
    );

    const filterByEmployees = useCallback(
        (employeeId: string) => {
            if (!selectedEmployees || selectedEmployees.length === 0)
                return true;
            if (!selectedEmployees.includes(employeeId)) return false;
            return true;
        },
        [selectedEmployees]
    );

    const allEmployeeIds = useMemo(
        () =>
            employees
                ?.filter(
                    (e) =>
                        selectedEmployeeGroups.length === 0 ||
                        selectedEmployeeGroups.includes(e.salaryGroupId)
                )
                .map((e) => e.id) ?? [],
        [employees, selectedEmployeeGroups]
    );

    const presentEmployeeIds = useMemo(
        () =>
            Base.getUniqueStringItems(
                workShifts?.map((w) => w.employeeId).filter(Boolean) ?? []
            ),
        [workShifts]
    );

    const presentEmployees = useMemo(
        () =>
            employees?.filter((v) => presentEmployeeIds?.includes(v.id)) ?? [],
        [employees, presentEmployeeIds]
    );

    const vehicleIds = useMemo(
        () =>
            Base.getUniqueStringItems(
                workShifts
                    ?.flatMap((w) =>
                        w.workShiftTimeSlots.map((s) => s.vehicleId)
                    )
                    .filter(Boolean) ?? []
            ),
        [workShifts]
    );

    const presentVehicles = useMemo(
        () => vehicles?.filter((v) => vehicleIds?.includes(v.id)) ?? [],
        [vehicleIds, vehicles]
    );

    const costCenterIds = useMemo(
        () =>
            Base.getUniqueStringItems(
                workShifts
                    ?.flatMap((w) =>
                        w.workShiftTimeSlots.map((s) => s.costCenterId)
                    )
                    .filter(Boolean) ?? []
            ),
        [workShifts]
    );

    const presentCostCenters = useMemo(
        () => costCenters?.filter((v) => costCenterIds?.includes(v.id)) ?? [],
        [costCenterIds, costCenters]
    );

    const workTimeTypeIds = useMemo(
        () =>
            Base.getUniqueStringItems(
                workShifts
                    ?.flatMap((w) =>
                        w.workShiftTimeSlots.map((s) => s.workTimeTypeId)
                    )
                    .filter(Boolean) ?? []
            ),
        [workShifts]
    );

    const presentWorkTimeTypes = useMemo(
        () => [
            new WorkTimeType({
                workTimeTypeId: "wtt_null",
                name: "Ei työvaihetta",
                type: 0,
            }),
            ...(workTimeTypes?.filter((v) =>
                workTimeTypeIds?.includes(v.workTimeTypeId)
            ) ?? []),
        ],
        [workTimeTypeIds, workTimeTypes]
    );

    const loadWorkHours = async (
        cb: (result: IWorkHoursItem[]) => void,
        signal?: AbortSignal
    ) => {
        await getVehicleWorkHours(
            allEmployeeIds,
            timeRange[0],
            timeRange[1],
            signal
        )
            .then(cb)
            .catch((err) => {
                if (!signal?.aborted) {
                    handleApiError(err, dispatch);
                    console.log("error", err);
                }
            });
    };

    const loadWorkShifts = async (signal?: AbortSignal) => {
        if (allEmployeeIds.length > 0) {
            await getWorkShifts(
                allEmployeeIds,
                timeRange[0],
                timeRange[1],
                true,
                signal
            )
                .then((res) => setWorkShifts(res))
                .catch((err) => {
                    if (!signal?.aborted) {
                        handleApiError(err, dispatch);
                        console.log("error", err);
                    }
                });
        }
    };

    useEffect(() => {
        const abortController = new AbortController();
        const responses = [];
        setLoading(true);

        if (includeWorkHours) {
            responses.push(
                void loadWorkHours((result) => {
                    setWorkHours(result.filter((r) => !!r.salaryRowTypeId));
                }),
                abortController.signal
            );
        }

        responses.push(loadWorkShifts(abortController.signal));

        Promise.all(responses).finally(() => setLoading(false));

        return () => {
            abortController.abort();
        };
    }, [timeRange, allEmployeeIds, includeWorkHours]);

    useEffect(() => {
        const promises = selectedOptions.map((o) =>
            dispatch(ReportOptionsMapping[o].method())
        );

        return () => {
            promises.forEach((p) => p.abort());
        };
    }, []);

    useAsyncThunkAction(
        () => {
            if (!timeRange[0] || !timeRange[1]) {
                return;
            }
            return fetchCalendarDays({
                startDate: timeRange[0],
                endDate: timeRange[1],
            });
        },
        { onError: () => null },
        [timeRange[0], timeRange[1]]
    );

    return {
        loading,
        vehicles,
        employees,
        costCenters,
        workTimeTypes,
        workShifts,
        workHours,
        salaryRowTypes,
        presentEmployees,
        presentVehicles,
        presentCostCenters,
        presentWorkTimeTypes,
        filterByVehicles,
        filterByCostCenters,
        filterByEmployees,
    };
};
