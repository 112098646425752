// SettingsEmployeeDialogEmployeeGroupList - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import * as employeeGroupService from "../../services/employeeGroupService";
import * as employeeService from "../../services/employeeService";
import { Translations } from "../../models/translations";
import { ToolButton } from "../framework/toolButton";
import { SelectIdTitleDialog } from "../framework/selectIdTitleDialog";
import { IEndlessList, EndlessList } from "../../models/common/endlessList";
import { IdTitle, IIdTitle } from "../../models/common/idTitle";
import { EmployeeEditEmployeeGroupSaveItem, IEmployeeEditEmployeeGroupItem } from "../../models/employee/employeeEditEmployeeGroupItem";
import { Base } from "../../framework/base";
import { SaveData } from "../../framework/saveData";
import { AppUtils } from "../../models/common/appUtils";

// SettingsEmployeeDialogEmployeeGroupListLine
export interface ISettingsEmployeeDialogEmployeeGroupListLineProp {
    item: IEmployeeEditEmployeeGroupItem;
    selectedId: string;
    onClick: (id: string) => void;
}

export class SettingsEmployeeDialogEmployeeGroupListLine extends React.Component<ISettingsEmployeeDialogEmployeeGroupListLineProp, {}> {
    render() {
        const item = this.props.item;
        return (
            <div className={"row line" + (item.id === this.props.selectedId ? " selected" : "")} onClick={() => { this.props.onClick(item.id); }} title={item.groupName} >
                <div className="col-12">{item.groupName}</div>
            </div>
        );
    }
}

// SettingsEmployeeDialogEmployeeGroupList
export interface ISettingsEmployeeDialogEmployeeGroupListProp {
    title?: string;
    readOnly: boolean;
    employeeId: string;
    reservedEmployeeGroupIds: string[];
    items: IEmployeeEditEmployeeGroupItem[];
    onRemoveEmployeeGroup?: (id: string) => void;
    onAddEmployeeGroup?: (id: string, employeeGroupId: string, groupName: string) => void;
}

interface ISettingsEmployeeDialogEmployeeGroupListState {
    selectedId: string;
    showEmployeeGroupDialog: boolean;
    employeeGroupItems: IIdTitle[];
}

export class SettingsEmployeeDialogEmployeeGroupList extends React.Component<ISettingsEmployeeDialogEmployeeGroupListProp, ISettingsEmployeeDialogEmployeeGroupListState> {
    constructor(props) {
        super(props);
        this.state = { selectedId: null, showEmployeeGroupDialog: false, employeeGroupItems: [] };
    }

    handleAdd = () => {
        const obj = this;
        employeeGroupService.getEmployeeGroupIdTitles(obj.props.items.map(i => i.employeeGroupId).concat(obj.props.reservedEmployeeGroupIds)).then(idTitleItems => {
            obj.setState({
                showEmployeeGroupDialog: true,
                employeeGroupItems: idTitleItems.items
            });
        });
    };

    handleLoadEmployeeGroup = (page: number, filter: string): Promise<IEndlessList<IIdTitle>> => {
        const obj = this;
        const lowFilter = filter.toLowerCase();
        return new Promise<IEndlessList<IdTitle>>((resolve, reject) => {
            const result = new EndlessList<IdTitle>(null, IdTitle);
            result.items = !lowFilter ? obj.state.employeeGroupItems : obj.state.employeeGroupItems.filter(i => i.title.toLowerCase().indexOf(lowFilter) > -1);
            result.page = 1;
            result.hasMore = false;
            console.log(result.items);
            resolve(result);
        });
    };

    private getSaveData = (employeeGroupId: string): SaveData => {
        const data = new SaveData();
        // Common
        data.append("employeeId", this.props.employeeId);
        const employeeEmployeeGroup = new EmployeeEditEmployeeGroupSaveItem();
        employeeEmployeeGroup.id = Base.emptyGuid;
        employeeEmployeeGroup.employeeGroupId = employeeGroupId;
        data.append("employeeEmployeeGroups", JSON.stringify([employeeEmployeeGroup]));
        return data;
    };

    saveEditItem = async(employeeGroupId: string, groupName: string) => {
        const saveData = this.getSaveData(employeeGroupId);
        // Call server
        const result = await AppUtils.callService(() => employeeService.saveEmployeeEmployeeGroups(saveData.formData));
        if (!result) return;
        this.props.onAddEmployeeGroup(result.ids[0], employeeGroupId, groupName);
    };

    handleSelectEmployeeGroupDialogOk = async(employeeGroupId: string, groupName: string) => {
        const obj = this;
        if (!employeeGroupId) return;
        obj.setState({
            showEmployeeGroupDialog: false
        });
        await this.saveEditItem(employeeGroupId, groupName);
    };

    handleSelectEmployeeGroupsDialogCancel = () => {
        this.setState({
            showEmployeeGroupDialog: false
        });
    };

    removeDayBooking = async(id: string) => {
        if (!id) return;
        //Create savedata
        const saveData = new SaveData();
        saveData.append("employeeId", this.props.employeeId);
        saveData.append("employeeEmployeeGroupIds", JSON.stringify([id]));
        //Save to db
        const result = await AppUtils.callService(() => employeeService.removeEmployeeEmployeeGroups(saveData.formData));
        if (!result) return;
        this.props.onRemoveEmployeeGroup(id);
    };

    handleRemove = () => {
        this.removeDayBooking(this.state.selectedId);
    };

    handleLineClick = (id: string) => {
        this.setState({
            selectedId: id
        });
    };

    render() {
        const props = this.props;
        const items = props.items;

        return (
            <div>
                <div className="commandRow">
                    {!!props.title &&
                        <label className="control-label listTitle">{props.title}</label>
                    }
                    <ToolButton
                        title={Translations.Remove}
                        enabled={!props.readOnly && !!this.state.selectedId}
                        classes={"round right remove"}
                        onClick={this.handleRemove}
                    />
                    <ToolButton
                        title={Translations.Add}
                        enabled={!this.props.readOnly}
                        classes={"round right add"}
                        onClick={this.handleAdd}
                    />
                </div>

                <div className="listContainer customerContactsContainer">
                    {items.length > 0 &&
                        <div className="list customerContacts">
                            {items.map((item) =>
                                <SettingsEmployeeDialogEmployeeGroupListLine
                                    key={item.id}
                                    item={item}
                                    selectedId={this.state.selectedId}
                                    onClick={this.handleLineClick}
                                />
                            )}
                        </div>
                    }
                </div>

                {this.state.showEmployeeGroupDialog &&
                    <SelectIdTitleDialog
                        title={Translations.EmployeeGroups}
                        selectedId={null}
                        listbox={true}
                        loadIdCodes={this.handleLoadEmployeeGroup}
                        onOk={this.handleSelectEmployeeGroupDialogOk}
                        onCancel={this.handleSelectEmployeeGroupsDialogCancel}
                    />
                }
            </div>
        );
    }
}
