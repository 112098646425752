import { Base } from "../../framework/base";
import { IWorkOrderCalendarLineItem } from "./workOrderCalendarLineItem";
import { IWorkOrderItem, WorkOrderItem } from "./workOrderItem";
import { ICalendarDayItem } from "../calendarDay/calendarDayItem";
import { IDayBookingItem, DayBookingItem } from "../dayBooking/dayBookingItem";
import { CalendarLineType, EnumHelper, CalendarAccuracyType } from "../common/enums";
import { Translations } from "../translations";
import { IEmployeeGroupItem } from "../employeeGroup/employeeGroupItem";
import { IVehicleGroupItem } from "../vehicleGroup/vehicleGroupItem";
import { IEmployeeParameters, EmployeeParametersHelper } from "../employee/employeeIParameters";

export interface IWorkOrderItems {
    items: IWorkOrderItem[];
}

export interface IWorkOrderCalendarWorkOrderGroup {
    groupIndex: number;
    lineIndex: number;
    disabled: boolean;
    state: number;
    items: IWorkOrderItem[];
    employeeIds: string[];
    vehicleIds: string[];
}

export interface IWorkOrderCalendarWorkOrder {
    workOrder: IWorkOrderItem;
    lineIndex: number;
    disabled: boolean;
}

export class WorkOrderCalendarWorkOrder implements IWorkOrderCalendarWorkOrder {
    workOrder: IWorkOrderItem;
    lineIndex: number;
    disabled: boolean;

    /* eslint-disable no-use-before-define */
    constructor();
    constructor(obj: IWorkOrderCalendarLine);
    constructor(obj?: any) {
        this.lineIndex = obj && obj.lineIndex || 0;
        this.disabled = obj && obj.disabled || false;
        this.workOrder = null;
        if (obj && obj.workOrder) {
            this.workOrder = new WorkOrderItem(obj.workOrder);
        }
    }
    /* eslint-enable no-use-before-define */

    static createWorkOrderCalendarWorkOrder(workOrder: IWorkOrderItem, disabled: boolean): IWorkOrderCalendarWorkOrder {
        const result = new WorkOrderCalendarWorkOrder();
        result.workOrder = workOrder;
        result.disabled = disabled;
        return result;
    }

    static getWorkOrderCalendarWorkOrdersAndMaxOverlap(workOrders: IWorkOrderItem[], enabledWorkOrderIds: string[], showOnlyEnabledWorkOrders: boolean): { items: IWorkOrderCalendarWorkOrder[], maxOverlap: number } {
        const result = { items: [], maxOverlap: 1 };
        if (!workOrders || workOrders.length < 1) return result;
        let relevantWorkOrders = showOnlyEnabledWorkOrders
            ? workOrders.filter(i => enabledWorkOrderIds.indexOf(i.id) > -1)
            : workOrders;
        if (!relevantWorkOrders || relevantWorkOrders.length < 1) return result;
        let maxLineIndex = 0;
        const lines: IWorkOrderItems[] = [];
        const line: {
            items: IWorkOrderItem[]
        } = { items: [] };
        relevantWorkOrders = relevantWorkOrders.sort((a, b) => {
            return a.startTime - b.startTime;
        });
        let a = WorkOrderCalendarWorkOrder.createWorkOrderCalendarWorkOrder(relevantWorkOrders[0], enabledWorkOrderIds.indexOf(relevantWorkOrders[0].id) < 0);
        result.items.push(a);
        if (relevantWorkOrders.length < 2) return result;
        line.items.push(relevantWorkOrders[0]);
        lines.push(line);
        for (let i = 1; i < relevantWorkOrders.length; i++) {
            a = WorkOrderCalendarWorkOrder.createWorkOrderCalendarWorkOrder(relevantWorkOrders[i], enabledWorkOrderIds.indexOf(relevantWorkOrders[i].id) < 0);
            result.items.push(a);
            let addToNewLine = true;
            for (let j = 0; j < lines.length; j++) {
                let addToLine = true;
                for (let k = 0; k < lines[j].items.length; k++) {
                    const b = lines[j].items[k];
                    if (Base.isOverlapping(a.workOrder.startTime, a.workOrder.endTime, b.startTime, b.endTime)) {
                        addToLine = false;
                        break;
                    }
                }
                if (addToLine) {
                    lines[j].items.push(a.workOrder);
                    a.lineIndex = j;
                    addToNewLine = false;
                    break;
                }
            }
            if (addToNewLine) {
                a.lineIndex = lines.length;
                const newLine: {
                    items: IWorkOrderItem[]
                } = { items: [a.workOrder] };
                maxLineIndex = Math.max(maxLineIndex, a.lineIndex);
                lines.push(newLine);
            }
        }
        result.maxOverlap = maxLineIndex + 1;
        return result;
    }
}

export interface IWorkOrderCalendarLine {
    id: string;
    name: string;
    abbreviation: string;
    picture: string;
    lineType: CalendarLineType;
    number: number;
    customerName: string;
    description: string;
    workOrders: IWorkOrderCalendarWorkOrder[];
    dayBookings: IDayBookingItem[];
    tooltip: string;
    hint: string;
    linesCount: number;
    linesCountBefore: number;
    visible: boolean;
    parenLineId: string;
    isLastChild: boolean;
    category: string;
    categoryVisible: boolean;
    childLineIds: string[];
    childLinesVisible: boolean;
    numberOfChildren: number;

    isProject(): boolean;
    isProjectTask(): boolean;
    isRecurringProject(): boolean;
    isUnassignedWork(): boolean;
    isTeam(): boolean;
    isEmployee(): boolean;
    isVehicle(): boolean;
    isTitle(): boolean;
    getKey(): string;
    getAvatarText(): string;
}

export class WorkOrderCalendarLine implements IWorkOrderCalendarLine {
    id: string;
    name: string;
    abbreviation: string;
    picture: string;
    lineType: CalendarLineType;
    number: number;
    customerName: string;
    description: string;
    workOrders: IWorkOrderCalendarWorkOrder[];
    dayBookings: IDayBookingItem[];
    tooltip: string;
    hint: string;
    linesCount: number;
    linesCountBefore: number;
    visible: boolean;
    parenLineId: string;
    isLastChild: boolean;
    category: string;
    categoryVisible: boolean;
    childLineIds: string[];
    childLinesVisible: boolean;
    numberOfChildren: number;

    constructor();
    constructor(obj: IWorkOrderCalendarLine);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.name = obj && obj.name || "";
        this.abbreviation = obj && obj.abbreviation || "";
        this.picture = obj && obj.picture || "";
        this.lineType = obj && obj.lineType || CalendarLineType.Employee;
        this.number = obj && obj.number || 0;
        this.customerName = obj && obj.customerName || "";
        this.description = obj && obj.description || "";
        this.tooltip = obj && obj.tooltip || "";
        this.hint = obj && obj.hint || "";
        this.linesCount = obj && obj.linesCount || 0;
        this.linesCountBefore = obj && obj.linesCountBefore || 0;
        this.visible = obj && obj.visible || false;
        this.parenLineId = obj && obj.parenLineId || "";
        this.isLastChild = obj && obj.isLastChild || false;
        this.category = obj && obj.category || "";
        this.categoryVisible = obj && obj.categoryVisible || false;
        this.childLinesVisible = obj && obj.childLinesVisible || false;
        this.childLineIds = obj && obj.childLineIds || [];
        this.numberOfChildren = obj && obj.numberOfChildren || 0;
        this.workOrders = Base.getTypedArray<WorkOrderCalendarWorkOrder>(!Base.isNullOrUndefined(obj) ? obj.workOrders : null, WorkOrderCalendarWorkOrder);
        this.dayBookings = Base.getTypedArray<DayBookingItem>(!Base.isNullOrUndefined(obj) ? obj.dayBookings : null, DayBookingItem);
    }

    isProject(): boolean {
        return EnumHelper.isEqual(this.lineType, CalendarLineType.Project) || EnumHelper.isEqual(this.lineType, CalendarLineType.RecurringProject);
    }

    isProjectTask(): boolean {
        return EnumHelper.isEqual(this.lineType, CalendarLineType.ProjectTask);
    }

    isRecurringProject(): boolean {
        return EnumHelper.isEqual(this.lineType, CalendarLineType.RecurringProject);
    }

    isUnassignedWork(): boolean {
        return EnumHelper.isEqual(this.lineType, CalendarLineType.Unassigned);
    }

    isTeam(): boolean {
        return EnumHelper.isEqual(this.lineType, CalendarLineType.Team);
    }

    isEmployee(): boolean {
        return EnumHelper.isEqual(this.lineType, CalendarLineType.Employee);
    }

    isVehicle(): boolean {
        return EnumHelper.isEqual(this.lineType, CalendarLineType.Vehicle);
    }

    isTitle(): boolean {
        return EnumHelper.isEqual(this.lineType, CalendarLineType.Title);
    }

    getKey(): string {
        return (this.parenLineId ?? "") + this.id + this.lineType.toString(10);
    }

    getAvatarText(): string {
        if (this.abbreviation) {
            return this.abbreviation.slice(0, 3);
        }
        if (this.name) {
            if (this.isEmployee()) {
                return this.name;
            } else {
                return this.name.slice(0, 3);
            }
        }
        return "";
    }

    static createWorkOrderCalendarLine(parenLineId: string, category: string, calendarLine: IWorkOrderCalendarLineItem, workOrders: IWorkOrderItem[], dayBookings: IDayBookingItem[], totalLinesCount: number, enabledWorkOrderIds: string[], showOnlyEnabledWorkOrders: boolean): WorkOrderCalendarLine {
        const result = new WorkOrderCalendarLine();
        result.id = calendarLine.id;
        result.name = calendarLine.name;
        result.abbreviation = calendarLine.abbreviation;
        result.picture = calendarLine.picture;
        result.name = calendarLine.name;
        result.lineType = calendarLine.lineType;
        result.number = calendarLine.number;
        result.customerName = calendarLine.customerName;
        result.description = calendarLine.description;
        result.linesCount = 1;
        if (workOrders) {
            const temp = WorkOrderCalendarWorkOrder.getWorkOrderCalendarWorkOrdersAndMaxOverlap(workOrders, enabledWorkOrderIds, showOnlyEnabledWorkOrders);
            result.workOrders = temp.items;
            result.linesCount = temp.maxOverlap;
        }
        result.linesCountBefore = totalLinesCount;
        result.visible = true;
        result.childLinesVisible = true;
        result.dayBookings = calendarLine.isEmployee() ? dayBookings.filter(j => j.employeeId === result.id) : [];
        result.parenLineId = parenLineId;
        result.category = category;
        result.categoryVisible = true;
        return result;
    }

    static createUnscheduledWorkOrderCalendarLine(parenLineId: string, category: string, workOrder: IWorkOrderItem, totalLinesCount: number): WorkOrderCalendarLine {
        const result = new WorkOrderCalendarLine();
        result.id = workOrder.id;
        result.name = workOrder.name;
        result.lineType = CalendarLineType.Unscheduled;
        result.number = workOrder.number;
        result.customerName = workOrder.customerName;
        result.description = workOrder.description;
        result.linesCount = 1;
        result.linesCountBefore = totalLinesCount;
        result.visible = true;
        result.childLinesVisible = true;
        result.parenLineId = parenLineId;
        result.category = category;
        result.categoryVisible = true;
        return result;
    }

    static createUnassignedWorkOrderCalendarLine(category: string, workOrder: IWorkOrderItem, totalLinesCount: number, enabledWorkOrderIds: string[]): WorkOrderCalendarLine {
        const result = new WorkOrderCalendarLine();
        result.id = workOrder.id;
        result.name = workOrder.name;
        result.lineType = CalendarLineType.Unassigned;
        result.number = workOrder.number;
        result.customerName = workOrder.customerName;
        result.description = workOrder.description;
        if (workOrder) {
            result.workOrders = [WorkOrderCalendarWorkOrder.createWorkOrderCalendarWorkOrder(workOrder, enabledWorkOrderIds.indexOf(workOrder.id) < 0)];
        }
        result.linesCount = 1;
        result.linesCountBefore = totalLinesCount;
        result.visible = true;
        result.childLinesVisible = true;
        result.category = category;
        result.categoryVisible = true;
        return result;
    }

    static createTitleCalendarLine(title: string, totalLinesCount: number): WorkOrderCalendarLine {
        const result = new WorkOrderCalendarLine();
        result.id = title;
        result.name = title;
        result.lineType = CalendarLineType.Title;
        result.linesCount = 1.5;
        result.linesCountBefore = totalLinesCount;
        result.visible = true;
        result.childLinesVisible = true;
        result.tooltip = title;
        result.categoryVisible = true;
        result.numberOfChildren = 0;
        return result;
    }
}

export interface IWorkOrderCalendarDate {
    dayTime: number;
    dayType: number;
    dayTitle: string;
    dayDescription: string;
    timeTime: number;
    title: string;
    colClass: string;
    visible: boolean;
    officeHour: boolean;
}

export class WorkOrderCalendarDate implements IWorkOrderCalendarDate {
    dayTime: number;
    dayType: number;
    dayTitle: string;
    dayDescription: string;
    timeTime: number;
    title: string;
    colClass: string;
    visible: boolean;
    officeHour: boolean;

    constructor();
    constructor(obj: IWorkOrderCalendarDate);
    constructor(obj?: any) {
        this.dayTime = obj && obj.dayTime || 0;
        this.dayType = obj && obj.dayType || 0;
        this.dayTitle = obj && obj.dayTitle || "";
        this.dayDescription = obj && obj.dayDescription || "";
        this.timeTime = obj && obj.timeTime || 0;
        this.title = obj && obj.title || "";
        this.colClass = obj && obj.colClass || "";
        this.visible = obj && obj.visible || "";
        this.officeHour = obj && obj.officeHour || "";
    }

    static createWorkOrderCalendarDate(calendarAccuracy: CalendarAccuracyType, officeStartTimeMin: number, officeEndTimeMin: number, calendarDay: ICalendarDayItem, day: Date, time: number, index: number): WorkOrderCalendarDate {
        const quarterHourOfficeStartHour = Math.floor(officeStartTimeMin / 60);
        const quarterHourOfficeStartMin = officeStartTimeMin - (quarterHourOfficeStartHour * 60);
        const quarterHourOfficeEndHour = Math.floor(officeEndTimeMin / 60);
        const quarterHourOfficeEndMin = officeEndTimeMin - (quarterHourOfficeEndHour * 60);
        const quarterHourVisibleHourThresholdMin = 30;
        const result = new WorkOrderCalendarDate();
        if (calendarDay) {
            result.dayTime = calendarDay.day;
            result.dayType = calendarDay.dayType;
            result.dayDescription = calendarDay.description;
        } else {
            result.dayTime = day.getTime();
        }
        const dayDate = new Date(result.dayTime);
        result.timeTime = time;
        const timeDate = new Date(result.timeTime);
        const hours = timeDate.getHours();
        const minutes = timeDate.getMinutes();
        result.visible = calendarAccuracy !== CalendarAccuracyType.QuarterHour
            ? true
            : Base.isOverlapping(quarterHourOfficeStartHour * 60 + quarterHourOfficeStartMin - quarterHourVisibleHourThresholdMin, quarterHourOfficeEndHour * 60 + quarterHourOfficeEndMin + quarterHourVisibleHourThresholdMin, hours * 60 + minutes, hours * 60 + minutes + 15);
        result.officeHour = calendarAccuracy !== CalendarAccuracyType.QuarterHour
            ? true
            : result.visible && Base.isOverlapping(quarterHourOfficeStartHour * 60 + quarterHourOfficeStartMin, quarterHourOfficeEndHour * 60 + quarterHourOfficeEndMin, hours * 60 + minutes, hours * 60 + minutes + 15);
        result.title = calendarAccuracy === CalendarAccuracyType.DayTwoWeeks
            ? dayDate.getDate().toString(10)
            : (calendarAccuracy === CalendarAccuracyType.FourHours
                ? timeDate.getHours().toString(10)
                : (index % 4 === 0
                    ? timeDate.getHours().toString(10)
                    : ""));
        result.colClass = calendarAccuracy === CalendarAccuracyType.DayTwoWeeks ? "dayCol ln" : (calendarAccuracy === CalendarAccuracyType.FourHours ? "hfCol ln" : ("qhCol" + (result.visible && !result.officeHour ? " nof" : "") + (minutes === 0 ? " ln" : "")));
        result.dayTitle = Base.getWeekDayNameShort(dayDate);
        if (calendarAccuracy === CalendarAccuracyType.FourHours && index % 6 !== 0 || calendarAccuracy === CalendarAccuracyType.QuarterHour && index % 96 !== 0) {
            result.dayTitle = "";
            result.dayDescription = "";
        } else if (calendarAccuracy !== CalendarAccuracyType.DayTwoWeeks) {
            result.dayTitle = result.dayTitle + " " + dayDate.getDate().toString(10) + "." + (dayDate.getMonth() + 1).toString(10);
        }
        return result;
    }
}

export interface IItemLeftWidth {
    left: number;
    width: number;
    fullWidth: number;
}

export interface ITimeRange {
    start: number;
    end: number;
}

export class TimeRange implements ITimeRange {
    start: number;
    end: number;

    constructor();
    constructor(obj: ITimeRange);
    constructor(obj?: any) {
        this.start = obj && obj.start || 0;
        this.end = obj && obj.end || 0;
    }
}

export interface ICalendarRect {
    x: number;
    y: number;
    width: number;
    height: number;
    startTime: number;
}

export interface ICalendarXPos {
    x: number;
    width: number;
    startTime: number;
}

export interface IWorkOrderCalendar {
    calendarAccuracy: CalendarAccuracyType;
    officeStartTimeMin: number;
    officeEndTimeMin: number;
    startDate: number;
    endDate: number;
    totalCols: number;
    ticksPerCol: number;
    totalLinesCount: number;
    visibleColsCount: number;
    visibleTimes: ITimeRange[];
    categories: string[];
    dates: IWorkOrderCalendarDate[];
    lines: IWorkOrderCalendarLine[];

    getTitle(): string;
    getWorkOrder(workOrderId: string): IWorkOrderItem;
    getCalendarXPos(x: number, colWidth: number, offsetLeft: number): ICalendarXPos;
    getCalendarItemLeftAndWidth(startTime: number, endTime: number, colWidth: number, offsetLeft: number): IItemLeftWidth;
}

export class WorkOrderCalendar implements IWorkOrderCalendar {
    calendarAccuracy: CalendarAccuracyType;
    officeStartTimeMin: number;
    officeEndTimeMin: number;
    timeOffsetMin: number;
    startDate: number;
    endDate: number;
    totalCols: number;
    ticksPerCol: number;
    totalLinesCount: number;
    visibleColsCount: number;
    visibleTimes: TimeRange[];
    categories: string[];
    dates: WorkOrderCalendarDate[];
    lines: WorkOrderCalendarLine[];

    constructor();
    constructor(obj: IWorkOrderCalendar);
    constructor(obj?: any) {
        this.calendarAccuracy = obj && obj.calendarAccuracy || CalendarAccuracyType.DayTwoWeeks;
        this.officeStartTimeMin = obj && obj.officeStartTimeMin || 0;
        this.officeEndTimeMin = obj && obj.officeEndTimeMin || 0;
        this.timeOffsetMin = obj && obj.timeOffsetMin || 0;
        this.startDate = obj && obj.startDate || 0;
        this.endDate = obj && obj.endDate || 0;
        this.totalCols = obj && obj.totalCols || 0;
        this.ticksPerCol = obj && obj.ticksPerCol || 0;
        this.totalLinesCount = obj && obj.totalLinesCount || 0;
        this.visibleColsCount = obj && obj.visibleColsCount || 0;
        this.visibleTimes = Base.getTypedArray<TimeRange>(!Base.isNullOrUndefined(obj) ? obj.visibleTimes : null, TimeRange);
        this.categories = obj && obj.categories || [];
        this.dates = Base.getTypedArray<WorkOrderCalendarDate>(!Base.isNullOrUndefined(obj) ? obj.dates : null, WorkOrderCalendarDate);
        this.lines = Base.getTypedArray<WorkOrderCalendarLine>(!Base.isNullOrUndefined(obj) ? obj.lines : null, WorkOrderCalendarLine);
    }

    collapseCategory(category: string, collapse: boolean) {
        let targetLineId = "";
        let totalLinesCount = 0;
        for (let i = 0; i < this.lines.length; i++) {
            const line = this.lines[i];
            const isTargetLine = line.id === category;
            line.linesCountBefore = totalLinesCount;
            if (targetLineId && line.category === targetLineId) {
                line.categoryVisible = !collapse;
            }
            if (line.categoryVisible && line.visible) {
                totalLinesCount = totalLinesCount + line.linesCount;
            }
            if (isTargetLine) {
                targetLineId = line.id;
                line.childLinesVisible = !collapse;
            }
        }
    }

    collapseLine(lineId: string, collapse: boolean) {
        let targetLineId = "";
        let totalLinesCount = 0;
        for (let i = 0; i < this.lines.length; i++) {
            const line = this.lines[i];
            const isTargetLine = line.id === lineId;
            line.linesCountBefore = totalLinesCount;
            if (targetLineId && line.parenLineId === targetLineId) {
                line.visible = !collapse;
            }
            if (line.categoryVisible && line.visible) {
                totalLinesCount = totalLinesCount + line.linesCount;
            }
            if (isTargetLine) {
                targetLineId = line.id;
                line.childLinesVisible = !collapse;
            }
        }
    }

    expandCategoriesByWorkOrderId(workOrderId: string): string[] {
        if (!workOrderId) return [];
        const lineCategories = Base.getUniqueStringItems(this.lines.filter(i => i.workOrders.filter(j => j.workOrder.id === workOrderId).length > 0).map(i => i.category));
        if (lineCategories.length < 1) return [];
        const categoryIds = this.lines.filter(i => !i.childLinesVisible && lineCategories.indexOf(i.id) > -1).map(i => i.id);
        if (categoryIds.length < 1) return [];
        for (const categoryId of categoryIds) {
            this.collapseCategory(categoryId, false);
        }
        return categoryIds;
    }

    getTitle(): string {
        return Base.timeToDateStr(this.startDate) + "-" + Base.timeToDateStr(this.endDate);
    }

    getWorkOrder(workOrderId: string): IWorkOrderItem {
        for (let i = 0; i < this.lines.length; i++) {
            for (let j = 0; j < this.lines[i].workOrders.length; j++) {
                if (this.lines[i].workOrders[j].workOrder.id === workOrderId) {
                    return this.lines[i].workOrders[j].workOrder;
                }
            }
        }
        return null;
    }

    getCalendarItemLeftAndWidth(startTime: number, endTime: number, colWidth: number, offsetLeft: number): IItemLeftWidth {
        startTime = startTime - this.timeOffsetMin * 60 * 1000;
        endTime = endTime - this.timeOffsetMin * 60 * 1000;
        let offsetTicks = 0;
        let widthTicks = 0;
        let startFound = false;
        //console.log("getCalendarItemLeftAndWidth", this.visibleTimes, startTime, endTime, colWidth, offsetLeft);
        for (let i = 0; i < this.visibleTimes.length; i++) {
            const visibleTime = this.visibleTimes[i];
            if (!startFound) {
                if (startTime < visibleTime.start) {
                    startFound = true;
                } else if (startTime >= visibleTime.start && startTime < visibleTime.end) {
                    offsetTicks = offsetTicks + (startTime - visibleTime.start);
                    startFound = true;
                }
            }
            /*console.log("loop", visibleTime, offsetTicks, widthTicks, startFound);*/
            if (startFound) {
                if (endTime < visibleTime.start) {
                    break;
                } else if (endTime >= visibleTime.start && endTime < visibleTime.end) {
                    widthTicks = widthTicks + (endTime - Math.max(visibleTime.start, startTime));
                    break;
                }
            }
            if (startFound) {
                widthTicks = widthTicks + (visibleTime.end - Math.max(visibleTime.start, startTime));
                continue;
            }
            offsetTicks = offsetTicks + (visibleTime.end - visibleTime.start);
        }
        //console.log("result", offsetTicks, widthTicks);
        return {
            left: (offsetTicks / this.ticksPerCol) * colWidth + offsetLeft,
            width: (widthTicks / this.ticksPerCol) * colWidth,
            fullWidth: ((endTime - startTime) / this.ticksPerCol) * colWidth
        };
    }

    getCalendarXPos(x: number, colWidth: number, offsetLeft: number): ICalendarXPos {
        const result: ICalendarXPos =
        {
            x: offsetLeft,
            width: colWidth,
            startTime: this.startDate
        };
        //console.log("getCalendarXPos", x, colWidth, offsetLeft, this.visibleTimes);
        let slotFound = false;
        for (let i = 0; i < this.visibleTimes.length; i++) {
            const visibleTime = this.visibleTimes[i];
            result.startTime = visibleTime.start;
            while (true) {
                if (x >= result.x && x < result.x + colWidth) {
                    slotFound = true;
                    break;
                }
                result.x = result.x + colWidth;
                result.startTime = result.startTime + this.ticksPerCol;
                if (result.startTime < visibleTime.end) continue;
                break;
            }
            if (slotFound) {
                break;
            }
        }
        //console.log("getCalendarXPos Result", result);
        result.startTime = result.startTime + this.timeOffsetMin * 60 * 1000;
        return result;
    }

    private addLines(lines: WorkOrderCalendarLine[]) {
        for (let i = 0; i < lines.length; i++) {
            const line = lines[i];
            line.linesCountBefore = this.totalLinesCount;
            this.lines.push(line);
            this.totalLinesCount = this.totalLinesCount + line.linesCount;
        }
    }

    static createWorkOrderCalendar(calendarAccuracy: CalendarAccuracyType, officeStartTimeMin: number, officeEndTimeMin: number, startDate: number, endDate: number, calendarLines: IWorkOrderCalendarLineItem[], workOrders: IWorkOrderItem[],
        calendarDays: ICalendarDayItem[], dayBookings: IDayBookingItem[], vehicleGroups: IVehicleGroupItem[], employeeGroups: IEmployeeGroupItem[], filteredWorkOrders: IWorkOrderItem[],
        employeeParameters: IEmployeeParameters, filterShowFiltered: boolean): WorkOrderCalendar {
        const minTicks = 60 * 1000;
        const hourTicks = 60 * minTicks;
        const showVehicleGroupIds = EmployeeParametersHelper.getShowOnDesignVehicleGroupIdsSpecified(employeeParameters)
            ? EmployeeParametersHelper.getShowOnDesignVehicleGroupIds(employeeParameters)
            : vehicleGroups.map(i => i.id);
        const showEmployeeGroupIds = EmployeeParametersHelper.getShowOnDesignEmployeeGroupIdsSpecified(employeeParameters)
            ? EmployeeParametersHelper.getShowOnDesignEmployeeGroupIds(employeeParameters)
            : employeeGroups.map(i => i.id);
        const result = new WorkOrderCalendar();
        result.calendarAccuracy = calendarAccuracy;
        result.officeStartTimeMin = officeStartTimeMin;
        result.officeEndTimeMin = officeEndTimeMin;
        result.startDate = startDate;
        result.endDate = endDate;
        const tempDate = new Date(startDate);
        result.timeOffsetMin = Base.dateDiffInMinutes(tempDate, new Date(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate()));
        result.visibleTimes = calendarAccuracy !== CalendarAccuracyType.QuarterHour ? [new TimeRange({ start: startDate, end: endDate })] : [];
        const totalDays = (endDate - startDate) / (24 * hourTicks);
        result.totalCols = calendarAccuracy === CalendarAccuracyType.DayTwoWeeks ? totalDays : (calendarAccuracy === CalendarAccuracyType.FourHours ? totalDays * 6 : totalDays * 24 * 4);
        result.ticksPerCol = calendarAccuracy === CalendarAccuracyType.DayTwoWeeks ? 24 * hourTicks : (calendarAccuracy === CalendarAccuracyType.FourHours ? 4 * hourTicks : 15 * minTicks);
        let dayOfWeek = new Date(startDate);
        dayOfWeek.setHours(0, 0, 0, 0);
        const firstNotIncludedTime = new Date(endDate);
        firstNotIncludedTime.setHours(0, 0, 0, 0);
        //Specify day columns of the calendar
        let columnIndex = 0;
        while (true) {
            const calendarDay = calendarDays.find(j => j.day === dayOfWeek.getTime());
            let dayFractionIndex = 0;
            let time = dayOfWeek.getTime();
            let dayTitle = "";
            const oldColumnIndex = columnIndex;
            while (true) {
                const newDate = WorkOrderCalendarDate.createWorkOrderCalendarDate(calendarAccuracy, officeStartTimeMin, officeEndTimeMin, calendarDay, dayOfWeek, time, columnIndex);
                if (newDate.dayTitle && !newDate.visible) {
                    dayTitle = newDate.dayTitle;
                }
                result.dates.push(newDate);
                dayFractionIndex++;
                columnIndex++;
                if (calendarAccuracy === CalendarAccuracyType.DayTwoWeeks) break;
                if (calendarAccuracy === CalendarAccuracyType.FourHours) {
                    if (dayFractionIndex >= 6) break;
                    time = time + 4 * hourTicks;
                } else {
                    if (dayFractionIndex >= 96) break;
                    time = time + 15 * minTicks;
                }
            }
            if (calendarAccuracy === CalendarAccuracyType.QuarterHour) {
                const timeRange = new TimeRange();
                for (let i = oldColumnIndex; i < columnIndex; i++) {
                    if (result.dates[i].visible) {
                        result.dates[i].dayTitle = dayTitle;
                        timeRange.start = result.dates[i].timeTime;
                        for (let j = i; j < columnIndex; j++) {
                            if (!result.dates[j].visible) break;
                            timeRange.end = result.dates[j].timeTime + 15 * minTicks;
                        }
                        result.visibleTimes.push(timeRange);
                        break;
                    }
                }
            }
            dayOfWeek = dayOfWeek.addDays();
            if (dayOfWeek >= firstNotIncludedTime) break;
        }
        for (let i = 0; i < workOrders.length; i++) {
            workOrders[i].setTooltip((lineId: string) => {
                const line = calendarLines.find(j => j.id === lineId);
                return line ? line.name : "";
            });
        }
        const enabledWorkOrderIds = filteredWorkOrders.map(i => i.id);
        const showOnlyEnabledWorkOrders = !filterShowFiltered;
        const scheduledWorkOrders = workOrders.filter(i => i.startTime && i.endTime && i.isOverlapping(startDate, endDate));
        const unscheduledAndAssignedWorkOrders = workOrders.filter(i => (!i.startTime || !i.endTime) && (i.employees.length > 0 || i.vehicles.length > 0));
        result.visibleColsCount = 0;
        for (let i = 0; i < result.visibleTimes.length; i++) {
            result.visibleColsCount = result.visibleColsCount + ((result.visibleTimes[i].end - result.visibleTimes[i].start) / result.ticksPerCol);
        }
        //console.log("result.visibleColsCount", result.visibleColsCount);
        result.totalLinesCount = 0;
        let assignedWorkOrderIds: string[] = [];

        //Add projects
        const projectLines: WorkOrderCalendarLine[] = [];
        let categoryLine = WorkOrderCalendarLine.createTitleCalendarLine(Translations.Projects, 0);
        result.categories.push(categoryLine.id);
        const projectCalendarLines = calendarLines ? calendarLines.filter(i => i.isProject() && !i.isRecurringProject()) : [];
        categoryLine.numberOfChildren = projectCalendarLines.length;
        projectLines.push(categoryLine);
        for (const projectCalendarLine of projectCalendarLines) {
            //Main line
            const line = WorkOrderCalendarLine.createWorkOrderCalendarLine("", categoryLine.id, projectCalendarLine, scheduledWorkOrders.filter(k => k.id === projectCalendarLine.id), dayBookings, result.totalLinesCount, enabledWorkOrderIds, showOnlyEnabledWorkOrders);
            assignedWorkOrderIds = assignedWorkOrderIds.concat(line.workOrders.map(j => j.workOrder.id));
            projectLines.push(line);
            //Sub lines: project: tasks
            const taskWorkOrders = workOrders.filter(k => k.parentId === line.id);
            WorkOrderItem.sortWorkOrderItems(taskWorkOrders, "startTime", true);
            let lastSubLine: WorkOrderCalendarLine = null;
            for (const taskWorkOrder of taskWorkOrders) {
                const childCalendarLine = calendarLines.find(k => k.id === taskWorkOrder.id);
                if (!childCalendarLine) continue;
                const subLine = WorkOrderCalendarLine.createWorkOrderCalendarLine(line.id, categoryLine.id, childCalendarLine, scheduledWorkOrders.filter(k => k.id === taskWorkOrder.id), dayBookings, result.totalLinesCount, enabledWorkOrderIds, showOnlyEnabledWorkOrders);
                subLine.tooltip = taskWorkOrder.tooltip;
                assignedWorkOrderIds = assignedWorkOrderIds.concat(subLine.workOrders.map(k => k.workOrder.id));
                line.childLineIds.push(subLine.id);
                projectLines.push(subLine);
                lastSubLine = subLine;
            }
            if (lastSubLine) {
                lastSubLine.isLastChild = true;
            }
            const workOrder = workOrders.find(j => j.id === line.id);
            if (workOrder) {
                line.tooltip = workOrder.tooltip;
            }
        }

        //Add recurring work projects
        const recurringProjectLines: WorkOrderCalendarLine[] = [];
        categoryLine = WorkOrderCalendarLine.createTitleCalendarLine(Translations.RecurringWorks, 0);
        result.categories.push(categoryLine.id);
        const recurringProjectCalendarLines = calendarLines ? calendarLines.filter(i => i.isRecurringProject()) : [];
        categoryLine.numberOfChildren = recurringProjectCalendarLines.length;
        recurringProjectLines.push(categoryLine);
        for (const recurringProjectCalendarLine of recurringProjectCalendarLines) {
            //Main line
            const line = WorkOrderCalendarLine.createWorkOrderCalendarLine("", categoryLine.id, recurringProjectCalendarLine, scheduledWorkOrders.filter(k => k.parentId === recurringProjectCalendarLine.id), dayBookings, result.totalLinesCount, enabledWorkOrderIds, showOnlyEnabledWorkOrders);
            assignedWorkOrderIds.push(recurringProjectCalendarLine.id);
            assignedWorkOrderIds = assignedWorkOrderIds.concat(line.workOrders.map(j => j.workOrder.id));
            recurringProjectLines.push(line);
            const workOrder = workOrders.find(j => j.id === line.id);
            if (workOrder) {
                line.tooltip = workOrder.tooltip;
            }
        }

        //Add EmployeeGroups
        const employeeLines: WorkOrderCalendarLine[] = [];
        const employeeCalendarLines = calendarLines ? calendarLines.filter(i => i.isEmployee()) : [];
        employeeGroups.forEach(employeeGroup => {
            const isVisibleEmployeeGroup = showEmployeeGroupIds.indexOf(employeeGroup.id) > -1;
            const relevantCalendarLines = employeeCalendarLines.filter(item => item.groupId === employeeGroup.id);
            categoryLine = WorkOrderCalendarLine.createTitleCalendarLine(employeeGroup.name, 0);
            categoryLine.numberOfChildren = relevantCalendarLines.length;
            if (isVisibleEmployeeGroup) {
                result.categories.push(categoryLine.id);
                employeeLines.push(categoryLine);
            }
            relevantCalendarLines.forEach(relevantCalendarLine => {
                const calendarLine = relevantCalendarLine;
                const line = WorkOrderCalendarLine.createWorkOrderCalendarLine("", categoryLine.id, calendarLine, scheduledWorkOrders.filter(j => !j.isProject() && j.employees.find(k => k.id === calendarLine.id)), dayBookings, result.totalLinesCount, enabledWorkOrderIds, showOnlyEnabledWorkOrders);
                assignedWorkOrderIds = assignedWorkOrderIds.concat(line.workOrders.map(j => j.workOrder.id));
                if (!isVisibleEmployeeGroup) return;
                employeeLines.push(line);
                line.tooltip = line.name;
                const employeeUnscheduledAndAssignedWorkOrders = unscheduledAndAssignedWorkOrders.filter(j => j.employees.find(k => k.id === calendarLine.id));
                let lastSubLine: WorkOrderCalendarLine = null;
                employeeUnscheduledAndAssignedWorkOrders.forEach(workOrder => {
                    const subLine = WorkOrderCalendarLine.createUnscheduledWorkOrderCalendarLine(line.id, categoryLine.id, workOrder, result.totalLinesCount);
                    subLine.tooltip = workOrder.tooltip;
                    line.childLineIds.push(subLine.id);
                    employeeLines.push(subLine);
                    lastSubLine = subLine;
                });
                if (lastSubLine) {
                    lastSubLine.isLastChild = true;
                }
            });
        });

        //Add VehicleGroups
        const vehicleLines: WorkOrderCalendarLine[] = [];
        const vehicleCalendarLines = calendarLines ? calendarLines.filter(i => i.isVehicle()) : [];
        vehicleGroups.forEach(vehicleGroup => {
            const isVisibleVehicleGroup = showVehicleGroupIds.indexOf(vehicleGroup.id) > -1;
            const relevantCalendarLines = vehicleCalendarLines.filter(item => item.groupId === vehicleGroup.id);
            categoryLine = WorkOrderCalendarLine.createTitleCalendarLine(vehicleGroup.name, 0);
            categoryLine.numberOfChildren = relevantCalendarLines.length;
            if (isVisibleVehicleGroup) {
                result.categories.push(categoryLine.id);
                vehicleLines.push(categoryLine);
            }
            relevantCalendarLines.forEach(relevantCalendarLine => {
                const calendarLine = relevantCalendarLine;
                const line = WorkOrderCalendarLine.createWorkOrderCalendarLine("", categoryLine.id, calendarLine, scheduledWorkOrders.filter(j => !j.isProject() && j.vehicles.find(k => k.id === calendarLine.id)), [], result.totalLinesCount, enabledWorkOrderIds, showOnlyEnabledWorkOrders);
                assignedWorkOrderIds = assignedWorkOrderIds.concat(line.workOrders.map(j => j.workOrder.id));
                if (!isVisibleVehicleGroup) return;
                vehicleLines.push(line);
                line.tooltip = line.name;
                const vehicleUnscheduledAndAssignedWorkOrders = unscheduledAndAssignedWorkOrders.filter(j => j.vehicles.find(k => k.id === calendarLine.id));
                let lastSubLine: WorkOrderCalendarLine = null;
                vehicleUnscheduledAndAssignedWorkOrders.forEach(workOrder => {
                    const subLine = WorkOrderCalendarLine.createUnscheduledWorkOrderCalendarLine(line.id, categoryLine.id, workOrder, result.totalLinesCount);
                    subLine.tooltip = workOrder.tooltip;
                    line.childLineIds.push(subLine.id);
                    vehicleLines.push(subLine);
                    lastSubLine = subLine;
                });
                if (lastSubLine) {
                    lastSubLine.isLastChild = true;
                }
            });
        });

        //Add StandByWorks: WorkOrders that have time specified, but not employee, vehicle or project specified
        const unassignedLines: WorkOrderCalendarLine[] = [];
        categoryLine = WorkOrderCalendarLine.createTitleCalendarLine(Translations.StandByWorks, 0);
        categoryLine.hint = Translations.UnresourcedStandByWorkOrders;
        result.categories.push(categoryLine.id);
        let unassignedScheduledWorkOrders = scheduledWorkOrders.filter(i => assignedWorkOrderIds.indexOf(i.id) < 0);
        categoryLine.numberOfChildren = unassignedScheduledWorkOrders.length;
        unassignedLines.push(categoryLine);
        if (showOnlyEnabledWorkOrders) {
            unassignedScheduledWorkOrders = unassignedScheduledWorkOrders.filter(i => enabledWorkOrderIds.indexOf(i.id) > -1);
        }
        if (unassignedScheduledWorkOrders.length) {
            for (let i = 0; i < unassignedScheduledWorkOrders.length; i++) {
                const line = WorkOrderCalendarLine.createUnassignedWorkOrderCalendarLine(categoryLine.id, unassignedScheduledWorkOrders[i], result.totalLinesCount, enabledWorkOrderIds);
                unassignedLines.push(line);
            }
        }
        //Set lines in desired order
        result.totalLinesCount = 0;
        result.addLines(unassignedLines);
        result.addLines(projectLines);
        result.addLines(employeeLines);
        result.addLines(vehicleLines);
        result.addLines(recurringProjectLines);
        return result;
    }
}
