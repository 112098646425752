import * as React from "react";
import { Paper } from "@mui/material";

export interface Props {
    children: JSX.Element;
    className?: string;
    topComponent?: JSX.Element;
    sideComponent?: JSX.Element;
    noContainer?: boolean;
}

// Keep this component as simple as possible. If you need something much more complex, create a new component instead.
export const MainLayout = ({ children, className, topComponent, sideComponent, noContainer }: Props) => {
    return (
        <section id="main-layout" className={className}>
            {topComponent &&
                <section id="section-top">
                    <Paper className="section-container">
                        {topComponent}
                    </Paper>
                </section>
            }
            <main className={topComponent ? "mt-3" : undefined}>
                <section id="section-main">
                    {noContainer ? children : <Paper className="section-container">{children}</Paper>}
                </section>

                {sideComponent &&
                    <section id="section-side">
                        <Paper className="section-container">
                            {sideComponent}
                        </Paper>
                    </section>
                }
            </main>
        </section>
    );
};
