// WorkMainList
// ***********************************************************************************************************************
import * as React from "react";
import { Translations } from "../../models/translations";
import { Base } from "../../framework/base";
import { RadioButton } from "../framework/radio";
import { IWorkOrderItem } from "../../models/work/workOrderItem";
import { Button } from "../framework/button";
import { ButtonDropdown } from "../framework/dropdown";
import { WorkMainListWorkOrderList } from "./workMainListWorkOrderList";
import { WorkMainViewMode } from "./workMain";
import { ToolButton } from "../framework/toolButton";
import { CalendarAccuracyType } from "../../models/common/enums";

interface IWorkMainListProps {
    startDate: number;
    endDate: number;
    selectedWorkOrderId: string;
    checkedWorkOrderIds: string[];
    calendarAccuracy: CalendarAccuracyType;
    workOrders: IWorkOrderItem[];
    workOrderTemplates: IWorkOrderItem[];

    onPreviousClick: () => void;
    onNextClick: () => void;
    onNewWorkOrder: () => void;
    onNewWorkOrderBasedOnTemplate: () => void;
    onNewProject: () => void;
    onNewProjectBasedOnTemplate: () => void;
    onOpenSelectedWorkOrder: (id: string) => void;
    onEditSelectedWorkOrder: () => void;
    onRemoveWorkOrder: () => void;
    onCopyWorkOrder: () => void;
    onAddCustomer: () => void;
    onAddContact: () => void;

    onSetSelectedIdAndCheckedIds: (selectedId: string, checkedIds: string[]) => void;
    onOpenWorkOrder: (id: string) => void;
    onChangeViewMode: (viewMode: WorkMainViewMode) => void;
}

interface IWorkMainListState {
    viewType: number;
    listHeight: number;
    yOffset: number;
}

export class WorkMainList extends React.Component<IWorkMainListProps, IWorkMainListState> {
    private weekDiv: HTMLDivElement;
    private titleRowDiv: HTMLDivElement;

    constructor(props: IWorkMainListProps) {
        super(props);
        this.state = {
            viewType: 1,
            yOffset: 0,
            listHeight: 0
        };
    }

    updateHeight = () => {
        if (!this.titleRowDiv) return;
        const state = this.state;
        const yOffset = this.titleRowDiv.clientHeight;
        const listHeight = this.weekDiv.clientHeight - yOffset;
        //console.log("listHeight", listHeight);
        if (state.listHeight !== listHeight) {
            this.setState({ listHeight: listHeight, yOffset: yOffset });
        }
    };

    componentDidMount(): void {
        this.updateHeight();
        window.addEventListener("resize", this.updateHeight);
    }

    componentWillUnmount(): void {
        window.removeEventListener("resize", this.updateHeight);
    }

    handleViewTypeChange = (viewType: number) => {
        this.setState({ viewType: viewType });
    };

    render() {
        const props = this.props;
        const state = this.state;
        const workOrderIsSelected = !!props.selectedWorkOrderId;
        return (
            <div className="week" ref={(elem) => { this.weekDiv = elem; }}>
                <WorkMainListWorkOrderList
                    style={{ height: state.listHeight ? state.listHeight + "px" : null }}
                    viewType={state.viewType}
                    workOrders={props.workOrders}
                    selectedId={props.selectedWorkOrderId}
                    checkedIds={props.checkedWorkOrderIds}
                    onSetSelectedIdAndCheckedIds={props.onSetSelectedIdAndCheckedIds}
                    onOpenWorkOrder={props.onOpenSelectedWorkOrder}
                />
                <div className="headerBackground">
                    <div className="titleRow" ref={(elem) => { this.titleRowDiv = elem; }}>
                        <div className="aRow">
                            <div className="titleCol">
                                <Button
                                    classes="btn-primary"
                                    title={Translations.NewWork}
                                    enabled={true}
                                    onClick={props.onNewWorkOrder}
                                    secondaryActions={[
                                        { title: Translations.NewProject, onClick: props.onNewProject },
                                        { title: Translations.NewWorkBasedOnWorkOrderTemplate, onClick: props.workOrderTemplates.filter(i => !i.isProject()).length > 0 ? props.onNewWorkOrderBasedOnTemplate : null },
                                        { title: Translations.NewProjectBasedOnWorkOrderTemplate, onClick: props.workOrderTemplates.filter(i => i.isProject()).length > 0 ? props.onNewProjectBasedOnTemplate : null }
                                    ]}
                                />
                                <ButtonDropdown
                                    selectedTitle={""}
                                    actions={[
                                        { disabled: !workOrderIsSelected, title: (Translations.Open), onClick: () => props.onOpenSelectedWorkOrder(null) },
                                        { disabled: !workOrderIsSelected, title: (Translations.Edit), onClick: props.onEditSelectedWorkOrder },
                                        { disabled: !workOrderIsSelected, title: (Translations.Remove), onClick: props.onRemoveWorkOrder },
                                        { disabled: !workOrderIsSelected, title: (Translations.Copy), onClick: props.onCopyWorkOrder },
                                        { title: (Translations.NewCustomer), onClick: props.onAddCustomer },
                                        { title: (Translations.NewContactPerson), onClick: props.onAddContact },
                                    ]}
                                />
                            </div>
                            <div className="contentCol">
                                <div>
                                    <ToolButton
                                        title={Translations.Previous}
                                        classes="previous noFrame left"
                                        enabled={true}
                                        onClick={props.onPreviousClick}
                                    />
                                    <ToolButton
                                        title={Translations.Next}
                                        classes="next noFrame left"
                                        enabled={true}
                                        onClick={props.onNextClick}
                                    />
                                </div>
                                <div className="btn-group btn-group-toggle states">
                                    <RadioButton
                                        key={1}
                                        classes={"btn-secondary list"}
                                        enabled={true}
                                        checked={state.viewType > 0.5}
                                        onRadioClick={() => this.handleViewTypeChange(1)}
                                    />
                                    <RadioButton
                                        key={1}
                                        classes={"btn-secondary grid"}
                                        enabled={true}
                                        checked={state.viewType < 0.5}
                                        onRadioClick={() => this.handleViewTypeChange(0)}
                                    />
                                </div>
                                <div className="mainTitle">{props.startDate && props.endDate ? Base.timeToDateStr(props.startDate) + "-" + Base.timeToDateStr(props.endDate - 1) : ""}</div>
                                <div className="btn-group btn-group-toggle states">
                                    <RadioButton
                                        key={1}
                                        classes={"btn-secondary"}
                                        title={Translations.TimeTable}
                                        enabled={true}
                                        checked={false}
                                        onRadioClick={() => { props.onChangeViewMode(WorkMainViewMode.Calendar); }}
                                    />
                                    <RadioButton
                                        key={2}
                                        classes={"btn-secondary"}
                                        title={Translations.List}
                                        enabled={true}
                                        checked={true}
                                        onRadioClick={() => { props.onChangeViewMode(WorkMainViewMode.List); }}
                                    />
                                    <RadioButton
                                        key={0}
                                        classes={"btn-secondary"}
                                        title={Translations.Map}
                                        enabled={true}
                                        checked={false}
                                        onRadioClick={() => { props.onChangeViewMode(WorkMainViewMode.Map); }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
