// SettingsEmployeeDialogEmployeeCompetencyList - MODULE
// ***********************************************************************************************************************
import downloadjs from "downloadjs";
import * as React from "react";
import Datetime from "react-datetime";
import * as store from "../../framework/customStore";
import * as storeActions from "../../models/store/storeActions";
import * as baseService from "../../services/baseService";
import * as documentService from "../../services/documentService";
import { Translations } from "../../models/translations";
import { PropertyDialog } from "../framework/dialog";
import { ToolButton } from "../framework/toolButton";
import { Base } from "../../framework/base";
import { IEmployeeEditItemEmployeeCompetencyItem, EmployeeEditItemEmployeeCompetencyItem } from "../../models/employee/employeeEditItemEmployeeCompetencyItem";
import { IEmployeeEditCompetencyItem } from "../../models/employee/employeeEditCompetencyItem";
import { IDocument, Document } from "../../models/document/document";
import { DocumentList } from "../document/documentList";
import { IIdTitle } from "../../models/common/idTitle";
import { DocumentType } from "../../models/common/enums";

// SettingsEmployeeDialogEmployeeCompetencyDialog
// ***********************************************************************************************************************
export interface ISettingsEmployeeDialogEmployeeCompetencyDialogProp {
    classes?: string;
    employeeId: string;
    employees: IIdTitle[];
    competencies: IEmployeeEditCompetencyItem[];
    item: IEmployeeEditItemEmployeeCompetencyItem;
    isNew: boolean;
    onOk: (employeeId: string, competencyId: string, date: number, expireDate: number, comment: string, documents: IDocument[], removedDocumentIds: string[]) => void;
    onCancel: () => void;
}

export interface ISettingsEmployeeDialogEmployeeCompetencyDialogState {
    employeeId: string;
    competencyId: string;
    dateStr: string;
    expireDateStr: string;
    comment: string;
    documents: IDocument[];
    removedDocumentIds: string[];
}

export class SettingsEmployeeDialogEmployeeCompetencyDialog extends React.Component<ISettingsEmployeeDialogEmployeeCompetencyDialogProp, ISettingsEmployeeDialogEmployeeCompetencyDialogState> {
    constructor(props) {
        super(props);
        const item = props.item;
        this.state = {
            employeeId: this.props.employeeId, competencyId: item.competencyId, dateStr: Base.utcTimeToDateStr(item.date), expireDateStr: Base.utcTimeToDateStr(item.expireDate), comment: item.comment, documents: item.documents.slice(0), removedDocumentIds: []
        };
    }

    handleChange = (event) => {
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        if (name === "employeeId") {
            this.setState({ employeeId: value });
        } else if (name === "competencyId") {
            this.setState({ competencyId: value });
        } else if (name === "comment") {
            this.setState({ comment: value });
        }
    };

    handleDateChange = (value: moment.Moment | string) => {
        if (!(typeof value === "string")) {
            this.setState({ dateStr: !Base.isNullOrUndefined(value) ? Base.utcTimeToDateStr(value) : "" });
        }
    };

    handleDateBlur = (value: moment.Moment | string) => {
        if (typeof value === "string") {
            this.setState({ dateStr: value ? Base.utcTimeToDateStr(value.toUtcDate().getTime()) : "" });
        } else {
            this.setState({ dateStr: !Base.isNullOrUndefined(value) ? Base.utcTimeToDateStr(value) : "" });
        }
    };

    handleExpireDateChange = (value: moment.Moment | string) => {
        if (!(typeof value === "string")) {
            this.setState({ expireDateStr: !Base.isNullOrUndefined(value) ? Base.utcTimeToDateStr(value) : "" });
        }
    };

    handleExpireDateBlur = (value: moment.Moment | string) => {
        if (typeof value === "string") {
            this.setState({ expireDateStr: value ? Base.utcTimeToDateStr(value.toUtcDate().getTime()) : "" });
        } else {
            this.setState({ expireDateStr: !Base.isNullOrUndefined(value) ? Base.utcTimeToDateStr(value) : "" });
        }
    };

    handleDocumentsAdd = (fileList: FileList) => {
        const employeeCompetency = this.props.item;
        Document.addFileListToDocuments(employeeCompetency.id, this.state.documents, fileList).then(documents => {
            this.setState({ documents: documents });
        });
    };

    handleDocumentEdit = (id: string, comment: string, documentType: DocumentType) => {
        if (!id) return;
        const documents = this.state.documents.slice(0);
        const document = documents.find(i => i.id === id);
        if (!document) return;
        document.comment = comment;
        document.documentType = documentType;
        this.setState({ documents: documents });
    };

    handleDocumentRemove = (id: string) => {
        if (!id) return;
        const documents = this.state.documents.filter(i => i.id !== id);
        const removedDocumentIds = this.state.removedDocumentIds.slice(0);
        removedDocumentIds.push(id);
        this.setState({ documents: documents, removedDocumentIds: removedDocumentIds });
    };

    handleDocumentDownload = (id: string) => {
        if (!id) return;
        const document = this.state.documents.find(i => i.id === id);
        if (!document) return;
        if (document.file) {
            downloadjs(document.file, document.reference); //, "image/jpeg"
            return;
        }
        if (document.isNew()) return;
        documentService.getDocument(id).catch(error => {
            store.customStore.dispatch(storeActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
        });
    };

    handleOkClick = () => {
        this.props.onOk(this.state.employeeId, this.state.competencyId, this.state.dateStr ? this.state.dateStr.toUtcDate().getTime() : null, this.state.expireDateStr ? this.state.expireDateStr.toUtcDate().getTime() : null, this.state.comment, this.state.documents, this.state.removedDocumentIds);
    };

    handleCancelClick = () => {
        this.props.onCancel();
    };

    render() {
        const props = this.props;
        const dialogClasses = "employeeCompetency px600" + (props.classes ? " " + props.classes : "");
        return (
            <div>
                <PropertyDialog
                    classes={dialogClasses}
                    title={Translations.Competency + " - " + (!props.isNew ? props.item.competencyName : Translations.New)}
                    show={true}
                    body={<div>
                        <div className="row">
                            {!Base.isNullOrUndefined(props.employees) &&
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="control-label smallFont">{Translations.Employee}</label>
                                        <select className="form-control" name="employeeId" title={Translations.Employee} value={this.state.employeeId} onChange={this.handleChange} disabled={!props.isNew}>
                                            {props.employees.map((employee) =>
                                                <option key={employee.id} value={employee.id}>{employee.title}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                            }
                            <div className="col-6">
                                <div className="form-group">
                                    <label className="control-label smallFont">{Translations.Competency}</label>
                                    <select className="form-control" name="competencyId" title={Translations.Competency} value={this.state.competencyId} onChange={this.handleChange}>
                                        {props.competencies.map((competency) =>
                                            <option key={competency.id} value={competency.id}>{competency.name}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="form-group">
                                    <label className="control-label smallFont">{Translations.GrantDate}</label>
                                    <Datetime
                                        locale={appConfig.culture}
                                        className={"roWhite"}
                                        value={this.state.dateStr}
                                        dateFormat={"D.M.YYYY"}
                                        timeFormat={false}
                                        closeOnSelect={true}
                                        inputProps={{ maxLength: 10, readOnly: false, disabled: false }}
                                        onChange={this.handleDateChange}
                                        onClose={this.handleDateBlur}
                                    />
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="form-group">
                                    <label className="control-label smallFont">{Translations.ExpirationDate}</label>
                                    <Datetime
                                        locale={appConfig.culture}
                                        className={"roWhite"}
                                        value={this.state.expireDateStr}
                                        dateFormat={"D.M.YYYY"}
                                        timeFormat={false}
                                        closeOnSelect={true}
                                        inputProps={{ maxLength: 10, readOnly: false, disabled: false }}
                                        onChange={this.handleExpireDateChange}
                                        onClose={this.handleExpireDateBlur}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="control-label smallFont">{Translations.Comment}</label>
                                    <textarea className="form-control" name="comment" title={Translations.Comment} value={this.state.comment} onChange={this.handleChange} maxLength={1000} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <DocumentList
                                classes="col-12"
                                relatedObjectId={props.item.id}
                                title={Translations.Attachments}
                                documents={this.state.documents}
                                isReadOnly={false}
                                canShowCamera={false}
                                changeDocumentTypeEnabled={true}
                                onEditDocument={this.handleDocumentEdit}
                                onRemoveDocument={this.handleDocumentRemove}
                                onDownloadDocument={this.handleDocumentDownload}
                                onAddDocuments={this.handleDocumentsAdd}
                                onAddPhoto={null}
                            />
                        </div>
                    </div>}
                    buttons={[
                        { title: Translations.OK, classes: "btn-primary", enabled: true, onClick: this.handleOkClick }]}
                    onClose={this.handleCancelClick}
                />
            </div>
        );
    }
}

// SettingsEmployeeDialogEmployeeCompetencyListLine
export interface ISettingsEmployeeDialogEmployeeCompetencyListLineProp {
    item: IEmployeeEditItemEmployeeCompetencyItem;
    selectedId: string;
    competencyWarningLimitInDays: number;
    onClick: (id: string) => void;
    onDoubleClick: (id: string) => void;
}

export class SettingsEmployeeDialogEmployeeCompetencyListLine extends React.Component<ISettingsEmployeeDialogEmployeeCompetencyListLineProp, {}> {
    render() {
        const props = this.props;
        const item = props.item;
        let expireInDays = -1000;
        if (!Base.isNullOrUndefined(item.expireDate)) {
            const nowDate = Base.getNowUtcDate().getTime();
            if (item.expireDate < nowDate) {
                expireInDays = -1;
            } else {
                expireInDays = (item.expireDate - nowDate) / (1000 * 60 * 60 * 24);
            }
        }
        return (
            <div className={"row line" + (item.id === props.selectedId ? " selected" : "") + (expireInDays >= -2 && expireInDays <= props.competencyWarningLimitInDays ? " expireLevel" + (expireInDays < 0 ? 1 : 2).toString(10) : "")} onClick={() => { props.onClick(item.id); }} onDoubleClick={() => { props.onDoubleClick(item.id); }}
                title={item.competencyTypeName + ", " + item.competencyName + ", " + Base.utcTimeToDateStr(item.date) + ", " + Base.utcTimeToDateStr(item.expireDate) + (expireInDays >= 0 ? " (" + expireInDays.toString(10) + " " + Translations.MeasureUnitD + ") " : "") + ", " + item.comment}
            >
                <div className="col-2">{item.competencyTypeName}</div>
                <div className="col-3">{item.competencyName}</div>
                <div className="col-2">{Base.utcTimeToDateStr(item.date)}</div>
                <div className="col-2 expireDate">{Base.utcTimeToDateStr(item.expireDate)}</div>
                <div className="col-3">{item.comment}</div>
            </div>
        );
    }
}

// SettingsEmployeeDialogEmployeeCompetencyListLineReadOnlyView
export interface ISettingsEmployeeDialogEmployeeCompetencyListLineReadOnlyViewProp {
    item: IEmployeeEditItemEmployeeCompetencyItem;
    competencyWarningLimitInDays: number;
}

export class SettingsEmployeeDialogEmployeeCompetencyListLineReadOnlyView extends React.Component<ISettingsEmployeeDialogEmployeeCompetencyListLineReadOnlyViewProp, {}> {
    render() {
        const props = this.props;
        const item = props.item;
        let expireInDays = -1000;
        if (!Base.isNullOrUndefined(item.expireDate)) {
            const nowDate = Base.getNowUtcDate().getTime();
            if (item.expireDate < nowDate) {
                expireInDays = -1;
            } else {
                expireInDays = (item.expireDate - nowDate) / (1000 * 60 * 60 * 24);
            }
        }
        return (
            <div className={"row line" + (expireInDays >= -2 && expireInDays <= props.competencyWarningLimitInDays ? " expireLevel" + (expireInDays < 0 ? 1 : 2).toString(10) : "")}
                title={item.competencyTypeName + ", " + item.competencyName + ", " + Base.utcTimeToDateStr(item.date) + ", " + Base.utcTimeToDateStr(item.expireDate) + (expireInDays >= 0 ? " (" + expireInDays.toString(10) + " " + Translations.MeasureUnitD + ") " : "") + ", " + item.comment}
            >
                <div className="col-2">{item.competencyTypeName}</div>
                <div className="col-3">{item.competencyName}</div>
                <div className="col-2">{Base.utcTimeToDateStr(item.date)}</div>
                <div className="col-2 expireDate">{Base.utcTimeToDateStr(item.expireDate)}</div>
                <div className="col-3">{item.comment}</div>
            </div>
        );
    }
}

// SettingsEmployeeDialogEmployeeCompetencyList
export interface ISettingsEmployeeDialogEmployeeCompetencyListProp {
    title?: string;
    competencies: IEmployeeEditCompetencyItem[];
    items: IEmployeeEditItemEmployeeCompetencyItem[];
    competencyWarningLimitInDays: number;
    onRemoveEmployeeCompetency: (id: string) => void;
    onAddEmployeeCompetency: (id: string, competencyId: string, date: number, expireDate: number, comment: string, documents: IDocument[], removedDocumentIds: string[]) => void;
    onEditEmployeeCompetency: (id: string, competencyId: string, date: number, expireDate: number, comment: string, documents: IDocument[], removedDocumentIds: string[]) => void;
}

interface ISettingsEmployeeDialogEmployeeCompetencyListState {
    selectedId: string;
    selectedEmployeeCompetency: IEmployeeEditItemEmployeeCompetencyItem;
    showEmployeeCompetencyDialog: boolean;
    showEmployeeCompetencyDialogForNew: boolean;
}

export class SettingsEmployeeDialogEmployeeCompetencyList extends React.Component<ISettingsEmployeeDialogEmployeeCompetencyListProp, ISettingsEmployeeDialogEmployeeCompetencyListState> {
    constructor(props) {
        super(props);
        this.state = { selectedId: null, selectedEmployeeCompetency: null, showEmployeeCompetencyDialog: false, showEmployeeCompetencyDialogForNew: false };
    }

    handleAdd = () => {
        const employeeCompetency = new EmployeeEditItemEmployeeCompetencyItem();
        employeeCompetency.id = Base.getGuid();
        employeeCompetency.competencyId = this.props.competencies[0].id;
        employeeCompetency.date = null;
        employeeCompetency.expireDate = null;
        this.setState({
            selectedEmployeeCompetency: employeeCompetency,
            showEmployeeCompetencyDialog: true,
            showEmployeeCompetencyDialogForNew: true
        });
    };

    handleEdit = () => {
        const selectedId = this.state.selectedId;
        if (!selectedId) return;
        const employeeCompetency = this.props.items.find(i => i.id === selectedId);
        if (Base.isNullOrUndefined(employeeCompetency)) return;
        this.setState({
            selectedEmployeeCompetency: employeeCompetency,
            showEmployeeCompetencyDialog: true,
            showEmployeeCompetencyDialogForNew: false
        });
    };

    handleBookingDialogOk = (employeeId: string, competencyId: string, date: number, expireDate: number, comment: string, documents: IDocument[], removedDocumentIds: string[]) => {
        const selectedEmployeeCompetency = this.state.selectedEmployeeCompetency;
        const showEmployeeCompetencyDialogForNew = this.state.showEmployeeCompetencyDialogForNew;
        this.setState({
            selectedId: selectedEmployeeCompetency.id,
            showEmployeeCompetencyDialog: false,
            showEmployeeCompetencyDialogForNew: false
        });
        if (showEmployeeCompetencyDialogForNew) {
            this.props.onAddEmployeeCompetency(selectedEmployeeCompetency.id, competencyId, date, expireDate, comment, documents, removedDocumentIds);
        } else {
            this.props.onEditEmployeeCompetency(selectedEmployeeCompetency.id, competencyId, date, expireDate, comment, documents, removedDocumentIds);
        }
    };

    handleBookingDialogCancel = () => {
        this.setState({
            showEmployeeCompetencyDialog: false
        });
    };

    handleRemove = () => {
        const selectedId = this.state.selectedId;
        if (!selectedId) return;
        this.props.onRemoveEmployeeCompetency(selectedId);
    };

    handleLineClick = (id: string) => {
        this.setState({
            selectedId: id
        });
    };

    handleLineDoubleClick = (id: string) => {
        this.handleEdit();
    };

    render() {
        const props = this.props;
        const state = this.state;
        const items = props.items;
        return (
            <div>
                <div className="commandRow">
                    {!!props.title &&
                        <label className="control-label listTitle">{props.title}</label>
                    }
                    <ToolButton
                        title={Translations.Remove}
                        enabled={!!state.selectedId}
                        classes={"round right remove"}
                        onClick={this.handleRemove}
                    />
                    <ToolButton
                        title={Translations.Edit}
                        enabled={!!state.selectedId}
                        classes={"round right edit"}
                        onClick={this.handleEdit}
                    />
                    <ToolButton
                        title={Translations.Add}
                        enabled={true}
                        classes={"round right add"}
                        onClick={this.handleAdd}
                    />
                </div>
                <div className="listContainer employeeCompetenciesContainer">
                    {items.length > 0 &&
                        <div className="list employeeCompetencies">
                            {items.map((item) =>
                                <SettingsEmployeeDialogEmployeeCompetencyListLine
                                    key={item.id}
                                    item={item}
                                    selectedId={state.selectedId}
                                    competencyWarningLimitInDays={props.competencyWarningLimitInDays}
                                    onClick={this.handleLineClick}
                                    onDoubleClick={this.handleLineDoubleClick}
                                />
                            )}
                        </div>
                    }
                </div>
                {this.state.showEmployeeCompetencyDialog && !Base.isNullOrUndefined(state.selectedEmployeeCompetency) &&
                    <SettingsEmployeeDialogEmployeeCompetencyDialog
                        employeeId={null}
                        employees={null}
                        competencies={props.competencies}
                        item={state.selectedEmployeeCompetency}
                        isNew={state.showEmployeeCompetencyDialogForNew}
                        onOk={this.handleBookingDialogOk}
                        onCancel={this.handleBookingDialogCancel}
                    />
                }
            </div>
        );
    }
}

// SettingsEmployeeDialogEmployeeCompetencyListReadOnlyView
export interface ISettingsEmployeeDialogEmployeeCompetencyListReadOnlyViewProp {
    title?: string;
    competencies: IEmployeeEditCompetencyItem[];
    items: IEmployeeEditItemEmployeeCompetencyItem[];
    competencyWarningLimitInDays: number;
}

export class SettingsEmployeeDialogEmployeeCompetencyListReadOnlyView extends React.Component<ISettingsEmployeeDialogEmployeeCompetencyListReadOnlyViewProp> {
    render() {
        const props = this.props;
        const items = props.items;
        return (
            <div>
                <div className="commandRow">
                    {!!props.title &&
                        <label className="control-label listTitle">{props.title}</label>
                    }
                </div>
                <div className="listContainer employeeCompetenciesContainer">
                    {items.length > 0 &&
                        <div className="list employeeCompetencies">
                            {items.map((item) =>
                                <SettingsEmployeeDialogEmployeeCompetencyListLineReadOnlyView
                                    key={item.id}
                                    item={item}
                                    competencyWarningLimitInDays={props.competencyWarningLimitInDays}

                                />
                            )}
                        </div>
                    }
                </div>
            </div>
        );
    }
}
