import React, { useEffect, useMemo } from "react";
import { Map, MapPointProps, MapRouteProps } from "../framework/map";
import { LatLngExpression } from "leaflet";
import { Base, maxBy, minBy } from "../../framework/base";
import { getVehiclePosition } from "../../services/vehicleService";
import { WorkShiftTimeSlotItem } from "../../models/workShitTimeSlot/workShiftTimeSlotItem";
import { SectionHeader } from "../layout/sectionHeader";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import { LinkIconButton } from "../framework/linkButton";
import { generatePath, useParams } from "react-router-dom";
import {
    IWorkTimeDetailsPage,
    WorkTimeDetailsComponent,
} from "./workTimeBetaDetailsPageMain";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useAppSelector } from "../../framework/customStore";

interface WorkShiftTimeSlotRoute {
    [id: string]: MapRouteProps;
}

interface WorkShiftTimeSlotPoint {
    [id: string]: MapPointProps;
}

interface WorkShiftTimeSlotMapData {
    routes: WorkShiftTimeSlotRoute;
    points: WorkShiftTimeSlotPoint;
}

export const WorkTimeDetailsMap = ({
    workShiftTimeSlots: timeSlots,
    isMaximized,
}: {
    workShiftTimeSlots: WorkShiftTimeSlotItem[];
    isMaximized?: boolean;
}) => {
    const [mapData, setMapData] = React.useState<WorkShiftTimeSlotMapData>();
    const { employeeId, date } = useParams<IWorkTimeDetailsPage>();
    const selectedId = useAppSelector(
        (state) => state.workShiftTimeSlot.selectedId
    );
    const hoveredId = useAppSelector(
        (state) => state.workTimeDetails.hoveredId
    );

    const workShiftTimeSlots = useMemo(
        () => timeSlots.filter((s) => !s.isCustomType),
        [timeSlots]
    );

    const routes = useMemo(
        () =>
            mapData
                ? workShiftTimeSlots
                    .map((s) => {
                        const r = mapData.routes[s.id];
                        return r
                            ? { ...r, highlight: selectedId === s.id || hoveredId === s.id }
                            : null;
                    })
                    .filter(Boolean)
                : null,
        [mapData, workShiftTimeSlots, selectedId, hoveredId]
    );

    const points = useMemo(
        () =>
            mapData
                ? workShiftTimeSlots
                    .map((s) => {
                        const p = mapData.points[s.id];
                        const slot = new WorkShiftTimeSlotItem(s);
                        return p
                            ? {
                                ...p,
                                text: slot.getMapToolTipText(),
                                textColorClass: `text-${slot.getContrastColorClass()}`,
                                textBgColorClass: `bg-${slot.getColorClass()}`,
                                highlight: selectedId === s.id,
                            }
                            : null;
                    })
                    .filter(Boolean)
                : null,
        [mapData, workShiftTimeSlots, selectedId]
    );

    useEffect(() => {
        const abortController = new AbortController();
        const routeBySlot: WorkShiftTimeSlotRoute = {};
        const pointBySlot: WorkShiftTimeSlotPoint = {};

        Promise.all(
            workShiftTimeSlots
                .map(
                    (w, i) =>
                        ({
                            ...w,
                            vehicleId:
                                !w.vehicleId &&
                                new WorkShiftTimeSlotItem(w).isBreak()
                                    ? workShiftTimeSlots[i - 1]?.vehicleId
                                    : w.vehicleId,
                        } as WorkShiftTimeSlotItem)
                )
                .filter((w) => w.vehicleId)
                .map((w) =>
                    getVehiclePosition(
                        w.vehicleId,
                        w.startDate,
                        w.endDate || Base.dayjsToJsonDateTimeOffset(),
                        abortController.signal
                    )
                        .then((res) => {
                            if (res?.data?.length > 0) {
                                routeBySlot[w.id] = {
                                    coords: res.data
                                        .sort((a, b) =>
                                            Base.dayjsCompare(
                                                a.timestamp,
                                                b.timestamp
                                            )
                                        )
                                        .map((d) => [
                                            d.latitude,
                                            d.longitude,
                                        ]) as LatLngExpression[],
                                };

                                pointBySlot[w.id] = {
                                    coords: routeBySlot[w.id].coords[0],
                                };
                            }
                        })
                        .catch(console.error)
                )
        ).finally(() => {
            setMapData({ routes: routeBySlot, points: pointBySlot });
        });
    }, [workShiftTimeSlots.map((s) => s.rowId).join(",")]);

    const items = useMemo(
        () =>
            workShiftTimeSlots.map((t) => ({
                ...t,
                endDate:
                    t.endDate ||
                    Base.dayjsToJsonDateTimeOffset(
                        Base.dateAtTz(t.timeZoneName)
                    ),
            })),
        [workShiftTimeSlots.map((s) => s.rowId).join(",")]
    );

    const headerLabel = useMemo(() => {
        const start = minBy(items, (t) =>
            Base.dateStrToDayjsIgnoreTimezone(t.startDate).valueOf()
        )?.startDate;
        const end = maxBy(items, (t) =>
            Base.dateStrToDayjsIgnoreTimezone(t.endDate).valueOf()
        )?.endDate;
        return [
            items[0]?.employeeName,
            Base.dayjsToDateStr(date),
            start && end
                ? `${Base.dateStrToOriginalTimezoneTimeStr(
                      start
                  )}-${Base.dateStrToOriginalTimezoneTimeStr(end)}`
                : "",
        ].join(" ");
    }, [items, date]);

    // Don't hide map when full screen
    if (!isMaximized && routes?.length === 0 && points?.length === 0)
        return null;

    return (
        <Box display="flex" flexDirection="column" minHeight="100%">
            <div>
                <SectionHeader>
                    <Typography
                        component="div"
                        sx={{ flexGrow: 1 }}
                        className="section-header text-truncate"
                    >
                        {headerLabel}
                    </Typography>
                    <LinkIconButton
                        size="large"
                        edge="end"
                        color="inherit"
                        to={generatePath(
                            "/worktimepage/details/:employeeId?/:date?/:component?",
                            {
                                employeeId,
                                date,
                                component: isMaximized
                                    ? null
                                    : WorkTimeDetailsComponent.Map,
                            }
                        )}
                    >
                        {isMaximized ? (
                            <FullscreenExitIcon />
                        ) : (
                            <FullscreenIcon />
                        )}
                    </LinkIconButton>
                </SectionHeader>
            </div>
            <Map routes={routes} points={points} minHeight="300px" />
        </Box>
    );
};
