/* eslint-disable no-prototype-builtins */
import {
    employeeParameterWorkOrderCalendarAccuracy,
    employeeParameterFavoriteWorkOrders,
    employeeParameterMapFilterMode,
    employeeParameterWorkOrderCalendarStartOfficeTime,
    defaultWorkOrderCalendarStartOfficeTime,
    employeeParameterWorkOrderCalendarEndOfficeTime,
    defaultWorkOrderCalendarEndOfficeTime,
    employeeParameterShowOnMapVehicles,
    employeeParameterShowOnDesignVehicleGroups,
    employeeParameterShowOnDesignEmployeeGroups
} from "../common/consts";
import { CalendarAccuracyType } from "../common/enums";
import { Base } from "../../framework/base";

export interface IEmployeeParameters {
    [index: string]: string;
}

export class EmployeeParameters implements IEmployeeParameters {
    /* eslint-disable no-undef */
    [index: string]: string;
    /* eslint-enable no-undef */

    constructor();
    constructor(obj: IEmployeeParameters);
    constructor(obj?: any) {
        if (!obj) return;
        for (const prop in obj) {
            if (!obj.hasOwnProperty(prop)) continue;
            this[prop] = obj[prop];
        }
    }
}

export class EmployeeParametersHelper {
    private static cloneParameters(parameters: IEmployeeParameters): IEmployeeParameters {
        return { ...parameters };
    }

    static setParameter(parameters: IEmployeeParameters, key: string, value: string): IEmployeeParameters {
        const result = EmployeeParametersHelper.cloneParameters(parameters);
        result[key] = value;
        return result;
    }

    static setBoolParameter(parameters: IEmployeeParameters, key: string, value: boolean): IEmployeeParameters {
        return EmployeeParametersHelper.setParameter(parameters, key, value ? "1" : "0");
    }

    static addValueToVectorParameter(parameters: IEmployeeParameters, key: string, value: string): IEmployeeParameters {
        const str = EmployeeParametersHelper.getParameter(parameters, employeeParameterFavoriteWorkOrders);
        const values: string[] = (str ? JSON.parse(str) : []).filter(i => i !== value);
        values.push(value);
        return EmployeeParametersHelper.setParameter(parameters, key, JSON.stringify(values));
    }

    static removeValueFromVectorParameter(parameters: IEmployeeParameters, key: string, value: string): IEmployeeParameters {
        const str = EmployeeParametersHelper.getParameter(parameters, employeeParameterFavoriteWorkOrders);
        const values: string[] = (str ? JSON.parse(str) : []).filter(i => i !== value);
        return EmployeeParametersHelper.setParameter(parameters, key, JSON.stringify(values));
    }

    static addToFavoriteWorkOrders(parameters: IEmployeeParameters, id: string): IEmployeeParameters {
        return EmployeeParametersHelper.addValueToVectorParameter(parameters, employeeParameterFavoriteWorkOrders, id);
    }

    static removeFromFavoriteWorkOrders(parameters: IEmployeeParameters, id: string): IEmployeeParameters {
        return EmployeeParametersHelper.removeValueFromVectorParameter(parameters, employeeParameterFavoriteWorkOrders, id);
    }

    static hasParameter(parameters: IEmployeeParameters, key: string): boolean {
        if (!parameters || !key) return false;
        //console.log("hasParameter", parameters[key]);
        return !Base.isNullOrUndefined(parameters[key]);
    }

    static getParameter(parameters: IEmployeeParameters, key: string): string {
        if (!parameters || !key) return "";
        const str = parameters[key];
        if (Base.isNullOrUndefined(str)) return "";
        return str;
    }
    
    static getNumberParameter(parameters: IEmployeeParameters, key: string, defaultValue: number): number {
        const str = EmployeeParametersHelper.getParameter(parameters, key);
        if (!str) return defaultValue;
        return str.toInteger();
    }

    static getCalendarAccuracy(parameters: IEmployeeParameters): CalendarAccuracyType {
        const str = EmployeeParametersHelper.getParameter(parameters, employeeParameterWorkOrderCalendarAccuracy);
        if (!str) return CalendarAccuracyType.DayTwoWeeks;
        const value: CalendarAccuracyType = <any>str;
        return value === CalendarAccuracyType.DayTwoWeeks || value === CalendarAccuracyType.FourHours || value === CalendarAccuracyType.QuarterHour ? value : CalendarAccuracyType.DayTwoWeeks;
    }

    static setCalendarAccuracy(parameters: IEmployeeParameters, value: CalendarAccuracyType): IEmployeeParameters {
        return EmployeeParametersHelper.setParameter(parameters, employeeParameterWorkOrderCalendarAccuracy, value);
    }

    static getCalendarStartOfficeTime(parameters: IEmployeeParameters): number {
        const value = EmployeeParametersHelper.getNumberParameter(parameters, employeeParameterWorkOrderCalendarStartOfficeTime, defaultWorkOrderCalendarStartOfficeTime);
        return value >= 0 && value <= 23 * 60 ? value : defaultWorkOrderCalendarStartOfficeTime;
    }

    static setCalendarStartOfficeTime(parameters: IEmployeeParameters, value: number): IEmployeeParameters {
        return EmployeeParametersHelper.setParameter(parameters, employeeParameterWorkOrderCalendarStartOfficeTime, value.toString(10));
    }

    static getCalendarEndOfficeTime(parameters: IEmployeeParameters): number {
        const value = EmployeeParametersHelper.getNumberParameter(parameters, employeeParameterWorkOrderCalendarEndOfficeTime, defaultWorkOrderCalendarEndOfficeTime);
        return value >= 1 && value <= 24 * 60 ? value : defaultWorkOrderCalendarEndOfficeTime;
    }

    static setCalendarEndOfficeTime(parameters: IEmployeeParameters, value: number): IEmployeeParameters {
        return EmployeeParametersHelper.setParameter(parameters, employeeParameterWorkOrderCalendarEndOfficeTime, value.toString(10));
    }
        
    static getMapFilterMode(parameters: IEmployeeParameters): string {
        return EmployeeParametersHelper.getParameter(parameters, employeeParameterMapFilterMode);
    }

    static setMapFilterMode(parameters: IEmployeeParameters, value: string): IEmployeeParameters {
        return EmployeeParametersHelper.setParameter(parameters, employeeParameterMapFilterMode, value);
    }

    static getFavoriteWorkOrderIds(parameters: IEmployeeParameters): string[] {
        const str = EmployeeParametersHelper.getParameter(parameters, employeeParameterFavoriteWorkOrders);
        if (!str) return [];
        return JSON.parse(str);
    }

    static isFavoriteWorkOrder(parameters: IEmployeeParameters, id: string): boolean {
        return EmployeeParametersHelper.getFavoriteWorkOrderIds(parameters).indexOf(id) >= 0;
    }

    static getShowOnMapVehicleIdsSpecified(parameters: IEmployeeParameters): boolean {
        return EmployeeParametersHelper.hasParameter(parameters, employeeParameterShowOnMapVehicles);
    }
    
    static getShowOnMapVehicleIds(parameters: IEmployeeParameters): string[] {
        const str = EmployeeParametersHelper.getParameter(parameters, employeeParameterShowOnMapVehicles);
        if (!str) return [];
        return JSON.parse(str);
    }
        
    static setShowOnMapVehicleIds(parameters: IEmployeeParameters, vehicleIds: string[]) {
        return EmployeeParametersHelper.setParameter(parameters, employeeParameterShowOnMapVehicles, JSON.stringify(vehicleIds));
    }

    static getShowOnDesignVehicleGroupIdsSpecified(parameters: IEmployeeParameters): boolean {
        return EmployeeParametersHelper.hasParameter(parameters, employeeParameterShowOnDesignVehicleGroups);
    }
    
    static getShowOnDesignVehicleGroupIds(parameters: IEmployeeParameters): string[] {
        const str = EmployeeParametersHelper.getParameter(parameters, employeeParameterShowOnDesignVehicleGroups);
        if (!str) return [];
        return JSON.parse(str);
    }
        
    static setShowOnDesignVehicleGroupIds(parameters: IEmployeeParameters, vehicleGroupIds: string[]) {
        return EmployeeParametersHelper.setParameter(parameters, employeeParameterShowOnDesignVehicleGroups, JSON.stringify(vehicleGroupIds));
    }

    static getShowOnDesignEmployeeGroupIdsSpecified(parameters: IEmployeeParameters): boolean {
        return EmployeeParametersHelper.hasParameter(parameters, employeeParameterShowOnDesignEmployeeGroups);
    }
    
    static getShowOnDesignEmployeeGroupIds(parameters: IEmployeeParameters): string[] {
        const str = EmployeeParametersHelper.getParameter(parameters, employeeParameterShowOnDesignEmployeeGroups);
        if (!str) return [];
        return JSON.parse(str);
    }
        
    static setShowOnDesignEmployeeGroupIds(parameters: IEmployeeParameters, employeeGroupIds: string[]) {
        return EmployeeParametersHelper.setParameter(parameters, employeeParameterShowOnDesignEmployeeGroups, JSON.stringify(employeeGroupIds));
    }
}