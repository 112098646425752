import * as React from "react";
import Button from "@mui/material/Button";
import Fab from "@mui/material/Fab";
import { Link } from "react-router-dom";
import { IconButton } from "@mui/material";

export const LinkButton = (props) => {
    return <Button LinkComponent={Link} {...props} />;
};

export const LinkFab = (props) => {
    return <Fab LinkComponent={Link} {...props} />;
};

export const LinkIconButton = (props) => {
    return <IconButton LinkComponent={Link} {...props} />;
};
