// SettingsCustomerSitesList - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import { Translations } from "../../models/translations";
import * as store from "../../framework/customStore";
import * as baseService from "../../services/baseService";
import * as customerService from "../../services/customerService";
import * as siteService from "../../services/siteService";
import { ToolButton } from "../framework/toolButton";
import { Base } from "../../framework/base";
import { ListSearchFilter } from "../framework/listSearchFilter";
import { ListHeaderCheckBoxColumn, ListHeaderColumn } from "../framework/listHeaderColumn";
import { ToolTitle } from "../framework/toolTitle";
import * as StoreActions from "../../models/store/storeActions";
import { ConfirmationDialogType } from "../../models/store/storeTypes";
import { infiniteListPageSize } from "../../models/common/consts";
import { handleApiError } from "../../models/store/storeEffects";
import { ICustomerSiteItem } from "../../models/customer/customerSiteItem";
import { SettingsSiteDialog } from "./settingsSiteDialog";
import { SelectIdTitleDialog } from "../framework/selectIdTitleDialog";
import { IIdTitle, IdTitle } from "../../models/common/idTitle";
import { IEndlessList, EndlessList } from "../../models/common/endlessList";
import { ButtonDropdown } from "../framework/dropdown";

// SettingsCustomerSitesListLineHeader
export interface ISettingsCustomerSitesListLineHeaderProp {
    sortColumn: string;
    sortOrderIsAsc: boolean;
    onColumnClick: (column: string) => void;
    onColumnCheckboxChange: (column: string, checked: boolean) => void;
}

export class SettingsCustomerSitesListLineHeader extends React.Component<ISettingsCustomerSitesListLineHeaderProp, {}> {
    render() {
        const props = this.props;
        return (
            <div className="row title">
                <ListHeaderCheckBoxColumn
                    title={Translations.Customer}
                    column="customerName"
                    classes="col-3"
                    sortColumn={props.sortColumn}
                    sortOrderIsAsc={props.sortOrderIsAsc}
                    onClick={props.onColumnClick}
                    onCheckboxChange={props.onColumnCheckboxChange}
                />
                <ListHeaderColumn
                    title={Translations.Name}
                    column="name"
                    classes="col-3"
                    sortColumn={props.sortColumn}
                    sortOrderIsAsc={props.sortOrderIsAsc}
                    onClick={props.onColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.Specifier}
                    column="name2"
                    classes="col-2"
                    sortColumn={props.sortColumn}
                    sortOrderIsAsc={props.sortOrderIsAsc}
                    onClick={props.onColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.Address}
                    column="address"
                    classes="col-3"
                    sortColumn={props.sortColumn}
                    sortOrderIsAsc={props.sortOrderIsAsc}
                    onClick={props.onColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.Number}
                    column="siteNumber"
                    classes="col-1"
                    sortColumn={props.sortColumn}
                    sortOrderIsAsc={props.sortOrderIsAsc}
                    onClick={props.onColumnClick}
                />
            </div>
        );
    }
}

// SettingsCustomerSitesListLine
export interface ISettingsCustomerSitesListLineProp {
    item: ICustomerSiteItem;
    selectedId: string;
    checked: boolean;
    onClick: (id: string) => void;
    onDoubleClick: (id: string) => void;
    onCheckboxChange: (id: string, checked: boolean) => void;
}

export class SettingsCustomerSitesListLine extends React.Component<ISettingsCustomerSitesListLineProp, {}> {
    handleNumberClick = (e) => {
        this.props.onDoubleClick(this.props.item.id);
    };

    handleCheckBoxClick = (e) => {
        e.stopPropagation();
        this.props.onCheckboxChange(this.props.item.id, !this.props.checked);
    };

    render() {
        const props = this.props;
        const item = props.item;
        return (
            <div className={"row line" + (item.id === props.selectedId ? " selected" : "")} onClick={() => { props.onClick(item.id); }} onDoubleClick={() => { props.onDoubleClick(item.id); }} >
                <div className="col-3" onClick={this.handleNumberClick}><input type="checkbox" checked={props.checked} onClick={this.handleCheckBoxClick} value="" />{item.customerName}</div>
                <div className="col-3">{item.name}</div>
                <div className="col-2">{item.name2}</div>
                <div className="col-3">{item.address()}</div>
                <div className="col-1">{item.siteNumber}</div>
            </div>
        );
    }
}

// SettingsCustomerSitesList
export interface ISettingsCustomerSitesListProp {
}

interface ISettingsCustomerSitesListState {
    // List
    pageSize: number;
    page: number;
    hasMore: boolean;
    filter: string;
    sortColumn: string;
    sortOrderIsAsc: boolean;
    items: ICustomerSiteItem[];
    selectedId: string;
    checkedIds: string[];

    // Edit
    selectedCustomerId: string;
    showSiteDialog: boolean;

    //Add
    showSelectCustomerDialog: boolean;
    selectCustomerDialogItems: IIdTitle[];
}

export class SettingsCustomerSitesList extends React.Component<ISettingsCustomerSitesListProp, ISettingsCustomerSitesListState> {
    private containerDiv: HTMLDivElement;
    private listDiv: HTMLDivElement;
    private isLoading: boolean;

    constructor(props) {
        super(props);
        this.isLoading = false;
        this.state = {
            pageSize: infiniteListPageSize,
            page: 1,
            hasMore: false,
            filter: "",
            sortColumn: "number",
            sortOrderIsAsc: true,
            items: [],
            selectedId: null,
            checkedIds: [],
            selectedCustomerId: null,
            showSiteDialog: false,
            showSelectCustomerDialog: false,
            selectCustomerDialogItems: []
        };
    }

    searchItems = (pageSize: number, page: number, filter: string, sortColumn: string, sortOrderIsAsc: boolean, resetItems: boolean, refreshList: boolean, successCallback: () => void = null) => {
        const obj = this;
        obj.isLoading = true;
        store.customStore.dispatch(StoreActions.fetchStart());
        siteService.getCustomerSiteItems(!refreshList ? pageSize : (page * infiniteListPageSize), !refreshList ? page : 1, filter, sortColumn, sortOrderIsAsc, undefined)
            .then(customerItems => {
                let items: ICustomerSiteItem[];
                if (!resetItems && !refreshList) {
                    const oldIds = {};
                    for (let j = 0; j < obj.state.items.length; j++) {
                        oldIds[obj.state.items[j].id] = true;
                    }
                    const oldItems = obj.state.items.slice(0);
                    const newItems = customerItems.items.filter(i => Object.prototype.isPrototypeOf.call(oldIds, i.id) ? false : (oldIds[i.id] = true));
                    items = [...oldItems, ...newItems];
                } else {
                    items = customerItems.items;
                }
                obj.setState({
                    page: !refreshList ? customerItems.page : page,
                    hasMore: customerItems.hasMore,
                    filter: filter,
                    sortColumn: sortColumn,
                    sortOrderIsAsc: sortOrderIsAsc,
                    items: items
                });
                if (!Base.isNullOrUndefined(successCallback)) {
                    successCallback();
                }
            },
            error => {
                handleApiError(error, store.customStore.dispatch);
            }).finally(() => {
                obj.isLoading = false;
                store.customStore.dispatch(StoreActions.fetchEnd());
            });
    };

    refreshList = () => {
        this.searchItems(this.state.pageSize, this.state.page, this.state.filter, this.state.sortColumn, this.state.sortOrderIsAsc, false, true);
    };

    handleScrollSub = Base.debounce((obj: SettingsCustomerSitesList) => {
        // console.log(obj.isLoading, obj.state.hasMore);
        if (obj.isLoading || !obj.state.hasMore) return;
        // console.log(obj.listDiv.offsetHeight, obj.containerDiv.clientHeight, obj.containerDiv.scrollTop);
        if (obj.listDiv.offsetHeight - (obj.containerDiv.clientHeight + obj.containerDiv.scrollTop) < 5) {
            obj.searchItems(obj.state.pageSize, obj.state.page + 1, obj.state.filter, obj.state.sortColumn, obj.state.sortOrderIsAsc, false, false);
        }
    }, 100);

    handleScroll = (event) => {
        this.handleScrollSub(this);
    };

    componentDidMount(): void {
        this.containerDiv.addEventListener("scroll", this.handleScroll);
        this.searchItems(this.state.pageSize, this.state.page, this.state.filter, this.state.sortColumn, this.state.sortOrderIsAsc, false, false);
    }

    componentWillUnmount(): void {
        this.containerDiv.removeEventListener("scroll", this.handleScroll);
    }

    changeFilter = (filter: string) => {
        this.searchItems(this.state.pageSize, 1, filter, this.state.sortColumn, this.state.sortOrderIsAsc, true, false);
    };

    changeSortColumn = (sortColumn: string) => {
        const oldSortColumn = this.state.sortColumn;
        const sortOrderIsAsc = oldSortColumn === sortColumn ? !this.state.sortOrderIsAsc : true;
        this.searchItems(this.state.pageSize, 1, this.state.filter, sortColumn, sortOrderIsAsc, true, false);
    };

    getEditItem = (id: string) => {
        if (!id) return;
        const state = this.state;
        const item = state.items.find(i => i.id === id);
        if (!item) return;
        this.setState({
            showSiteDialog: true,
            selectedId: item.id,
            selectedCustomerId: item.customerId
        });
    };

    handleLoadCustomers = (page: number, filter: string): Promise<IEndlessList<IIdTitle>> => {
        const obj = this;
        const lowFilter = filter.toLowerCase();
        return new Promise<IEndlessList<IdTitle>>((resolve, reject) => {
            const result = new EndlessList<IdTitle>(null, IdTitle);
            result.items = !lowFilter ? obj.state.selectCustomerDialogItems : obj.state.selectCustomerDialogItems.filter(i => i.title.toLowerCase().indexOf(lowFilter) > -1);
            result.page = 1;
            result.hasMore = false;
            console.log(result.items);
            resolve(result);
        });
    };

    handleSelectCustomerDialogOk = (customerId: string, title: string) => {
        if (!customerId) return;
        this.setState({
            showSiteDialog: true,
            selectedId: Base.emptyGuid,
            selectedCustomerId: customerId,
            showSelectCustomerDialog: false
        });
    };

    handleSelectCustomerDialogCancel = () => {
        this.setState({
            showSelectCustomerDialog: false
        });
    };

    handleAdd = () => {
        const obj = this;
        customerService.getCustomerIdTitles([]).then(idTitleItems => {
            if (idTitleItems.items.length > 1) {
                obj.setState({
                    showSelectCustomerDialog: true,
                    selectCustomerDialogItems: idTitleItems.items
                });
            } else if (idTitleItems.items.length === 1) {
                this.setState({
                    showSiteDialog: true,
                    selectedId: Base.emptyGuid,
                    selectedCustomerId: idTitleItems.items[0].id,
                });
            } else {
                store.customStore.dispatch(StoreActions.showErrorMessage(Translations.AddCustomersBeforeAddingCustomerSites));
            }
        });
    };

    handleEdit = () => {
        const selectedId = this.state.selectedId;
        if (!selectedId) return;
        this.getEditItem(selectedId);
    };

    handleSiteDialogOk = () => {
        this.setState({
            showSiteDialog: false
        });
        this.refreshList();
    };

    handleSiteDialogCancel = () => {
        this.setState({
            showSiteDialog: false
        });
    };

    handleRemove = () => {
        const obj = this;
        const state = this.state;
        const selectedIds = Base.getSelectedIds(state.selectedId, state.checkedIds);
        if (selectedIds.length < 1) return;
        const items = state.items.filter(i => selectedIds.indexOf(i.id) >= 0);
        if (items.length < 1) return;
        store.customStore.dispatch(StoreActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, items.length < 2 ? String.format(Translations.SiteRemoveConfirmation, items[0].name) : String.format(Translations.SitesRemoveConfirmation, items.length), () => {
            store.customStore.dispatch(StoreActions.clearConfirmation());
            // Call server
            store.customStore.dispatch(StoreActions.fetchStart());
            siteService.removeSites(selectedIds)
                .then(success => {
                    store.customStore.dispatch(StoreActions.showSuccessMessage(success.message));
                    obj.refreshList(); // TODO UPDATE ONLY CHANGED CUSTOMER DATA
                })
                .catch(error => {
                    store.customStore.dispatch(StoreActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
                    return null;
                })
                .finally(() => store.customStore.dispatch(StoreActions.fetchEnd()));
        }, () => {
            store.customStore.dispatch(StoreActions.clearConfirmation());
        }));
    };

    handleCreateMapLink = () => {
        const obj = this;
        const state = this.state;
        const selectedIds = Base.getSelectedIds(state.selectedId, state.checkedIds);
        if (selectedIds.length < 1) return;
        // Call server
        store.customStore.dispatch(StoreActions.fetchStart());
        siteService.updateMapLinks(selectedIds)
            .then(success => {
                store.customStore.dispatch(StoreActions.showSuccessMessage(success.message));
                obj.refreshList(); // TODO UPDATE ONLY CHANGED CUSTOMER DATA
            })
            .catch(error => {
                store.customStore.dispatch(StoreActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
                return null;
            })
            .finally(() => store.customStore.dispatch(StoreActions.fetchEnd()));
    };

    handleClick = (id: string) => {
        this.setState({
            selectedId: id
        });
    };

    handleDoubleClick = (id: string) => {
        this.getEditItem(id);
    };

    handleColumnCheckboxChange = (column: string, checked: boolean) => {
        const state = this.state;
        const result = Base.getIdsOnColumnCheckboxChange(state.items, checked);
        this.setState({
            selectedId: result.selectedId,
            checkedIds: result.checkedIds,
        });
    };

    handleLineCheckboxChange = (id: string, checked: boolean) => {
        const state = this.state;
        const result = Base.getIdsOnLineCheckboxChange(state.selectedId, state.checkedIds, id, checked);
        this.setState({
            selectedId: result.selectedId,
            checkedIds: result.checkedIds
        });
    };

    render() {
        const state = this.state;
        const items = state.items;
        return (
            <div className="backgroundContent">
                <div className="row commandRow main">
                    <ToolTitle
                        title={Translations.Sites}
                    />
                    <div className="col">
                        <ToolButton
                            title={Translations.Add}
                            enabled={true}
                            classes={"round left add"}
                            onClick={this.handleAdd}
                        />
                        <ToolButton
                            title={Translations.Edit}
                            enabled={!!state.selectedId}
                            classes={"round left edit"}
                            onClick={this.handleEdit}
                        />
                        <ToolButton
                            title={Translations.Remove}
                            enabled={!!state.selectedId || state.checkedIds.length > 0}
                            classes={"round left remove"}
                            onClick={this.handleRemove}
                        />
                        <ButtonDropdown
                            selectedTitle={""}
                            classes={"dropdown-tool"}
                            disabled={!state.selectedId && state.checkedIds.length < 1}
                            actions={[{ title: Translations.CreateMapLink, onClick: this.handleCreateMapLink }]}
                        />
                    </div>
                    <div className="col-auto right">
                        <ListSearchFilter
                            searchFilter={state.filter}
                            onSearchClick={this.changeFilter}
                        />
                    </div>
                </div>
                <div>
                    <SettingsCustomerSitesListLineHeader
                        sortColumn={state.sortColumn}
                        sortOrderIsAsc={state.sortOrderIsAsc}
                        onColumnClick={this.changeSortColumn}
                        onColumnCheckboxChange={this.handleColumnCheckboxChange}
                    />
                </div>
                <div className="listContainer main customersContainer" ref={(elem) => { this.containerDiv = elem; }}>
                    <div className="list customers striped">
                        <div className="lineContainer" ref={(elem) => { this.listDiv = elem; }}>
                            {items.map((item) =>
                                <SettingsCustomerSitesListLine
                                    key={item.id}
                                    item={item}
                                    selectedId={state.selectedId}
                                    checked={state.checkedIds.indexOf(item.id) > -1}
                                    onClick={this.handleClick}
                                    onDoubleClick={this.handleDoubleClick}
                                    onCheckboxChange={this.handleLineCheckboxChange}
                                />
                            )}
                        </div>
                    </div>
                </div>
                {state.showSiteDialog &&
                    <SettingsSiteDialog
                        readOnly={false}
                        customerId={state.selectedCustomerId}
                        siteId={state.selectedId}
                        newSiteName={""}
                        onOk={this.handleSiteDialogOk}
                        onCancel={this.handleSiteDialogCancel}
                    />
                }
                {state.showSelectCustomerDialog &&
                    <SelectIdTitleDialog
                        title={Translations.SelectCustomer}
                        selectedId={""}
                        loadIdCodes={this.handleLoadCustomers}
                        onOk={this.handleSelectCustomerDialogOk}
                        onCancel={this.handleSelectCustomerDialogCancel}
                    />
                }
            </div>
        );
    }
}
