import * as React from "react";
import * as store from "../../framework/customStore";
import * as storeActions from "../../models/store/storeActions";
import * as contactService from "../../services/contactService";
import * as workOrderService from "../../services/workOrderService";
import { Base } from "../../framework/base";
import { Translations } from "../../models/translations";
import { ButtonDropdown } from "../framework/dropdown";
import { IWorkOrderEdit, WorkOrderEdit } from "../../models/work/workOrderEdit";
import { SettingsEmployeeDialog } from "../settings/settingsEmployeeDialog";
import { IEmployeeEdit } from "../../models/employee/employeeEdit";
import { ICustomerEdit } from "../../models/customer/customerEdit";
import { SettingsCustomerDialog } from "../settings/settingsCustomerDialog";
import { ICustomerEditItemSiteItem } from "../../models/customer/customerEditItemSiteItem";
import { SettingsSiteDialog } from "../settings/settingsSiteDialog";
import { WorkOrderDialogWorkTimeList } from "./workOrderDialogWorkTimeList";
import { DrawerView } from "../framework/drawerView";
import { WorkOrderDialogInvoicedRecordsList } from "./workOrderDialogInvoicedRecordsList";
import { EnumHelper, WorkOrderState, TimeFormat } from "../../models/common/enums";
import { IWorkOrderEditItemHourBooking } from "../../models/work/workOrderEditItemHourBooking";
import { IWorkOrderEditItemRideBooking } from "../../models/work/workOrderEditItemRideBooking";
import { WorkOrderDialogDocumentList } from "./workOrderDialogDocumentList";
import { IDocument } from "../../models/document/document";
import { WorkOrderDialogState } from "./workOrderDialogState";
import { IWorkOrderEditItemWorkOrderTask } from "../../models/work/workOrderEditItemWorkOrderTask";
import { IWorkOrderEditItemProductBooking } from "../../models/work/workOrderEditItemProductBooking";
import { EmployeeParametersHelper, IEmployeeParameters } from "../../models/employee/employeeIParameters";
import { AppUtils } from "../../models/common/appUtils";
import { IVehicleEdit } from "../../models/vehicle/vehicleEdit";
import { SettingsVehicleDialog } from "../settings/settingsVehicleDialog";
import { NumberTitle } from "../../models/common/numberTitle";
import { WorkOrderEditItem } from "../../models/work/workOrderEditItem";
import { WorkOrderDialogRoutePointListConnected } from "./workOrderDialogRoutePointList";
import { IContactEdit } from "../../models/contact/contactEdit";
import { IWorkOrderEditContact } from "../../models/work/workOrderEditContact";
import { WorkOrderDialogSelectContactDialog } from "./workOrderDialogSelectContactDialog";
import { SettingsContactDialog } from "../settings/settingsContactDialog";
import { IRoutePointItem, RoutePointItem } from "../../models/routePoint/routePointItem";
import { LoadingIndicator } from "../framework/loadingIndicator";
import { EmployeeOperations } from "../../models/employee/employeeOperations";
import { CustomerOperations } from "../../models/customer/customerOperations";
import { VehicleOperations } from "../../models/vehicle/vehicleOperations";
import { ContactOperations } from "../../models/contact/contactOperations";
import { AvatarImage } from "../framework/avatarImage";
import { WorkOrderDragData } from "../../models/work/workOrderDragData";
import { WorkListLineSimple } from "./workListLineSimple";
import * as storeEffects from "../../models/store/storeEffects";
import { connect } from "react-redux";
import { TextareaEditor } from "../framework/textareaEditor";
import { SaveData } from "../../framework/saveData";
import { WorkOrderPlannedTimeEditor } from "./workOrderPlannedTimeEditor";
import { WorkOrderDialogBookingsList } from "./workOrderDialogBookingsList";

/* eslint-disable no-unused-vars */
export const enum WorkOrderDetailsViewMode {
    WorkOrder = "0",
    InvoiceData = "1",
}
/* eslint-enable no-unused-vars */

// WorkOrderDetailsContent
// ***********************************************************************************************************************
export interface IWorkOrderDetailsContentProp {
    classes?: string;
    workTimeFormat: TimeFormat;
    item: IWorkOrderEdit;
    isFavorite: boolean;
    isLoading: boolean;
    viewMode: WorkOrderDetailsViewMode;
    titleSet?: boolean;
    onOpenWorkOrder: (id: string) => void;
    onRefresh: () => void;
    onRemove: () => void;
    onPrint: () => void;
    onCopyWorkOrder: () => void;
    convertToProject: () => void;
    detachFromProject: () => void;
    getOwnerRoutePoints: () => void;
    onCancelWorkOrder: () => void;
    onRestoreWorkOrder: () => void;
    onStateChange: (state: number) => void;
    onAddToFavorites: () => void;
    onRemoveFromFavorites: () => void;
    onSetDurationByEstimatedHours: () => void;
    onAddEmployeeToWorkOrder: (id: string, employeeId: string) => void;
    onAddVehicleToWorkOrder: (id: string, vehicleId: string) => void;
    setEmployeeParameters: (parameters: IEmployeeParameters, saveToDb: boolean) => void;
}

export interface IWorkOrderDetailsContentState {
    item: IWorkOrderEdit;
    showSiteDialog: boolean;
    showCustomerDialog: boolean;
    showEmployeeDialog: boolean;
    showVehicleDialog: boolean;
    siteDialogSiteId: string;
    siteDialogCustomerId: string;
    customerEdit: ICustomerEdit;
    employeeEdit: IEmployeeEdit;
    vehicleEdit: IVehicleEdit;
    maxProjectState: number;
    uniqueProjectStates: number[];
    uniqueProjectStatesHash: string;
    projectTime: { startTime: number; endTime: number; fullDay: boolean };
    //RoutePoints
    routePoints: IRoutePointItem[];
    //SelectContactDialog
    showSelectContactDialog: boolean;
    selectContactDialogName: string;
    selectContactDialogItems: IWorkOrderEditContact[];
    selectContactDialogCustomerId: string;
    selectContactDialogOkCallback: (contactId: string) => void;
    //ContactDialog
    showContactDialog: boolean;
    contactDialogItem: IContactEdit;
    contactDialogCustomerId: string;
    contactDialogOkCallback: (contactId: string) => void;
}

export class WorkOrderDetailsContent extends React.Component<IWorkOrderDetailsContentProp, IWorkOrderDetailsContentState> {
    private employeesDropTarget: HTMLDivElement;
    private vehiclesDropTarget: HTMLDivElement;

    componentDidMount() {
        if (this.props.getOwnerRoutePoints) {
            this.props.getOwnerRoutePoints();
        }
    }

    private static getUniqueProjectStates = (projectTasks: IWorkOrderEditItemWorkOrderTask[]): number[] => {
        const projectStates = projectTasks.map(i => i.state);
        if (projectStates.length < 1) {
            projectStates.push(WorkOrderState.Preliminary);
        }
        const result = Base.getUniqueNumberItems(projectStates);
        result.sort();
        return result;
    };

    private static getUniqueProjectStatesHash = (uniqueProjectStates: number[]): string => {
        return uniqueProjectStates.join(",");
    };

    private static getStateFromProps(props: IWorkOrderDetailsContentProp): IWorkOrderDetailsContentState {
        const workOrderEdit = props.item;
        const uniqueProjectStates = workOrderEdit && workOrderEdit.workOrder.isProject() ? WorkOrderDetailsContent.getUniqueProjectStates(workOrderEdit.workOrder.projectTasks) : [];
        //console.log("projectStartTime", projectStartTime, projectEndTime);
        return {
            item: workOrderEdit,
            showSiteDialog: false,
            showCustomerDialog: false,
            showEmployeeDialog: false,
            showVehicleDialog: false,
            siteDialogSiteId: "",
            siteDialogCustomerId: "",
            customerEdit: null,
            employeeEdit: null,
            vehicleEdit: null,
            maxProjectState: Math.max(...uniqueProjectStates),
            uniqueProjectStates: uniqueProjectStates,
            uniqueProjectStatesHash: this.getUniqueProjectStatesHash(uniqueProjectStates),
            projectTime: WorkOrderEditItem.getProjectTime(workOrderEdit && workOrderEdit.workOrder.isProject()
                ? workOrderEdit.workOrder.projectTasks
                : []),
            //RoutePoints
            routePoints: workOrderEdit
                ? workOrderEdit.workOrder.routePoints.slice(0)
                : [],
            //SelectContactDialog
            showSelectContactDialog: false,
            selectContactDialogName: "",
            selectContactDialogItems: [],
            selectContactDialogCustomerId: "",
            selectContactDialogOkCallback: null,
            //ContactDialog
            showContactDialog: false,
            contactDialogItem: null,
            contactDialogCustomerId: "",
            contactDialogOkCallback: null
        };
    }

    constructor(props) {
        super(props);
        this.state = WorkOrderDetailsContent.getStateFromProps(props);
    }

    componentDidUpdate(prevProps: IWorkOrderDetailsContentProp, prevState: IWorkOrderDetailsContentState): void {
        const props = this.props;
        const uniqueProjectStates = props.item && props.item.workOrder.isProject() ? WorkOrderDetailsContent.getUniqueProjectStates(props.item.workOrder.projectTasks) : [];
        const uniqueProjectStatesHash = WorkOrderDetailsContent.getUniqueProjectStatesHash(uniqueProjectStates);
        if (!prevProps.item && !props.item || prevProps.item && props.item && prevProps.item.workOrder.id === props.item.workOrder.id &&
            prevProps.item.workOrder.rowId === props.item.workOrder.rowId && prevState.uniqueProjectStatesHash === uniqueProjectStatesHash &&
            RoutePointItem.getHash(prevProps.item?.workOrder.routePoints) === RoutePointItem.getHash(props.item?.workOrder.routePoints)) return;
        this.setState(WorkOrderDetailsContent.getStateFromProps(props));
    }

    // #region Calculated properties
    isLessThanPlanned = (): boolean => {
        const workOrder = this.props.item.workOrder;
        return workOrder.isProject()
            ? EnumHelper.isLessThan(this.state.maxProjectState, WorkOrderState.Planned)
            : EnumHelper.isLessThan(workOrder.state, WorkOrderState.Planned);
    };

    isLessThanInProgress = (): boolean => {
        const workOrder = this.props.item.workOrder;
        return workOrder.isProject()
            ? EnumHelper.isLessThan(this.state.maxProjectState, WorkOrderState.InProgress)
            : EnumHelper.isLessThan(workOrder.state, WorkOrderState.InProgress);
    };

    isGreaterThanDone = (): boolean => {
        const workOrder = this.props.item.workOrder;
        return workOrder.isProject()
            ? EnumHelper.isGreaterThan(this.state.maxProjectState, WorkOrderState.Done)
            : EnumHelper.isGreaterThan(workOrder.state, WorkOrderState.Done);
    };

    isGreaterThanPlanned = (): boolean => {
        const workOrder = this.props.item.workOrder;
        return workOrder.isProject()
            ? EnumHelper.isGreaterThan(this.state.maxProjectState, WorkOrderState.Planned)
            : EnumHelper.isGreaterThan(workOrder.state, WorkOrderState.Planned);
    };
    // #region Calculated properties

    // #region field edit
    private getSaveDataFromState = (state: IWorkOrderDetailsContentState, comment: string, startTime: number, endTime: number): SaveData => {
        const data = new SaveData();
        const workOrder = state.item.workOrder;
        // Common - Data that does not change
        data.append("id", workOrder.id);
        data.append("rowId", workOrder.rowId);
        // General - Data that changes
        data.append("comment", comment);
        data.append("startTime", startTime ? startTime.toString(10) : "");
        data.append("endTime", endTime ? endTime.toString(10) : "");
        data.append("fullDay", workOrder.fullDay ? "1" : "0");
        return data;
    };

    saveWorkOrderDetails = async(comment: string, startTime: number, endTime: number): Promise<boolean> => {
        const saveData = this.getSaveDataFromState(this.state, comment, startTime, endTime);
        const result = await AppUtils.callService(() => workOrderService.saveWorkOrderDetails(saveData.formData));
        if (!result) {
            return false;
        }
        const item = new WorkOrderEdit(this.state.item);
        item.workOrder.comment = comment;
        item.workOrder.startTime = startTime;
        item.workOrder.endTime = endTime;
        item.workOrder.rowId = result.rowId;
        this.setState({ item: item });
        this.props.onRefresh();
        return true;
    };

    handleSaveComment = async(comment: string): Promise<boolean> => {
        const workOrder = this.props.item.workOrder;
        return await this.saveWorkOrderDetails(comment, workOrder.startTime, workOrder.endTime);
    };

    handleSavePlannedTime = async(startTime: number, endTime: number): Promise<boolean> => {
        const workOrder = this.props.item.workOrder;
        return await this.saveWorkOrderDetails(workOrder.comment, startTime, endTime);
    };
    // #region field edit

    //siteEdit
    handleSiteDialogOk = (item: ICustomerEditItemSiteItem) => {
        this.setState({
            showSiteDialog: false,
            siteDialogSiteId: ""
        });
        this.props.onRefresh();
    };

    handleSiteDialogCancel = () => {
        this.setState({
            showSiteDialog: false,
            siteDialogSiteId: ""
        });
    };

    handleEditSite = (customerId: string, siteId: string) => {
        if (!customerId || !siteId || siteId === Base.emptyGuid) return;
        this.setState({
            showSiteDialog: true,
            siteDialogSiteId: siteId,
            siteDialogCustomerId: customerId,
        });
    };
    //end siteEdit

    //employee edit
    getEmployeeEditItem = (id: string) => {
        const obj = this;
        EmployeeOperations.getEmployeeEdit(id)
            .then(editItem => {
                obj.setState({
                    employeeEdit: editItem,
                    showEmployeeDialog: true,
                });
            });
    };

    handleEditEmployee = (id: string) => {
        this.getEmployeeEditItem(id);
    };

    handleEmployeeEditDialogOk = () => {
        this.setState({
            showEmployeeDialog: false
        });
        this.props.onRefresh();
    };

    handleEmployeeEditDialogCancel = (hasBeenChanged: boolean) => {
        this.setState({
            showEmployeeDialog: false
        });
        return !hasBeenChanged;
    };
    //end employee edit

    //vehicle edit
    getVehicleEditItem = (id: string) => {
        const obj = this;
        VehicleOperations.getVehicleEdit(id)
            .then(editItem => {
                obj.setState({
                    vehicleEdit: editItem,
                    showVehicleDialog: true,
                });
            });
    };

    handleEditVehicle = (id: string) => {
        this.getVehicleEditItem(id);
    };

    handleVehicleEditDialogOk = () => {
        this.setState({
            showVehicleDialog: false
        });
        this.props.onRefresh();
    };

    handleVehicleEditDialogCancel = () => {
        this.setState({
            showVehicleDialog: false
        });
    };
    //end vehicle edit

    //employees and vehicles drag and drop
    canDropInto = (dragData: WorkOrderDragData, employees: boolean): boolean => {
        const props = this.props;
        const state = this.state;
        if (!state.item || !state.item.workOrder) return false;
        const workOrder = state.item.workOrder;
        return !state.item.workOrder.isProject() && EnumHelper.isLessThan(workOrder.state, WorkOrderState.InProgress) && (
            employees && dragData.isEmployeeType() && workOrder.employeeIds.indexOf(dragData.id) < 0 && !!props.onAddEmployeeToWorkOrder ||
            !employees && dragData.isVehicleType() && workOrder.vehicleIds.indexOf(dragData.id) < 0 && !!props.onAddVehicleToWorkOrder);
    };

    onDragOver = (ev, employees: boolean) => {
        const dragData = WorkOrderDragData.getWorkOrderDragData(ev);
        //console.log("onDragOver", employees, dragData);
        if (!this.canDropInto(dragData, employees)) return;
        ev.preventDefault();
        if (employees) {
            if (this.employeesDropTarget.classList.contains("focused")) return;
            this.employeesDropTarget.classList.add("focused");
        } else {
            if (this.vehiclesDropTarget.classList.contains("focused")) return;
            this.vehiclesDropTarget.classList.add("focused");
        }
    };

    onDragLeave = (ev, employees: boolean) => {
        const dragData = WorkOrderDragData.getWorkOrderDragData(ev);
        if (!this.canDropInto(dragData, employees)) return;
        ev.preventDefault();
        if (employees) {
            this.employeesDropTarget.classList.remove("focused");
        } else {
            this.vehiclesDropTarget.classList.remove("focused");
        }
        // console.log("employeeColOnDragLeave", id);
    };

    onDrop = (ev, employees: boolean) => {
        const props = this.props;
        const dragData = WorkOrderDragData.getWorkOrderDragData(ev);
        if (!this.canDropInto(dragData, employees)) return;
        ev.preventDefault();
        const workOrder = this.state.item.workOrder;
        if (employees) {
            this.employeesDropTarget.classList.remove("focused");
            props.onAddEmployeeToWorkOrder(workOrder.id, dragData.id);
        } else {
            this.vehiclesDropTarget.classList.remove("focused");
            props.onAddVehicleToWorkOrder(workOrder.id, dragData.id);
        }
        // console.log("employeeColOnDrop", id);
    };
    //end employees and vehicles drag and drop

    //edit customer
    getCustomerEditItem = (id: string) => {
        const obj = this;
        CustomerOperations.getCustomerEdit(id)
            .then(editItem => {
                obj.setState({
                    showCustomerDialog: true,
                    customerEdit: editItem
                });
            });
    };

    handleEditCustomer = (id: string) => {
        this.getCustomerEditItem(id);
    };

    handleSettingsCustomerDialogDialogOk = (customerId: string, name: string) => {
        this.setState({
            showCustomerDialog: false,
            customerEdit: null
        });
        this.props.onRefresh();
    };

    handleSettingsCustomerDialogDialogCancel = () => {
        this.setState({
            showCustomerDialog: false
        });
    };
    //end edit customer

    // #region Contact
    getContact = (customerId: string, contactId: string, name: string, callback: (contactId: string) => void) => {
        const obj = this;
        ContactOperations.getContactEdit(contactId)
            .then(editItem => {
                if (name) {
                    editItem.contact.name = name;
                }
                obj.setState({
                    showContactDialog: true,
                    contactDialogItem: editItem,
                    contactDialogCustomerId: customerId,
                    contactDialogOkCallback: callback
                });
            });
    };

    handleAddContact = (customerId: string, name: string, callback: (contactId: string) => void) => {
        this.getContact(customerId, Base.emptyGuid, name, (contactId: string) => {
            CustomerOperations.addCustomerContact(customerId, contactId)
                .then(success => {
                    store.customStore.dispatch(storeActions.showSuccessMessage(success.message));
                    callback(contactId);
                });
        });
    };

    handleAddContactFromRoutePointCopy = (contactId: string): void => {
        const workOrder = this.props.item.workOrder;
        CustomerOperations.addCustomerContact(workOrder.customerId, contactId);
    };

    handleAttachContact = (customerId: string, name: string, callback: (contactId: string) => void) => {
        const obj = this;
        contactService.getWorkOrderEditContacts(obj.state.item.contacts.map(i => i.id)).then(workOrderEditContacts => {
            obj.setState({
                showSelectContactDialog: true,
                selectContactDialogName: name,
                selectContactDialogCustomerId: customerId,
                selectContactDialogOkCallback: callback,
                selectContactDialogItems: workOrderEditContacts.items
            });
        });
    };

    handleSelectContactDialogOk = (contactId: string, name: string, phone: string, email: string, comment: string) => {
        const obj = this;
        const state = this.state;
        if (!contactId) return;
        if (state.selectContactDialogCustomerId) {
            //Connect contact to customer
            CustomerOperations.addCustomerContact(state.selectContactDialogCustomerId, contactId)
                .then(success => {
                    store.customStore.dispatch(storeActions.showSuccessMessage(success.message));
                    obj.props.onRefresh();
                });
        } else {
            //Add contact before customer is selected
            obj.props.onRefresh();
        }
    };

    handleSelectContactDialogCancel = () => {
        this.setState({
            showSelectContactDialog: false
        });
    };

    handleEditContact = (customerId: string, contactId: string) => {
        if (!contactId || contactId === Base.emptyGuid) return;
        this.getContact(customerId, contactId, null, null);
    };

    closeContactDialog = () => {
        this.setState({
            showContactDialog: false,
            contactDialogItem: null
        });
    };

    handleContactDialogOk = (id: string, name: string, phone: string, email: string, comment: string) => {
        if (!id) return;
        this.closeContactDialog();
        this.props.onRefresh();
    };

    handleContactDialogCancel = () => {
        this.closeContactDialog();
    };
    // #endregion Contact

    // #region Documents
    handleDocumentsModified = (documents: IDocument[]) => {
        this.props.onRefresh();
    };

    handleDocumentRemoved = (documentId: string) => {
        this.props.onRefresh();
    };
    // #endregion Documents

    // #region RoutePoints
    handleRoutePointsModified= (modifiedRoutePoints: IRoutePointItem[], removedRoutePointIds: string[]) => {
        const props = this.props;
        if (modifiedRoutePoints.length < 1 && removedRoutePointIds.length < 1) return;
        if (removedRoutePointIds.length < 1) { //Add
            const routePoints = this.state.routePoints.concat(modifiedRoutePoints);
            RoutePointItem.sortRoutePointItems(routePoints);
            this.setState({ routePoints: routePoints });
        } else {
            props.onRefresh();
            if (props.getOwnerRoutePoints) {
                props.getOwnerRoutePoints();
            }
        }
    };
    // #endregion RoutePoints

    // #region HourBookings
    handleHourBookingModified = (hourBooking: IWorkOrderEditItemHourBooking) => {
        this.props.onRefresh();
    };

    handleHourBookingRemoved = (hourBookingId: string) => {
        this.props.onRefresh();
    };
    // #endregion HourBookings

    // #region ProductBookings
    handleProductBookingModified = (productBooking: IWorkOrderEditItemProductBooking) => {
        this.props.onRefresh();
    };

    handleProductBookingRemoved = (productBookingId: string) => {
        this.props.onRefresh();
    };
    // #endregion ProductBookings

    // #region RideBookings
    handleRideBookingModified = (rideBooking: IWorkOrderEditItemRideBooking) => {
        this.props.onRefresh();
    };

    handleRideBookingRemoved = (rideBookingId: string) => {
        this.props.onRefresh();
    };
    // #endregion RideBookings

    render() {
        const state = this.state;
        const props = this.props;
        const item = this.props.item;
        if (!item) {
            return (
                <div className={"detailsContent" + (props.classes ? " " + props.classes : "")}>
                    {props.isLoading &&
                        <LoadingIndicator />
                    }
                    <div className="content">
                        <div className="item">
                            {!props.titleSet &&
                                <span className="text">{Translations.NoSelectedWorkOrders}</span>
                            }
                        </div>
                    </div>
                </div>
            );
        }
        const workOrder = item.workOrder;
        const customer = item.customers.find(i => i.id === workOrder.customerId);
        const customerName = customer ? customer.title : "";
        const workOrderType = item.workOrderTypes.find(i => i.id === workOrder.workOrderTypeId);
        const site = item.sites.find(i => i.id === workOrder.siteId);
        const siteName = site ? site.name + (site.name2 ? ", " + site.name2 : "") : "";
        const siteAddress = site ? Base.getStringWithSeparators([site.street, Base.getStringWithSeparators([site.postalCode, site.city], " ")], ", ") : "";
        const workOrderEmployees = item.employees.filter(i => workOrder.employeeIds.indexOf(i.id) >= 0);
        const workOrderVehicles = item.vehicles.filter(i => workOrder.vehicleIds.indexOf(i.id) >= 0);
        const recursInfo = AppUtils.getRecurrenceInfo(workOrder.recurrenceType, workOrder.recurrenceInterval, workOrder.recurrenceIntervalDetail);

        if (item.workOrder.projectTasks.length && item.workOrder.hasSequentialTasks) {
            item.workOrder.projectTasks.sort((a, b) => a.sortOrder - b.sortOrder);
        } else {
            item.workOrder.projectTasks.sort((a, b) => a.number - b.number);
        }

        return (
            <div className={"detailsContent" + (props.classes ? " " + props.classes : "")}>
                {props.isLoading &&
                    <LoadingIndicator />
                }
                <div className="content">
                    <div className="right dropdownContainer">
                        <ButtonDropdown
                            menuRight={true}
                            fixedPosition={true}
                            selectedTitle={""}
                            classes={"property"}
                            actions={[
                                { title: Translations.Print, onClick: props.onPrint },
                                { title: Translations.Copy, onClick: props.onCopyWorkOrder },
                                { title: Translations.ConvertToProject, onClick: props.convertToProject, hidden: !workOrder.isWork() },
                                { title: (workOrder.isRecurringTask() ? Translations.DetachFromRecurringWork : Translations.DetachFromProject), onClick: props.detachFromProject, hidden: !workOrder.isTask() },
                                { title: Translations.CancelWorkOrder, onClick: props.onCancelWorkOrder, disabled: workOrder.canceled, hidden: workOrder.isProject() },
                                { title: Translations.RestoreWorkOrder, onClick: props.onRestoreWorkOrder, disabled: !workOrder.canceled, hidden: workOrder.isProject() },
                                { title: Translations.SetDurationByEstimatedHours, onClick: props.onSetDurationByEstimatedHours, hidden: workOrder.isProject() || (workOrderType && !workOrderType.showEstimatedHours) },
                                { title: (Translations.Remove), onClick: () => { props.onRemove(); } },
                                { title: (props.isFavorite ? Translations.RemoveFromFavories : Translations.AddToFavories), onClick: () => { props.isFavorite ? props.onRemoveFromFavorites() : props.onAddToFavorites(); } },
                            ]}
                        />
                    </div>
                    {workOrderType &&
                        <label className="control-label smallFont">{Translations.AType}</label>
                    }
                    {workOrderType &&
                        <div className="detailsListLine">{workOrderType.title}</div>
                    }
                    <label className="control-label smallFont">{Translations.State + ": " + NumberTitle.getTitleByNumber(item.workOrderStates, item.workOrder.state)}</label>
                    <WorkOrderDialogState
                        classes={"small"}
                        isReadOnly={true}
                        isProject={workOrder.isProject()}
                        state={workOrder.state}
                        projectStates={state.uniqueProjectStates}
                        workOrderStates={item.workOrderStates}
                        onStateChange={props.onStateChange}
                    />
                    {workOrder.isTask() &&
                        <div>
                            <label className="control-label smallFont">{Translations.AttachedToProject}</label>
                            {item.workOrder.parentId &&
                                <WorkListLineSimple
                                    id={workOrder.parentId}
                                    number={workOrder.parentNumber}
                                    parentNumber={null}
                                    name={workOrder.parentName}
                                    state={workOrder.parentState}
                                    tooltip={null}
                                    isSelected={false}
                                    onClick={null}
                                    onDoubleClick={props.onOpenWorkOrder}
                                />
                            }
                        </div>
                    }
                    {customer &&
                        <label className="control-label smallFont">{Translations.Customer}</label>
                    }
                    {customer &&
                        <div className="detailsListLine">
                            <a className="readOnlyLink" onClick={() => { this.handleEditCustomer(workOrder.customerId); }}>{customerName}</a>
                        </div>
                    }
                    {site &&
                        <label className="control-label smallFont">{Translations.Site}</label>
                    }
                    {site &&
                        <div className="detailsListLine">
                            <a className="readOnlyLink" onClick={() => { this.handleEditSite(workOrder.customerId, workOrder.siteId); }}>{siteName}</a>
                            {site.siteHasLocation() && siteAddress ? ", " + siteAddress : ""}
                        </div>
                    }
                    <label className="control-label smallFont">{Translations.WorkTitle}</label>
                    <div className="detailsListLine">
                        <a className="readOnlyLink" onClick={() => props.onOpenWorkOrder(workOrder.id)}>{workOrder.name}</a>
                    </div>
                    <label className="control-label smallFont">{Translations.Time}</label>
                    {(!workOrder.isProject() && this.isLessThanInProgress()) &&
                        <div className="detailsListLine">
                            <WorkOrderPlannedTimeEditor
                                workOrderState={workOrder.state}
                                employeeIds={workOrder.employeeIds}
                                hourBookings={workOrder.hourBookings}
                                startTime={workOrder.startTime}
                                endTime={workOrder.endTime}
                                fullDay={workOrder.fullDay}
                                onSave={this.handleSavePlannedTime}
                            />
                        </div>
                    }
                    {(!workOrder.isProject() && !this.isLessThanInProgress()) &&
                        <div className="detailsListLine">{AppUtils.getTimeDurationStr(item.workOrder.startTime, item.workOrder.endTime, item.workOrder.fullDay)}</div>
                    }
                    {workOrder.isProject() &&
                        <div className="detailsListLine">
                            {workOrder.isRecurring() &&
                                <span>
                                    {AppUtils.getTimeDurationStrByDurationMin(state.projectTime.startTime, state.projectTime.endTime, state.projectTime.fullDay, 0)}<br />
                                    {Translations.Occurs} {recursInfo}
                                </span>
                            }
                            {!workOrder.isRecurring() &&
                                <span>{AppUtils.getTimeDurationStr(state.projectTime.startTime, state.projectTime.endTime, state.projectTime.fullDay)}</span>
                            }
                        </div>
                    }
                    {!workOrder.isProject() &&
                        <label className="control-label smallFont ">{workOrderEmployees.length > 1 ? Translations.Employees : Translations.Employee}</label>
                    }
                    {!workOrder.isProject() &&
                        <div ref={(elem) => { this.employeesDropTarget = elem; }} className={"dropTarget " + (props.onAddEmployeeToWorkOrder ? "droppable" : "")} onDrop={(e) => this.onDrop(e, true)} onDragOver={(e) => this.onDragOver(e, true)} onDragLeave={(e) => this.onDragLeave(e, true)}>
                            {workOrderEmployees.map((employee) =>
                                <AvatarImage
                                    key={employee.id}
                                    classes="inline"
                                    image={employee.picture}
                                    fullText={!!employee.abbreviation}
                                    name={employee.getAvatarText()}
                                    tooltip={employee.name}
                                    onClick={() => { this.handleEditEmployee(employee.id); }}
                                />
                            )}
                        </div>
                    }
                    {!workOrder.isProject() &&
                        <label className="control-label smallFont ">{workOrderVehicles.length > 1 ? Translations.Vehicles : Translations.Vehicle}</label>
                    }
                    {!workOrder.isProject() &&
                        <div ref={(elem) => { this.vehiclesDropTarget = elem; }} className={"dropTarget " + (props.onAddVehicleToWorkOrder ? "droppable" : "")} onDrop={(e) => this.onDrop(e, false)} onDragOver={(e) => this.onDragOver(e, false)} onDragLeave={(e) => this.onDragLeave(e, false)}>
                            {workOrderVehicles.map((vehicle) =>
                                <AvatarImage
                                    key={vehicle.id}
                                    classes="inline oval"
                                    image={vehicle.picture}
                                    fullText={true}
                                    name={vehicle.getAvatarText()}
                                    tooltip={vehicle.getTitle()}
                                    onClick={() => { this.handleEditVehicle(vehicle.id); }}
                                />
                            )}
                        </div>
                    }
                    {/*WorkOrder Mode Lists*/}
                    {(props.viewMode === WorkOrderDetailsViewMode.WorkOrder && workOrderType && workOrderType.routePointsUsed()) &&
                        <WorkOrderDialogRoutePointListConnected
                            classes="narrowView"
                            plannedRoute={!this.isGreaterThanPlanned()}
                            title={Translations.Route}
                            titleClass="smallFont"
                            detailsView={true}
                            isReadOnly={workOrder.isTask() || this.isGreaterThanDone()}
                            mapLinkTemplate={item.mapLinkTemplate}
                            directionsMapLinkTemplate={item.directionsMapLinkTemplate}
                            customerId={workOrder.customerId}
                            vehicles={workOrderVehicles}
                            employees={workOrderEmployees}
                            workOrderId={workOrder.id}
                            workOrderIsProject={workOrder.isProject()}
                            routePointWorkShiftTimeSlotTypes={item.routePointWorkShiftTimeSlotTypes.filter(i => workOrderType?.workShiftTimeSlotTypeIds.indexOf(i.id) > -1)}
                            routePointTypes={item.routePointTypes}
                            contacts={item.contacts}
                            routePoints={state.routePoints}
                            productBookings={[]}
                            onAddContact={this.handleAddContact}
                            onAddContactFromRoutePointCopy={this.handleAddContactFromRoutePointCopy}
                            onAttachContact={this.handleAttachContact}
                            onEditContact={this.handleEditContact}
                            onRoutePointsModified={this.handleRoutePointsModified}
                        />
                    }
                    {props.viewMode === WorkOrderDetailsViewMode.WorkOrder && !workOrder.isProject() &&
                        <div>
                            {!!workOrder.description &&
                                <label className="control-label smallFont ">{Translations.WorkDescription}</label>
                            }
                            {!!workOrder.description &&
                                <div className="detailsListLine textArea">{workOrder.description}</div>
                            }
                            {workOrderType?.showComment &&
                                <div className="detailsListLine">
                                    <label className="control-label smallFont">{Translations.Comment}</label>
                                    <TextareaEditor
                                        value={workOrder.comment}
                                        maxLength={1000}
                                        tooltip={Translations.Comment}
                                        onSave={this.handleSaveComment}
                                    />
                                </div>
                            }
                        </div>
                    }
                    {props.viewMode === WorkOrderDetailsViewMode.WorkOrder && workOrder.isProject() &&
                        <div>
                            <label className="control-label smallFont">{Translations.WorkOrderTasks}</label>
                            {item.workOrder.projectTasks.map((task) => {
                                const taskEmployees = task.getTaskEmployees(item.employees);
                                const taskVehicles = task.getTaskVehicles(item.vehicles);
                                return (
                                    <WorkListLineSimple
                                        key={task.id}
                                        id={task.id}
                                        number={task.number}
                                        parentNumber={null}
                                        name={task.name}
                                        state={task.state}
                                        tooltip={Base.getStringWithSeparators([Translations.WorkOrderTask + " " + task.number.toString(10), customerName, task.name, siteName, siteAddress, taskEmployees.map(i => i.getTitle()).join(", "), taskVehicles.map(i => i.getTitle()).join(", "), Base.timeToDateStr(task.startTime)], "\n")}
                                        startTime={task.startTime}
                                        endTime={task.endTime}
                                        employees={taskEmployees}
                                        vehicles={taskVehicles}
                                        isSelected={false}
                                        onClick={null}
                                        onDoubleClick={props.onOpenWorkOrder}
                                        onEditEmployee={this.handleEditEmployee}
                                        onEditVehicle={this.handleEditVehicle}
                                    />
                                );
                            })}
                        </div>
                    }
                    {props.viewMode === WorkOrderDetailsViewMode.WorkOrder && workOrder.documents.length > 0 &&
                        <div>
                            <WorkOrderDialogDocumentList
                                title={Translations.Attachments}
                                titleClass={"smallFont"}
                                documents={workOrder.documents}
                                isReadOnly={this.isGreaterThanDone()}
                                smallImage={true}
                                workOrderId={workOrder.id}
                                onDocumentsModified={this.handleDocumentsModified}
                                onDocumentRemoved={this.handleDocumentRemoved}
                            />
                        </div>
                    }
                    {/*InvoiceData Mode Lists*/}
                    {props.viewMode === WorkOrderDetailsViewMode.InvoiceData && !workOrder.isProject() &&
                        <div>
                            <label className="control-label smallFont ">{Translations.WorkDescription}</label>
                            <div className="detailsListLine textArea">{workOrder.description ?? ""}</div>
                        </div>
                    }
                    {props.viewMode === WorkOrderDetailsViewMode.InvoiceData && !workOrder.isProject() &&
                        <div>
                            <WorkOrderDialogInvoicedRecordsList
                                isReadOnly={workOrder.isProject() || this.isLessThanPlanned() || this.isGreaterThanDone()}
                                title={Translations.ToBeInvoicedRecords}
                                titleClass="smallFont"
                                employeeIds={workOrder.employeeIds}
                                workOrderId={workOrder.id}
                                productBookings={workOrder.productBookings}
                                products={item.products}
                                measureUnits={item.measureUnits}
                                employees={workOrderEmployees}
                                onProductBookingModified={this.handleProductBookingModified}
                                onProductBookingRemoved={this.handleProductBookingRemoved}
                            />
                            <WorkOrderDialogBookingsList
                                isReadOnly={workOrder.isProject() || this.isLessThanPlanned() || this.isGreaterThanDone()}
                                workTimeFormat={props.workTimeFormat}
                                title={Translations.Bookings}
                                titleClass="smallFont"
                                employeeIds={workOrder.employeeIds}
                                workOrderId={workOrder.id}
                                workOrderStartTime={workOrder.startTime}
                                hourBookings={workOrder.hourBookings}
                                showHourBookingInvoiceHours={true}
                                site={site}
                                rideBookings={workOrder.rideBookings}
                                employees={workOrderEmployees}
                                vehicles={item.vehicles}
                                onHourBookingModified={this.handleHourBookingModified}
                                onHourBookingRemoved={this.handleHourBookingRemoved}
                                onRideBookingModified={this.handleRideBookingModified}
                                onRideBookingRemoved={this.handleRideBookingRemoved}
                            />
                            <label className="control-label smallFont ">{Translations.WorkingTimeRecords}</label>
                            <WorkOrderDialogWorkTimeList
                                workTimeFormat={props.workTimeFormat}
                                workTimes={workOrder.workTimes}
                            />
                        </div>
                    }
                </div>
                {state.showSiteDialog && state.siteDialogSiteId &&
                    <SettingsSiteDialog
                        readOnly={false}
                        customerId={state.siteDialogCustomerId}
                        siteId={state.siteDialogSiteId}
                        newSiteName={""}
                        onOk={this.handleSiteDialogOk}
                        onCancel={this.handleSiteDialogCancel}
                    />
                }
                {state.showCustomerDialog &&
                    <SettingsCustomerDialog
                        customerEdit={state.customerEdit}
                        onOk={this.handleSettingsCustomerDialogDialogOk}
                        onCancel={this.handleSettingsCustomerDialogDialogCancel}
                    />
                }
                {state.showEmployeeDialog &&
                    <SettingsEmployeeDialog
                        employeeEdit={state.employeeEdit}
                        onOk={this.handleEmployeeEditDialogOk}
                        onCancel={this.handleEmployeeEditDialogCancel}
                        setEmployeeParameters={props.setEmployeeParameters}
                    />
                }
                {state.showVehicleDialog &&
                    <SettingsVehicleDialog
                        editItem={state.vehicleEdit}
                        onOk={this.handleVehicleEditDialogOk}
                        onCancel={this.handleVehicleEditDialogCancel}
                    />
                }
                {state.showSelectContactDialog &&
                    <WorkOrderDialogSelectContactDialog
                        name={state.selectContactDialogName}
                        selectedId={""}
                        items={state.selectContactDialogItems}
                        onOk={this.handleSelectContactDialogOk}
                        onCancel={this.handleSelectContactDialogCancel}
                    />
                }
                {state.showContactDialog &&
                    <SettingsContactDialog
                        customersReadOnly={true}
                        editItem={state.contactDialogItem}
                        onOk={this.handleContactDialogOk}
                        onCancel={this.handleContactDialogCancel}
                    />
                }
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getOwnerRoutePoints: () => dispatch(storeEffects.getOwnerRoutePoints()),
    };
}

export const WorkOrderDetailsContentConnected = connect(null, mapDispatchToProps)(WorkOrderDetailsContent);

// WorkOrderDetailsView
// ***********************************************************************************************************************
export interface IWorkOrderDetailsViewProp {
    classes?: string;
    workTimeFormat: TimeFormat;
    employeeParameters: IEmployeeParameters;
    title: string;
    item: IWorkOrderEdit;
    viewMode: WorkOrderDetailsViewMode;
    expanded: boolean;
    isLoading: boolean;
    onOpenWorkOrder: (id: string) => void;
    onRefresh: () => void;
    onRemove: () => void;
    onPrint: () => void;
    onCopyWorkOrder?: () => void;
    convertToProject: () => void;
    detachFromProject: () => void;
    onCancelWorkOrder: () => void;
    onRestoreWorkOrder: () => void;
    onChangeExpansionMode: (expanded: boolean) => void;
    onStateChange: (state: number) => void;
    onAddToFavorites: () => void;
    onRemoveFromFavorites: () => void;
    onSetDurationByEstimatedHours: () => void;
    onAddEmployeeToWorkOrder: (id: string, employeeId: string) => void;
    onAddVehicleToWorkOrder: (id: string, vehicleId: string) => void;
    setEmployeeParameters: (parameters: IEmployeeParameters, saveToDb: boolean) => void;
}

export class WorkOrderDetailsView extends React.Component<IWorkOrderDetailsViewProp, {}> {
    render() {
        const props = this.props;
        const workOrderId = props.item ? props.item.workOrder.id : "";
        return (
            <DrawerView
                classes={"backgroundContent detailsView" + (props.classes ? " " + props.classes : "")}
                title={props.item ? props.item.workOrder.getUiTitle() : Translations.NoSelectedWorkOrders}
                headerClasses={"workOrderState" + (props.item ? " state" + props.item.workOrder.state.toString(10) : "")}
                content={
                    <WorkOrderDetailsContentConnected
                        workTimeFormat={props.workTimeFormat}
                        item={props.item}
                        isFavorite={props.item && EmployeeParametersHelper.isFavoriteWorkOrder(props.employeeParameters, workOrderId)}
                        isLoading={props.isLoading}
                        viewMode={props.viewMode}
                        titleSet={true}
                        onOpenWorkOrder={props.onOpenWorkOrder}
                        onRefresh={props.onRefresh}
                        onRemove={props.onRemove}
                        onPrint={props.onPrint}
                        onCopyWorkOrder={props.onCopyWorkOrder}
                        convertToProject={props.convertToProject}
                        detachFromProject={props.detachFromProject}
                        onCancelWorkOrder={props.onCancelWorkOrder}
                        onRestoreWorkOrder={props.onRestoreWorkOrder}
                        onStateChange={props.onStateChange}
                        onAddToFavorites={props.onAddToFavorites}
                        onRemoveFromFavorites={props.onRemoveFromFavorites}
                        onSetDurationByEstimatedHours={props.onSetDurationByEstimatedHours}
                        onAddEmployeeToWorkOrder={props.onAddEmployeeToWorkOrder}
                        onAddVehicleToWorkOrder={props.onAddVehicleToWorkOrder}
                        setEmployeeParameters={props.setEmployeeParameters}
                    />
                }
                expanded={props.expanded}
                onTitleClick={() => props.onOpenWorkOrder(workOrderId)}
                onChangeMode={props.onChangeExpansionMode}
            />
        );
    }
}
