import moment from "moment";
import dayjsDefault, { Dayjs, ManipulateType, OpUnitType } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import customParseFormat from "dayjs/plugin/customParseFormat";
import minMax from "dayjs/plugin/minMax";
import weekday from "dayjs/plugin/weekday";
import isoWeek from "dayjs/plugin/isoWeek";
import "dayjs/locale/fi";

dayjsDefault.extend(utc);
dayjsDefault.extend(timezone);
dayjsDefault.extend(isBetween);
dayjsDefault.extend(isSameOrAfter);
dayjsDefault.extend(isSameOrBefore);
dayjsDefault.extend(customParseFormat);
dayjsDefault.extend(minMax);
dayjsDefault.extend(weekday);
dayjsDefault.extend(isoWeek);

dayjsDefault.locale("fi");

// Dayjs loses tz information when reinitiliazing existing dayjs-object when tz is UTC.
const dayjs = (date?: Date | Dayjs | string | number): Dayjs =>
    date instanceof dayjsDefault ? date as Dayjs : dayjsDefault(date || dayjsDefault());

/* eslint-disable no-unused-vars */
export const enum FileType {
    Unknown,
    Word,
    Excel,
    PowerPoint,
    Pdf,
    Image,
    Archive,
    Text,
    Html
}
/* eslint-enable no-unused-vars */

export interface IIdItem {
    id: string;
}

export interface ILocation {
    top: number;
    bottom: number;
    left: number;
    right: number;
}

export class Base {
    static emptyGuid = "00000000-0000-0000-0000-000000000000";
    static dayDurationTics = 24 * 60 * 60 * 1000;

    static isNullOrUndefined(test: any): boolean {
        return test === null || test === undefined;
    }

    static nullToEmpty(test: string): string {
        return test !== null && test !== undefined ? test : "";
    }

    static numberHasValue(number: number): boolean {
        return !this.isNullOrUndefined(number) && !isNaN(number);
    }

    static isIntStr(str: string): boolean {
        if (!str) return false;
        for (let i = 0; i <= str.length - 1; i++) {
            const chr = str.charAt(i);
            if (["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"].indexOf(chr) < 0) {
                return false;
            }
        }
        return true;
    }

    static intToFileSizeStr(n: number, addBrackets = true) :string {
        const kb = 1024;
        const mb = kb * 1024;
        if (n < mb) return (addBrackets ? "(" : "") + (`${(n / kb).toFixed(1)} KB`) + (addBrackets ? ")" : "");
        return (addBrackets ? "(" : "") + (`${(n / mb).toFixed(1)} MB`) + (addBrackets ? ")" : "");
    }

    static capitalizeFirstChar(s: string): string {
        if (!s) return s;
        return s.charAt(0).toUpperCase() + s.slice(1);
    }

    static isInteger(value: any): boolean {
        return typeof value === "number" &&
            isFinite(value) &&
            Math.floor(value) === value;
    }

    static isEqualInteger(a: number, b: number): boolean {
        return Base.isInteger(a) === Base.isInteger(b) && a === b;
    }

    static intRoundUpByStep(n: number, stepInMin: number): number {
        let result = n;
        if (n > 0 && stepInMin > 0 && n % stepInMin > 0) {
            result = Math.floor(n / stepInMin) * stepInMin + stepInMin;
        }
        return result;
    }

    static getStringWithSeparators(values: string[], separator: string): string {
        if (Base.isNullOrUndefined(values) || values.length < 1 || Base.isNullOrUndefined(separator)) return "";
        let result = "";
        for (let i = 0; i < values.length; i++) {
            if (!values[i]) continue;
            result = result + values[i] + separator;
        }
        if (result.length > 0) {
            result = result.substr(0, result.length - separator.length);
        }
        return result;
    }

    static getStringWithSeparatorsInt(values: number[], separator: string): string {
        if (Base.isNullOrUndefined(values) || values.length < 1 || Base.isNullOrUndefined(separator)) return "";
        let result = "";
        for (let i = 0; i < values.length; i++) {
            if (Base.isNullOrUndefined(values[i])) continue;
            result = result + values[i].toString(10) + separator;
        }
        if (result.length > 0) {
            result = result.substr(0, result.length - separator.length);
        }
        return result;
    }

    static right(s: string, l: number): string {
        return s.substr(s.length - l);
    }

    static leftPad(s: string, l: number, c = "0"): string {
        if (s.length > l - 1) return s;
        return Base.right(`${c.repeat(l)}${s}`, l);
    }

    static isString(data: any): data is string {
        return typeof data === "string";
    }

    static getDecimalAmountForPrice(value: number): number {
        // This will always show two decimals even if price has less than two decimals.
        if (Math.floor(value) === value || value.toString().split(".")[1].length < 2) {
            return 2;
        } else {
            return value.toString().split(".")[1].length;
        }
    }

    // ------------------------------
    // Guid
    // ------------------------------
    static getGuid(): string {
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
            const r = Math.random() * 16 | 0;
            const v = c === "x" ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        }).toLowerCase();
    }

    // ------------------------------
    // Times and Dates
    // ------------------------------
    static timeZoneName(): string {
        return dayjsDefault.tz.guess();
    }

    static dateTzOffset(date: string | Dayjs | Date | number, tz: string): string {
        return dayjsDefault.tz(date, tz).format("Z");
    }

    static dateTzOffsetShort(date: string | Dayjs | Date | number, tz: string): string {
        return this.dateTzOffset(date, tz)
            .replace(/\+0/, "+") // Remove leading 0 when +0
            .replace(/\-0/, "-") // Remove leading 0 when -0
            .replace(/:00/, ""); // Remove minutes part when it's :00
    }

    // Compare date tz with local date tz at the same time to avoid different offset because of DST.
    static dateHasLocalTz(date: string, tz: string): boolean {
        return this.dateTzOffset(date, tz) !== this.dateTzOffset(date, this.timeZoneName());
    }

    // Returns date tz offset if different than local tz
    static dateTzOffsetIfNotLocal(date: string, tz: string): string {
        const offset = this.dateTzOffset(date, tz);
        return offset !== this.dateTzOffset(date, this.timeZoneName()) ? offset : "";
    }

    static getFirstDayOfWeek(date: Date) {
        const day = date.getDay();
        const diff = date.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
        return new Date(date.setDate(diff));
    }

    static getFirstDayOfWeekUtcDate(date: Date): Date {
        return moment.utc(date).weekday(0).toDate();
    }

    static getLastDayOfWeekUtcDate(date: Date): Date {
        return moment.utc(date).weekday(7).toDate();
    }

    static isFirstDayOfWeek(date: Date): boolean {
        if (!date) return false;
        return moment(date).isoWeekday() === 1;
    }

    static isFirstDayOfWeekLocal(date: Date): boolean {
        if (!date) return false;
        return moment(date).weekday() === 0;
    }

    static getMinuteAccuracyDate(date: Date): Date {
        if (!date) return date;
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes());
    }

    static getMinuteAccuracyTime(time: number): number {
        if (!time) return time;
        return Math.floor(time / 60000) * 60000;
    }

    static dayjsToMinuteAccuracy(date?: string | Dayjs | Date | number): Dayjs {
        return this.dateStartOf(date, "minute");
    }

    static getDayAccuracyTime(time: number): number {
        if (!time) return time;
        return Math.floor(time / 86400000) * 86400000;
    }

    static timeHasValue(time: number): boolean {
        return !this.isNullOrUndefined(time) && time !== 0 && !isNaN(time);
    }

    static timeToDateStr(value: moment.Moment | number): string {
        if (this.isNullOrUndefined(value)) return "";
        let m: moment.Moment;
        if (typeof value === "number") {
            if (!this.timeHasValue(value)) {
                return "";
            }
            m = moment(value);
        } else {
            m = value;
        }
        return m.format("D.M.YYYY");
    }

    static dateToDateStr(value: Date): string {
        if (!value) return "";
        return Base.timeToDateStr(value.getTime());
    }

    static timeToTimeStr(value: moment.Moment | number): string {
        if (this.isNullOrUndefined(value)) return "";
        let m: moment.Moment;
        if (typeof value === "number") {
            if (!this.timeHasValue(value)) {
                return "";
            }
            m = moment(value);
        } else {
            m = value;
        }
        return m.format("H.mm");
    }

    static hoursToMinutes(hours: number): number {
        if (Base.isNullOrUndefined(hours)) return 0;
        const value = hours ?? 0;
        const minutes = Math.round(value * 60);
        const hour = Math.floor(minutes / 60);
        const min = Math.round(minutes - hour * 60);
        return 60 * hour + min;
    }

    static hoursToTimeStr(hours: number): string {
        if (Base.isNullOrUndefined(hours)) return "";
        const value = hours ?? 0;
        const minutes = Math.round(value * 60);
        const hour = Math.floor(minutes / 60);
        const min = Math.round(minutes - hour * 60);
        //console.log("hoursToTimeStr", value, minutes, hour, min);
        return hour.toString(10) + ":" + Base.leftPad(min.toString(10), 2);
    }

    static utcTimeToTimeStr(time: number): string {
        if (!this.timeHasValue(time)) return "";
        return moment.utc(time).format("H.mm");
    }

    static utcTimeToDateTimeTimeStr(time: number, includeSeconds = false): string {
        if (!this.timeHasValue(time)) return "";
        return moment.utc(time).format("D.M.YYYY H.mm" + (includeSeconds ? ":ss" : ""));
    }

    static dateToTimeStr(value: Date): string {
        if (!value) return "";
        return Base.timeToTimeStr(value.getTime());
    }

    static timeToDateTimeStr(time: number, includeSeconds = false): string {
        if (!this.timeHasValue(time)) return "";
        return moment(time).format("D.M.YYYY H.mm" + (includeSeconds ? ":ss" : ""));
    }

    static utcTimeToDateStr(value: moment.Moment | number): string {
        if (this.isNullOrUndefined(value)) {
            return "";
        }
        let m: moment.Moment;
        if (typeof value === "number") {
            if (!this.timeHasValue(value)) {
                return "";
            }
            m = moment.utc(value);
        } else {
            m = moment.utc([value.year(), value.month(), value.date(), 0, 0, 0, 0]);
        }
        return m.format("D.M.YYYY");
    }

    static localDateToUtcDate(value: Date): Date {
        if (this.isNullOrUndefined(value)) {
            return null;
        }
        return moment.utc([value.getFullYear(), value.getMonth(), value.getDate(), 0, 0, 0, 0]).toDate();
    }

    static dayjsParse(date: string, format: string) {
        return dayjsDefault(date, format);
    }

    static dayjsToJsonDate(date?: string | Dayjs | Date | number): string {
        return dayjs(date).format("YYYY-MM-DD");
    }

    static dayjsToJsonDateTime(date: string | Dayjs | Date | number): string {
        return dayjs(date || dayjs()).format("YYYY-MM-DDTHH:mm:ss");
    }

    static dayjsToJsonDateTimeOffset(date?: string | Dayjs | Date | number): string {
        return dayjs(date || dayjs()).format("YYYY-MM-DDTHH:mm:ssZ");
    }

    static dayjsToDateStr(date: string | Dayjs | Date | number): string {
        return dayjs(date).format("D.M.YYYY");
    }

    static dayjsToDateStrWithWeekday(date: string | Dayjs | Date | number): string {
        return this.capitalizeFirstChar(dayjs(date).format("dd D.M.YYYY"));
    }

    static dayjsToDateStrShort(date: string | Dayjs | Date | number): string {
        return dayjs(date).format("D.M.YY");
    }

    static dayjsToShortDateStr(date: string | Dayjs | Date | number): string {
        return dayjs(date).format("D.M.");
    }

    static dayjsToTimeStr(date: string | Dayjs | Date | number): string {
        return dayjs(date).format("H.mm");
    }

    static dayjsDateToDateTimeStr(
        date: string | Dayjs | Date | number,
        includeSeconds = false
    ): string {
        return dayjs(date).format(
            "D.M.YYYY H.mm" + (includeSeconds ? ":ss" : "")
        );
    }

    static timeStrToHours(time: string) {
        const date = this.dayjsParse(time, "HH:mm");
        return date.hour() + date.minute() / 60;
    }

    // Keep time value and set the timezone to "tz"
    static dateAtTz(tz: string, date?: string | Dayjs | Date | number): Dayjs {
        return dayjsDefault.tz(date || dayjs(), tz);
    }

    // Convert to another timezone by changing the time value
    static dateToTz(tz: string, date?: string | Dayjs | Date | number): Dayjs {
        return dayjs(date || dayjs()).tz(tz);
    }

    static dateStrToDayjsIgnoreTimezone(dateStr: string) {
        if (!dateStr) return null;
        // Remove timezone information from str to avoid converting when initializing new object
        return dayjs(dateStr.split(/(Z|(\+|-)\d\d\:\d\d)$/gi)[0]);
    }

    static dateStrToOriginalTimezoneDateStr(dateStr: string): string {
        return this.dayjsToDateStr(this.dateStrToDayjsIgnoreTimezone(dateStr));
    }

    static dateStrToOriginalTimezoneShortDateStr(dateStr: string): string {
        return this.dayjsToShortDateStr(this.dateStrToDayjsIgnoreTimezone(dateStr));
    }

    static dateStrToOriginalTimezoneTimeStr(dateStr: string): string {
        return this.dayjsToTimeStr(this.dateStrToDayjsIgnoreTimezone(dateStr));
    }

    static dateStrToOriginalTimezoneDateTimeStr(dateStr: string): string {
        return this.dayjsDateToDateTimeStr(this.dateStrToDayjsIgnoreTimezone(dateStr));
    }

    static dateStrToOriginalTimezoneJsonDateTime(dateStr: string): string {
        return this.dayjsToJsonDateTime(this.dateStrToDayjsIgnoreTimezone(dateStr));
    }

    static tzOffsetFromStr(dateStr: string): string {
        return dateStr.split(/(Z|(\+|-)\d\d\:\d\d)$/gi)[1];
    }

    // Formats date to specific tz offset string without converting given date time.
    static dateToIsoStringAtTimezone(date: Date | Dayjs | string | number, tzOffset: string): string {
        return `${this.dayjsToJsonDateTime(date)}${tzOffset}`;
    }

    static dateStartOf(
        date: Date | Dayjs | string | number,
        unit: OpUnitType
    ): Dayjs {
        return dayjs(date || dayjs()).startOf(unit);
    }

    static dateEndOf(
        date: Date | Dayjs | string | number,
        unit: OpUnitType
    ): Dayjs {
        return dayjs(date || dayjs()).endOf(unit);
    }

    static getWeekNumber(date?: Date | Dayjs | string | number): number {
        return dayjs(date || dayjs()).isoWeek();
    }

    static getWeekNumberUtcDate(date: Date | number): number {
        return moment.utc(date).isoWeek();
    }

    static getWeekDayNameShort(date: Date | number): string {
        return moment(date).format("dd");
    }

    static getWeekDayNameShortUtcDate(date: Date | number): string {
        return moment.utc(date).format("dd");
    }

    static getWeekDayNameLongUtcDate(date: Date | number): string {
        return moment.utc(date).format("dddd");
    }

    static getDateStrUtcDate(date: Date | number): string {
        return moment.utc(date).format("D.M.YYYY");
    }

    static getDayAndMonth(date: Date | number): string {
        return moment(date).format("D.M");
    }

    static getUtcDate(time: number): Date {
        const date = new Date(time);
        return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0, 0));
    }

    static getNowDate(): Date {
        const date = new Date();
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
    }

    static getNowUtcDate(): Date {
        const date = new Date();
        return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0, 0));
    }

    static isOverlapping(startTimeA: number, endTimeA: number, startTimeB: number, endTimeB: number): boolean {
        return startTimeA < endTimeB && startTimeB < endTimeA;
    }

    static isOverlappingDate(
        startTimeA: string | number | Dayjs | Date,
        endTimeA: string | number | Dayjs | Date,
        startTimeB: string | number | Dayjs | Date,
        endTimeB: string | number | Dayjs | Date,
        inclusive = false,
        granularity?: OpUnitType,
    ): boolean {
        return inclusive ? (
            dayjs(startTimeA).isSameOrBefore(endTimeB, granularity) &&
            dayjs(startTimeB).isSameOrBefore(endTimeA, granularity)
        ) : (
            dayjs(startTimeA).isBefore(endTimeB, granularity) &&
            dayjs(startTimeB).isBefore(endTimeA, granularity)
        );
    }

    static isBetween(
        time: string | number | Dayjs | Date,
        rangeStart: string | number | Dayjs | Date,
        rangeEnd: string | number | Dayjs | Date,
        granularity?: OpUnitType,
        inclusivity?: "[]" | "()" | "[)" | "(]"
    ): boolean {
        return dayjs(time).isBetween(
            rangeStart,
            rangeEnd,
            granularity,
            inclusivity
        );
    }

    static isSameMinute(
        date1: string | Dayjs | Date | number,
        date2: string | Dayjs | Date | number
    ): boolean {
        return dayjs(date1).isSame(date2, "minute");
    }

    static isSameDay(
        date1: string | Dayjs | Date | number,
        date2: string | Dayjs | Date | number
    ): boolean {
        return dayjs(date1).isSame(date2, "day");
    }

    static isSameDate(
        date1: string | Dayjs | Date | number,
        date2: string | Dayjs | Date | number
    ): boolean {
        return dayjs(date1).isSame(date2);
    }

    static isSameOrAfter(
        date1: string | Dayjs | Date | number,
        date2: string | Dayjs | Date | number,
        granularity?: OpUnitType,
    ): boolean {
        return dayjs(date1).isSameOrAfter(date2, granularity);
    }

    static isAfter(
        date1: string | Dayjs | Date | number,
        date2: string | Dayjs | Date | number,
        granularity?: OpUnitType,
    ): boolean {
        return dayjs(date1).isAfter(date2, granularity);
    }

    static isBefore(
        date1: string | Dayjs | Date | number,
        date2: string | Dayjs | Date | number,
        granularity?: OpUnitType,
    ): boolean {
        return dayjs(date1).isBefore(date2, granularity);
    }

    static isSameOrBefore(
        date1: string | Dayjs | Date | number,
        date2: string | Dayjs | Date | number,
        granularity?: OpUnitType,
    ): boolean {
        return dayjs(date1).isSameOrBefore(date2, granularity);
    }

    static minDate(dates: (string | Dayjs | Date | number)[]): Dayjs {
        return dayjsDefault.min(dates.map(d => dayjs(d)));
    }

    static maxDate(dates: (string | Dayjs | Date | number)[]): Dayjs {
        return dayjsDefault.max(dates.map(d => dayjs(d)));
    }

    static convertUtcTimeToLocalDate(time: number): Date {
        const date = new Date(time);
        return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds(), date.getUTCMilliseconds());
    }

    static dateDiffMoment(mDate1: moment.Moment, mDate2: moment.Moment, unit: moment.unitOfTime.Diff): number {
        if (Base.isNullOrUndefined(mDate1) || Base.isNullOrUndefined(mDate2)) return 0;
        return mDate2.diff(mDate1, unit);
    }

    static dayjsDateDiff(date1: Dayjs, date2: Dayjs, unit: OpUnitType): number {
        if (Base.isNullOrUndefined(date1) || Base.isNullOrUndefined(date2))
            return 0;
        return date2.diff(date1, unit);
    }

    static dayjsDiffInMinutes(
        date1: Date | Dayjs | string | number,
        date2: Date | Dayjs | string | number
    ): number {
        if (Base.isNullOrUndefined(date1) || Base.isNullOrUndefined(date2))
            return 0;
        return this.dayjsDateDiff(dayjs(date1), dayjs(date2), "minutes");
    }

    static dateDiffInMinutes(date1: Date | number, date2: Date | number): number {
        if (Base.isNullOrUndefined(date1) || Base.isNullOrUndefined(date2)) return 0;
        return Base.dateDiffMoment(moment(date1), moment(date2), "minutes");
    }

    static dateDiffInDays(date1: Date | number, date2: Date | number): number {
        if (Base.isNullOrUndefined(date1) || Base.isNullOrUndefined(date2)) return 0;
        return Base.dateDiffMoment(moment(date1), moment(date2), "days");
    }

    static dateDiffInMonths(date1: Date | number, date2: Date | number): number {
        if (Base.isNullOrUndefined(date1) || Base.isNullOrUndefined(date2)) return 0;
        return Base.dateDiffMoment(moment(date1), moment(date2), "months");
    }

    static dateDiffInYears(date1: Date | number, date2: Date | number): number {
        if (Base.isNullOrUndefined(date1) || Base.isNullOrUndefined(date2)) return 0;
        return Base.dateDiffMoment(moment(date1), moment(date2), "years");
    }

    static dateDiffInMinutesFromParts(d1Year: number, d1Month: number, d1Date: number, d1Hours: number, d1Minutes: number, d1Seconds: number, d2Year: number, d2Month: number, d2Date: number, d2Hours: number, d2Minutes: number, d2Seconds: number): number {
        if (Base.isNullOrUndefined(d1Year) || Base.isNullOrUndefined(d2Year)) return 0;
        return Base.dateDiffMoment(moment({ y: d1Year, M: d1Month, d: d1Date, h: d1Hours, m: d1Minutes, s: d1Seconds }), moment({ y: d2Year, M: d2Month, d: d2Date, h: d2Hours, m: d2Minutes, s: d2Seconds }), "minutes");
    }

    static dateDiffInDaysFromParts(d1Year: number, d1Month: number, d1Date: number, d1Hours: number, d1Minutes: number, d1Seconds: number, d2Year: number, d2Month: number, d2Date: number, d2Hours: number, d2Minutes: number, d2Seconds: number): number {
        if (Base.isNullOrUndefined(d1Year) || Base.isNullOrUndefined(d2Year)) return 0;
        return Base.dateDiffMoment(moment({ y: d1Year, M: d1Month, d: d1Date, h: d1Hours, m: d1Minutes, s: d1Seconds }), moment({ y: d2Year, M: d2Month, d: d2Date, h: d2Hours, m: d2Minutes, s: d2Seconds }), "days");
    }

    static dateDiffInMonthsFromParts(d1Year: number, d1Month: number, d1Date: number, d1Hours: number, d1Minutes: number, d1Seconds: number, d2Year: number, d2Month: number, d2Date: number, d2Hours: number, d2Minutes: number, d2Seconds: number): number {
        if (Base.isNullOrUndefined(d1Year) || Base.isNullOrUndefined(d2Year)) return 0;
        return Base.dateDiffMoment(moment({ y: d1Year, M: d1Month, d: d1Date, h: d1Hours, m: d1Minutes, s: d1Seconds }), moment({ y: d2Year, M: d2Month, d: d2Date, h: d2Hours, m: d2Minutes, s: d2Seconds }), "months");
    }

    static dateDiffInYearsFromParts(d1Year: number, d1Month: number, d1Date: number, d1Hours: number, d1Minutes: number, d1Seconds: number, d2Year: number, d2Month: number, d2Date: number, d2Hours: number, d2Minutes: number, d2Seconds: number): number {
        if (Base.isNullOrUndefined(d1Year) || Base.isNullOrUndefined(d2Year)) return 0;
        return Base.dateDiffMoment(moment({ y: d1Year, M: d1Month, d: d1Date, h: d1Hours, m: d1Minutes, s: d1Seconds }), moment({ y: d2Year, M: d2Month, d: d2Date, h: d2Hours, m: d2Minutes, s: d2Seconds }), "years");
    }

    static startOfDayLocal(date: moment.Moment): moment.Moment {
        if (Base.isNullOrUndefined(date)) return null;
        // set current offset
        date.utcOffset(moment().utcOffset());
        return date.clone().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    }

    static endOfDayLocal(date: moment.Moment): moment.Moment {
        if (Base.isNullOrUndefined(date)) return null;
        date.utcOffset(moment().utcOffset());
        return date.clone().set({ hour: 23, minute: 59, second: 59, millisecond: 999 });
    }


    static dateAdd(date: Dayjs, value: number, unit: ManipulateType): Dayjs {
        return date.add(value, unit);
    }

    static dateAddDays(
        date: Date | Dayjs | string | number,
        value: number
    ): Dayjs {
        return this.dateAdd(dayjs(date || dayjs()), value, "days");
    }

    static dateAddMinutes(
        date: Date | Dayjs | string | number,
        value: number
    ): Dayjs {
        return this.dateAdd(dayjs(date || dayjs()), value, "minutes");
    }

    // ------------------------------
    // Sorting
    // ------------------------------
    static numberCompare(a: number, b: number): number {
        if (Base.isNullOrUndefined(a) && Base.isNullOrUndefined(b)) return 0;
        if (Base.isNullOrUndefined(a)) return 1;
        if (Base.isNullOrUndefined(b)) return -1;
        return a - b;
    }

    static stringCompare(a: string, b: string): number {
        if (Base.isNullOrUndefined(a) && Base.isNullOrUndefined(b)) return 0;
        if (Base.isNullOrUndefined(a)) return 1;
        if (Base.isNullOrUndefined(b)) return -1;
        return a.localeCompare(b);
    }

    static booleanCompare(a: boolean, b: boolean): number {
        if (Base.isNullOrUndefined(a) && Base.isNullOrUndefined(b)) return 0;
        if (Base.isNullOrUndefined(a)) return 1;
        if (Base.isNullOrUndefined(b)) return -1;
        return (a ? 1 : 0) - (b ? 1 : 0);
    }

    static momentCompare(a: moment.Moment, b: moment.Moment): number {
        if (Base.isNullOrUndefined(a) && Base.isNullOrUndefined(b)) return 0;
        if (Base.isNullOrUndefined(a)) return 1;
        if (Base.isNullOrUndefined(b)) return -1;
        return a.isAfter(b) ? 1 : a.isBefore(b) ? -1 : 0;
    }

    static dayjsCompare(a: string | Dayjs | Date | number, b: string | Dayjs | Date | number): number {
        return dayjs(a).isAfter(b) ? 1 : dayjs(a).isBefore(b) ? -1 : 0;
    }

    // ------------------------------
    // Validation
    // ------------------------------
    static isValidUsername(username: string) {
        if (!username) return false;
        if (username.length < 8) return false;
        return true;
    }

    static isValidPassword(password: string): boolean {
        if (!password) return false;
        if (password.length < 8) return false;
        if (!/[A-Z]/.test(password) && !/\u00c4/.test(password) && !/\u00c5/.test(password) && !/\u00c6/.test(password) && !/\u00d6/.test(password)) return false; // Uppercase letter required
        if (!/[a-z]/.test(password) && !/\u00e4/.test(password) && !/\u00e5/.test(password) && !/\u00e6/.test(password) && !/\u00f6/.test(password)) return false; // Lowercase letter required
        if (!/[0-9]/.test(password) && !/[!@#$%^&*()_+{}:"<>?|[\];\\',./`~=]/.test(password)) return false; // Number or special character required
        return true;
    }

    // ------------------------------
    // Filetypes
    // ------------------------------
    static fileExtensions = {
        wordExtensions: [".DOCX", ".DOC", ".DOCM", ".DOT", ".DOTM", ".DOTX"],
        excelExtensions: [".XLSX", ".XLS", ".XLSM", ".XLSB", ".XLTX", ".XLTM", ".XLT", ".CSV"],
        powerPointExtensions: [".PPTX", ".PPT", ".PPTM", ".POTX", ".POTM", ".POT", ".PPS", ".PPSX", ".PPSM", ".PPAM", ".PPA"],
        pdfExtensions: [".PDF"],
        imageExtensions: [".PNG", ".JPG", ".GIF", ".BMP", ".EMF", ".EXIF", ".ICON", ".TIF", ".WMF"],
        archiveExtensions: [".ZIP", ".7Z", ".S7Z", ".RAR", ".ZIPX", ".ZZ", ".CAB", ".ARJ", ".ACE"],
        textExtensions: [".TXT"],
        htmlExtensions: [".HTML", ".HTM", ".MHTM"],
    };

    static getFileType(extension: string): FileType {
        if (!extension || Base.isNullOrUndefined(this.fileExtensions)) return FileType.Unknown;
        const index = extension.lastIndexOf(".");
        if (index < 0) return FileType.Unknown;
        let ext = extension.substring(index);
        if (!ext) return FileType.Unknown;
        ext = ext.toUpperCase();
        if (this.fileExtensions.wordExtensions.indexOf(ext) !== -1) return FileType.Word;
        if (this.fileExtensions.excelExtensions.indexOf(ext) !== -1) return FileType.Excel;
        if (this.fileExtensions.powerPointExtensions.indexOf(ext) !== -1) return FileType.PowerPoint;
        if (this.fileExtensions.pdfExtensions.indexOf(ext) !== -1) return FileType.Pdf;
        if (this.fileExtensions.imageExtensions.indexOf(ext) !== -1) return FileType.Image;
        if (this.fileExtensions.archiveExtensions.indexOf(ext) !== -1) return FileType.Archive;
        if (this.fileExtensions.textExtensions.indexOf(ext) !== -1) return FileType.Text;
        if (this.fileExtensions.htmlExtensions.indexOf(ext) !== -1) return FileType.Html;
        return FileType.Unknown;
    }

    static isImageFile(file: File): boolean {
        return !Base.isNullOrUndefined(file) && Base.getFileType(file.name) === FileType.Image;
    }

    // ------------------------------
    // Generics
    // ------------------------------
    static getTypedArray<T>(items: any, type: (new (...args: any[]) => T)): T[] {
        const result: T[] = [];
        if (items) {
            for (let i = 0; i < items.length; i++) {
                /* eslint-disable new-cap */
                result.push(new type(items[i]));
                /* eslint-enable new-cap */
            }
        }
        return result;
    }

    static getPromiseResult<T>(value: T): Promise<T> {
        return new Promise<T>((resolve) => { resolve(value); });
    }

    //!!! USE ONLY FOR SIMPLE TYPES
    private static getUniqueItems<T>(items: T[]): T[] {
        return [...Array.from(new Set(items))];
    }

    // ------------------------------
    // Array
    // ------------------------------
    static getUniqueStringItems(items: string[]): string[] {
        return Base.getUniqueItems<string>(items);
    }

    static getUniqueNumberItems(items: number[]): number[] {
        return Base.getUniqueItems<number>(items);
    }

    static selectManyStringItems(items: string[][]): string[] {
        const result: string[] = [];
        for (const a of items) {
            for (const b of a) {
                result.push(b);
            }
        }
        return result;
    }

    static swapArrayItems<T>(items: T[], index1: number, index2: number): T[] {
        return items.map((val, i) => {
            if (i === index1) return items[index2];
            if (i === index2) return items[index1];
            return val;
        });
    }

    static moveArrayItem<T>(items: T[], source: number, destination: number): T[] {
        const result = items.slice(0);
        if (!items || items.length < 2 || Base.isNullOrUndefined(source) || Base.isNullOrUndefined(destination) || source < 0 || destination < 0 || source > items.length - 1 || destination > items.length - 1 || source === destination) return result;
        result.splice(destination, 0, result.splice(source, 1)[0]);
        return result;
    }

    static groupArray<T>(items: T[], key: string): { [key: string]: T[] } {
        if (!items || items.length < 1) return {};
        const result: { [key: string]: T[] } = {};
        for (const item of items) {
            (result[item[key]] = result[item[key]] || []).push(item);
        }
        return result;
    }

    // ------------------------------
    // IIdItem
    // ------------------------------
    static getItemById<T extends IIdItem>(items: T[], id: string): T {
        if (!items || !id || items.length < 1) return null;
        return items.find(i => i.id === id);
    }

    // ------------------------------
    // Media
    // ------------------------------
    static getCameraIds(): Promise<string[]> {
        if (!(("mediaDevices" in navigator) && ("enumerateDevices" in (<any>navigator).mediaDevices))) {
            return new Promise<string[]>((resolve) => { resolve([]); });
        }
        return (navigator as any).mediaDevices.enumerateDevices().then(deviceInfos => {
            const result: string[] = [];
            for (let i = 0; i < deviceInfos.length; i++) {
                if (deviceInfos[i].kind === "videoinput") {
                    result.push(deviceInfos[i].deviceId);
                }
            }
            return result;
        }).catch(error => {
            console.log("getCameraIds", error);
        });
    }

    static getDataUriSize(dataUri: string): number {
        if (!dataUri) return 0;
        const byteString = atob(dataUri.split(",")[1]);
        return byteString.length;
    }

    static dataUriToBlob(dataUri: string): Blob {
        const byteString = atob(dataUri.split(",")[1]);
        const mimeString = dataUri.split(",")[0].split(":")[1].split(";")[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    }

    static blobToFile(blob: Blob, fileName: string): File {
        const b = <any>blob;
        b.lastModified = new Date();
        b.name = fileName;
        return <File>b;
    }

    static blobToBase64(file: Blob): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = error => reject(error);
        });
    }

    static urlToDataUri(url: string): Promise<string> {
        return fetch(url).then(data => {
            return data.blob().then(blob => {
                return Base.blobToBase64(blob);
            });
        });
    }

    // ------------------------------
    // List Utils
    // ------------------------------
    static getListItems<T extends IIdItem>(stateItems: T[], stateSelectedId: string, stateCheckedIds: string[], dbItems: T[], resetItems: boolean, refreshList: boolean): { items: T[]; selectedId: string; checkedIds: string[] } {
        let items: T[];
        if (!resetItems && !refreshList) {
            const oldIds = {};
            for (let j = 0; j < stateItems.length; j++) {
                oldIds[stateItems[j].id] = true;
            }
            const oldItems = stateItems.slice(0);
            const newItems = dbItems.filter(i => Object.prototype.isPrototypeOf.call(oldIds, i.id) ? false : (oldIds[i.id] = true));
            items = [...oldItems, ...newItems];
        } else {
            items = dbItems;
        }
        return {
            items: items,
            selectedId: items.findIndex(i => i.id === stateSelectedId) > -1 ? stateSelectedId : "",
            checkedIds: items.filter(i => stateCheckedIds.indexOf(i.id) > -1).map(i => i.id)
        };
    }

    static getSelectedIds = (selectedId: string, checkedIds: string[]): string[] => {
        let result = [];
        if (!Base.isNullOrUndefined(checkedIds) && checkedIds.length > 0) {
            result = checkedIds.slice(0);
        } else if (selectedId) {
            result.push(selectedId);
        }
        return result;
    };

    static getIdsOnColumnCheckboxChange = (items: IIdItem[], checked: boolean) => {
        let selectedId = "";
        let checkedIds: string[] = [];
        if (checked && !Base.isNullOrUndefined(items) && items.length) {
            checkedIds = items.map(i => {
                return i.id;
            });
            selectedId = checkedIds[0];
        }
        return {
            selectedId: selectedId,
            checkedIds: checkedIds
        };
    };

    static getIdsOnLineCheckboxChange = (aSelectedId: string, aCheckedIds: string[], id: string, checked: boolean) => {
        let selectedId = aSelectedId;
        const checkedIds = !Base.isNullOrUndefined(aCheckedIds) ? aCheckedIds.slice(0) : [];
        const index = checkedIds.indexOf(id);
        if (checked && index < 0) {
            checkedIds.push(id);
        } else if (!checked && index > -1) {
            checkedIds.splice(index, 1);
        }
        if (!checked && id === selectedId) {
            selectedId = "";
        }
        if (!selectedId && checkedIds.length === 1) {
            selectedId = checkedIds[0];
        }
        return {
            selectedId: selectedId,
            checkedIds: checkedIds
        };
    };

    static openLocationInMaps(latitude: number, longitude: number) {
        if (Base.isNullOrUndefined(latitude) || Base.isNullOrUndefined(longitude)) return;
        window.open("https://www.google.com/maps/search/?api=1&query=" + latitude.toFixed(6) + "," + longitude.toFixed(6) + "&zoom=12", "_blank");
    }

    static isValidMapLink(link: string): boolean {
        if (!link) return false;
        const trimmed = link.trim();
        return trimmed.indexOf("http://") === 0 || trimmed.indexOf("https://") === 0;
    }

    static openMapLinkInMaps(link: string) {
        if (!Base.isValidMapLink(link)) return;
        window.open(link.trim(), "_blank");
    }

    static generateMapLink(mapLinkTemplate: string, street: string, postalCode: string, city: string) {
        if (!mapLinkTemplate) return "";
        let address = "";
        if (street) {
            address = address + street;
            if (postalCode || city) {
                address = address + ",";
            }
        }
        if (postalCode) {
            address = address + postalCode;
            if (city) {
                address = address + " ";
            }
        }
        if (city) {
            address = address + city;
        }
        return String.format(mapLinkTemplate, address.replace(/ /g, "+"));
    }

    static getGoogleMapCoordinateStr(latitude: number, longitude: number): string {
        return latitude.toFixed(6) + "," + longitude.toFixed(6);
    }

    static generateMapLinkByCoordinates(mapLinkTemplate: string, latitude: number, longitude: number) {
        if (!mapLinkTemplate) return "";
        return String.format(mapLinkTemplate, Base.getGoogleMapCoordinateStr(latitude, longitude));
    }

    // ------------------------------
    // Json
    // ------------------------------
    static isJsonString(str: string): boolean {
        if (!str) return false;
        try {
            const obj = JSON.parse(str);
            if (obj && typeof obj === "object") {
                return true;
            }
        } catch (e) {
            return false;
        }
        return true;
    }

    // https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding
    static b64EncodeUnicode(str: string) {
        // first we use encodeURIComponent to get percent-encoded UTF-8,
        // then we convert the percent encodings into raw bytes which
        // can be fed into btoa.
        return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
            function toSolidBytes(match, p1) {
                return String.fromCharCode(parseInt("0x" + p1));
            }));
    }


    // Wait for X milliseconds before the same function is allowed to execute again.
    // Use for functions that might run multiple times a second, e.g, onscroll handlers.
    static throttle<F extends Function>(fn: F, waitMs: number): F {
        let inThrottle: boolean;
        let lastFn: ReturnType<typeof setTimeout>;
        let lastTime: number;
        return <F><any> function(this: any, ...args: any[]) {
            if (!inThrottle) {
                fn.apply(this, args);
                lastTime = Date.now();
                inThrottle = true;
            } else {
                clearTimeout(lastFn);
                lastFn = setTimeout(() => {
                    if (Date.now() - lastTime >= waitMs) {
                        fn.apply(this, args);
                        lastTime = Date.now();
                    }
                }, Math.max(waitMs - (Date.now() - lastTime), 0));
            }
        };
    }

    static debounce<F extends Function>(func:F, wait:number):F {
        let timeoutId:number;
        if (!Number.isInteger(wait)) {
            console.log("Called debounce without a valid number");
            wait = 300;
        }
        // conversion through any necessary as it wont satisfy criteria otherwise
        return <F><any> function(this:any, ...args: any[]) {
            clearTimeout(timeoutId);
            timeoutId = window.setTimeout(() => {
                func.apply(this, args);
            }, wait);
        };
    }

    // ------------------------------
    // WebPush
    // ------------------------------
    static urlBase64ToUint8Array(base64Str: string): Uint8Array {
        const padding = "=".repeat((4 - base64Str.length % 4) % 4);
        const base64 = (base64Str + padding).replace(/-/g, "+").replace(/_/g, "/");
        const rawData = window.atob(base64);
        const result = new Uint8Array(rawData.length);
        for (let i = 0; i < rawData.length; ++i) {
            result[i] = rawData.charCodeAt(i);
        }
        return result;
    }

    // ------------------------------
    // DOM
    // ------------------------------
    static getElementPadding(element: HTMLElement): ILocation {
        const style = window.getComputedStyle(element);
        return {
            top: parseInt(style.paddingTop, 10),
            left: parseInt(style.paddingLeft, 10),
            right: parseInt(style.paddingRight, 10),
            bottom: parseInt(style.paddingBottom, 10),
        };
    }

    static getElementMargin(element: HTMLElement): ILocation {
        const style = window.getComputedStyle(element);
        return {
            top: parseInt(style.marginTop, 10),
            left: parseInt(style.marginLeft, 10),
            right: parseInt(style.marginRight, 10),
            bottom: parseInt(style.marginBottom, 10),
        };
    }

    static selectElementContents(el: Node) {
        const range = document.createRange();
        range.selectNodeContents(el);
        const sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
    }

    static htmlToText(html: string): string {
        if (!html) return html;
        return html.replace(/(<([^>]+)>)/ig, "").replace(/&nbsp;/g, " ");
    }

    static textToHtml(text: string): string {
        if (!text) return text;
        return text.replace(/ /g, "&nbsp;");
    }

    static setNotAllowedCursor(value: boolean) {
        if (value) {
            document.body.classList.add("notAllowedCursor");
        } else {
            document.body.classList.remove("notAllowedCursor");
        }
    }

    static elementsAreOverlapping(
        el1: Element,
        el2: Element,
        padding = 0
    ): boolean {
        const rect1 = el1.getBoundingClientRect();
        const rect2 = el2.getBoundingClientRect();

        return !(
            rect1.right + padding < rect2.left - padding ||
            rect1.left - padding > rect2.right + padding ||
            rect1.bottom + padding < rect2.top - padding ||
            rect1.top - padding > rect2.bottom + padding
        );
    }

    /**
     * Use density to check every nth element.
     * density = 1 checks every element.
     * Padding adds the number of pixels to each element when checking.
     */
    static anyElementsOverlap(
        collection: HTMLCollection,
        density = 1,
        padding = 0
    ): boolean {
        const elements = [...collection];
        return elements.some((e: Element, i) => {
            if (i % density !== 0) return;
            const nextEl = elements[i + density];
            // Skip if no next element
            if (!nextEl) return false;
            return this.elementsAreOverlapping(e, nextEl, padding);
        });
    }

    /**
     * Provide maxDensity to avoid infinite loop.
     */
    static findNonOverlappingDensity(
        collection: HTMLCollection,
        padding = 0,
        maxDensity = 100
    ): number {
        for (let i = 1; i <= maxDensity; i++) {
            if (!this.anyElementsOverlap(collection, i, padding)) {
                return i;
            }
        }

        return maxDensity;
    }

    // ------------------------------
    // Links
    // ------------------------------
    static sanitizeHtml(txt: string): string {
        const temp = document.createElement("div");
        temp.textContent = txt;
        return temp.innerHTML;
    }

    // Handles only https-urls and sanitizes content
    static linkifyText(txt: string): string {
        if (!txt) return "";
        const urlPattern = /\b(?:https):\/\/[a-z0-9-+&@#/%?=~_|!:,.;]*[a-z0-9-+&@#/%=~_|]/gim;
        const temps: string[] = [];
        const links: string[] = [];
        let result = txt.replace(urlPattern, (str) => {
            const replacement = Base.getGuid();
            temps.push(replacement);
            links.push('<a href="' + str + '" target="_blank">' + Base.sanitizeHtml(str) + "</a>");
            return replacement;
        });
        result = Base.sanitizeHtml(result);
        for (let i = 0; i < temps.length; i++) {
            result = result.replace(temps[i], links[i]);
        }
        return result;
    }

    // ------------------------------
    // DOM
    // ------------------------------
    static getElementIndex(node: Element): number {
        let index = 0;
        while ((node = node.previousElementSibling)) {
            index++;
        }
        return index;
    }

    // ------------------------------
    // Formatted Text
    // ------------------------------
    static lf = "#LF#";

    static isFormattedText(str: string): boolean {
        if (!str) return false;
        return str.indexOf(Base.lf) > -1;
    }

    static getFormattedText(str: string): string {
        if (!str) return str;
        return str.replace(/#LF#/gi, "<br/>");
    }

    static userNameInitials(userNames : string[]) : string {
        if(!userNames) return "";
        return userNames.reduce((initials, name, idx) =>
            (idx === 0 || idx === userNames.length - 1)
                ? initials + name[0]
                : initials, "");
    }

    // ------------------------------
    // View Refresh Timer
    // ------------------------------
    private static viewRefreshTimer = 0;
    private static viewRefreshTimerTimeout = 120 * 1000; // 120 s

    static clearViewRefreshTimer() {
        if (this.viewRefreshTimer !== 0) {
            window.clearTimeout(this.viewRefreshTimer);
            this.viewRefreshTimer = 0;
        }
    }

    static setViewRefreshTimer(refreshCallback: () => void) {
        this.viewRefreshTimer = window.setTimeout(() => {
            refreshCallback();
        }, this.viewRefreshTimerTimeout);
    }

    static resetViewRefreshTimer(refreshCallback: () => void) {
        this.clearViewRefreshTimer();
        this.setViewRefreshTimer(refreshCallback);
    }

    // ------------------------------
    // Object and classes
    // ------------------------------

    static cloneClassObject<T>(obj: T) {
        return Object.assign(Object.create(Object.getPrototypeOf(obj)), obj) as T;
    }


    static roundDateToNearestMinutes(date: Date, minutes: number) {
        if (minutes > 60) {
            throw new Error("minutes must be less than 60");
        }
        // make copy of date
        date = new Date(date.getTime());
        const roundedMinutes = (Math.round(date.getMinutes() / minutes)) * minutes;
        date.setMinutes(roundedMinutes, 0, 0);
        return date;
    }

    static formDataToObject(data: FormData): any {
        const object: any = {};
        data.forEach((value, key) => {
            // handle null
            if (value === "null") {
                value = null;
            }
            object[key] = value;
        });
        return object;
    }

}

// ***********************************************************************
// Moment locale FI import from moment/locale/fi did not work
// ***********************************************************************
const numbersPast = "nolla yksi kaksi kolme neljä viisi kuusi seitsemän kahdeksan yhdeksän".split(" ");
const numbersFuture = [
    "nolla", "yhden", "kahden", "kolmen", "neljän", "viiden", "kuuden",
    numbersPast[7], numbersPast[8], numbersPast[9]
];
function translate(number, withoutSuffix, key, isFuture) {
    let result = "";
    switch (key) {
        case "s":
            return isFuture ? "muutaman sekunnin" : "muutama sekunti";
        case "ss":
            return isFuture ? "sekunnin" : "sekuntia";
        case "m":
            return isFuture ? "minuutin" : "minuutti";
        case "mm":
            result = isFuture ? "minuutin" : "minuuttia";
            break;
        case "h":
            return isFuture ? "tunnin" : "tunti";
        case "hh":
            result = isFuture ? "tunnin" : "tuntia";
            break;
        case "d":
            return isFuture ? "päivän" : "päivä";
        case "dd":
            result = isFuture ? "päivän" : "päivää";
            break;
        case "M":
            return isFuture ? "kuukauden" : "kuukausi";
        case "MM":
            result = isFuture ? "kuukauden" : "kuukautta";
            break;
        case "y":
            return isFuture ? "vuoden" : "vuosi";
        case "yy":
            result = isFuture ? "vuoden" : "vuotta";
            break;
    }
    result = verbalNumber(number, isFuture) + " " + result;
    return result;
}
function verbalNumber(number, isFuture) {
    return number < 10 ? (isFuture ? numbersFuture[number] : numbersPast[number]) : number;
}
moment.defineLocale("fi", {
    months: "tammikuu_helmikuu_maaliskuu_huhtikuu_toukokuu_kesäkuu_heinäkuu_elokuu_syyskuu_lokakuu_marraskuu_joulukuu".split("_"),
    monthsShort: "tammi_helmi_maalis_huhti_touko_kesä_heinä_elo_syys_loka_marras_joulu".split("_"),
    weekdays: "sunnuntai_maanantai_tiistai_keskiviikko_torstai_perjantai_lauantai".split("_"),
    weekdaysShort: "su_ma_ti_ke_to_pe_la".split("_"),
    weekdaysMin: "su_ma_ti_ke_to_pe_la".split("_"),
    longDateFormat: {
        LT: "HH.mm",
        LTS: "HH.mm.ss",
        L: "DD.MM.YYYY",
        LL: "Do MMMM[ta] YYYY",
        LLL: "Do MMMM[ta] YYYY, [klo] HH.mm",
        LLLL: "dddd, Do MMMM[ta] YYYY, [klo] HH.mm",
        l: "D.M.YYYY",
        ll: "Do MMM YYYY",
        lll: "Do MMM YYYY, [klo] HH.mm",
        llll: "ddd, Do MMM YYYY, [klo] HH.mm"
    },
    calendar: {
        sameDay: "[tänään] [klo] LT",
        nextDay: "[huomenna] [klo] LT",
        nextWeek: "dddd [klo] LT",
        lastDay: "[eilen] [klo] LT",
        lastWeek: "[viime] dddd[na] [klo] LT",
        sameElse: "L"
    },
    relativeTime: {
        future: "%s päästä",
        past: "%s sitten",
        s: translate,
        ss: translate,
        m: translate,
        mm: translate,
        h: translate,
        hh: translate,
        d: translate,
        dd: translate,
        M: translate,
        MM: translate,
        y: translate,
        yy: translate
    },
    dayOfMonthOrdinalParse: /\d{1,2}\./,
    ordinal: (n: number) => { return n + "."; },
    week: {
        dow: 1, // Monday is the first day of the week.
        doy: 4 // The week that contains Jan 4th is the first week of the year.
    }
});

export function areObjectsEqual(obj1, obj2) {
    const props1 = Object.getOwnPropertyNames(obj1);
    const props2 = Object.getOwnPropertyNames(obj2);
    if (props1.length !== props2.length) {
        return false;
    }
    for (let i = 0; i < props1.length; i++) {
        const val1 = obj1[props1[i]];
        const val2 = obj2[props1[i]];
        const areObjects = isObject(val1) && isObject(val2);
        if (areObjects && !areObjectsEqual(val1, val2)) {
            return false;
        }
        if (!areObjects && val1 !== val2) {
            return false;
        }
    }
    return true;
}

function isObject(object) {
    return object != null && typeof object === "object";
}

export function minBy<T>(arr: T[], comparisonGetter: (el: T) => any): T | undefined {
    if (!arr || arr.length === 0) return undefined;
    const min = {
        value: arr[0],
        comparison: comparisonGetter(arr[0])
    };
    for (let i = 1; i < arr.length; i++) {
        const comparison = comparisonGetter(arr[i]);
        if (comparison < min.comparison) {
            min.comparison = comparison;
            min.value = arr[i];
        }
    }
    return min.value;
}

export function maxBy<T>(arr: T[], comparisonGetter: (el: T) => any): T | undefined {
    if (!arr || arr.length === 0) return undefined;

    const max = {
        value: arr[0],
        comparison: comparisonGetter(arr[0])
    };

    for (let i = 1; i < arr.length; i++) {
        const comparison = comparisonGetter(arr[i]);
        if (comparison > max.comparison) {
            max.comparison = comparison;
            max.value = arr[i];
        }
    }
    return max.value;
}
