/* global JSX */
// Button
// ***********************************************************************************************************************
import * as React from "react";
import { Base } from "../../framework/base";
import { ReactNode } from "react";

export interface IButtonSecondaryAction {
    disabled?: boolean;
    hidden?: boolean;
    classes?: string;
    title: string | JSX.Element;
    onClick?: () => void;
}

export interface IButtonProp {
    index?: number;
    autoFocus?: boolean;
    classes?: string;
    title: string;
    tooltip?: string;
    enabled: boolean;
    onClick: (index?: number) => void;
    icon?: string;
    stopPropagation?: boolean;
    secondaryActions?: IButtonSecondaryAction[];
    secondaryActionsClasses?: string;
    secondaryActionsFixedPosition?: boolean;
    secondaryActionsMenuRight?: boolean;
    children?: ReactNode;
}

export class Button extends React.Component<IButtonProp, {}> {
    dropdownButton: HTMLButtonElement;
    dropdownDiv: HTMLDivElement;
    buttonId: string;
    dropdownId: string;

    constructor(props) {
        super(props);
        this.buttonId = Base.getGuid();
        this.dropdownId = Base.getGuid();
    }

    handleClick = (e) => {
        const props = this.props;
        if (!props.enabled) return;
        if (props.stopPropagation) {
            e.stopPropagation();
        }
        const activeElement: any = document.activeElement;
        if (activeElement && activeElement.blur && activeElement.id !== this.buttonId) {
            activeElement.blur();
            window.setTimeout(() => {
                props.onClick(!Base.isNullOrUndefined(props.index) ? props.index : 0);
            }, 50);
        } else {
            props.onClick(!Base.isNullOrUndefined(props.index) ? props.index : 0);
        }
    };

    // #region Dropdown
    dropdownClickEvent = (ev: MouseEvent) => {
        const element = ev.target as HTMLElement;
        const dropdown = element.closest("[data-id='" + this.dropdownId + "']");
        if (!dropdown) {
            this.closeDropDown();
        }
    };

    componentDidMount(): void {
        window.addEventListener("click", this.dropdownClickEvent, true);
        window.addEventListener("resize", this.updateFixedDropDownPosition);
    }

    componentWillUnmount(): void {
        window.removeEventListener("click", this.dropdownClickEvent);
        window.removeEventListener("resize", this.updateFixedDropDownPosition);
    }

    dropdownIsOpen = (): boolean => {
        if (!this.dropdownDiv) return false;
        //console.log("dropdownDiv.style.display", this.dropdownDiv.style.display);
        return this.dropdownDiv.style.display === "block";
    };

    setFixedDropDownPosition = () => {
        const props = this.props;
        if (!props.secondaryActionsFixedPosition || !this.dropdownDiv || !this.dropdownButton) return;
        const buttonRect = this.dropdownButton.getBoundingClientRect();
        this.dropdownDiv.style.top = (buttonRect.bottom + 3).toString(10) + "px";
        if (props.secondaryActionsMenuRight) {
            this.dropdownDiv.style.right = (window.innerWidth - buttonRect.right).toString(10) + "px";
        } else {
            this.dropdownDiv.style.left = buttonRect.left.toString(10) + "px";
            this.dropdownDiv.style.right = "auto";
        }
    };

    updateFixedDropDownPosition = () => {
        if (!this.dropdownIsOpen()) return;
        this.setFixedDropDownPosition();
    };

    openDropDown = () => {
        //console.log("openDropDown");
        if (!this.dropdownDiv) return;
        if (!this.dropdownIsOpen()) {
            const subActions = this.dropdownDiv.getElementsByClassName("subActions");
            if (subActions && subActions.length) {
                for (let i = 0; i < subActions.length; i++) {
                    subActions[i].classList.add("collapsed");
                }
            }
            this.setFixedDropDownPosition();
            this.dropdownDiv.style.display = "block";
        }
    };

    closeDropDown = () => {
        if (!this.dropdownDiv) return;
        if (this.dropdownIsOpen()) {
            this.dropdownDiv.style.display = "none";
        }
    };

    handleActionClick = (e, action: IButtonSecondaryAction) => {
        if (action.disabled) return;
        const obj = this;
        e.stopPropagation();
        e.preventDefault();
        const activeElement: any = document.activeElement;
        if (activeElement && activeElement.blur && activeElement.id !== this.buttonId) {
            activeElement.blur();
            window.setTimeout(() => {
                obj.closeDropDown();
                action.onClick();
            }, 50);
        } else {
            obj.closeDropDown();
            action.onClick();
        }
    };

    handleDrowdownButtonClick = (e) => {
        if (!this.props.enabled) return;
        if (this.dropdownIsOpen()) {
            this.closeDropDown();
        } else {
            this.openDropDown();
        }
    };
    // #endregion Dropdown

    render() {
        const props = this.props;
        const buttonClasses = "btn" + (props.classes ? " " + props.classes : "") + (!props.enabled ? " disabled" : "") + (props.icon && props.title ? " iconBtn" : "");
        const visibleActions = props.secondaryActions ? props.secondaryActions.filter(i => !i.hidden && i.onClick) : [];
        if (visibleActions.length < 1) {
            return (
                <button id={this.buttonId} type="button" className={buttonClasses} title={props.tooltip ?? props.title} onClick={this.handleClick} autoFocus={!!props.autoFocus}>{props.icon ? <div className={"icon " + props.icon} /> : ""}{props.title}</button>
            );
        } else {
            return (
                <div data-id={this.dropdownId} className={"btn-group button-with-secondary-actions" + (props.secondaryActionsClasses ? " " + props.secondaryActionsClasses : "")}>
                    <button id={this.buttonId} type="button" className={buttonClasses} title={props.tooltip ?? props.title} onClick={this.handleClick} autoFocus={!!props.autoFocus}>{props.icon ? <div className={"icon " + props.icon} /> : ""}{props.title}</button>
                    <button type="button" className={"btn" + (props.classes ? " " + props.classes : "") + " dropdown-toggle dropdown-toggle-split"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={this.handleDrowdownButtonClick} ref={(button) => { this.dropdownButton = button; }}>
                        <span className="sr-only" />
                    </button>
                    <div className={"dropdown-menu" + (props.secondaryActionsMenuRight ? " dropdown-menu-right" : "") + (props.secondaryActionsFixedPosition ? " fixedPosition" : "")} ref={(div) => { this.dropdownDiv = div; }}>
                        {visibleActions.map((action, index) =>
                            <a key={index} className={"dropdown-item" + (action.classes ? " " + action.classes : "") + (action.disabled ? " disabled" : "")} onClick={ (e) => this.handleActionClick(e, action) } href="#">{action.title}</a>
                        )}
                    </div>
                </div>
            );
        }
    }
}
