import { WorkShift, WorkShiftDto } from "../models/workShift/workShift";
import { apiCall } from "./apiClient";
import { getApiCall, postApiCall } from "./tokenService";

export const getWorkShifts = (
    employeeIds: string | string[],
    startDate: string,
    endDate: string,
    withSlots = false,
    signal?: AbortSignal
): Promise<WorkShiftDto[]> => {
    return getApiCall<WorkShiftDto[]>(
        `api/workshifts?startDate=${encodeURIComponent(
            startDate
        )}&endDate=${encodeURIComponent(endDate)}&withSlots=${withSlots}${[
            employeeIds,
        ]
            .flat()
            .map((id) => `&employeeIds=${encodeURIComponent(id)}`)
            .join("")}`,
        null,
        signal
    );
};

export const getWorkShiftsNew = (
    employeeIds: string | string[],
    startDate: string,
    endDate: string,
    withSlots = false,
    signal?: AbortSignal
) => {
    return apiCall<WorkShiftDto[]>(
        `workshifts?startDate=${encodeURIComponent(
            startDate
        )}&endDate=${encodeURIComponent(endDate)}&withSlots=${withSlots}${[
            employeeIds,
        ]
            .flat()
            .map((id) => `&employeeIds=${encodeURIComponent(id)}`)
            .join("")}`,
        "GET",
        null,
        signal
    );
};

export const saveWorkShifts = (
    employeeIds: string[],
    startDate: string,
    endDate: string,
    workShifts: WorkShift[]
): Promise<void> => {
    return postApiCall<void>(
        "api/workshifts/save",
        null,
        JSON.stringify({
            employeeIds,
            startDate,
            endDate,
            workShifts
        })
    );
};
