// WorkMainWorkListFavorites
// ***********************************************************************************************************************
import * as React from "react";
import { IWorkOrderItem } from "../../models/work/workOrderItem";
import { Base } from "../../framework/base";
import { WorkOrderDragData } from "../../models/work/workOrderDragData";
import { WorkMainPortlet } from "./workMainPortlet";
import { WorkListLineShort } from "./workListLineShort";
import { IEmployeeParameters } from "../../models/employee/employeeIParameters";
import { employeeParameterWorkOrderCalendarWorkListExpanded } from "../../models/common/consts";
import { Translations } from "../../models/translations";

// WorkMainWorkListFavorites
// ***********************************************************************************************************************
export interface IWorkMainWorkListFavoritesProp {
    classes?: string;
    employeeParameters: IEmployeeParameters;
    listCode: string;
    title: string;
    selectedId: string;
    favoriteWorkOrderIds: string[];
    items: IWorkOrderItem[];
    onItemClick: (id: string) => void;
    onEditWorkOrderItem: (id: string) => void;
    onAddWorkOrderToList: (id: string) => void;
    onChangeExpanded: (listExpandedKey: string, expanded: boolean) => void;
    onRemoveFromFavorites?: (id: string) => void;
}

export interface IWorkMainWorkListFavoritesState {
    isDragged: boolean;
    isInitiallyExpanded: boolean;
}

export class WorkMainWorkListFavorites extends React.Component<IWorkMainWorkListFavoritesProp, IWorkMainWorkListFavoritesState> {
    private listDiv: HTMLDivElement;

    static getListExpandedKey = (props: IWorkMainWorkListFavoritesProp): string => {
        return employeeParameterWorkOrderCalendarWorkListExpanded + props.listCode;
    };

    constructor(props: IWorkMainWorkListFavoritesProp) {
        super(props);
        const expanded = props.employeeParameters
            ? props.employeeParameters[WorkMainWorkListFavorites.getListExpandedKey(props)]
            : null;
        this.state = { isDragged: false, isInitiallyExpanded: !Base.isNullOrUndefined(expanded) ? expanded === "1" : null };
    }

    componentDidUpdate(prevProps: IWorkMainWorkListFavoritesProp, prevState: IWorkMainWorkListFavoritesState): void {
        if (!Base.isNullOrUndefined(prevState.isInitiallyExpanded)) return;
        const props = this.props;
        const expanded = props.employeeParameters
            ? props.employeeParameters[WorkMainWorkListFavorites.getListExpandedKey(props)]
            : null;
        const isInitiallyExpanded = !Base.isNullOrUndefined(expanded) ? expanded === "1" : null;
        if (prevState.isInitiallyExpanded === isInitiallyExpanded) return;
        this.setState({
            isInitiallyExpanded: isInitiallyExpanded
        });
    }

    handleLineClick = (id: string) => {
        if (!id) return;
        this.props.onItemClick(id);
    };

    handleLineDoubleClick = (id: string) => {
        if (!id) return;
        this.props.onEditWorkOrderItem(id);
    };

    isValidDrop = (dragData: WorkOrderDragData): boolean => {
        return !this.props.items.find(i => i.id === dragData.id);
    };

    setListFocused = () => {
        if (!this.listDiv) return;
        if (this.listDiv.classList.contains("focused")) return;
        this.listDiv.classList.add("focused");
    };

    unsetListFocused = () => {
        if (!this.listDiv) return;
        this.listDiv.classList.remove("focused");
    };

    onDragOver = (ev) => {
        const dragData = WorkOrderDragData.getWorkOrderDragData(ev);
        if (!this.isValidDrop(dragData)) return;
        // console.log("onDragOver", id);
        this.setListFocused();
        ev.preventDefault();
    };

    onDragLeave = (ev) => {
        const dragData = WorkOrderDragData.getWorkOrderDragData(ev);
        if (!this.isValidDrop(dragData)) return;
        ev.preventDefault();
        this.unsetListFocused();
        // console.log("employeeColOnDragLeave", id);
    };

    onDrop = (ev) => {
        const dragData = WorkOrderDragData.getWorkOrderDragData(ev);
        if (!this.isValidDrop(dragData)) return;
        ev.preventDefault();
        this.setListFocused();
        this.props.onAddWorkOrderToList(dragData.id);
        // console.log("onDrop", id);
    };

    render() {
        const props = this.props;
        const state = this.state;
        return (
            <WorkMainPortlet
                classes={"pWorkList" + (props.classes ? " " + props.classes : "") + (props.items.length < 1 ? " pEmpty" : "")}
                expanded={state.isInitiallyExpanded}
                title={props.title}
                content={<div className="pContent filterList" ref={(elem) => { this.listDiv = elem; }}>
                    <div className="listContent">
                        {props.items.map((item) =>
                            // Correct! Key should be specified inside the array.
                            <WorkListLineShort
                                key={item.id}
                                item={item}
                                draggable={true}
                                employeeId={item.employees.length > 0 ? item.employees[0].id : ""}
                                vehicleId={item.vehicles.length > 0 ? item.vehicles[0].id : ""}
                                isSelected={props.selectedId === item.id}
                                removeTitle={Translations.RemoveFromFavories}
                                onClick={this.handleLineClick}
                                onDoubleClick={this.handleLineDoubleClick}
                                onRemoveFromFavorites={props.favoriteWorkOrderIds.indexOf(item.id) >= 0 ? props.onRemoveFromFavorites : null}
                            />
                        )}
                    </div>
                </div>}
                replaceContent={true}
                onDragOver={this.onDragOver}
                onDrop={this.onDrop}
                onDragLeave={this.onDragLeave}
                onChangeExpanded={(expanded: boolean) => { props.onChangeExpanded(WorkMainWorkListFavorites.getListExpandedKey(props), expanded); }}
            />
        );
    }
}
