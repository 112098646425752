import React, { useCallback, useEffect } from "react";
import Box from "@mui/material/Box";
import {
    CircleMarker,
    MapContainer,
    Polyline,
    TileLayer,
    Tooltip,
    useMap,
} from "react-leaflet";
import { LatLngBounds, LatLngExpression, latLngBounds } from "leaflet";
import { useDebouncedEventListener } from "../hooks/useDebouncedEventListener";
import { useTheme } from "@mui/material";

export interface MapRouteProps {
    coords: LatLngExpression[];
    color?: string;
    highlight?: boolean;
}

export interface MapPointProps {
    coords: LatLngExpression;
    text?: string;
    color?: string;
    textColorClass?: string;
    textBgColorClass?: string;
    highlight?: boolean;
}

interface MapProps {
    routes?: MapRouteProps[];
    points?: MapPointProps[];
    minHeight?: string;
}

const circleMarkerRadius = 5;

export const Map = ({ routes, points, minHeight = "200px" }: MapProps) => {
    const theme = useTheme();

    return (
        <Box
            display="flex"
            flexDirection="column"
            flexGrow={1}
            minHeight={minHeight}
            height="100%"
        >
            <MapContainer style={{ flexGrow: 1 }}>
                <UseMapContainer routes={routes} points={points} />
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {routes?.map((r, i) => (
                    <Polyline
                        key={`${i}-${r.coords.join(
                            ","
                        )}-${r.highlight.toString()}`}
                        positions={r.coords}
                        color={r.highlight ? theme.palette.error.main : (r.color ?? theme.palette.primary.main)}
                        weight={r.highlight ? 5 : 3}
                    />
                ))}
                {points?.map((r, i) => (
                    <CircleMarker
                        key={`${i}-${r.coords.toString()}`}
                        center={r.coords}
                        color={r.color ?? theme.palette.primary.main}
                        radius={circleMarkerRadius}
                        pathOptions={{ fillOpacity: 1 }}
                    >
                        {r.text &&
                        (r.highlight || points.every((p) => !p.highlight)) ? (
                            <Tooltip
                                permanent
                                className={[
                                    r.textColorClass,
                                    r.textBgColorClass,
                                ]
                                    .filter(Boolean)
                                    .join(" ")}
                            >
                                {r.text}
                            </Tooltip>
                            ) : null}
                    </CircleMarker>
                ))}
            </MapContainer>
        </Box>
    );
};

const fitMapDelay = 300;

// Container to be used as a descendant of MapContainer
// Handles fitting map to bounds etc.
const UseMapContainer = ({
    routes,
    points,
}: {
    routes: MapRouteProps[];
    points: MapPointProps[];
}) => {
    const map = useMap();

    const fitMap = useCallback(() => {
        let bounds: LatLngBounds;

        if (routes?.filter((r) => r.coords?.length > 0).length > 0) {
            // Calculate the bounds of all polylines
            bounds = routes.reduce(
                (acc, r) =>
                    r.coords?.length > 0
                        ? acc.extend(latLngBounds(r.coords))
                        : acc,
                latLngBounds(routes[0].coords)
            );
        }

        if (points?.filter((r) => r.coords).length > 0) {
            // Get the bounds from all points
            bounds = points.reduce(
                (acc, r) => (r.coords ? acc.extend(r.coords) : acc),
                bounds || latLngBounds([points[0].coords])
            );
        }

        if (bounds) {
            // Fit the map to the calculated bounds
            map.fitBounds(bounds);
        }
    }, [map, routes, points]);

    useEffect(() => {
        fitMap();
    }, [fitMap]);

    useDebouncedEventListener("resize", fitMap, fitMapDelay);

    return null;
};
