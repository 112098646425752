/* global JSX */
// Dropdown
// ***********************************************************************************************************************
import * as React from "react";
import { Base } from "../../framework/base";
import { Translations } from "../../models/translations";
import { CheckBox } from "./checkbox";
import { ToolButton } from "./toolButton";

export interface IDropdownAction {
    disabled?: boolean;
    classes?: string;
    selected?: boolean;
    title: string | JSX.Element;
    onClick: () => void;
}

export interface IDropdownProp {
    index?: number;
    autoFocus?: boolean;
    menuRight?: boolean;
    classes?: string;
    buttonClasses?: string;
    disabled?: boolean;
    selectedTitle: string | JSX.Element;
    actions: IDropdownAction[];
}

export class Dropdown extends React.Component<IDropdownProp, {}> {
    private dropdownDiv: HTMLDivElement;
    private iconSpan: HTMLSpanElement;
    private closeDropDownTime: number;

    openDropDown = () => {
        if (!this.dropdownDiv) return;
        if (this.dropdownDiv.style.display !== "block") {
            this.dropdownDiv.style.display = "block";
            this.iconSpan.className = "icon doCollapse";
        }
    };

    closeDropDown = () => {
        if (!this.dropdownDiv) return;
        if (this.dropdownDiv.style.display === "block") {
            this.dropdownDiv.style.display = "none";
            this.iconSpan.className = "icon doExpand";
            this.closeDropDownTime = new Date().getTime();
        }
    };

    componentDidMount(): void {
        window.addEventListener("click", this.closeDropDown, true);
    }

    componentWillUnmount(): void {
        window.removeEventListener("click", this.closeDropDown);
    }

    handleButtonClick = (e) => {
        if (this.props.disabled) return;
        const nowTime = new Date().getTime();
        if (nowTime - this.closeDropDownTime < 50) return;
        this.openDropDown();
    };

    handleActionClick = (index?: number) => {
        const i = index !== null ? index : 0;
        if (i < 0 || i >= this.props.actions.length) return;
        if (this.props.actions[i].disabled) return;
        this.closeDropDown();
        this.props.actions[i].onClick();
    };

    render() {
        const props = this.props;
        const disabled = !Base.isNullOrUndefined(props.disabled) ? props.disabled : false;
        const groupClasses = "dropdown" + (props.classes ? " " + props.classes : "") + (disabled ? " disabled" : "");
        const buttonClasses = "btn btn-default dropdown-toggle" + (props.buttonClasses ? " " + props.buttonClasses : "") + (disabled ? " disabled" : "");
        const actions: (string | JSX.Element)[] = [];
        for (let i = 0; i < props.actions.length; i++) {
            if (props.actions[i].onClick === null) {
                actions.push(props.actions[i].title);
                continue;
            }
            actions.push(<a className={"dropdown-item" + (props.actions[i].classes ? " " + props.actions[i].classes : "") + (props.actions[i].selected ? " selected" : "") + (props.actions[i].disabled ? " disabled" : "")} key={i} href="#" onClick={(ev) => { ev.preventDefault(); this.handleActionClick(i); }}>{props.actions[i].title}</a>);
        }
        return (
            <div className={groupClasses}>
                <button type="button" className={buttonClasses} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" autoFocus={props.autoFocus} onClick={this.handleButtonClick}>
                    {props.selectedTitle}
                    <span className="icon doExpand" ref={(span) => { this.iconSpan = span; }} />
                </button>
                <div className={"dropdown-menu" + (props.menuRight ? " dropdown-menu-right" : "")} ref={(div) => { this.dropdownDiv = div; }} >
                    {actions}
                </div>
            </div>
        );
    }
}

// ButtonDropdown
// ***********************************************************************************************************************
export interface IButtonDropdownAction {
    disabled?: boolean;
    hidden?: boolean;
    classes?: string;
    selected?: boolean;
    title: string;
    actions?: IButtonDropdownAction[];
    onClick?: () => void;
}

export interface IButtonDropdownProp {
    index?: number;
    autoFocus?: boolean;
    menuRight?: boolean;
    classes?: string;
    buttonClasses?: string;
    buttonIconClasses?: string;
    removeDefaultButtonClasses?: boolean;
    dropdownMenuClasses?: string;
    disabled?: boolean;
    selectedTitle?: string | JSX.Element;
    tooltip?: string;
    fixedPosition?: boolean;
    actions: IButtonDropdownAction[];
}

export class ButtonDropdown extends React.Component<IButtonDropdownProp, {}> {
    private dropdownButton: HTMLButtonElement;
    private dropdownDiv: HTMLDivElement;
    private iconSpan: HTMLSpanElement;
    private dropDownId: string;

    constructor(props: IButtonDropdownProp) {
        super(props);
        this.dropDownId = Base.getGuid();
    }

    getButtonIconClasses = (): string => {
        const props = this.props;
        return props.buttonIconClasses !== null && props.buttonIconClasses !== undefined
            ? " " + props.buttonIconClasses
            : " menu";
    };

    dropdownIsOpen = (): boolean => {
        if (!this.dropdownDiv) return false;
        //console.log("dropdownDiv.style.display", this.dropdownDiv.style.display);
        return this.dropdownDiv.style.display === "block";
    };

    setFixedDropDownPosition = () => {
        const props = this.props;
        if (!props.fixedPosition || !this.dropdownDiv || !this.dropdownButton) return;
        const buttonRect = this.dropdownButton.getBoundingClientRect();
        this.dropdownDiv.style.top = (buttonRect.bottom + 3).toString(10) + "px";
        if (props.menuRight) {
            this.dropdownDiv.style.right = (window.innerWidth - buttonRect.right).toString(10) + "px";
        } else {
            this.dropdownDiv.style.left = buttonRect.left.toString(10) + "px";
            this.dropdownDiv.style.right = "auto";
        }
    };

    updateFixedDropDownPosition = () => {
        if (!this.dropdownIsOpen()) return;
        this.setFixedDropDownPosition();
    };

    openDropDown = () => {
        //console.log("openDropDown");
        if (!this.dropdownDiv) return;
        if (!this.dropdownIsOpen()) {
            const subActions = this.dropdownDiv.getElementsByClassName("subActions");
            if (subActions && subActions.length) {
                for (let i = 0; i < subActions.length; i++) {
                    subActions[i].classList.add("collapsed");
                }
            }
            this.setFixedDropDownPosition();
            this.dropdownDiv.style.display = "block";
            this.dropdownButton.ariaExpanded = "true";
            this.iconSpan.className = "icon" + this.getButtonIconClasses();
        }
    };

    closeDropDown = () => {
        if (!this.dropdownDiv) return;
        if (this.dropdownIsOpen()) {
            this.dropdownDiv.style.display = "none";
            this.dropdownButton.ariaExpanded = "false";
            this.iconSpan.className = "icon" + this.getButtonIconClasses();
        }
    };

    dropDownClickEvent = (ev: MouseEvent) => {
        const element = ev.target as HTMLElement;
        const dropdown = element.closest("[data-id='" + this.dropDownId + "']");
        if (!dropdown) {
            this.closeDropDown();
        }
    };

    componentDidMount(): void {
        window.addEventListener("click", this.dropDownClickEvent, true);
        window.addEventListener("resize", this.updateFixedDropDownPosition);
    }

    componentWillUnmount(): void {
        window.removeEventListener("click", this.dropDownClickEvent);
        window.removeEventListener("resize", this.updateFixedDropDownPosition);
    }

    handleButtonClick = (e) => {
        //console.log("handleButtonClick", this.dropdownIsOpen(), this.props.disabled);
        if (this.props.disabled) return;
        if (this.dropdownIsOpen()) {
            this.closeDropDown();
        } else {
            this.openDropDown();
        }
    };

    handleParentActionClick = (ev, subActionsId: string) => {
        ev.preventDefault();
        ev.stopPropagation();
        if (!subActionsId) return;
        const element = document.getElementById(subActionsId);
        if (!element) return;
        if (element.className.indexOf("collapsed") > -1) {
            element.classList.remove("collapsed");
        } else {
            element.classList.add("collapsed");
        }
    };

    handleActionClick = (ev: React.MouseEvent, action: IButtonDropdownAction) => {
        ev.preventDefault();
        if (!action) return;
        if (action.disabled) return;
        this.closeDropDown();
        action.onClick();
    };

    getActionElements = (actions: IButtonDropdownAction[]): (string | JSX.Element)[] => {
        const result: (string | JSX.Element)[] = [];
        for (let i = 0; i < actions.length; i++) {
            const action = actions[i];
            if (action.hidden) continue;
            const hasActions = action.actions && action.actions.length > 0;
            if (!action.onClick && !hasActions) {
                result.push(action.title);
            } else if (hasActions) {
                const subActionsId = Base.getGuid();
                result.push(<a title={action.title} className={"dropdown-item" + (action.classes ? " " + action.classes : "") + (action.selected ? " selected" : "") + (action.disabled ? " disabled" : "")} key={i} href="#" onClick={(ev) => { this.handleParentActionClick(ev, subActionsId); }}>{action.title}</a>);
                result.push(<div id={subActionsId} className="subActions collapsed">{this.getActionElements(action.actions)}</div>);
            } else {
                result.push(<a title={action.title} className={"dropdown-item" + (action.classes ? " " + action.classes : "") + (action.selected ? " selected" : "") + (action.disabled ? " disabled" : "")} key={i} href="#" onClick={(ev) => { this.handleActionClick(ev, action); }}>{action.title}</a>);
            }
        }
        return result;
    };

    render() {
        const props = this.props;
        const disabled = !Base.isNullOrUndefined(props.disabled) ? props.disabled : false;
        const groupClasses = "dropdown" + (props.classes ? " " + props.classes : "") + (disabled ? " disabled" : "");
        const buttonClasses = (props.removeDefaultButtonClasses ? "" : "btn btn-default dropdown-toggle") + (props.buttonClasses ? " " + props.buttonClasses : "") + (disabled ? " disabled" : "");
        return (
            <div data-id={this.dropDownId} className={groupClasses}>
                <button type="button" className={buttonClasses} data-toggle="dropdown" aria-controls="dropdown-menu" aria-haspopup="true" aria-expanded="false" autoFocus={props.autoFocus} onClick={this.handleButtonClick} title={props.tooltip} ref={(button) => { this.dropdownButton = button; }}>
                    {props.selectedTitle}
                    <span className={"icon" + this.getButtonIconClasses()} ref={(span) => { this.iconSpan = span; }} />
                </button>
                <div id="dropdown-menu" className={"dropdown-menu" + (props.menuRight ? " dropdown-menu-right" : "") + (props.fixedPosition ? " fixedPosition" : "") + (props.dropdownMenuClasses ? ` ${props.dropdownMenuClasses}` : "")} ref={(div) => { this.dropdownDiv = div; }}>
                    {this.getActionElements(props.actions)}
                </div>
            </div>
        );
    }
}

// CheckBoxDropdown
// ***********************************************************************************************************************

export interface ICheckBoxDropdownItem {
    classes?: string;
    disabled?: boolean;
    id: string;
    getTitle(): string;
}

export interface ICheckBoxDropdownProp {
    autoFocus?: boolean;
    classes?: string;
    buttonClasses?: string;
    disabled?: boolean;
    menuRight?: boolean;
    required?: boolean;
    selectedIds: string[];
    values: ICheckBoxDropdownItem[];
    dropup?: boolean;
    onChange?: (selectedIds: string[]) => void;
    onChangeSingle?: (id: string, checked: boolean) => void;
}

export interface ICheckBoxDropdownState {
    editMode: boolean;
    selectedValueTitles: string;
}

export class CheckBoxDropdown extends React.Component<ICheckBoxDropdownProp, ICheckBoxDropdownState> {
    dropdownId: string;

    private selectedValueTitles = (props: ICheckBoxDropdownProp): string => {
        const selecedValues = props.values.filter(i => props.selectedIds.indexOf(i.id) >= 0);
        return selecedValues.map(i => i.getTitle()).join(", ");
    };

    constructor(props: ICheckBoxDropdownProp) {
        super(props);
        this.dropdownId = Base.getGuid();
        this.state = {
            editMode: false, selectedValueTitles: this.selectedValueTitles(props)
        };
    }

    dropdownClickEvent = (ev: MouseEvent) => {
        const element = ev.target as HTMLElement;
        const multiSelect = element.closest("[data-id='" + this.dropdownId + "']");
        if (!multiSelect) {
            this.setState({
                editMode: false
            });
        }
    };

    componentDidMount(): void {
        window.addEventListener("click", this.dropdownClickEvent, true);
    }

    componentWillUnmount(): void {
        window.removeEventListener("click", this.dropdownClickEvent);
    }

    componentDidUpdate(prevProps: ICheckBoxDropdownProp, prevState: ICheckBoxDropdownState): void {
        const selectedValueTitles = this.selectedValueTitles(this.props);
        if (prevState.selectedValueTitles === selectedValueTitles) return;
        this.setState({
            selectedValueTitles: selectedValueTitles
        });
    }

    private getSelectedIds = (select: HTMLSelectElement): string[] => {
        const ids: string[] = [];
        for (let i = 0, l = select.selectedOptions.length; i < l; i++) {
            ids.push(select.selectedOptions[i].value);
        }
        return ids;
    };

    handleChange = (event) => {
        this.props.onChange(this.getSelectedIds(event.target as HTMLSelectElement));
    };

    handleClearClick = () => {
        const props = this.props;
        if (props.disabled) return;
        if (props.onChange) {
            props.onChange([]);
            return;
        }
        if (props.onChangeSingle) {
            props.onChangeSingle("", false);
        }
    };

    handleToggleDropdown = () => {
        this.setState({
            editMode: !this.state.editMode
        });
    };

    handleCheckboxClickBoolean = (id: string, checked: boolean) => {
        //console.log("handleCheckboxClickBoolean", checked)
        const props = this.props;
        const selectedIds = props.selectedIds.filter(i => i !== id);
        if (checked) {
            selectedIds.push(id);
        }
        props.onChange(selectedIds);
    };

    render() {
        const props = this.props;
        const state = this.state;
        return (
            <div data-id={this.dropdownId} className={"checkboxDropdown" + (!props.disabled ? " input-group" : " disabled")}>
                <div className="form-control" title={state.selectedValueTitles}>
                    <div className="selectedData">{state.selectedValueTitles}</div>
                    {state.editMode &&
                        <div className={"rbt-menu dropdown-menu show" + (props.dropup ? " dropdown-menu--dropup" : "")}>
                            {props.values.map((value) =>
                                <CheckBox
                                    key={value.id}
                                    classes={"dropdown-item" + (value.classes ? " " + value.classes : "") + (value.disabled ? " disabled" : "")}
                                    enabled={!props.disabled}
                                    checked={props.selectedIds.indexOf(value.id) > -1}
                                    title={value.getTitle()}
                                    onCheckboxClickBoolean={(checked: boolean) => {
                                        props.onChange
                                            ? this.handleCheckboxClickBoolean(value.id, checked)
                                            : props.onChangeSingle(value.id, checked);
                                    }}
                                />
                            )}
                        </div>
                    }
                </div>
                {!props.disabled &&
                    <span className="input-group-append">
                        {!props.required && props.selectedIds && props.selectedIds.length > 0 &&
                            <ToolButton
                                removeDefaultClasses={true}
                                title={Translations.Clear}
                                classes="btn withIcon xCross gray"
                                enabled={true}
                                onClick={this.handleClearClick}
                            />
                        }
                        <ToolButton
                            removeDefaultClasses={true}
                            title={Translations.Open}
                            classes="btn withIcon doExpand"
                            enabled={true}
                            onClick={this.handleToggleDropdown}
                        />
                    </span>
                }
            </div>
        );
    }
}
