export class WorkTimeType {
    workTimeTypeId: string;
    name: string;
    type: number;

    constructor();
    constructor(obj: WorkTimeType);
    constructor(obj?: any) {
        this.workTimeTypeId = obj && obj.workTimeTypeId || "";
        this.name = obj && obj.name || "";
        this.type = obj && obj.type || 0;
    }
}
