// WorkOrderDialogSelectContactDialog - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import { Base } from "../../framework/base";
import { Translations } from "../../models/translations";
import { IWorkOrderEditContact } from "../../models/work/workOrderEditContact";
import { SettingsContactDialog } from "../settings/settingsContactDialog";
import { IContactEdit } from "../../models/contact/contactEdit";
import { SelectIdTitleDialog } from "../framework/selectIdTitleDialog";
import { IIdTitle, IdTitle } from "../../models/common/idTitle";
import { IEndlessList, EndlessList } from "../../models/common/endlessList";
import { ContactOperations } from "../../models/contact/contactOperations";

// WorkOrderDialogSelectContactDialog
export interface IWorkOrderDialogSelectContactDialogProps {
    classes?: string;
    selectedId: string;
    name: string;
    items: IWorkOrderEditContact[];
    onOk: (id: string, name: string, phone: string, email: string, comment: string) => void;
    onCancel: () => void;
}

export interface IWorkOrderDialogSelectContactDialogState {
    selectedId: string;
    showEditDialog: boolean;
    editItem: IContactEdit;
    items: IIdTitle[];
}

export class WorkOrderDialogSelectContactDialog extends React.Component<IWorkOrderDialogSelectContactDialogProps, IWorkOrderDialogSelectContactDialogState> {
    constructor(props: IWorkOrderDialogSelectContactDialogProps) {
        super(props);
        this.state = { selectedId: props.selectedId ?? "", showEditDialog: false, editItem: null, items: props.items.map(i => IdTitle.createIdTitle(i.id, i.getTitle())) };
    }

    handleCancel = () => {
        this.props.onCancel();
    };

    handleOk = (id: string, title: string) => {
        if (!id) return;
        const props = this.props;
        const item = props.items.find(i => i.id === id);
        if (!item) return;
        this.props.onOk(item.id, item.name, item.phone, item.email, item.comment);
    };

    getEditItem = (id: string, name: string) => {
        const obj = this;
        ContactOperations.getContactEdit(id)
            .then(editItem => {
                if (name) {
                    editItem.contact.name = name;
                }
                obj.setState({
                    showEditDialog: true,
                    editItem: editItem
                });
            });
    };

    handleLoadContacts = (page: number, filter: string): Promise<IEndlessList<IIdTitle>> => {
        const obj = this;
        const lowFilter = filter.toLowerCase();
        return new Promise<IEndlessList<IdTitle>>((resolve, reject) => {
            const result = new EndlessList<IdTitle>(null, IdTitle);
            result.items = !lowFilter ? obj.state.items : obj.state.items.filter(i => i.title.toLowerCase().indexOf(lowFilter) > -1);
            result.page = 1;
            result.hasMore = false;
            console.log(result.items);
            resolve(result);
        });
    };

    handleAddContact = (name: string) => {
        this.getEditItem(Base.emptyGuid, name);
    };

    handleEditDialogOk = (id: string, name: string, phone: string, email: string, comment: string) => {
        this.setState({
            showEditDialog: false
        });
        if (!id) return;
        this.props.onOk(id, name, phone, email, comment);
    };

    handleEditDialogCancel = () => {
        this.setState({
            showEditDialog: false
        });
    };

    render() {
        const props = this.props;
        const state = this.state;
        const dialogClasses = "selectContact select px400" + (props.classes ? " " + props.classes : "");
        return (
            <div>
                <SelectIdTitleDialog
                    classes={dialogClasses}
                    filter={props.name}
                    title={Translations.SelectContact}
                    selectedId={state.selectedId}
                    listbox={false}
                    autoFilter={true}
                    loadIdCodes={this.handleLoadContacts}
                    onAdd={this.handleAddContact}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                />
                {state.showEditDialog &&
                    <SettingsContactDialog
                        editItem={state.editItem}
                        onOk={this.handleEditDialogOk}
                        onCancel={this.handleEditDialogCancel}
                    />
                }
            </div>
        );
    }
}