import HelpIcon from "@mui/icons-material/Help";
import { Link, Stack, Typography } from "@mui/material";
import { t } from "i18next";
import React from "react";
import { MainLayout } from "../layout/mainLayout";

export const FrontPageMain = () => {
    return (
        <MainLayout>
            <div style={{ display: "block", margin: "0 auto", maxWidth: "1500px" }}>
                <Stack>

                    <Stack spacing={1} alignSelf="end" alignItems="end">
                        <div>
                            <Link href="https://easyopp.atlassian.net/servicedesk/" >
                                <Typography variant="h3" m="0" sx={{ fontStyle: "italic", color: "primary.dark" }}>
                                    <HelpIcon sx={{ mb: "3px" }} /> {t("supportPortal")}
                                </Typography>
                            </Link>
                        </div>
                        <div>
                            <Link href="mailto:asiakaspalvelu@easyopp.fi" >
                                <Typography variant="h3" m="0" sx={{ color: "primary.dark" }}>
                                    asiakaspalvelu@easyopp.fi
                                </Typography>
                            </Link>
                        </div>
                    </Stack>

                    <Stack spacing={5} alignSelf="center" width="100%" maxWidth="700px">
                        <img
                            src={appConfig.ownRoot + "EasyOpp.svg"}
                            alt="EasyOpp - Decorative logo"
                        />
                        <Typography
                            variant="h3"
                            fontSize="32px"
                            sx={{
                                width: "100%",
                                pl: "23%",
                                textAlign: "center",
                                fontStyle: "italic",
                                color: "primary.dark" }}
                        >
                            {t("greeting")}
                        </Typography>
                    </Stack>

                </Stack>
            </div>
        </MainLayout>
    );
};