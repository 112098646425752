import * as React from "react";
import * as store from "../../framework/customStore";
import * as storeActions from "../../models/store/storeActions";
import * as settingsService from "../../services/settingsService";
import { Translations } from "../../models/translations";
import { SettingsCustomersList } from "./settingsCustomersList";
import { SettingsEmployeeList } from "./settingsEmployeeList";
import { SettingsContactList } from "./settingsContactList";
import { SettingsVehicleList } from "./settingsVehicleList";
import { SettingsToolList } from "./settingsToolList";
import { SettingsProductList } from "./settingsProductList";
import { SettingsCompetencyList } from "./settingsCompetencyList";
import { SettingsEmployeeCompetencyList } from "./settingsEmployeCompetencyList";
import { SettingsCustomerSitesList } from "./settingsCustomerSitesList";
import { SettingsCostCenterList } from "./settingsCostCenterList";
import { SettingsCustomerTypeList } from "./settingsCustomerTypeList";
import { SettingsEmployeeGroupList } from "./settingsEmployeeGroupList";
import { SettingsVehicleGroupList } from "./settingsVehicleGroupList";
import { SettingsProductGroupList } from "./settingsProductGroupList";
import { IEmployeeParameters } from "../../models/employee/employeeIParameters";
import { IOwnerState, IUserState } from "../../models/store/storeTypes";
import { handleApiError } from "../../models/store/storeEffects";
import { RouteComponentProps } from "react-router-dom";
import { SettingsWorkTimeTypeList } from "./settingsWorkTimeTypeList";

/* eslint-disable no-unused-vars */
export const enum SettingsMainMenu {
    Customers = "customers",
    CustomerSites = "customersites",
    Vehicles = "vehicles",
    Competencies = "competencies",
    Products = "products",
    Tools = "tools",
    Employees = "employees",
    EmployeeCompetencies = "employeecompetencies",
    Contacts = "contacts",
    CostCenters = "costcenters",
    CustomerTypes = "customertypes",
    EmployeeGroups = "employeegroups",
    VehicleGroups = "vehiclegroups",
    ProductGroups = "productgroups",
    WorkTimeTypes = "worktimetypes"
}

export const enum SettingsMainSubMenu {
    Customers = "customers",
    Products = "products",
    Employees = "employees",
    Vehicles = "vehicles",
    WorkTimeTypes = "worktimetypes"
}
/* eslint-enable no-unused-vars */

export interface ISettingsMainOwnProps {
}

export interface ISettingsMainStateProps {
    owner: IOwnerState;
    user: IUserState;
}

export interface ISettingsMainDispatchProps {
    setEmployeeParameters: (parameters: IEmployeeParameters, saveToDb: boolean) => void;
    onLogout: () => void;
}

export interface ISettingsMainPathProps {
    submenu: SettingsMainSubMenu;
    menu: SettingsMainMenu;
}

type SettingsMainProp = ISettingsMainOwnProps & ISettingsMainStateProps & ISettingsMainDispatchProps & RouteComponentProps<ISettingsMainPathProps>;

interface ISettingsMainState {
    settingsMenuItem: SettingsMainMenu;
    activeSubMenu: SettingsMainSubMenu;
    isLoading: boolean;
}

export class SettingsMain extends React.Component<SettingsMainProp, ISettingsMainState> {
    constructor(props) {
        super(props);
        //console.log("props.match.params", props.match.params.submenu, props.match.params.menu);
        const subMenu = props.match.params.submenu ? props.match.params.submenu as SettingsMainSubMenu : SettingsMainSubMenu.Customers;
        const menu = props.match.params.menu ? props.match.params.menu as SettingsMainMenu : SettingsMainMenu.Customers;
        this.state = {
            settingsMenuItem: menu,
            activeSubMenu: subMenu,
            isLoading: false,
        };
    }

    componentDidMount(): void {
        this.getSettingsMain();
    }

    getSettingsMain = (): Promise<void> => {
        const obj = this;
        obj.setState({
            isLoading: true
        });
        store.customStore.dispatch(storeActions.fetchStart());
        return settingsService.getSettingsMain()
            .then(
                settingsMain => {
                    store.customStore.dispatch(storeActions.setUserParameters(settingsMain.employeeParameters));
                },
                error => {
                    handleApiError(error, store.customStore.dispatch);
                })
            .finally(() => {
                obj.setState({
                    isLoading: false
                });
                store.customStore.dispatch(storeActions.fetchEnd());
            });
    };

    handleMenuItemClick = (activeSubMenu: SettingsMainSubMenu) => {
        this.setState({ activeSubMenu: activeSubMenu });
    };

    handleSubMenuClick = (settingsMenuItem: SettingsMainMenu) => {
        this.setState({ settingsMenuItem: settingsMenuItem });
    };

    render() {
        const state = this.state;
        return (
            <div>
                <div className="row mainContent settingsMain">
                    <div className="col-2">
                        <div className="backgroundBoard fullHeight">
                            <div className="panel verticalMenu">
                                <div className={"menuItem" + (state.activeSubMenu === SettingsMainSubMenu.Customers ? " active" : "")} onClick={() => { this.handleMenuItemClick(SettingsMainSubMenu.Customers); }}>{Translations.Customers}</div>
                                {state.activeSubMenu === SettingsMainSubMenu.Customers &&
                                    <div className="sub">
                                        <div className={"menuItem" + (state.settingsMenuItem === SettingsMainMenu.CustomerTypes ? " active" : "")} onClick={() => { this.handleSubMenuClick(SettingsMainMenu.CustomerTypes); }} >{Translations.CustomerTypes}</div>
                                        <div className={"menuItem" + (state.settingsMenuItem === SettingsMainMenu.Customers ? " active" : "")} onClick={() => { this.handleSubMenuClick(SettingsMainMenu.Customers); }} >{Translations.Customers}</div>
                                        <div className={"menuItem" + (state.settingsMenuItem === SettingsMainMenu.CustomerSites ? " active" : "")} onClick={() => { this.handleSubMenuClick(SettingsMainMenu.CustomerSites); }} >{Translations.CustomerSites}</div>
                                        <div className={"menuItem" + (state.settingsMenuItem === SettingsMainMenu.Contacts ? " active" : "")} onClick={() => { this.handleSubMenuClick(SettingsMainMenu.Contacts); }}>{Translations.Contacts}</div>
                                    </div>
                                }
                                <div className={"menuItem" + (state.activeSubMenu === SettingsMainSubMenu.Products ? " active" : "")} onClick={() => { this.handleMenuItemClick(SettingsMainSubMenu.Products); }}>{Translations.Products}</div>
                                {state.activeSubMenu === SettingsMainSubMenu.Products &&
                                    <div className="sub">
                                        <div className={"menuItem" + (state.settingsMenuItem === SettingsMainMenu.ProductGroups ? " active" : "")} onClick={() => { this.handleSubMenuClick(SettingsMainMenu.ProductGroups); }}>{Translations.ProductGroups}</div>
                                        <div className={"menuItem" + (state.settingsMenuItem === SettingsMainMenu.Products ? " active" : "")} onClick={() => { this.handleSubMenuClick(SettingsMainMenu.Products); }}>{Translations.Products}</div>
                                    </div>
                                }
                                <div className={"menuItem" + (state.activeSubMenu === SettingsMainSubMenu.Employees ? " active" : "")} onClick={() => { this.handleMenuItemClick(SettingsMainSubMenu.Employees); }}>{Translations.Employees}</div>
                                {state.activeSubMenu === SettingsMainSubMenu.Employees &&
                                    <div className="sub">
                                        <div className={"menuItem" + (state.settingsMenuItem === SettingsMainMenu.CostCenters ? " active" : "")} onClick={() => { this.handleSubMenuClick(SettingsMainMenu.CostCenters); }} >{Translations.CostCenters}</div>
                                        <div className={"menuItem" + (state.settingsMenuItem === SettingsMainMenu.EmployeeGroups ? " active" : "")} onClick={() => { this.handleSubMenuClick(SettingsMainMenu.EmployeeGroups); }} >{Translations.EmployeeGroups}</div>
                                        <div className={"menuItem" + (state.settingsMenuItem === SettingsMainMenu.Employees ? " active" : "")} onClick={() => { this.handleSubMenuClick(SettingsMainMenu.Employees); }} >{Translations.Employees}</div>
                                        <div className={"menuItem" + (state.settingsMenuItem === SettingsMainMenu.Competencies ? " active" : "")} onClick={() => { this.handleSubMenuClick(SettingsMainMenu.Competencies); }}>{Translations.Competencies}</div>
                                        <div className={"menuItem" + (state.settingsMenuItem === SettingsMainMenu.EmployeeCompetencies ? " active" : "")} onClick={() => { this.handleSubMenuClick(SettingsMainMenu.EmployeeCompetencies); }}>{Translations.EmployeeCompetencies}</div>
                                    </div>
                                }
                                <div className={"menuItem" + (state.activeSubMenu === SettingsMainSubMenu.Vehicles ? " active" : "")} onClick={() => { this.handleMenuItemClick(SettingsMainSubMenu.Vehicles); }}>{Translations.Vehicles}</div>
                                {state.activeSubMenu === SettingsMainSubMenu.Vehicles &&
                                    <div className="sub">
                                        <div className={"menuItem" + (state.settingsMenuItem === SettingsMainMenu.VehicleGroups ? " active" : "")} onClick={() => { this.handleSubMenuClick(SettingsMainMenu.VehicleGroups); }} >{Translations.VehicleGroups}</div>
                                        <div className={"menuItem" + (state.settingsMenuItem === SettingsMainMenu.Vehicles ? " active" : "")} onClick={() => { this.handleSubMenuClick(SettingsMainMenu.Vehicles); }} >{Translations.Vehicles}</div>
                                        <div className={"menuItem" + (state.settingsMenuItem === SettingsMainMenu.Tools ? " active" : "")} onClick={() => { this.handleSubMenuClick(SettingsMainMenu.Tools); }} >{Translations.Tools}</div>
                                    </div>
                                }
                                <div className={"menuItem" + (state.activeSubMenu === SettingsMainSubMenu.WorkTimeTypes ? " active" : "")} onClick={() => { this.handleSubMenuClick(SettingsMainMenu.WorkTimeTypes); this.handleMenuItemClick(SettingsMainSubMenu.WorkTimeTypes);}}>{Translations.WorkTimeTypes}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-10 ">
                        <div className="backgroundBoard">
                            <div className="panel">
                                {state.settingsMenuItem === SettingsMainMenu.Customers &&
                                <SettingsCustomersList />
                                    }
                                {state.settingsMenuItem === SettingsMainMenu.CustomerSites &&
                                <SettingsCustomerSitesList />
                                    }
                                {state.settingsMenuItem === SettingsMainMenu.Vehicles &&
                                <SettingsVehicleList />
                                    }
                                {state.settingsMenuItem === SettingsMainMenu.VehicleGroups &&
                                <SettingsVehicleGroupList />
                                    }
                                {state.settingsMenuItem === SettingsMainMenu.CostCenters &&
                                <SettingsCostCenterList />
                                    }
                                {state.settingsMenuItem === SettingsMainMenu.Competencies &&
                                <SettingsCompetencyList />
                                    }
                                {state.settingsMenuItem === SettingsMainMenu.Products &&
                                <SettingsProductList />
                                    }
                                {state.settingsMenuItem === SettingsMainMenu.ProductGroups &&
                                <SettingsProductGroupList />
                                    }
                                {state.settingsMenuItem === SettingsMainMenu.Tools &&
                                <SettingsToolList />
                                    }
                                {state.settingsMenuItem === SettingsMainMenu.Employees &&
                                <SettingsEmployeeList
                                    setEmployeeParameters={this.props.setEmployeeParameters}
                                />
                                    }
                                {state.settingsMenuItem === SettingsMainMenu.EmployeeCompetencies &&
                                <SettingsEmployeeCompetencyList />
                                    }
                                {state.settingsMenuItem === SettingsMainMenu.EmployeeGroups &&
                                <SettingsEmployeeGroupList />
                                    }
                                {state.settingsMenuItem === SettingsMainMenu.Contacts &&
                                <SettingsContactList />
                                    }
                                {state.settingsMenuItem === SettingsMainMenu.CustomerTypes &&
                                <SettingsCustomerTypeList />
                                    }
                                {state.settingsMenuItem === SettingsMainMenu.WorkTimeTypes &&
                                    <SettingsWorkTimeTypeList />
                                    }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
