// SettingsContactDialogCustomerList - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import * as customerService from "../../services/customerService";
import { Translations } from "../../models/translations";
import { ToolButton } from "../framework/toolButton";
import { SelectIdTitleDialog } from "../framework/selectIdTitleDialog";
import { IEndlessList, EndlessList } from "../../models/common/endlessList";
import { IdTitle, IIdTitle } from "../../models/common/idTitle";
import { IContactEditItemCustomerContactItem } from "../../models/contact/contactEditItemCustomerContactItem";

// SettingsContactDialogCustomerListLine
export interface ISettingsContactDialogCustomerListLineProp {
    item: IContactEditItemCustomerContactItem;
    selectedId: string;
    onClick: (id: string) => void;
}

export class SettingsContactDialogCustomerListLine extends React.Component<ISettingsContactDialogCustomerListLineProp, {}> {
    render() {
        const item = this.props.item;
        return (
            <div className={"row line" + (item.id === this.props.selectedId ? " selected" : "")} onClick={() => { this.props.onClick(item.id); }} title={item.title } >
                <div className="col-12">{item.title}</div>
            </div>
        );
    }
}

// SettingsContactDialogCustomerList
export interface ISettingsContactDialogCustomerListProp {
    title?: string;
    readOnly: boolean;
    items: IContactEditItemCustomerContactItem[];
    onRemoveCustomer: (id: string) => void;
    onAddCustomer: (customerId: string, title: string) => void;
}

interface ISettingsContactDialogCustomerListState {
    selectedId: string;
    showSelectCustomerDialog: boolean;
    selectCustomerDialogItems: IIdTitle[];
}

export class SettingsContactDialogCustomerList extends React.Component<ISettingsContactDialogCustomerListProp, ISettingsContactDialogCustomerListState> {
    constructor(props) {
        super(props);
        this.state = { selectedId: null, showSelectCustomerDialog: false, selectCustomerDialogItems: [] };
    }

    handleAdd = () => {
        const obj = this;
        customerService.getCustomerIdTitles(obj.props.items.map(i => i.customerId)).then(idTitleItems => {
            obj.setState({
                showSelectCustomerDialog: true,
                selectCustomerDialogItems: idTitleItems.items
            });
        });
    };

    handleLoadCustomers = (page: number, filter: string): Promise<IEndlessList<IIdTitle>> => {
        const obj = this;
        const lowFilter = filter.toLowerCase();
        return new Promise<IEndlessList<IdTitle>>((resolve, reject) => {
            const result = new EndlessList<IdTitle>(null, IdTitle);
            result.items = !lowFilter ? obj.state.selectCustomerDialogItems : obj.state.selectCustomerDialogItems.filter(i => i.title.toLowerCase().indexOf(lowFilter) > -1);
            result.page = 1;
            result.hasMore = false;
            console.log(result.items);
            resolve(result);
        });
    };

    handleSelectCustomerDialogOk = (customerId: string, title: string) => {
        const obj = this;
        if (!customerId) return;
        this.props.onAddCustomer(customerId, title);
        obj.setState({
            showSelectCustomerDialog: false
        });
    };

    handleSelectCustomerDialogCancel = () => {
        this.setState({
            showSelectCustomerDialog: false
        });
    };

    handleRemove = () => {
        const selectedId = this.state.selectedId;
        if (!selectedId) return;
        this.props.onRemoveCustomer(selectedId);
    };

    handleLineClick = (id: string) => {
        this.setState({
            selectedId: id
        });
    };

    render() {
        const props = this.props;
        const items = props.items;
        return (
            <div>
                <div className="commandRow">
                    {!!props.title &&
                        <label className="control-label listTitle">{props.title}</label>
                    }
                    <ToolButton
                        title={Translations.Remove}
                        enabled={!props.readOnly && !!this.state.selectedId}
                        classes={"round right remove"}
                        onClick={this.handleRemove}
                    />
                    <ToolButton
                        title={Translations.Add}
                        enabled={!this.props.readOnly}
                        classes={"round right add"}
                        onClick={this.handleAdd}
                    />
                </div>
                <div className="listContainer customerContactsContainer">
                    {items.length > 0 &&
                        <div className="list customerContacts">
                            {items.map((item) =>
                                <SettingsContactDialogCustomerListLine
                                    key={item.id}
                                    item={item}
                                    selectedId={this.state.selectedId}
                                    onClick={this.handleLineClick}
                                />
                            )}
                        </div>
                    }
                </div>
                {this.state.showSelectCustomerDialog &&
                    <SelectIdTitleDialog
                        title={Translations.SelectCustomer}
                        selectedId={null}
                        listbox={true}
                        loadIdCodes={this.handleLoadCustomers}
                        onOk={this.handleSelectCustomerDialogOk}
                        onCancel={this.handleSelectCustomerDialogCancel}
                    />
                }
            </div>
        );
    }
}