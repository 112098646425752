// SettingsSiteRouteDialog - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import { LatLngExpression, LatLngBounds, latLngBounds, divIcon, Point, DivIcon, Map as LeafletMap } from "leaflet";
import { MapContainer, Marker, TileLayer, LayersControl } from "react-leaflet";
import * as store from "../../framework/customStore";
import * as storeActions from "../../models/store/storeActions";
import * as locationService from "../../services/locationService";
import { PropertyDialog } from "../framework/dialog";
import { Translations } from "../../models/translations";
import { IRoutePointCheckEditItem, RoutePointCheckEditItem } from "../../models/routePointCheck/routePointCheckEditItem";
import { SaveData } from "../../framework/saveData";
import { IRoutePointItem, RoutePointItem } from "../../models/routePoint/routePointItem";
import { Button } from "../framework/button";
import { ConfirmationDialogType } from "../../models/store/storeTypes";
import { SettingsRoutePointDialogRoutePointCheckList } from "./settingsRoutePointDialogRoutePointCheckList";
import { Base } from "../../framework/base";
import { ToolButton } from "../framework/toolButton";
import { GeocodeResult } from "../../framework/geocodeResult";
import { RoutePointDragData } from "../../models/routePoint/routePointDragData";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import { createRef } from "react";

// SettingsSiteRouteDialogRoutePointAdd
export interface ISettingsSiteRouteDialogRoutePointAddProp {
    mapLinkTemplate: string;
    item: IRoutePointItem;
    onOk: (item: IRoutePointItem) => void;
    onCancel: () => void;
}

interface ISettingsSiteRouteDialogRoutePointAddState {
    value: string;
}

export class SettingsSiteRouteDialogRoutePointAdd extends React.Component<ISettingsSiteRouteDialogRoutePointAddProp, ISettingsSiteRouteDialogRoutePointAddState> {
    constructor(props: ISettingsSiteRouteDialogRoutePointAddProp) {
        super(props);
        this.state = {
            value: "",
        };
    }

    componentDidMount(): void {
        const routePointAdd = document.getElementById("routePointAdd") as HTMLDivElement;
        if (!routePointAdd) return;
        const routePointsContent = document.getElementById("routePointsContent");
        if (!routePointsContent) return;
        routePointsContent.scroll({ top: routePointAdd.offsetTop + routePointAdd.offsetHeight, behavior: "smooth" });
    }

    handleChange = (event) => {
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        if (name === "value") {
            this.setState({ value: value });
        }
    };

    private static validate = (state: ISettingsSiteRouteDialogRoutePointAddState): boolean => {
        if (!state.value) {
            store.customStore.dispatch(storeActions.showErrorMessage(Translations.NameMustBeDefined));
            return false;
        }
        return true;
    };

    geocodeFreeText = (text: string): Promise<GeocodeResult> => {
        return locationService.geocodeFreeText(text)
            .then(result => {
                return result;
            })
            .catch(error => {
                console.log(error);
                return new Promise<GeocodeResult>((resolve) => { resolve(null); });
            });
    };

    handleOkClick = () => {
        const props = this.props;
        const state = this.state;
        if (!SettingsSiteRouteDialogRoutePointAdd.validate(state)) return;
        const item = new RoutePointItem(this.props.item);
        item.name = state.value;
        item.name2 = "";
        item.street = "";
        item.postalCode = "";
        item.city = "";
        item.countryCode = "";
        item.mapLink = "";
        item.latitude = null;
        item.longitude = null;
        item.checks = [];
        this.geocodeFreeText(state.value).then(geocodeResult => {
            if (geocodeResult) {
                item.latitude = geocodeResult.latitude;
                item.longitude = geocodeResult.longitude;
                item.street = geocodeResult.street;
                item.postalCode = geocodeResult.postalCode;
                item.city = geocodeResult.city;
                item.countryCode = geocodeResult.countryCode;
                item.mapLink = Base.generateMapLink(props.mapLinkTemplate, geocodeResult.latitude.toFixed(6), geocodeResult.longitude.toFixed(6), null);
            } else if (!!item.street || !!item.postalCode || !!item.city) {
                item.mapLink = Base.generateMapLink(props.mapLinkTemplate, item.street, item.postalCode, item.city);
            }
            this.props.onOk(item);
        });
    };

    handleCancelClick = () => {
        const obj = this;
        if (this.state.value) {
            store.customStore.dispatch(storeActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, Translations.YouHaveNotSavedChangesDoYouWantToSaveChanges,
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                    obj.handleOkClick();
                },
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                    obj.props.onCancel();
                },
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                }));
        } else {
            obj.props.onCancel();
        }
    };

    handleKeyUp = (e) => {
        if (e.keyCode === 13) {
            this.handleOkClick();
            window.getSelection().removeAllRanges();
            e.preventDefault();
        }
    };

    render() {
        const state = this.state;
        return (
            <div id="routePointAdd" className="routePoint">
                <div className="row">
                    <div className="col-12">
                        <div className="form-group required">
                            <ToolButton
                                classes={"close smallButton right"}
                                enabled={true}
                                stopPropagation={true}
                                title={Translations.Cancel}
                                onClick={this.handleCancelClick}
                            />
                            <label className="control-label smallFont">{Translations.Address}</label>
                            <input type="text" className="form-control" name="value" title={Translations.Address} value={state.value} autoFocus={true} onKeyUp={this.handleKeyUp} onChange={this.handleChange} maxLength={250} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

// SettingsSiteRouteDialogRoutePointEdit
export interface ISettingsSiteRouteDialogRoutePointEditProp {
    mapLinkTemplate: string;
    showAdditionalInformation: boolean;
    item: IRoutePointItem;
    removedCheckIds: string[];
    savedCheckIds: string[];
    onOk: (item: IRoutePointItem, removedCheckIds: string[], savedCheckIds: string[]) => void;
    onCancel: () => void;
}

interface ISettingsSiteRouteDialogRoutePointEditState {
    name: string;
    name2: string;
    street: string;
    postalCode: string;
    city: string;
    countryCode: string;
    mapLink: string;
    longitudeStr: string;
    latitudeStr: string;
    checks: IRoutePointCheckEditItem[];
    removedCheckIds: string[];
    savedCheckIds: string[];
}

export class SettingsSiteRouteDialogRoutePointEdit extends React.Component<ISettingsSiteRouteDialogRoutePointEditProp, ISettingsSiteRouteDialogRoutePointEditState> {
    private static orgStateHash: string = "";

    private static getHashDataFromState = (state: ISettingsSiteRouteDialogRoutePointEditState): string => {
        const data = new SaveData();
        data.append("name", state.name);
        data.append("name2", state.name2);
        data.append("street", state.street);
        data.append("postalCode", state.postalCode);
        data.append("city", state.city);
        data.append("countryCode", state.countryCode);
        data.append("mapLink", state.mapLink);
        data.append("longitudeStr", state.longitudeStr);
        data.append("latitudeStr", state.latitudeStr);
        data.append("checks", JSON.stringify(state.checks));
        data.append("removedCheckIds", JSON.stringify(state.removedCheckIds));
        data.append("savedCheckIds", JSON.stringify(state.savedCheckIds));
        return data.hash;
    };

    constructor(props: ISettingsSiteRouteDialogRoutePointEditProp) {
        super(props);
        const item = props.item;
        this.state = {
            name: item.name,
            name2: item.name2,
            street: item.street,
            postalCode: item.postalCode,
            city: item.city,
            countryCode: item.countryCode,
            mapLink: item.mapLink,
            longitudeStr: item.longitude ? item.longitude.toLocaleFixed(6) : "",
            latitudeStr: item.latitude ? item.latitude.toLocaleFixed(6) : "",
            checks: item.checks.slice(0),
            removedCheckIds: props.removedCheckIds.slice(0),
            savedCheckIds: props.savedCheckIds.slice(0),
        };
        SettingsSiteRouteDialogRoutePointEdit.orgStateHash = SettingsSiteRouteDialogRoutePointEdit.getHashDataFromState(this.state);
    }

    handleChange = (event) => {
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        if (name === "Nm") {
            this.setState({ name: value });
        } else if (name === "Nm2") {
            this.setState({ name2: value });
        } else if (name === "Ads") {
            this.setState({ street: value });
        } else if (name === "pC") {
            this.setState({ postalCode: value });
        } else if (name === "cty") {
            this.setState({ city: value });
        } else if (name === "cntrCd") {
            this.setState({ countryCode: value });
        }
    };

    // #region RoutePointChecks
    handleRemoveRoutePointCheck = (id: string) => {
        const state = this.state;
        if (!id) return;
        const item = state.checks.find(i => i.id === id);
        if (!item) return;
        const removedCheckIds = state.removedCheckIds.filter(i => i !== id);
        if (!item.isNew()) {
            removedCheckIds.push(id);
        }
        this.setState({ checks: state.checks.filter(i => i.id !== id), savedCheckIds: state.savedCheckIds.filter(i => i !== id), removedCheckIds: removedCheckIds });
    };

    handleSaveRoutePointCheck = (item: IRoutePointCheckEditItem) => {
        const state = this.state;
        if (!item || !item.id) return;
        const checks = state.checks.filter(i => i.id !== item.id);
        const savedCheckIds = state.savedCheckIds.filter(i => i !== item.id);
        checks.push(item);
        savedCheckIds.push(item.id);
        RoutePointCheckEditItem.sortRoutePointCheckEditItems(checks, "number", true);
        this.setState({ checks: checks, savedCheckIds: savedCheckIds });
    };

    handleMoveRoutePointCheck = (id: string, step: number) => {
        const state = this.state;
        if (!id) return;
        //RoutePoints
        const checks = state.checks.slice(0);
        const index = checks.findIndex(i => i.id === id);
        if (index < 0 || index + step < 0 || index + step > checks.length - 1) return;
        const item = checks[index];
        const id2 = checks[index + step].id;
        checks[index] = checks[index + step];
        checks[index + step] = item;
        checks[index].number = index + 1;
        checks[index + step].number = index + step + 1;
        const savedCheckIds = state.savedCheckIds.filter(i => i !== id && i !== id2);
        savedCheckIds.push(id);
        savedCheckIds.push(id2);
        RoutePointCheckEditItem.sortRoutePointCheckEditItems(checks, "number", true);
        this.setState({
            checks: checks,
            savedCheckIds: savedCheckIds
        });
    };
    // #endregion RoutePointChecks

    private static validate = (state: ISettingsSiteRouteDialogRoutePointEditState): boolean => {
        if (!state.name) {
            store.customStore.dispatch(storeActions.showErrorMessage(Translations.NameMustBeDefined));
            return false;
        }
        return true;
    };

    geocodeAddress = (): Promise<GeocodeResult> => {
        const state = this.state;
        const props = this.props;
        const item = props.item;
        if ((item.street === state.street && item.postalCode === state.postalCode && item.city === state.city && item.countryCode === state.countryCode) || (!state.street && !state.postalCode && !state.city && !state.countryCode)) {
            return new Promise<GeocodeResult>((resolve) => { resolve(null); });
        }
        return locationService.geocode(state.street, state.postalCode, state.city, state.countryCode)
            .then(result => {
                return result;
            })
            .catch(error => {
                console.log(error);
                return new Promise<GeocodeResult>((resolve) => { resolve(null); });
            });
    };

    handleOkClick = () => {
        const state = this.state;
        const props = this.props;
        if (!SettingsSiteRouteDialogRoutePointEdit.validate(state)) return;
        const item = new RoutePointItem(this.props.item);
        item.name = state.name;
        item.name2 = state.name2;
        item.street = state.street;
        item.postalCode = state.postalCode;
        item.city = state.city;
        item.countryCode = state.countryCode;
        item.mapLink = state.mapLink;
        item.latitude = state.latitudeStr ? state.latitudeStr.toDecimal() : null;
        item.longitude = state.longitudeStr ? state.longitudeStr.toDecimal() : null;
        item.checks = state.checks.slice(0);
        this.geocodeAddress().then(geocodeResult => {
            if (geocodeResult) {
                item.latitude = geocodeResult.latitude;
                item.longitude = geocodeResult.longitude;
                if (!item.street) {
                    item.street = geocodeResult.street;
                }
                if (!item.postalCode) {
                    item.postalCode = geocodeResult.postalCode;
                }
                if (!item.city) {
                    item.city = geocodeResult.city;
                }
                if (!item.countryCode) {
                    item.countryCode = geocodeResult.countryCode;
                }
                item.mapLink = Base.generateMapLink(props.mapLinkTemplate, geocodeResult.latitude.toFixed(6), geocodeResult.longitude.toFixed(6), null);
            } else {
                item.mapLink = Base.generateMapLink(props.mapLinkTemplate, state.street, state.postalCode, state.city);
            }
            this.props.onOk(item, state.removedCheckIds, state.savedCheckIds);
        });
    };

    handleCancelClick = () => {
        const obj = this;
        const hashData = SettingsSiteRouteDialogRoutePointEdit.getHashDataFromState(this.state);
        if (hashData !== SettingsSiteRouteDialogRoutePointEdit.orgStateHash) {
            store.customStore.dispatch(storeActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, Translations.YouHaveNotSavedChangesDoYouWantToSaveChanges,
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                    obj.handleOkClick();
                },
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                    obj.props.onCancel();
                },
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                }));
        } else {
            obj.props.onCancel();
        }
    };

    render() {
        const state = this.state;
        return (
            <div className="routePoint">
                <div className="buttonsBar">
                    <Button
                        classes={"btn-primary"}
                        title={Translations.OK}
                        enabled={true}
                        onClick={this.handleOkClick}
                    />
                    <Button
                        classes={"btn-default"}
                        title={Translations.Cancel}
                        enabled={true}
                        onClick={this.handleCancelClick}
                    />
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="form-group required">
                            <label className="control-label smallFont">{Translations.Name}</label>
                            <input type="text" className="form-control" name="Nm" title={Translations.Name} value={state.name} onChange={this.handleChange} maxLength={50} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="form-group">
                            <label className="control-label smallFont">{Translations.Specifier}</label>
                            <input type="text" className="form-control" name="Nm2" title={Translations.Specifier} value={state.name2} onChange={this.handleChange} maxLength={50} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="form-group">
                            <label className="control-label smallFont">{Translations.StreetAddress}</label>
                            <input type="text" className="form-control" name="Ads" title={Translations.StreetAddress} value={state.street} onChange={this.handleChange} maxLength={50} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-3">
                        <div className="form-group">
                            <label className="control-label smallFont">{Translations.PostalCode}</label>
                            <input type="text" className="form-control" name="pC" title={Translations.PostalCode} value={state.postalCode} onChange={this.handleChange} maxLength={10} />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-group">
                            <label className="control-label smallFont">{Translations.CityAddress}</label>
                            <input type="text" className="form-control" name="cty" title={Translations.CityAddress} value={state.city} onChange={this.handleChange} maxLength={50} />
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="form-group">
                            <label className="control-label smallFont">{Translations.CountryCode}</label>
                            <input type="text" className="form-control" name="cntrCd" title={Translations.CountryCode} value={state.countryCode} onChange={this.handleChange} maxLength={50} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <SettingsRoutePointDialogRoutePointCheckList
                            title={Translations.RoutePointChecks}
                            titleStyles={"smallFont"}
                            readOnly={false}
                            items={state.checks}
                            onRemoveRoutePointCheck={this.handleRemoveRoutePointCheck}
                            onAddRoutePointCheck={this.handleSaveRoutePointCheck}
                            onEditRoutePointCheck={this.handleSaveRoutePointCheck}
                            onMoveRoutePointCheck={this.handleMoveRoutePointCheck}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

// SettingsSiteRouteDialogRoutePoint
export interface ISettingsSiteRouteDialogRoutePointProp {
    showAdditionalInformation: boolean;
    readOnly: boolean;
    selected: boolean;
    draggedOver: boolean;
    item: IRoutePointItem;
    onClick: (id: string) => void;
    onEdit: (id: string) => void;
    onRemove: (id: string) => void;
    onMoveRoutePoint: (id: string, step: number) => void;
    onSetDraggedOverId: (id: string) => void;
}

export class SettingsSiteRouteDialogRoutePoint extends React.Component<ISettingsSiteRouteDialogRoutePointProp, {}> {
    handleClick = () => {
        const props = this.props;
        props.onClick(props.item.id);
    };

    handleEdit = () => {
        const props = this.props;
        if (props.readOnly) return;
        props.onEdit(props.item.id);
    };

    handleRemove = () => {
        const props = this.props;
        if (props.readOnly) return;
        props.onRemove(props.item.id);
    };

    // #region drag and drop
    onDragStart = (ev, id: string, number: number) => {
        RoutePointDragData.setRoutePointDragData(ev, id, number);
    };

    canDropInto = (dragData: RoutePointDragData): boolean => {
        const props = this.props;
        return props.item.id !== dragData.id;
    };

    onDragOver = (ev: React.DragEvent<HTMLDivElement>) => {
        const props = this.props;
        const dragData = RoutePointDragData.getRoutePointDragData(ev);
        if (!this.canDropInto(dragData)) return;
        console.log("onDragOver", props.item.id);
        ev.preventDefault();
        props.onSetDraggedOverId(props.item.id);
    };

    onDragLeave = (ev) => {
        const dragData = RoutePointDragData.getRoutePointDragData(ev);
        if (!this.canDropInto(dragData)) return;
        console.log("onDragLeave", this.props.item.id);
        ev.preventDefault();
        this.props.onSetDraggedOverId("");
    };

    onDrop = (ev: React.DragEvent<HTMLDivElement>) => {
        const props = this.props;
        const dragData = RoutePointDragData.getRoutePointDragData(ev);
        if (!this.canDropInto(dragData)) return;
        ev.preventDefault();
        if (dragData.number - props.item.number !== -1) {
            props.onMoveRoutePoint(props.item.id, dragData.number - props.item.number);
        }
        props.onSetDraggedOverId("");
    };
    // #endregion drag and drop

    render() {
        const props = this.props;
        const buttons = props.readOnly
            ? null
            : <div>
                <ToolButton
                    title={Translations.Remove}
                    enabled={true}
                    classes={"remove smallButton right"}
                    onClick={this.handleRemove}
                />
                <ToolButton
                    title={Translations.Edit}
                    enabled={true}
                    classes={"edit smallButton right"}
                    onClick={this.handleEdit}
                />
            </div>;
        return (
            <div className={"routePointContainer" + (props.draggedOver ? " draggedOver" : "")} onClick={this.handleClick}
                draggable={!props.readOnly} onDragStart={!props.readOnly ? (ev) => this.onDragStart(ev, props.item.id, props.item.number) : null}
                onDrop={this.onDrop} onDragOver={this.onDragOver} onDragLeave={this.onDragLeave}
            >
                <div className={"routePoint readOnly" + (props.selected ? " selected" : "")}>
                    {!props.showAdditionalInformation &&
                        <div>
                            {buttons}
                            <div className="readOnlyText">{props.item.name}</div>
                        </div>
                    }
                    {props.showAdditionalInformation &&
                        <div>
                            <div className="row">
                                <div className="col-12">
                                    {buttons}
                                    <label className="control-label smallFont">{Translations.Name}</label>
                                    <div className="readOnlyText">{props.item.name}</div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <label className="control-label smallFont">{Translations.Specifier}</label>
                                    <div className="readOnlyText">{props.item.name2}</div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div>
                                        <div className="right">
                                            {!!props.item.latitude && !!props.item.longitude &&
                                                <div><div className="mapMarker green smallMarker left" /><span>{Translations.LocationSuccess}</span></div>
                                            }
                                            {(!props.item.latitude || !props.item.longitude) &&
                                                <div><div className="mapMarker red smallMarker left" /><span>{Translations.LocationError}</span></div>
                                            }
                                        </div>
                                        <label className="control-label smallFont">{Translations.Address}</label>
                                    </div>
                                    <div className="readOnlyText">{Base.getStringWithSeparators([props.item.street, Base.getStringWithSeparators([props.item.postalCode, props.item.city], " "), props.item.countryCode], ", ")}</div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <SettingsRoutePointDialogRoutePointCheckList
                                        title={Translations.RoutePointChecks}
                                        titleStyles={"smallFont"}
                                        readOnly={true}
                                        hideButtons={true}
                                        items={props.item.checks}
                                        onRemoveRoutePointCheck={null}
                                        onAddRoutePointCheck={null}
                                        onEditRoutePointCheck={null}
                                        onMoveRoutePointCheck={null}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

// SettingsSiteRouteDialog
export interface ISettingsSiteRouteDialogProp {
    classes?: string;
    readOnly: boolean;
    siteName: string;
    mapLinkTemplate: string;
    items: IRoutePointItem[];
    removedRoutePointCheckIds: string[];
    savedRoutePointCheckIds: Map<string, string[]>;
    onRemoveRoutePoint: (id: string) => void;
    onAddRoutePoint: (item: IRoutePointItem, removedCheckIds: string[], savedCheckIds: string[]) => void;
    onEditRoutePoint: (item: IRoutePointItem, removedCheckIds: string[], savedCheckIds: string[]) => void;
    onMoveRoutePoint: (id: string, step: number) => void;
    onCancel: () => void;
}

interface ISettingsSiteRouteDialogState {
    showAdditionalInformation: boolean;
    selectedIds: string[];
    draggedOverId: string;
    editId: string;
    showAddNewRoutePoint: boolean;
    addNewRoutePointItem: IRoutePointItem;
    locationRoutePoints: IRoutePointItem[];
    mapBounds: LatLngBounds;
    locationHash: string;
    prevLocationHash: string;
}

export class SettingsSiteRouteDialog extends React.Component<ISettingsSiteRouteDialogProp, ISettingsSiteRouteDialogState> {
    defaultPosition: LatLngExpression;
    mapRef = createRef<LeafletMap>();

    getMapBounds = (locationRoutePoints: IRoutePointItem[]): LatLngBounds => {
        let result: LatLngBounds;
        if (locationRoutePoints.length > 0) {
            result = latLngBounds([
                { lat: locationRoutePoints[0].latitude, lng: locationRoutePoints[0].longitude },
                { lat: locationRoutePoints[0].latitude, lng: locationRoutePoints[0].longitude }
            ]);
            for (let i = 1; i < locationRoutePoints.length; i++) {
                result.extend({ lat: locationRoutePoints[i].latitude, lng: locationRoutePoints[i].longitude });
            }
        } else {
            result = latLngBounds([this.defaultPosition, this.defaultPosition]);
        }
        return result;
    };

    constructor(props) {
        super(props);
        this.defaultPosition = { lat: 60.171976, lng: 24.941447 };
        const locationRoutePoints = props.items.filter(i => i.hasLocation());
        const locationHash = locationRoutePoints.map(i => i.getLocationHash()).join("#");
        //const dataId = locationHash;
        this.state = {
            showAdditionalInformation: false,
            selectedIds: [],
            draggedOverId: "",
            editId: "",
            showAddNewRoutePoint: false,
            addNewRoutePointItem: null,
            mapBounds: this.getMapBounds(locationRoutePoints),
            locationRoutePoints: locationRoutePoints,
            locationHash: locationHash,
            prevLocationHash: locationHash
        };
    }

    componentDidUpdate(prevProps: ISettingsSiteRouteDialogProp, prevState: ISettingsSiteRouteDialogState): void {
        const preVocationHash = this.state.locationHash;
        const locationRoutePoints = this.props.items.filter(i => i.hasLocation());
        const locationHash = locationRoutePoints.map(i => i.getLocationHash()).join("#");
        if (preVocationHash === locationHash) return;
        //console.log("preVocationHash", preVocationHash);
        //console.log("locationHash", locationHash);
        const bounds = this.getMapBounds(locationRoutePoints);
        this.setState({
            prevLocationHash: preVocationHash,
            locationHash: locationHash,
            locationRoutePoints: locationRoutePoints,
            mapBounds: bounds
        });

        if (bounds) {
            this.mapRef.current?.fitBounds(bounds, { padding: [50, 50] });
        }
    }

    handleToggleShowAdditionalInformation = (e) => {
        e.preventDefault();
        this.setState({ showAdditionalInformation: !this.state.showAdditionalInformation });
    };

    handleClickRoutePoint = (id: string) => {
        this.setState({ selectedIds: [id] });
    };

    handleSetDraggedOverId = (id: string) => {
        this.setState({ draggedOverId: id });
    };

    handleAddRoutePoint = (e) => {
        e.preventDefault();
        const props = this.props;
        if (props.readOnly) return;
        const addNewRoutePointItem = new RoutePointItem();
        addNewRoutePointItem.id = Base.getGuid();
        addNewRoutePointItem.number = props.items.length > 0 ? props.items.reduce((a, b) => { return Math.max(a, b.number); }, 0) + 1 : 1;
        this.setState({
            showAddNewRoutePoint: true,
            addNewRoutePointItem: addNewRoutePointItem
        });
    };

    handleEditRoutePoint = (id: string) => {
        this.setState({ editId: id, selectedIds: [id] });
    };

    handleRemoveRoutePoint = (id: string) => {
        const props = this.props;
        if (!id) return;
        const selectedItem = this.props.items.find(i => i.id === id);
        if (!selectedItem) return;
        store.customStore.dispatch(storeActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, String.format(Translations.RoutePointRemoveConfirmation, selectedItem.name), () => {
            store.customStore.dispatch(storeActions.clearConfirmation());
            props.onRemoveRoutePoint(id);
        }, () => {
            store.customStore.dispatch(storeActions.clearConfirmation());
        }));
    };

    handleRoutePointAddOk = (item: IRoutePointItem) => {
        const props = this.props;
        const addNewRoutePointItem = this.state.addNewRoutePointItem;
        if (!addNewRoutePointItem) return;
        this.setState({
            showAddNewRoutePoint: false,
        });
        props.onAddRoutePoint(item, [], []);
    };

    handleRoutePointAddCancel = () => {
        this.setState({
            showAddNewRoutePoint: false,
        });
    };

    handleRoutePointEditOk = (item: IRoutePointItem, removedCheckIds: string[], savedCheckIds: string[]) => {
        const props = this.props;
        const editId = this.state.editId;
        if (!editId) return;
        const editItem = this.props.items.find(i => i.id === editId);
        if (!editItem) return;
        this.setState({
            editId: ""
        });
        props.onEditRoutePoint(item, removedCheckIds, savedCheckIds);
    };

    handleRoutePointEditCancel = () => {
        this.setState({
            editId: ""
        });
    };

    handleCancelClick = () => {
        this.props.onCancel();
    };

    selectWorkOrdersByCoordinateHash = (hash: string, layerPoint: Point, containerPoint: Point, originalEvent: MouseEvent) => {
        const selectedLocationRoutePointIds: string[] = [];
        const state = this.state;
        for (let i = 0; i < state.locationRoutePoints.length; i++) {
            if (state.locationRoutePoints[i].getLocationHash() !== hash) continue;
            selectedLocationRoutePointIds.push(state.locationRoutePoints[i].id);
        }
        this.setState({
            selectedIds: selectedLocationRoutePointIds
        });
    };

    getMarkerIcon = (classes: string): DivIcon => {
        return divIcon({
            className: "mapMarker " + classes,
            iconSize: new Point(36, 36),
            iconAnchor: new Point(18, 36),
            html: "<div></div>"
        });
    };

    render() {
        const obj = this;
        const props = this.props;
        const state = this.state;
        const dialogClasses = "siteRoute px800" + (props.classes ? " " + props.classes : "");
        const blueMarkerIcon = this.getMarkerIcon("blue");
        const redMarkerIcon = this.getMarkerIcon("red");
        return (
            <div>
                <PropertyDialog
                    classes={dialogClasses}
                    title={Translations.Route + " - " + props.siteName}
                    show={true}
                    body={<div>
                        <div className="maxHeightContent">
                            <div className="row">
                                <div className="col-7">
                                    <div className="routePointsHeader"><a className="readOnlyLink" onClick={this.handleToggleShowAdditionalInformation}>{this.state.showAdditionalInformation ? Translations.HideAdditionalInformation : Translations.ShowAdditionalInformation}</a></div>
                                    <div id="routePointsContent" className="routePointsContainer">
                                        {props.items.map((item) =>
                                            (item.id !== state.editId
                                                ? <SettingsSiteRouteDialogRoutePoint
                                                        key={item.id}
                                                        showAdditionalInformation={state.showAdditionalInformation}
                                                        readOnly={props.readOnly}
                                                        selected={state.selectedIds.indexOf(item.id) > -1}
                                                        draggedOver={state.draggedOverId === item.id}
                                                        item={item}
                                                        onClick={this.handleClickRoutePoint}
                                                        onEdit={this.handleEditRoutePoint}
                                                        onRemove={this.handleRemoveRoutePoint}
                                                        onMoveRoutePoint={props.onMoveRoutePoint}
                                                        onSetDraggedOverId={this.handleSetDraggedOverId}
                                                  />
                                                : <SettingsSiteRouteDialogRoutePointEdit
                                                        key={item.id}
                                                        mapLinkTemplate={props.mapLinkTemplate}
                                                        showAdditionalInformation={state.showAdditionalInformation}
                                                        item={item}
                                                        removedCheckIds={props.removedRoutePointCheckIds}
                                                        savedCheckIds={props.savedRoutePointCheckIds.get(state.editId) ?? []}
                                                        onOk={this.handleRoutePointEditOk}
                                                        onCancel={this.handleRoutePointEditCancel}
                                                  />
                                            )
                                        )}
                                        {state.showAddNewRoutePoint &&
                                            <SettingsSiteRouteDialogRoutePointAdd
                                                mapLinkTemplate={props.mapLinkTemplate}
                                                item={state.addNewRoutePointItem}
                                                onOk={this.handleRoutePointAddOk}
                                                onCancel={this.handleRoutePointAddCancel}
                                            />
                                        }
                                    </div>
                                    {!props.readOnly &&
                                        <div className="routePointsFooter"><a className="readOnlyLink" onClick={this.handleAddRoutePoint}>{Translations.AddRoutePoint}</a></div>
                                    }
                                </div>
                                <div className="col-5">
                                    <MapContainer ref={this.mapRef} bounds={state.mapBounds} boundsOptions={{ padding: [50, 50] }} zoom={11} scrollWheelZoom={false}>
                                        <LayersControl position="topright">
                                            <LayersControl.BaseLayer checked name="OpenStreetMap">
                                                <TileLayer
                                                    attribution="&copy; <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors"
                                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                />
                                            </LayersControl.BaseLayer>
                                            <LayersControl.BaseLayer name={"Google Maps - " + Translations.Satellite}>
                                                {/* @ts-expect-error: library types not correct */}
                                                <ReactLeafletGoogleLayer apiKey={appConfig.googleApiKey} type="satellite"/>
                                            </LayersControl.BaseLayer>
                                            <LayersControl.BaseLayer name={"Google Maps - " + Translations.Roadmap}>
                                                {/* @ts-expect-error: library types not correct */}
                                                <ReactLeafletGoogleLayer apiKey={appConfig.googleApiKey} type="roadmap"/>
                                            </LayersControl.BaseLayer>
                                            <LayersControl.BaseLayer name={"Google Maps - " + Translations.Terrain}>
                                                {/* @ts-expect-error: library types not correct */}
                                                <ReactLeafletGoogleLayer apiKey={appConfig.googleApiKey} type="terrain"/>
                                            </LayersControl.BaseLayer>
                                        </LayersControl>
                                        {state.locationRoutePoints.map((routePoint) =>
                                            <Marker
                                                key={routePoint.id}
                                                position={{ lat: routePoint.latitude, lng: routePoint.longitude }}
                                                icon={state.selectedIds.indexOf(routePoint.id) > -1 ? redMarkerIcon : blueMarkerIcon}
                                                eventHandlers={{
                                                    click: (ev) => {
                                                        this.selectWorkOrdersByCoordinateHash(routePoint.getLocationHash(), ev.layerPoint, ev.containerPoint, ev.originalEvent);
                                                    },
                                                }}
                                            />
                                        )}
                                    </MapContainer>
                                </div>
                            </div>
                        </div>
                    </div>}
                    buttons={[]}
                    onClose={this.handleCancelClick}
                />
            </div>
        );
    }
}