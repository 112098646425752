import {
    FormControlLabel,
    FormControlLabelProps,
    Switch,
    SwitchProps,
} from "@mui/material";
import React from "react";

interface Props extends SwitchProps {
    label?: string;
    labelSlotProps?: FormControlLabelProps["slotProps"];
}

export const MuiSwitch = (props: Props) => {
    const { label, labelSlotProps, ...rest } = props;

    return (
        <FormControlLabel
            label={label}
            slotProps={labelSlotProps}
            control={<Switch {...rest} />}
        />
    );
};
