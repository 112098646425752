import React, { useCallback, useMemo, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { Alert, Box, IconButton } from "@mui/material";
import { GridInitialStatePremium } from "@mui/x-data-grid-premium/models/gridStatePremium";
import {
    gridClasses,
    GridColDef,
    GridEventListener,
    GridInitialState,
    GridRenderCellParams,
    GridRowParams,
    GridToolbarContainer,
    GridToolbarExport,
    GRID_DETAIL_PANEL_TOGGLE_FIELD,
    GRID_TREE_DATA_GROUPING_FIELD,
    useGridApiRef,
    useGridApiContext,
    useGridSelector,
    gridRowsLookupSelector,
    gridDetailPanelExpandedRowIdsSelector,
    gridDetailPanelExpandedRowsContentCacheSelector,
    GridRowId,
    GridGroupNode,
    GRID_ROOT_GROUP_ID,
    GridGroupingColDefOverride,
    GridRowsProp,
} from "@mui/x-data-grid-premium";

import useEmployeeUiState from "../../hooks/useEmployeeUiState";
import { Base } from "../../framework/base";
import { StripedDataGrid } from "./muiDataGrid";
import { MuiSwitch } from "./muiSwitch";
import { Translations } from "../../models/translations";
import { useTranslation } from "react-i18next";

export interface GridRow {
    id: string;
    hierarchy: string[];
    date: string;
    totals: Record<string, number>;
}

interface DataGridProps {
    noRowsText: string;
    detailsComponent: (props: any) => JSX.Element;
    columns: GridColDef[];
    rows: GridRowsProp;
    groupingColDef: GridGroupingColDefOverride;
    getGroupLabel?: (id: string) => string;
    persistStateKey?: string;
    pinnedColumns?: string[];
}

export const GroupedDataGrid = ({
    noRowsText,
    detailsComponent: DetailsComponent,
    columns,
    rows,
    groupingColDef,
    getGroupLabel,
    persistStateKey,
    pinnedColumns,
}: DataGridProps) => {
    const [hideEmptyColumns, setHideEmptyColumns] = useState(true);
    const { t } = useTranslation();

    const {
        value: persistedGridState,
        setValue: setPersistedGridState,
        initialized: gridStateInitialized,
    } = useEmployeeUiState<GridInitialState>(persistStateKey, {});

    const apiRef = useGridApiRef();

    const persistGridState = useCallback(() => {
        if (apiRef?.current?.exportState) {
            const currentState = apiRef.current.exportState();
            setPersistedGridState({
                columns: {
                    orderedFields: currentState.columns.orderedFields,
                },
            });
        }
    }, [apiRef]);

    const columnVisibility = useMemo(
        () =>
            Object.fromEntries(
                columns.map((c) => [
                    c.field,
                    !hideEmptyColumns || rows.some((r) => r.totals[c.field]),
                ])
            ),
        [columns, hideEmptyColumns, rows]
    );

    const columnVisibilityModel = {
        [GRID_DETAIL_PANEL_TOGGLE_FIELD]: false,
        ...columnVisibility,
    };

    const aggregationModel = useMemo(
        () => Object.fromEntries([...columns.map((c) => [c.field, "sum"])]),
        [columns]
    );

    const initialState: GridInitialStatePremium = {
        aggregation: {
            model: aggregationModel,
        },
        columns: {
            ...persistedGridState?.current?.columns,
            columnVisibilityModel,
        },
    };

    const onRowClick: GridEventListener<"rowClick"> = (params) => {
        const rowNode = apiRef.current.getRowNode(params.id);
        if (!rowNode) return;

        if (rowNode.type === "group") {
            apiRef.current.setRowChildrenExpansion(
                params.id,
                !rowNode.childrenExpanded
            );
        } else if (rowNode.type === "leaf") {
            apiRef.current.toggleDetailPanel(params.id);
        }
    };

    const getDetailPanelContent = useCallback(
        (params: GridRowParams) => {
            return <DetailsComponent row={params.row} />;
        },
        [DetailsComponent]
    );

    const ExpandOrCollapseAllButton = () => {
        const [isExpanded, setIsExpanded] = useState(false);
        const apiRef = useGridApiContext();
        const expandedRowIds = useGridSelector(
            apiRef,
            gridDetailPanelExpandedRowIdsSelector
        );
        const rowsWithDetailPanels = useGridSelector(
            apiRef,
            gridDetailPanelExpandedRowsContentCacheSelector
        );
        const noDetailPanelsOpen = expandedRowIds.length === 0;
        const expandOrCollapseAll = () => {
            const dataRowIdToModelLookup = gridRowsLookupSelector(apiRef);
            const groups =
                apiRef.current.getRowNode<GridGroupNode>(
                    GRID_ROOT_GROUP_ID
                )!.children;
            if (!isExpanded) {
                if (groups.length > 0) {
                    for (const group of groups) {
                        apiRef.current.setRowChildrenExpansion(group, true);
                    }
                }

                const allRowIdsWithDetailPanels: GridRowId[] = Object.keys(
                    rowsWithDetailPanels
                ).map((key) =>
                    apiRef.current.getRowId(dataRowIdToModelLookup[key])
                );

                apiRef.current.setExpandedDetailPanels(
                    allRowIdsWithDetailPanels
                );
                setIsExpanded(true);
            } else {
                if (groups.length > 0) {
                    for (const group of groups) {
                        apiRef.current.setRowChildrenExpansion(group, false);
                    }
                }
                apiRef.current.setExpandedDetailPanels([]);
                setIsExpanded(false);
            }
        };
        const Icon = noDetailPanelsOpen ? UnfoldMoreIcon : UnfoldLessIcon;
        return (
            <IconButton
                size="small"
                tabIndex={-1}
                onClick={expandOrCollapseAll}
                aria-label={noDetailPanelsOpen ? "Expand All" : "Collapse All"}
            >
                <Icon fontSize="inherit" />
            </IconButton>
        );
    };

    const CustomToolbar = () => {
        return (
            <GridToolbarContainer>
                <GridToolbarExport
                    csvOptions={{
                        utf8WithBom: true,
                    }}
                />
                <ExpandOrCollapseAllButton />
                <Box flexGrow={1} />
                <MuiSwitch
                    checked={hideEmptyColumns}
                    onChange={() => setHideEmptyColumns(!hideEmptyColumns)}
                    label={t("workTime.hideEmptyColumns")}
                    size="small"
                    labelSlotProps={{ typography: { variant: "subtitle2" } }}
                />
            </GridToolbarContainer>
        );
    };

    if (!gridStateInitialized) return null;

    return (
        <StripedDataGrid
            columnVisibilityModel={columnVisibilityModel}
            onColumnOrderChange={persistGridState}
            initialState={initialState}
            slots={{
                noRowsOverlay: () => (
                    <Alert severity="info" sx={{ p: 2 }}>
                        {noRowsText}
                    </Alert>
                ),
                toolbar: CustomToolbar,
            }}
            apiRef={apiRef}
            onRowClick={onRowClick}
            disableColumnMenu
            rowSelection={false}
            density="compact"
            hideFooter
            columns={columns}
            rows={rows}
            disableColumnResize
            treeData
            getTreeDataPath={(row: GridRow) => row.hierarchy}
            groupingColDef={{
                hideDescendantCount: true,
                valueFormatter: (data) => {
                    // for exports
                    const value = data?.value ?? Translations.Total;
                    return value;
                },
                renderCell: (params: GridRenderCellParams<GridRow>) => (
                    <GroupingCol
                        params={params}
                        getGroupLabel={getGroupLabel}
                    />
                ),
                ...groupingColDef,
            }}
            pinnedColumns={{
                left: [GRID_TREE_DATA_GROUPING_FIELD, ...(pinnedColumns ?? [])],
            }}
            getDetailPanelContent={getDetailPanelContent}
            getDetailPanelHeight={() => "auto"}
            getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
            }
            sx={{
                width: "100%",
                // Hide the "sum" label on headers
                [`& .${gridClasses.aggregationColumnHeaderLabel}`]: {
                    display: "none",
                },
                // disable cell selection style
                ".MuiDataGrid-cell:focus": {
                    outline: "none",
                },
                "& .MuiDataGrid-row:hover": {
                    cursor: "pointer",
                },
                // This is needed to make sticky positioning work for the detail panel
                "& .MuiDataGrid-detailPanel": {
                    overflow: "visible",
                },
                // Needed to display "no rows" overlay correctly
                ".MuiDataGrid-overlayWrapper": {
                    height: "auto !important",
                },
                ".MuiDataGrid-overlayWrapperInner": {
                    height: "auto !important",
                },
            }}
        />
    );
};

interface GroupingColProps {
    params: GridRenderCellParams<GridRow>;
    getGroupLabel: (id: string) => string;
}

const GroupingCol = (props: GroupingColProps) => {
    const { params, getGroupLabel } = props;
    const { value } = params;

    if (params?.rowNode?.type === "group") {
        const open = params.rowNode?.childrenExpanded ?? false;
        return (
            <div>
                {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                {getGroupLabel ? getGroupLabel(value) : ""}
            </div>
        );
    } else if (params?.rowNode?.type === "leaf") {
        return (
            <Box ml={2}>
                {Base.dayjsToDateStrWithWeekday(params?.row?.date)}
            </Box>
        );
    } else if (params?.rowNode?.type === "pinnedRow") {
        return <div className="font-weight-bold">{Translations.Total}</div>;
    }
};
