import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

interface SectionHeaderProps {
    children: JSX.Element | JSX.Element[];
}

export const SectionHeader = ({ children }: SectionHeaderProps) => {
    return (
        <AppBar position="relative" sx={{ bgcolor: "primary.dark" }}>
            <Toolbar variant="dense">{children}</Toolbar>
        </AppBar>
    );
};
