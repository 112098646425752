import * as React from "react";
import { Switch, RouteComponentProps, Redirect } from "react-router-dom";
import { PrivateRoute } from "../app/privateRoute";
import { ReportingWorkTime } from "./reportingWorkTime";
import { VehiclesMain } from "./vehicle/vehiclesMain";
import { EmployeesMain } from "./employee/employeesMain";

export const ReportingBase = (props: RouteComponentProps) => {
    return (
        <Switch>
            <PrivateRoute
                path={`${props.match.path}/worktime`}
                component={ReportingWorkTime}
            />
            <PrivateRoute
                path={`${props.match.path}/vehicles`}
                component={VehiclesMain}
            />
            <PrivateRoute
                path={`${props.match.path}/employees`}
                component={EmployeesMain}
            />
            <Redirect to={`${props.match.path}/worktime`} push />
        </Switch>
    );
};
